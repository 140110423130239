// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Images/Home/BG-2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 .policylogo{
  max-width: 120px;
 }
 .backimg{
	background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center;
	background-size: cover;
}
.instcontainer{
  text-transform: capitalize;
}
 
.privacy{
  height: calc(100vh - 100px);
  overflow: auto;
}
/* .insttxtDiv{
  box-shadow: 1px 0 5px 1px var(--boxshadow);
  padding: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  max-width: 1000px;
} */
 .privacyheader {
  background: #353535;
  height: auto;
  padding: 20px 50px;
  position: sticky;
  top: 0;
  z-index: 10000;
  box-shadow: 0 0 10px 0 var(--transgray);
}  
.insttext{
  font-size: 17px;
}

`, "",{"version":3,"sources":["webpack://./src/Assets/Css/policy.css"],"names":[],"mappings":";CACC;EACC,gBAAgB;CACjB;CACA;CACA,iEAAwD;CACxD,sBAAsB;AACvB;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,cAAc;AAChB;AACA;;;;;;;;;GASG;CACF;EACC,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,MAAM;EACN,cAAc;EACd,uCAAuC;AACzC;AACA;EACE,eAAe;AACjB","sourcesContent":["\n .policylogo{\n  max-width: 120px;\n }\n .backimg{\n\tbackground: url('../Images/Home/BG-2.png') center center;\n\tbackground-size: cover;\n}\n.instcontainer{\n  text-transform: capitalize;\n}\n \n.privacy{\n  height: calc(100vh - 100px);\n  overflow: auto;\n}\n/* .insttxtDiv{\n  box-shadow: 1px 0 5px 1px var(--boxshadow);\n  padding: 20px;\n  border-radius: 5px;\n  -webkit-border-radius: 5px;\n  -moz-border-radius: 5px;\n  -ms-border-radius: 5px;\n  -o-border-radius: 5px;\n  max-width: 1000px;\n} */\n .privacyheader {\n  background: #353535;\n  height: auto;\n  padding: 20px 50px;\n  position: sticky;\n  top: 0;\n  z-index: 10000;\n  box-shadow: 0 0 10px 0 var(--transgray);\n}  \n.insttext{\n  font-size: 17px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
