// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buyNowCarddiv {
  position: relative;
  background: #fff;
  z-index: 1;
}
.offerimg {
  width: 20px;
}

.buyNowCarddiv input {
  padding: 4px;
  padding-left: 30px;
  background: transparent;
}
.buyNowCarddiv input:focus-visible {
  border: 0;
  outline: 0;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/buyNowcard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":[".buyNowCarddiv {\n  position: relative;\n  background: #fff;\n  z-index: 1;\n}\n.offerimg {\n  width: 20px;\n}\n\n.buyNowCarddiv input {\n  padding: 4px;\n  padding-left: 30px;\n  background: transparent;\n}\n.buyNowCarddiv input:focus-visible {\n  border: 0;\n  outline: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
