// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.matching-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .states, .capitals {
    display: flex;
    justify-content: space-around;
    width: 600px;
    margin: 10px 0;
  }
  
  .state, .capital {
    width: 200px;
    padding: 20px;
    text-align: center;
    border: 2px solid #000;
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .state.selected {
    background-color: #d5e8d4;
  }
  
  .capital {
    background-color: #d0e1f9;
  }
  
  .line {
    margin: 10px 0;
  }
  
  .score {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/Assets/Css/MatchingPairs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB","sourcesContent":[".matching-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .states, .capitals {\n    display: flex;\n    justify-content: space-around;\n    width: 600px;\n    margin: 10px 0;\n  }\n  \n  .state, .capital {\n    width: 200px;\n    padding: 20px;\n    text-align: center;\n    border: 2px solid #000;\n    cursor: pointer;\n    background-color: #f5f5f5;\n    border-radius: 8px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .state.selected {\n    background-color: #d5e8d4;\n  }\n  \n  .capital {\n    background-color: #d0e1f9;\n  }\n  \n  .line {\n    margin: 10px 0;\n  }\n  \n  .score {\n    margin-top: 20px;\n    font-size: 24px;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
