import React, { useState, useEffect } from 'react';

import '../App.css';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { 
  totalAnsweredCountForReview,
  totalNotAnsweredCountForReview
} from '../Utils/utils';

function TestAlertPopUp(props:any) {
  const [open, setOpen] = useState(true); 
 
 const questionData = props.examDetails?.questionsData;
 

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(
  //     getState((dataState) => {
  //       setStateData(dataState);
  //     })
  //   );
  // }, []);
 
  const handleClose = () => {
    setOpen(false);
    props.handleAutoSubmitExam()
  }; 
  

 
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
      <div className="modeldiv">
                <h5 className="colorblue fw-bold text-center">{props?.examDetails?.name}</h5>
                <h6 className="text-center w-75 m-auto my-4">
                Your Time is over please click "Submit" to complete the exam
                </h6>
                <div className="row justify-content-center">
                  {props.examDetails?.sections.map((section:any, index:any) => {
                    
                    let totalAnsweredCountForReviewPerSection = totalAnsweredCountForReview(questionData, section?._id)
                    let totalNotAnsweredCountForReviewPerSection = totalNotAnsweredCountForReview(questionData, section?._id)
                    let totalQuestionsCountPerSection:any = totalAnsweredCountForReviewPerSection + totalNotAnsweredCountForReviewPerSection;
                    totalQuestionsCountPerSection = totalQuestionsCountPerSection < 10 ? `0${totalQuestionsCountPerSection}` : `${totalQuestionsCountPerSection}`
                    return (
                      <div key={section?._id} className="col-6 col-lg-4 confirmReview ">
                        <span className="fw-bold mb-2"> 
                        </span>
                        <div className="Reviewdetails rounded">
                          <span className="fw-bold d-block mb-2 text-center colorgreen">
                            {section.name}
                          </span>
                          <span>Total Question: {totalQuestionsCountPerSection}</span>
                          <span>
                            Total Answered:{' '}
                            {totalAnsweredCountForReviewPerSection < 10
                              ? `0${totalAnsweredCountForReviewPerSection}`
                              : `${totalAnsweredCountForReviewPerSection}`}
                          </span>
                          <span>
                            Not Answered:{' '}
                            {totalNotAnsweredCountForReviewPerSection < 10
                              ? `0${totalNotAnsweredCountForReviewPerSection}`
                              : `${totalNotAnsweredCountForReviewPerSection}`}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                
                <div className="d-flex justify-content-center mt-5">
                  <button type="button"  className="btn btn-primary px-5" onClick={handleClose}>
                    submit
                  </button>
                </div>
              </div>
      </Fade>
    </Modal>
    
  );
}

export default TestAlertPopUp;
