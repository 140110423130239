

import parser from "html-react-parser";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
function BlogCard(props:any) {  
  const navigate = useNavigate();
  const goToBlogDetails=(id:string)=>{
    navigate( `/BlogDetails/${id}`)
  }  
  return (
    <div className='col-12 col-sm-6 col-md-4 col-lg-4 mb-3' key={props?.data?.blogId}>
      <div className='p-3 h-100'>
        <img src={props?.data?.blogImage} alt={props?.data?.title} className='w-100' />
        <div className='mt-3'>{props?.data?.readingTime} MIN READ</div>
        <div className='programHeading fw-bold mt-3 mb-2 lh-sm'>{props?.data?.title}</div>
        <div className='blogContent font16'>{parser(props?.data?.content)}</div>
        <div className="mt-4">
          <button className="contactUSbtn py-2 px-4 font16 rounded" onClick={()=>goToBlogDetails(props?.data?.blogId)}>Know more</button>
        </div>
      </div>
    </div>
  );
}

export default memo(BlogCard);
