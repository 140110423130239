import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Buttonnormal from '../Atom/buttonnormal';
//import { examList } from '../Utils/dataConstants';


function SessionPopup(props: any) {
  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        >
        <Fade in={props.open}>
           <div className="modeldiv p-5">
           
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className='boldfont colorblue mb-0'>Select Batch Timing</h5>
            <ClearOutlinedIcon
              className="colorblue fs-3 rounded-circle  cursor float-end"
              onClick={props.handleClose}
            />
            </div>
              <div className='mt-4 pe-1'>
                {props?.sessionData?.map((item:any,index:any)=>(
                  <div className='d-flex justify-content-between mb-3' key={item?._id}>
                    <div>{item?.name}</div>
                    <input type='radio' name="session" value={item._id} onChange={props.handleChange}/>
                  </div>
                ))}                
              </div>
              <div className='text-center mt-5'>
                <Buttonnormal name={"Save and Proceed"} disabled={props?.SelectedSession===undefined || props?.SelectedSession===""?true:false} handleClick={props?.addToCart}/>
              </div>
            </div>
          
        </Fade>
      </Modal>
    </div>
  );
}

export default SessionPopup;
