import DatePicker from "react-horizontal-datepicker";

function LecturedatePicker(props) {
  return (
      <DatePicker
            className="DatePicker_dayLabel__2trhR  DatePicker_dateLabel__xMZ2T"
            getSelectedDay={props.selectedDay}
            labelFormat={'MMMM'}
            color={'#016fe9'}
          />
  );
}

export default LecturedatePicker;
