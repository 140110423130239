import { EventKey } from '@restart/ui/esm/types';
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { getCssClassNameFromQuestionType } from '../Utils/utils';
import ExamStatus from './examStatus';

function HorizontalTab(props: { sections: any; handleClick?: any; section?: any; examData?: any; questionData: any }) {
  const { sections, section, examData } = props;

  const [key, setKey] = useState<any>(0);

  const [questionData, setQuestionData] = useState(examData?.questionData);

  const [sectionQuestionData, setSectionQuestionData] = useState(
    questionData?.filter((element: { section: { sectionId: any; }; }) => element.section?.sectionId === examData.sections[0]._id)
  );
  const [selectedSection, setSelectedSection] = useState(section);

  const sectionTypeCount = (type: number) => {
    const questionSet = sectionQuestionData;
    let count = 0;
    questionSet?.forEach((question: { ansType: number; }) => {
      if (question.ansType == type) count++;
    });
    return count;
  };

  const handleChange = (currentSectionIndex: any) => {
    setKey(currentSectionIndex);
    let index = parseInt(currentSectionIndex);
    const filteredSectionQuestionData = questionData.filter(
      (element: { section: { sectionId: any; }; }) => element?.section?.sectionId === examData?.sections[index]._id
    );
    setSectionQuestionData(filteredSectionQuestionData);
    setSelectedSection(examData.sections[index]);
  };

  return (
    <Tabs
      id="tinyTab"
      className="tabcontent"
      activeKey={key}
      onSelect={(e) => handleChange(e)}
      fill
      variant="pills">
      {sections?.map((section: { _id: React.Key ; name: string ; totalQuestions: string ; maxAttempt: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }, index: EventKey | undefined) => {
        return (
          <Tab key={section?._id} eventKey={index} title={section.name}>
            <div>
              <div className="d-flex justify-content-between pt-3 pe-2">
                <div className="d-flex">
                  <h6 className="fw-bold colorblue me-3">{section.name}</h6>
                  <span className="insttxt colorgrey">
                    (Total Questions: {examData?.isSectionWise ? section?.totalQuestions : examData.totalQuestion})

                  </span>
                </div>
                {examData?.isSectionWise ? <div className="insttxt colorblue">Max Attempt:{section.maxAttempt}</div>
                  :
                  <div className="insttxt colorblue">{""}</div>}
              </div>
              <div>
                <ExamStatus
                  answered={
                    sectionTypeCount(1) < 10 ? `0${sectionTypeCount(1)}` : `${sectionTypeCount(1)}`
                  }
                  notAnswered={
                    sectionTypeCount(2) < 10 ? `0${sectionTypeCount(2)}` : `${sectionTypeCount(2)}`
                  }
                  marked={
                    sectionTypeCount(3) < 10 ? `0${sectionTypeCount(3)}` : `${sectionTypeCount(3)}`
                  }
                  markAndSave={
                    sectionTypeCount(4) < 10 ? `0${sectionTypeCount(4)}` : `${sectionTypeCount(4)}`
                  }
                  notVisited={
                    sectionTypeCount(0) < 10 ? `0${sectionTypeCount(0)}` : `${sectionTypeCount(0)}`
                  }
                  sections={props.sections}
                  selectedSection={selectedSection}
                  questionData={questionData}
                  isSection
                />
                <div className="d-flex flex-wrap calheight align-content-start">
                  {sectionQuestionData.map((question: { _id: React.Key; ansType: any; questionNumber: string }, qindex: any) => {
                    return (
                      <div
                        key={question?._id}
                        className={`examno cursor ${getCssClassNameFromQuestionType(question.ansType)}`} onClick={() => props?.handleClick(question.questionNumber, index, qindex)}>
                        {question.questionNumber}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default HorizontalTab;
