
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Buttonnormal from '../Atom/buttonnormal';
import Lottie from 'react-lottie';
import payment_failed from '../Assets/Images/json/payment_failed.json';
import { useNavigate } from 'react-router-dom';
//import { examList } from '../Utils/dataConstants';


function PaymentErrorPopup(props: any) {
  const navigate = useNavigate(); 
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: payment_failed,
    renderer: 'svg'
  };
  const gotoOrder=()=>{
    navigate("/PlaceOrder")
  }
  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        >
        <Fade in={props.open}>
        <div className="modeldiv p-5 text-center">
          <Lottie options={defaultOptions} height={130} width={200}></Lottie>
          <p className="text-center colorblue font24 mt-3">
            <b>Payment Failed!</b>
          </p>
          <div className='text-center mt-5'>
                <Buttonnormal name={"Retry"} handleClick={gotoOrder}/>
              </div>
        </div>          
        </Fade>
      </Modal>
    </div>
  );
}

export default PaymentErrorPopup;
