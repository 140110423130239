import React, { useEffect, useState } from 'react';
import localImages from '../Constant/localImagesNew';
import Footer from '../Component/footer';
import { useNavigate } from 'react-router-dom';
import HeaderHome from '../Component/headerHome';
import Sidebar from '../Module/sidebar';
import { useSelector } from 'react-redux';
import { SidebarDataFreeUser, SidebarDataPaidUser } from '../Module/sidebarData';
import '../Assets/Css/blog.css';
import BlogCard from '../Module/blogCard';
import { getBlog } from '../Redux/Actions/AuthAction';
import { useAppDispatch } from '../hooks';
export default function Blog() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isHidden, setisHidden] = useState<boolean>(true);
    const [blogData, setblogData] = useState([]);
  
    const {ACCESS_TOKEN,hasActivePaidPlan} = useSelector((state:any) => ({
        ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
        hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
      }));
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getBlog(
            (data: any) => {
                setblogData(data)
            },
            () => { })
        );
    }, []);
    
    return (
        <div>
            <HeaderHome isHidden={isHidden} />
            <div className="d-block d-md-none">
                <Sidebar data={hasActivePaidPlan === true ? SidebarDataPaidUser : SidebarDataFreeUser} />
            </div>
            {/*************Banner************* */}
            <div className='position-relative blogBannerContainer'>
                <img src={localImages?.ccLeftArrow} className='ccLeftArrow' alt='learnSolleft' />
                <img src={localImages?.ccRightArrow} className='ccRightArrow' alt='learnSolright' />
                <div className='width800 mx-auto px-4'>
                    <h1 className='headingFont text-center'>Blogs</h1>
                    <div className='font16 mt-4 text-center px-4'>
                        Explore insightful stories, unlocking a wealth of knowledge
                    </div>
                </div>
            </div>

            <div className='row commonPad'>
                {
                    blogData?.map((item: any, index) => {                       
                            return (
                                <BlogCard data={item} />
                            )
                    })
                }
            </div>

            {/***************Footer*************** */}
            <Footer />

        </div>
            )
}
