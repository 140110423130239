
import { memo } from 'react';
import localImages from '../Constant/localImagesNew';


function BookSession(props: any) {
  return (
    <div className='boxshadow bookSessionbox'>
      <div className='row'>
        <div className="col-md-6 d-flex flex-column justify-content-center">
          <div className='bookSessionHeading'>Book your session now</div>
          <div className='bookSessionSubText'>One of our Academic Counsellor will get in touch with you to discuss further.</div>
          <div className='mt-5'>
            <button className='signBtn px-5' onClick={props?.clickEvent}>Book Now</button>
          </div>
        </div>
        <div className="col-md-6">
          <img src={localImages?.bookSession} alt='Book Session' className='w-100' />
        </div>
      </div>
    </div>
  );
}

export default memo(BookSession);
