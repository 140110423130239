// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additionalPackagePopup{
    max-width: 1000px;
    max-height: calc(100vh - 50px);
}
.model.additionalPAckageModel{
    z-index: 1000 !important;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/additionalPackagePopup.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,8BAA8B;AAClC;AACA;IACI,wBAAwB;AAC5B","sourcesContent":[".additionalPackagePopup{\n    max-width: 1000px;\n    max-height: calc(100vh - 50px);\n}\n.model.additionalPAckageModel{\n    z-index: 1000 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
