import { useEffect, useState } from "react";
import { useAppDispatch } from "../hooks";
import { useSelector } from "react-redux";
import {
  getOrderHistory,
} from "../Redux/Actions/AuthAction";
import Lottie from "react-lottie";
import emptyorder from '../Assets/Images/json/order history.json';
import { useNavigate } from "react-router-dom";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import moment from "moment";
import { AuthReducerModal } from "../Modals"; 
function OrderHistory() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [orderHistory, setorderHistory] = useState([]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyorder,
    renderer: 'svg'
  };
  const {
    studentId
  } = useSelector((state: { AuthReducer: AuthReducerModal }) => ({
    studentId: state?.AuthReducer?.studentId,

  }));

  useEffect(() => {
    dispatch(
      getOrderHistory(
        studentId,
        (data: any) => {
          setorderHistory(data?.data)
        },
        () => { }
      )
    );
  }, []);

  const routeOrderHistory = (orderId: any,paymentId:number) => {
    navigate('/User/OrderDetails', { state: { orderId: orderId,paymentId:paymentId } });
  };
  const courseroute = () => {

  };
  function goToLink(val:string): void {
    navigate('/'+val);
  }
  return (
    <div className="rightPanel">
      <h4 className="fw-bold mb-5">Order History</h4>
      <div className="row">
        {orderHistory?.length !== 0 ?
          orderHistory?.map((c: any, index: any) => {
           
            return (
              <div key={index.toString()} className="col-md-5 ">
                {
                  <div
                    className="card boxshadow p-3 mt-2 me-md-3 cursor border-0 mb-2"
                    onClick={() => {
                      routeOrderHistory(c?.orderId,c?.pymentId)
                    }}>

                    <div className="d-flex">
                      {c?.status === "success" ? (
                        <span className="successcolor">
                          <TaskAltIcon />
                        </span>
                      ) : (
                        <span>
                          <CancelOutlinedIcon style={{ color: 'red' }} />
                        </span>
                      )}
                      <div className="ms-2 ellipsis" style={{ lineHeight: 1.2 }}>
                        {c?.packageNames?.map((item: any, index: number) => (
                          <span key={index.toString()} className="">
                            {/* <b className="font12">{item.courseId.name}</b> */}
                            <span className=''>{index > 0 ? '\u002C ' : ''}{item}</span>
                          </span>
                        ))}
                      </div>
                    </div>
                    <p className="font12 mb-0 mt-3 text-secondary">
                    Purchase Date {moment(c?.purchaseDate).format('DD MMM YYYY')} 
                    </p>
                    <p className="font12 mb-0 text-secondary">Order No: {c?.orderId}</p>
                    <p className="font12 mb-0 text-secondary">Amount Paid:  {c?.totalAmount}</p>
                    <p className="font12 mb-0 text-secondary">{c?.planName} - {c?.lectureCount} Lecture</p>
                    <p className="font12 mb-0 text-secondary">Subscription Start date :{moment(c?.subscriptionStartDate).format('DD MMM YYYY')}  </p>
                    <p className="font12 mb-0 text-secondary">Subscription End date :{c?.subscriptionEndDate  ?<> {moment(c?.subscriptionEndDate).format('DD MMM YYYY')} </> :"-"}</p>
                    {c?.remainingAmount?<p className="font12 mb-0 text-secondary">Reaming Amount :{c?.remainingAmount}</p>:null}
                    <div className="d-flex flex-column align-items-end">
                      {/* <button
                      type="button"
                      className="border-0 greentxt align-self-end"
                      onClick={() => {
                        routeOrderHistory(c?.status);
                      }}
                       >
                      Successful
                    </button> */}
                      {/* {c?.status === 2 ?<button type="button" className="btn btn-primary py-1 font12 " onClick={CourseDetails}>Extend Subscription</button>:""} */}
                      {c?.status === "success" ? (
                        <button
                          type="button"
                          onClick={() => {
                            routeOrderHistory(c?.orderId,c?.pymentId)
                          }}
                          className="border-0 greentxt align-self-end">
                          Successful
                        </button>
                      ) : (
                        <button
                          type="button"
                          className=" border-0 redtxt align-self-end"
                          onClick={() => {
                            routeOrderHistory(c?.orderId,c?.pymentId)
                          }}>
                          Payment Failed
                        </button>
                      )}
                    </div>
                  </div>
                }
              </div>
            )
          })
          :
          <div className="col-md-10 justify-content-center bgwhite boxshadow py-5 ">
            <Lottie options={defaultOptions} height={130} width={200}></Lottie>
            <p className="text-center">
              <b>No orders Yet</b>
            </p>
            <div className='d-flex justify-content-center'>
              <button
                type="button"
                className="btn btn-primary py-2 px-4 font12 me-md-1"
                onClick={()=>goToLink('User/Course')}
              >
                Browse Courses
              </button>
            </div>
          </div>}
      </div>


    </div>
  );
}

export default OrderHistory;
