import { useEffect, useRef, useState } from 'react';
import '../App.css';
import '../Assets/Css/cart.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../Component/header';
import BuyNowCard from '../Module/buyNowCard';
import {  getCartList } from '../Redux/Actions/examActions';
import { useSelector } from 'react-redux';
import { AuthReducerModal } from '../Modals';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { validateEmailAddress, validateIsNumberOnly } from '../Utils/validators';
import { useAppDispatch } from '../hooks';
import { getCountry, getUserData, updateMoreValues, updateProfile } from '../Redux/Actions/AuthAction';
import HeaderHome from '../Component/headerHome';

function Address() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cartList, setCartList] = useState<any>([])
  const [totalPrice, settotalPrice] = useState("");  
  const location = useLocation();
  const { isAdditionalPackage } = location?.state;
  const {
    firstName,
    lastName,
    email,
    mobileNo,
    parentMobile,
    parentEmail,
    studentId,
    countryCode
  } = useSelector((state: { AuthReducer: AuthReducerModal }) => ({
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    mobileNo: state?.AuthReducer?.mobileNo,
    parentMobile: state?.AuthReducer?.parentNumber,
    parentEmail: state?.AuthReducer?.parentEmail,
    studentId: state?.AuthReducer?.studentId,
    countryCode:state?.AuthReducer?.countryCode
  }));
  const [emailAddress, setEmailAddress] = useState(email);
  const [pmob, setpmob] = useState(parentMobile?parentMobile:"");
  const [pemail, setpemail] = useState(parentEmail?parentEmail:"");

  const [country, setCountry] = useState("");
  const { userCountryCallingCode } = useSelector((state: any) => ({
    userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode
  }));
  useEffect(() => {
    dispatch(getCountry(
      (data: any) => {
        const filterCountry = data?.filter((item: any) => item?.phonecode == userCountryCallingCode?.substring(1))
        setCountry(filterCountry[0]?._id)
      },
      () => { })
    );

  }, [userCountryCallingCode]);
  useEffect(() => {
    if(country){
      getCart();
    }
  }, [country]);
 

  var emails =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function allemail(inputtxt:string) {
  if (inputtxt.match(emails)) {
    return true;
  } else {
     dispatch(showErrorSnackbar('Please input valid email'));
    return false;
  }
}

  function getCart() {
    setCartList([]);
    settotalPrice("0")
    const postData={countryId:country}
    if(isAdditionalPackage){
      postData["customPackage"]=true
    }
    dispatch(
      getCartList(
        postData,
        (res: any) => {
          setCartList(res?.cart);
          settotalPrice(res?.fullAmount)
        },
        () => { }
      )
    );
  }

  const handleCheckout = () => {
    const payload: any = {
      parentEmail: pemail,
      parentNumber: pmob,
    };
   
    dispatch(
      updateProfile(
        payload,
        studentId,
        (data: any) => {
          getData();
          navigate('/PlaceOrder',{state:{isExtended:false,isAdditionalPackage:isAdditionalPackage}});
        },
        () => { }
      )
    );
    
  };
  const getData = () => {
    dispatch(
      getUserData(
        studentId,
        (data: any) => {
          if (data) {
            const { parentNumber, parentEmail } = data?.data[0]
            dispatch(updateMoreValues({ parentNumber: parentNumber }));
            dispatch(updateMoreValues({ parentEmail: parentEmail }));

          }
        },
        () => { }
      )
    );
  }
  function changepMob(e: React.ChangeEvent<HTMLInputElement>): void {
    if (!validateIsNumberOnly(e.target.value)) {
      setpmob(e.target.value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid mobile number"));
    }
  }
  const validation=()=>{
    return (pmob==="" || pmob?.length!==10 || pemail==="" || !validateEmailAddress(pemail))
  }
  return (
    <div>
       <HeaderHome isHidden={false} />
      <div className="container ContainerDiv pt-4">
        <div className="row">
        <div className="row">
            <div className="col-md-6 Addressleftside mt-3 pt-1 mx-auto">
            <h3 className="fw-bold mb-5 ">Mailing Address</h3>
              <div className="boxshadow p-3 rounded bgwhite mb-3">
                <p className="fw-bold mb-4 font16">Personal Details</p>
                <div className="row">
                  <div className="col-12 ps-0">
                    <div className="d-flex row font12">
                      <div className="col-sm-6 ps-0">
                        <label className="mb-2 d-block font16">First Name</label>
                        <input
                          className="form-control p-2"
                          type="text"
                          placeholder={firstName}                       
                          disabled/>
                      </div>
                      <div className="col-sm-6 ps-0">
                        <label className="mb-2 d-block font16">Last Name</label>
                        <input
                          className="form-control p-2"
                          type="text"
                          placeholder={lastName}                        
                          disabled/>
                      </div>
                      <div className="col-sm-12 mt-2 ps-0">
                        <label className="mb-2 d-block font16">Mobile Number</label>
                        <input
                          className="form-control p-2"
                          type="text"
                          placeholder={`${countryCode}-${mobileNo}`}                          
                          disabled/>                        
                      </div>
                      <div className="col-sm-12 mt-2 ps-0 position-relative">
                        <label className="mb-2 d-block font16">Email Address</label>
                         <input
                          className="form-control p-2"
                          type="email"
                          placeholder="Enter Mail ID"                       
                          value={emailAddress}
                          disabled/>
                      </div>
                      {!emailAddress ? (
                        <div className="ps-0 font10 text-danger">Please Enter Email ID </div>
                      ) : (
                        ''
                      )}

                      <div className="col-sm-12 mt-2 ps-0">
                        <label className="mb-2 d-block font16">Parent Mobile Number</label>
                        <input
                          className="form-control p-2"
                          type="email"
                          placeholder="Enter Parent Mobile Number"                       
                          value={pmob}
                          maxLength={10}
                          onChange={changepMob}/>
                        
                      </div>
                      <div className="col-sm-12 mt-2 ps-0 position-relative">
                        <label className="mb-2 d-block font16">Parent Email Address</label>
                        <input
                          className="form-control p-2"
                          type="email"
                          placeholder="Enter Mail ID"                       
                          value={pemail}
                          maxLength={40}
                          onChange={(e) => setpemail(e.target.value)}/>
                      </div>
                      {!emailAddress ? (
                        <div className="ps-0 font10 text-danger">Please Parent Enter Email ID </div>
                      ) : (
                        ''
                      )}                      
                    
                    </div>
                  </div>
                </div>
              </div>
              <BuyNowCard disabled={validation()} btnname="Checkout" setOnClick={handleCheckout} amount={totalPrice} count={cartList?.length} />
            </div>
            
          </div>
        </div>
      </div>
     
    </div>
  );
}

export default Address;
