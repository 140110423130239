export function disableCtrlKeyCombination(e:any) {
  //list all CTRL + key combinations you want to disable
  let forbiddenKeys = new Array("a", "n", "c", "x", "v", "j", "w", "s", "j");
  let key:any;
  let isCtrl:any;

  if (window.event) {
    key = (window as any).event.keyCode; //IE
    if ((window as any).event.ctrlKey) isCtrl = true;
    else isCtrl = false;
  } else {
    key = e.which; //firefox
    if (e.ctrlKey) isCtrl = true;
    else isCtrl = false;
  }

  //if ctrl is pressed check if other key is in forbidenKeys array
  if (isCtrl) {
    for (let i = 0; i < forbiddenKeys.length; i++) {
      //case-insensitive comparation
      if (
        forbiddenKeys[i].toLowerCase() == String.fromCharCode(key).toLowerCase()
      ) {
        return false;
      }
    }
  }
  return true;
}

// code for ctrl + disable ends

// code for disabling right click

let message = "";

export function clickIE() {
  if (document.all) {
    message = "Right click not allowed"
    return false;
  }
}

export function clickNS(e:any) {
  // if (document.layers || (document.getElementById && !document.all)) {
    if (e.which == 2 || e.which == 3) {
      message = "Right click not allowed"
      return false;
    }
  // }
}

// if (document.layers) {
//   document.captureEvents(Event.MOUSEDOWN);
//   document.onmousedown = clickNS;
// } else {
//   document.onmouseup = clickNS;
//   document.oncontextmenu = clickIE;
// }

// document.oncontextmenu = new Function("return false");
