// FileName: App.js

import React, { useState } from 'react';
import '../Assets/Css/calclayout1.css';
import * as math from 'mathjs';

function Calculator1(props) {
  const [expression, setExpression] = useState('');
  const [screenVal, setScreenVal] = useState('');

  function handleChange(e) {
    setExpression(e.target.value);
  }

  function handleClick(input) {
    setExpression((prevExpression) => prevExpression + input);
  }

  function calculate() {
    try {
      const allVariables = {
        pi: Math.PI,
        e: Math.E,
        // Add factorial function
        fact: math.factorial,
        sin: Math.sin,
        cos: Math.cos,
        tan: Math.tan,
        asin: Math.asin,
        acos: Math.acos,
        atan: Math.atan
      };

      const result = math.evaluate(expression, allVariables);
      if (typeof result === 'number' && !isNaN(result)) {
        setScreenVal(Number(result).toFixed(4));
      } else {
        setScreenVal('Error: Invalid expression');
      }
    } catch (error) {
      setScreenVal('Error: Invalid expression');
    }
  }

  function clearScreen() {
    setExpression('');
    setScreenVal('');
  }

  function backspace() {
    const newExpression = expression.slice(0, -1);
    setExpression(newExpression);
  }

  return (
    <div className="calc-body">
      <div id="helptopDiv">
        <span>Scientific Calculator</span>
      </div>
      <div
        className="calc_close"
        id="closeButton"
        onClick={() => props?.setIsOpenCalc(false)}></div>
      <div className="input-section">
        <input className="screen" type="text" value={expression} onChange={handleChange} readOnly />
        <input type="text" id="keyPad_UserInput1" className="screen" readOnly value={screenVal} />
      </div>
      <div className="button-section">
        <div className="numeric-pad">
          {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map((input) => (
            <button key={input} className="calcBtn" onClick={() => handleClick(input)}>
              {input}
            </button>
          ))}
          <button className="calcBtn" onClick={() => handleClick('.')}>
            ,
          </button>
        </div>
        <div className="operators">
          {[
            '+',
            '-',
            '*',
            '/',
            '^',
            'sqrt(',
            'sin(',
            'cos(',
            'tan(',
            'cbrt(',
            'asin(',
            'acos(',
            'atan(',
            // Add open parenthesis
            '(',
            // Add close parenthesis
            ')'
          ].map((input) => (
            <button key={input} className="calcBtn" onClick={() => handleClick(input)}>
              {input}
            </button>
          ))}

          <button className="calcBtn" onClick={() => handleClick('pi')}>
            Pi
          </button>
          <button className="calcBtn" onClick={() => handleClick('fact(')}>
            Factorial
          </button>
        </div>
        <div className="control-buttons">
          <button className="clear-button" onClick={clearScreen}>
            C
          </button>
          <button className="equals-button" onClick={calculate}>
            =
          </button>
          <button className="backspace-button" onClick={backspace}>
            del
          </button>
        </div>
      </div>
    </div>
  );
}

export default Calculator1;
