import React, { useEffect, useState } from 'react';
import '../App.css';  
import '../Assets/Css/placeorder.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../Component/header';

import PaymentHold from '../Module/paymentHold';
import PaymentErrorPopup from '../Module/paymentErrorPopup';
import { paymentCancel } from '../Redux/Actions/examActions';
import HeaderHome from '../Component/headerHome';

function PaymentError() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [openError, setopenError] = useState(false);
  const [openPending, setopenPending] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get("token")
   useEffect(() => {
    // setopenError(true)
    setopenPending(true)
  }, []);
  useEffect(() => {
    dispatch(
      paymentCancel(
        {
          token:token
        },
        (data: any) => {
          setopenPending(false)
          setopenError(true)
        },
        () => { }
      )
    );
  }, [token]);

  return (
    <div> 
      {/* <Header isHidden={false} /> */}
      <HeaderHome isHidden={false} />  
      <PaymentErrorPopup open={openError}/>      
      <PaymentHold open={openPending}/>       
    </div>
  );
}

export default PaymentError;
