import { useEffect, useState } from "react";
import "../App.css";
import "../Assets/Css/cart.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import norecord from "../Assets/Images/json/no_record_found.json";
import Lottie from "react-lottie";
import {
  getNotificationCount,
  getnotificationList,
  getnotificationTypeList,
} from "../Redux/Actions/examActions";
import moment from "moment";
import NotificationPopUp from "../Module/NotificationPopUp";
function Notification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationTypeList, setNotificationTypeList] = useState<any>([]);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationFilter, setNotificationFilter] = useState<any>([]);
  const [NotificationType, setNotificationType] = useState<any>("1");
  const [notificationData, setNotificationData] = useState();
  const [open, setopen] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: norecord,
    renderer: "svg",
  };

  useEffect(() => {
    dispatch(
      getnotificationTypeList(
        (res: any) => {
          setNotificationTypeList(res);
        },
        () => {}
      )
    );

    dispatch(
      getnotificationList(
        {},
        (data: any) => {
          setNotificationList(data);
          const filter = data?.filter(
            (item: any) => item.notificationType?.name === "Class Schedule"
          );
          const groups = filter?.reduce((groups: any, game: any) => {
            const date = game?.scheduledAt.split("T")[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(game);
            return groups;
          }, {});

          // Edit: to add it in the array format instead
          const groupArrays = Object.keys(groups).map((date) => {
            return {
              date,
              notification: groups[date],
            };
          });

          setNotificationFilter(groupArrays);
        },
        () => {}
      )
    );
  }, []);
  const onCardpress = (dataCard: any) => {
    setNotificationData(dataCard);
    setopen(true);
    dispatch(
      getNotificationCount(
        {},
        dataCard?.notificationId,
        (data: any) => {
          dispatch(
            getnotificationList(
              {},
              (data: any) => {
                setNotificationList(data);
                const filter = data?.filter(
                  (item: any) =>
                    item.notificationType?.name === "Class Schedule"
                );
                const groups = filter?.reduce((groups: any, game: any) => {
                  const date = game?.scheduledAt.split("T")[0];
                  if (!groups[date]) {
                    groups[date] = [];
                  }
                  groups[date].push(game);
                  return groups;
                }, {});

                // Edit: to add it in the array format instead
                const groupArrays = Object.keys(groups).map((date) => {
                  return {
                    date,
                    notification: groups[date],
                  };
                });

                setNotificationFilter(groupArrays);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  const handleClose = () => {
    setopen(false);
  };
  const handleClickNew = (c: any) => {
    setNotificationType(c);
    const filter = notificationList?.filter(
      (item: any) => item.notificationType?.name === c?.name
    );
    const groups = filter?.reduce((groups: any, game: any) => {
      const date = game?.scheduledAt.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(game);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        notification: groups[date],
      };
    });

    setNotificationFilter(groupArrays);
  };
  return (
    <div className="rightPanel">
      <div className="container   ">
        <h3 className="fw-bold ">Notification</h3>

        <div className="customtab mt-4 ">
          <ul
            className="nav nav-pills  p-4 pb-0  rounded bgwhite "
            id="pills-tab"
            role="tablist"
          >
            <div className="d-flex align-items-center border-bottom border-2 w-100">
              {notificationTypeList?.map((c: any, index: any) => (
                <li
                  key={index.toString()}
                  className="nav-item  "
                  role="presentation"
                >
                  <a
                    className={` ${
                      c._id === NotificationType?._id
                        ? "text-Primary fw-bold border-bottom border-primary border-3 "
                        : "text-secondary fw-bold border-0"
                    }   position-relative me-4 text-capitalize textUnderline`}
                    type="button"
                    role="tab"
                    onClick={() => handleClickNew(c)}
                  >
                    {c?.name}
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {c?.badge}
                    </span>
                  </a>
                </li>
              ))}
            </div>
          </ul>

          <div className="tab-content notificatioContent" id="pills-tabContent">
            {notificationTypeList?.map((data: any, index: any) => {
              return (
                <div
                  key={index.toString()}
                  className={` p-3 tab-pane fade ${
                    index === 1 ? "show active" : ""
                  }`}
                  id={"pills-" + index}
                  role="tabpanel"
                  aria-labelledby={"pills-" + index + "-tab"}
                >
                  <div className="bgwhite p-2 notificationShow">
                    {notificationFilter.length !== 0 ? (
                      notificationFilter.map((item: any) => {
                        const notificationDateNew = moment(
                          item?.date,
                          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                        ).format("DD MMMM YYYY");
                        return (
                          <div className="mb-2">
                            {moment().diff(notificationDateNew, "days") ===
                            0 ? (
                              <p className="font-12 m-2 text-secondary">
                                <b>TODAY</b>
                              </p>
                            ) : moment().diff(notificationDateNew, "days") ===
                              1 ? (
                              <p className="font-12 m-2 text-secondary">
                                <b>YESTERDAY</b>
                              </p>
                            ) : (
                              <p className="font-12 m-2 text-secondary">
                                <b>
                                  {moment(notificationDateNew).format(
                                    "DD MMMM YYYY"
                                  )}
                                </b>
                              </p>
                            )}

                            <>
                              {item?.notification?.map((c: any) => {
                                return (
                                  <div
                                    className=" row align-items-center boxshadow border border-2 p-4 cursor mb-2 rounded bg-success p-2 text-dark bg-opacity-10"
                                    onClick={() => onCardpress(c)}
                                  >
                                    <div className="col-2 col-sm-1 justify-centent-end">
                                      <img
                                        src={c.notificationIcon}
                                        alt="icon"
                                        className="notiicon"
                                      />
                                    </div>
                                    <div className="col-7 col-sm-8 ">
                                      <p
                                        className={
                                          c?.isread
                                            ? "fw-bold text-success fs-6 m-0 ellipsisNotificationTitle"
                                            : "fw-bold text-danger fs-6 m-0 ellipsisNotificationTitle"
                                        }
                                      >
                                        {c.notificationTitle}
                                      </p>
                                      <p className="  m-0 ellipsisNotification">
                                        Dear {c?.userName}....
                                      </p>
                                      {/* <p className="  m-0 ellipsisNotification">
                                        {c?.notificationText1}
                                      </p>
                                      <ul>
                                        <li>Topic : {c?.Topic}</li>
                                        <li>Date : {c?.Date}</li>
                                        <li>Time : {c?.Time}</li>
                                      </ul>
                                      <p className="  m-0 ellipsisNotification">
                                        {c?.notificationText2}
                                      </p>
                                      <p className="  m-0 ellipsisNotification mt-3">
                                        Best Regards
                                      </p>
                                      <p className="  m-0 ellipsisNotification">
                                        Lurnigo Team
                                      </p> */}
                                    </div>
                                    <div className="col-3 d-flex justify-content-end">
                                      <p className="font10 text-secondary">
                                        {moment
                                          .utc(c?.scheduledAt)
                                          .format("HH:mm")}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </>

                            <NotificationPopUp
                              openPopup={open}
                              handleClose={handleClose}
                              dataCard={notificationData}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="px-2">
                        <Lottie
                          options={defaultOptions}
                          height={100}
                          width={100}
                        ></Lottie>
                        <p className="text-center font15">
                          <b>No New Notification</b>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <NoRecord /> */}
      </div>
    </div>
  );
}

export default Notification;
