import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hooks";
import localImages from "../Constant/localImagesNew";
import RatingStar from "../Module/ratingStar";
import TextArea from "../Component/textArea";
import {
  addSessionFeedback,
  getSessionFeedback,
} from "../Redux/Actions/examActions";
import moment from "moment";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import OverallRating from "../Module/overallRating";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
export default function Feedback() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [q1ans, setq1ans] = useState(0);
  const [q2ans, setq2ans] = useState(0);
  const [q3ans, setq3ans] = useState("");
  const [q3anstxt, setq3anstxt] = useState("");
  const [q4ans, setq4ans] = useState(0);
  const [q5ans, setq5ans] = useState("");
  const [q6ans, setq6ans] = useState("");
  const [isFeedback, setIsFeedback] = useState(false);
  // const [demoData,setdemoData]=useState<any>("")
  const { demoData } = location.state;
  const labels: { [index: string]: string } = {
    1: "Bad",
    2: "Below Average",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const postData = {
      demoId: demoData?.demoId,
      teacherId: demoData?.teacherId,
    };
    dispatch(
      getSessionFeedback(
        postData,
        (data: any) => {
          setIsFeedback(true);
          setq1ans(data?.feedback[0]?.answer);
          setq2ans(parseInt(data?.feedback[1]?.answer));
          setq3ans(data?.feedback[2]?.answer === "Yes" ? "Yes" : "No");
          if (data?.feedback[2]?.answer === "Yes") {
            setq3anstxt("");
          } else {
            setq3anstxt(data?.feedback[2]?.answer?.replace("No, ", ""));
          }
          setq4ans(parseInt(data?.feedback[3]?.answer));
          setq5ans(data?.feedback[4]?.answer);
          setq6ans(data?.feedback[5]?.answer);
        },
        () => {}
      )
    );
  }, [demoData]);
  const submitFeedBack = (e: any) => {
    e.preventDefault();
    const postData = {
      demoId: demoData?.demoId,
      teacherId: demoData?.teacherId,
      feedback: [
        {
          questionId: 1,
          answer: "" + q1ans,
        },
        {
          questionId: 2,
          answer: "" + q2ans,
        },
        {
          questionId: 3,
          answer: q3ans === "No" ? q3ans + ", " + q3anstxt : q3ans,
        },
        {
          questionId: 4,
          answer: "" + q4ans,
        },
        {
          questionId: 5,
          answer: q5ans,
        },
        {
          questionId: 6,
          answer: q6ans,
        },
      ],
    };
    dispatch(
      addSessionFeedback(
        postData,
        (data: any) => {
          navigate("/user/demoDashboard");
        },
        () => {}
      )
    );
  };
  const onChangeQ1Star = (val: any) => {
    setq1ans(val);
  };
  const onChangeQ2Star = (val: any) => {
    setq2ans(val);
  };
  const onChangeQ4Star = (val: any) => {
    setq4ans(val);
  };
  function validations() {
    return (
      q1ans === 0 ||
      q2ans === 0 ||
      q3ans === "" ||
      q4ans === 0 ||
      q5ans === "" ||
      q6ans === ""
    );
  }
  return (
    <div className="feedbackPage">
      {/* <img src={localImages?.loginleftarrow} className='loginLeftArrow' alt='loginLeftArrow' />
                <img src={localImages?.loginrightarrow} className='loginRightArrow' alt='loginRightArrow' /> */}
      <div className="feedbackCard rounded shadow-sm">
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="feedbackHeader d-flex align-items-center">
              <div className="text-center me-3">
                {/* <img src={localImages.logo} alt="IFAS Logo" className="feedBacklogo cursor mb-2 rounded" /> */}
                <span className="headerIcon">
                  <img
                    src={localImages?.logoIcon}
                    alt="Demo Session"
                    className="w-100"
                  />
                </span>
              </div>
              <div>
                <h4 className="boldfont fw-bold  mb-2">
                  Trial Session Feedback
                </h4>
                <div className=" fw-bold mb-1">
                  {isFeedback ? (
                    <div>Tutor Name : {demoData?.teacherName}</div>
                  ) : (
                    <div>
                      Please let us know about your experience with our faculty{" "}
                      {demoData?.teacherName}.
                    </div>
                  )}
                </div>
                <div className="d-flex  justify-content-between">
                  <div>
                    <label className="fw-bold  me-2 mb-2">
                      Date of the Trial Session :{" "}
                    </label>
                    {moment(demoData?.date).format("LL")} {demoData?.time}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4">
              {/* <div className='d-flex  justify-content-between'>
                  <div><label className='fw-bold  me-2 mb-2'>Parent Name : </label>{demoData?.parentName}</div>
                  <div><label className='fw-bold me-2 mb-2'>Student Name: </label>{demoData?.studentName} </div>
                </div> */}
              {/* <div className='d-flex  justify-content-between'>
                  <div><label className='fw-bold  me-2 mb-2'>Date of the Trial Session : </label>{moment(demoData?.date).format('LL')}{" "} {demoData?.time}</div>
                  <div><label className='fw-bold me-2 mb-2'>Tutor's Name : </label>{demoData?.teacherName}</div>
                </div> */}
              <div className="my-2">
                <div className="fw-bold mb-2">
                  1. How satisfied were you with the trial session provided by
                  LurniGo Tutors?
                </div>
                <div className="mt-3">
                  <RatingStar
                    setValue={(val: any) => onChangeQ1Star(val)}
                    value={q1ans}
                    label={labels}
                    disabled={isFeedback}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  2. How would you rate the tutor's expertise in the subject
                  taught?
                </div>
                {/* <div><RatingStar setValue={(val:any)=>onChangeQ2Star(val)} value={q2ans?.id} label={labels}/></div> */}
                <div className="mx-2 mt-3">
                  <OverallRating
                    setValue={(val: any) => onChangeQ2Star(val)}
                    val={q2ans}
                    disabled={isFeedback}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  3. Was the session interactive and engaging for your child?
                </div>
                {/* <div className='d-flex'>
                    <div className="form-check me-3">
                      <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" onChange={()=>setq3ans("Yes")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" onChange={()=>setq3ans("No")}/>
                      <label className="form-check-label" htmlFor="exampleRadios2">
                        No
                      </label>
                    </div>
                  </div> */}
                <div className="d-flex mt-3">
                  <div
                    className={`rounded-pill py-1 px-3 d-flex align-items-center cursor yesNoCard ${
                      q3ans === "Yes" ? "active" : ""
                    }`}
                    onClick={isFeedback ? undefined : () => setq3ans("Yes")}
                  >
                    YES{" "}
                    <ThumbUpIcon
                      className={`font16 ms-2 thumbsIcon ${
                        q3ans === "Yes" ? "active" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`rounded-pill py-1 px-3 d-flex align-items-center cursor yesNoCard ms-2 ${
                      q3ans === "No" ? "active" : ""
                    }`}
                    onClick={isFeedback ? undefined : () => setq3ans("No")}
                  >
                    No{" "}
                    <ThumbDownIcon
                      className={`font16 ms-2 thumbsIcon ${
                        q3ans === "No" ? "active" : ""
                      }`}
                    />
                  </div>
                </div>
                {q3ans === "No" ? (
                  <div className="mt-3">
                    <TextArea
                      disabled={isFeedback}
                      setValue={(val: any) => setq3anstxt(val)}
                      value={q3anstxt}
                    />
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  4. How likely are you to enroll your child for regular
                  sessions with LurniGo Tutors based on this trial?
                </div>
                {/* <div><RatingStar setValue={(val:any)=>onChangeQ4Star(val)} value={q4ans?.id} label={labels}/></div> */}
                <div className="mx-2 mt-3 bookSessionOrange">
                  <OverallRating
                    setValue={(val: any) => onChangeQ4Star(val)}
                    val={q4ans}
                    disabled={isFeedback}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  5. What did you like the most about the trial session?
                </div>
                <div className="mt-3">
                  <TextArea
                    disabled={isFeedback}
                    setValue={(val: any) => setq5ans(val)}
                    value={q5ans}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  6. Any other comments or suggestions?
                </div>
                <div className="mt-3">
                  <TextArea
                    disabled={isFeedback}
                    setValue={(val: any) => setq6ans(val)}
                    value={q6ans}
                  />
                </div>
              </div>
              {isFeedback ? null : (
                <div className="mt-5 d-flex justify-content-center">
                  <button
                    className={`sendOTPBtn px-5 ${
                      validations() ? "" : "active"
                    }`}
                    disabled={validations()}
                    onClick={submitFeedBack}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
