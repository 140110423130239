import moment from "moment";
import { useState } from "react";

export const today = moment(new Date()).format("YYYY-MM-DD")

export const dateFormat = {
  yyyymmdd: "YYYY-MM-DD",
  yyyymmddA: "YYYY-MM-DD LT"
}
export const timeFormat = {
  LT: "LT"
}

export const teacherId = 4

export const getTableDataSlots = (count: any) => {
  const slotsArray = [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 200, label: 200 },
    { value: count, label: 'All' }
  ]
  return slotsArray
}

export function usePagination(data: string | any[], itemsPerPage: number) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data?.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}