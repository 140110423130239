import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({
  color1,
  color2, 
  lable1,
  lable2, 
  sectionData,
  style
}) => {
  const data = {
    labels: [lable1, lable2 ],
    datasets: [
      {
        label: '',
        data: sectionData,
        backgroundColor: [color1, color2 ],
        barThickness: 40
      }
    ]
  };
  const topLabels = {
    id: 'topLabels',
    afterDatasetsDraw(chart:any) {
      const { ctx, data } = chart;
      chart.data.datasets.forEach((dataset:any, i:any) => {
        const meta = chart.getDatasetMeta(i);
        meta.data.forEach((bar:any, index:any) => {
          const data = dataset.data[index];
          ctx.font = 'bold 15px Arial'; 
          ctx.fillStyle = 'white'; 
          ctx.textAlign = 'center'; 
          ctx.fillText(`${data}%`, bar.x, bar.y - 5); 
        });
      });
    }
  };
  const options = {
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grace: 10,
        min: 0,
      }
    },
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false
      },
    }
  };
  return (
    <div style={style}>
      <Bar data={data} options={options} plugins={[topLabels]} height={350} />
    </div>
  );
};

export default BarChart;
