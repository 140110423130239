// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.profImg {
  width: 200px;
  height: auto;
  max-height: 200px;
  aspect-ratio: 3/3;
  }

  .addressdiv{
    height: 1px;
  }

  .selectedGray {
    background-color: var(--selectedgray);
  }
  
  .colorLightPurple {
    background-color: var(--lightpurple);
  }`, "",{"version":3,"sources":["webpack://./src/Assets/Css/myProfile.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,qCAAqC;EACvC;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":["\n.profImg {\n  width: 200px;\n  height: auto;\n  max-height: 200px;\n  aspect-ratio: 3/3;\n  }\n\n  .addressdiv{\n    height: 1px;\n  }\n\n  .selectedGray {\n    background-color: var(--selectedgray);\n  }\n  \n  .colorLightPurple {\n    background-color: var(--lightpurple);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
