import React, { useState, useEffect } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Import styles
import "../Assets/Css/lectureTimer.css";
import Draggable from "react-draggable";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import localImagesNew from "../Constant/localImagesNew";

const LectureTimer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Toggle popup visibility
  const [minutes, setMinutes] = useState<any>(0);
  const [seconds, setSeconds] = useState<any>(0);
  const [timeLeft, setTimeLeft] = useState<any>(null); // Time left in seconds
  const [isTimerRunning, setIsTimerRunning] = useState<any>(false);
  const [totalTime, setTotalTime] = useState<any>(0); // Total time for the timer
  const [isPaused, setIsPaused] = useState<boolean>(false); // Track whether the timer is paused
  console.log("isPopupOpen", isPopupOpen);
  // Open/Close the popup
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Handle form submission to start timer
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let min = "0",
      sec = "0";
    if (minutes) {
      min = minutes;
    }
    if (seconds) {
      sec = seconds;
    }
    const totalSeconds = parseInt(min) * 60 + parseInt(sec);
    setTotalTime(totalSeconds); // Set total time to track progress
    setTimeLeft(totalSeconds);
    setIsTimerRunning(true);
    setIsPaused(false); // Timer starts, so it's not paused
    //setIsPopupOpen(false); // Close popup after submission
  };

  // Countdown logic using useEffect
  useEffect(() => {
    let interval: any = null;
    if (isTimerRunning && timeLeft > 0 && !isPaused) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(interval);
      setIsTimerRunning(false);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning, timeLeft, isPaused]);

  // Format time for display (MM:SS)
  const formatTime = () => {
    const mins = Math.floor(timeLeft / 60);
    const secs = timeLeft % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  // Calculate percentage for progress bar
  const getProgress = () => {
    if (!totalTime || totalTime === 0) return 0;
    return ((totalTime - timeLeft) / totalTime) * 100;
  };

  // Play the timer
  const handlePlay = () => {
    setIsTimerRunning(true);
    setIsPaused(false);
  };

  // Pause the timer
  const handlePause = () => {
    setIsPaused(true);
  };

  // Stop the timer and reset
  const handleStop = () => {
    setIsTimerRunning(false);
    setTimeLeft(totalTime); // Reset to the total time
    setIsPaused(false); // Ensure the timer is not paused
    setMinutes("0");
    setSeconds("0");
    setIsPopupOpen(false);
  };

  return (
    <div>
      {/* Time Icon to open popup */}
      <div onClick={togglePopup} className="cursor h-100">
        {/* <AccessTimeIcon className="me-2 colorgray" /> */}
        <img
          src={localImagesNew?.timerIcon}
          alt="calculator"
          className=""
          style={{ width: "35px" }}
        />
      </div>

      {/* Popup for input time */}
      {isPopupOpen && (
        <Draggable>
          <div className="" style={timerStyle}>
            <h3 className="text-center mb-4">
              {isTimerRunning ? "Time Left" : "Set Time"}
            </h3>

            {isTimerRunning && timeLeft > 0 ? (
              <div>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "10px auto",
                  }}
                >
                  <CircularProgressbar
                    value={getProgress()}
                    text={formatTime()}
                    styles={buildStyles({
                      pathColor: "#F15D22", // Customize the progress bar color (green progress)
                      trailColor: "#d6d6d6", // Background color of the progress bar (gray trail)
                      strokeLinecap: "butt", // Line cap of the path (for a sharp end)
                      textColor: "#ffffff", // Customize the text color
                      pathTransitionDuration: 0.5, // Animation speed of the progress bar
                      pathTransition: "none", // Make transition smoother
                    })}
                  />
                </div>

                {/* Play, Pause, and Stop Buttons */}
                <div className="d-flex justify-content-center mt-3">
                  {isPaused && isTimerRunning ? (
                    <button
                      className="btn btn-success me-3"
                      onClick={handlePlay}
                      disabled={!isPaused && isTimerRunning}
                    >
                      <PlayCircleOutlineIcon className="font28" />
                    </button>
                  ) : null}
                  {!isPaused && isTimerRunning ? (
                    <button
                      className="btn btn-warning me-3"
                      onClick={handlePause}
                      disabled={isPaused || !isTimerRunning}
                    >
                      <PauseCircleOutlineIcon className="font28" />
                    </button>
                  ) : null}
                  {isTimerRunning ? (
                    <button
                      className="btn btn-danger"
                      onClick={handleStop}
                      disabled={!isTimerRunning}
                    >
                      <StopCircleIcon className="font28" />
                    </button>
                  ) : null}
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="text-center">
                <div className="d-flex align-items-center">
                  <div className="me-4">
                    <input
                      type="number"
                      value={minutes}
                      onChange={(e) => setMinutes(e.target.value)}
                      min="0"
                      className="form-control"
                      style={{ width: "60px" }}
                    />
                    <div className="text-center">Min</div>
                  </div>
                  <div>
                    <input
                      type="number"
                      value={seconds}
                      onChange={(e) => setSeconds(e.target.value)}
                      min="0"
                      max="59"
                      className="form-control"
                      style={{ width: "60px" }}
                    />
                    <div className="text-center">Sec</div>
                  </div>
                </div>

                <button className="btn btn-primary mt-4" type="submit">
                  Set Timer
                </button>
              </form>
            )}
          </div>
        </Draggable>
      )}
    </div>
  );
};

// Simple styling for timer at the bottom of the screen
const timerStyle: any = {
  position: "fixed",
  bottom: "70px",
  //   left: "50%",
  //   transform: "translateX(-50%)",
  backgroundColor: "#282c34",
  color: "white",
  padding: "25px 50px",
  borderRadius: "5px",
  zIndex: "1",
  right: "10px",
  width: "250px",
};

export default LectureTimer;
