import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Lottie from 'react-lottie';
import feedback from "../Assets/Images/json/feedback.json";
//import { examList } from '../Utils/dataConstants';


function UserFeedbackPopup(props: any) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: feedback,
    renderer: "svg",
  };
  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
       >
        <Fade in={props.open}>
           <div className="userFeedbackModel p-4">
           <ClearOutlinedIcon
              className="colorblue fs-1 p-1 shadow rounded-circle border-primary cursor float-end"
              onClick={props.handleClose}
            />
              <div>
                <div className='mt-4 text-center'>
              <Lottie options={defaultOptions} height={130} width={475}></Lottie>
                </div>
                <div>
                  <h4 className="my-4 fw-bold text-center">Trial Class Completed </h4>
                  <div className='text-center '>We had a great time talking to you. We will get back to you shortly for the next step.</div>
                  <div className='text-center'>Provide your response on trial session.</div>
                </div>
                <div className="d-flex justify-content-evenly mt-5">
                  <button type="button" className="sendOTPBtn rounded mt-5 px-4" onClick={props?.goto}>
                  Your Feedback
                  </button>
                </div>
              </div>
            </div>
          
        </Fade>
      </Modal>
    </div>
  );
}

export default UserFeedbackPopup;
