import React, { useEffect, useState } from 'react';
import localImages from '../Constant/localImagesNew';
import Faq from '../Component/faq';
import Footer from '../Component/footer';
import { useNavigate } from 'react-router-dom';
import HeaderHome from '../Component/headerHome';
import Sidebar from '../Module/sidebar';
import { useSelector } from 'react-redux';
import { SidebarDataFreeUser, SidebarDataPaidUser } from '../Module/sidebarData';
import BookSession from '../Module/bookSession';
import StudentSayCarousel from '../Component/studentSayCarousel';
import '../Assets/Css/collegeConsulting.css';
import dataConstant from '../Constant/dataConstant';
import '../Assets/Css/customTable.css';
import BlogCard from '../Module/blogCard';
import { getBlog } from '../Redux/Actions/AuthAction';
import { useAppDispatch } from '../hooks';
import StatCard from '../Module/statCard';
export default function AlumniNetwork() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [blogData, setblogData] = useState([]);
      const {ACCESS_TOKEN,hasActivePaidPlan} = useSelector((state:any) => ({
        ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
        hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
      }));

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getBlog(
            (data: any) => {
                setblogData(data)
            },
            () => { })
        );     
    }, []);
    function goToLink(val:string): void {
        if(val==="BookSession"){
            navigate('/'+val,{ state: { mobNo: "",coutryCodeNo:"",isOTPVerify:ACCESS_TOKEN?true:false } });  
        }
        else{
            navigate('/'+val);
        }
      }
    
      const contactUS = (val:string) => {
        const element = document.getElementById(val);
        if (element) { 
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      };

    return (
      <div>
        <HeaderHome isHidden={true} />
        <div className="d-block d-md-none">
          <Sidebar
            data={
              hasActivePaidPlan === true
                ? SidebarDataPaidUser
                : SidebarDataFreeUser
            }
          />
        </div>
        {/*************Banner************* */}
        <div className="position-relative collegeConsultingContainer">
          <img
            src={localImages?.ccLeftArrow}
            className="ccLeftArrow"
            alt="learnSolleft"
          />
          <img
            src={localImages?.ccRightArrow}
            className="ccRightArrow"
            alt="learnSolright"
          />
          <div className="width800 mx-auto px-4">
            <h1 className="headingFont text-center">
              Legacy of Achievers: Celebrating Lurnigo Alumni Success Stories
            </h1>
            <div className="font16 mt-4 text-center px-4">
              Dive into a gallery of triumphs as we proudly showcase the
              journeys and accomplishments of our esteemed Lurnigo alumni. From
              academic milestones to professional successes, explore the
              inspiring narratives that define the lasting impact of the Lurnigo
              experience.
            </div>
          </div>
        </div>
        <StatCard
          buttonName="Explore Programs"
          handleClick={() => goToLink("Academics")}
        />

        <div className="commonPad">
          <h1 className="headingFont width800 mx-auto text-center">
            How Lurnigo's Class of 2027
          </h1>
          <h1 className="headingFont width800 mx-auto text-center">
            results compare!
          </h1>
          <div className="mt-5 commonPad">
              <table className="table customTable ">
                        <thead>
                            <tr>
                                <th scope="col">University</th>
                                <th scope="col">General Admission Rate</th>
                                <th scope="col">LurniGo student Admission Rate</th>
                            </tr>
                        </thead>
                        <tbody className='border'>
                            {dataConstant?.universityTable?.map((item)=>(
                                <tr>
                                <td>{item?.universityname}</td>
                                <td>{item?.generalRate}</td>
                                <td>{item?.lurnigoRate}%</td>
                            </tr>
                            ))}
                            
                        </tbody>
                    </table>
          </div>
        </div>

        <div className="commonPad bgLightGrey">
          <div className="width800 mx-auto my-5">
            <h1 className="headingFont text-center">
              Don't just take our word for it
            </h1>
            <div className="bannersubtext mb-3 text-center">
              Hear what our students have to say
            </div>
          </div>
          <div>
            <StudentSayCarousel
              Data={dataConstant?.studentSayData}
              id="carouselStudent"
            />
          </div>
          <div></div>
        </div>

        <div className="commonPad ">
          <div className="width800 mx-auto mt-5">
            <h1 className="headingFont text-center">
              Explore insightful stories unlocking a wealth of knowledge
            </h1>
          </div>
          <div className="row learnSolTab">
            {blogData?.map((item: any, index) => {
              if (index < 3) {
                return <BlogCard data={item} />;
              }
            })}
          </div>
          <div className="text-center mb-4">
            <button
              className="contactUSbtn px-4"
              onClick={() => goToLink("Blog")}
            >
              See More
            </button>
          </div>
        </div>
        <div className="commonPad ">
          <div className="row mx-0">
            <div className="col-md-3">
              <h1 className="headingFont mb-4">FAQs</h1>
              <div className="mb-4">
                <button
                  className="contactUSbtn px-5"
                  onClick={() => contactUS("Footer")}
                >
                  Contact Us
                </button>
              </div>
            </div>
            <div className="col-md-9">
              <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
            </div>
          </div>
        </div>

        <div className="commonPad ">
          <BookSession clickEvent={() => goToLink("BookSession")} />
        </div>
        {/***************Footer*************** */}
        <Footer id="Footer" />
      </div>
    );
}
