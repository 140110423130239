import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BarChart from "./barChart";  
import localImages from "../Constant/localImagesNew";
import MultiLayerChart from "./multiLayerChart";
import SemiCircleChart from "./semiCircleChart"; 
import moment from "moment";
import { getTimeTakenToMinutesStringFormat, getTimeTakenToStringFormat } from "../Utils/utils";
function ResultAnalysis(props: any) {
  console.log("props",props)
 
   const ScoreHolder = [
        {
          icon: localImages?.rightTick,
          backgroundColor: '#158803',
          color: '#158803',
          heading: 'Your Earned Points',
         marks:props?.examsStats?.lectureEarnedPoints,
          totalMarks: props?.examsStats?.totalPoints,
          children:"   OVERALL"
        },
        {
          icon: localImages?.star,
          backgroundColor: '#FFA40B',
          color: '#FFA40B',
          heading: 'Your Rank',
          marks: props?.examsStats?.rank,
          totalMarks: props?.examsStats?.totalStudents,
          children:"   OVERALL"
        },
        {
          icon: localImages?.clock,
          backgroundColor: '#FF3B30',
          color: '#FF3B30',
          heading: 'Time Spent',
           marks: getTimeTakenToMinutesStringFormat(parseInt(props?.examsStats?.totalTimeTaken)?.toFixed(2)) ,
          totalMarks:props?.examsStats?.totalDuration,
          children:"   OVERALL"
        }
      ]  

      const DotListItem = ({ backgroundColor, text, value }) => (
        <li className="mb-2">
          <div className="d-flex">
            <span className="dot me-3" style={{ backgroundColor }}></span>
            <div className="text-holder">
              {text}
              <span>{value}</span>
            </div>
          </div>
        </li>
      );

      const filterQuestion = props?.questionsData?.filter(
    (item: any) => item?.type === 2
  );
  let correctQuestion = 0, 
  incorrectQuestion = 0,descriptiveQuestionCount=0;
  console.log("filterQuestion", filterQuestion);
  for (let data of filterQuestion) {
  let isCorrectAns = false;
 let isDescAns=false
  console.log("dafilterQuestionta", data);
  if (data?.questionType === "1") {
    isCorrectAns =
      data?.options.find(
        (element: any) => element.isCorrect && element.isSelected &&
        data?.earnedPoints > 0
      ) != (undefined || null);
    // console.log("isCorrectAns", isCorrectAns);
  } else  if (data?.questionType === "2")  {
    isCorrectAns = (data?.options[0]?.description === data?.userAnswer &&
      data?.earnedPoints > 0);
  }
  else if (data?.questionType === "4" || data?.questionType === "6"){
    isDescAns=true
  }
  else if (data?.questionType === "5") {
    const leftItems = data?.options?.filter((data: any) => data?.part === "partA");
    const userAns = JSON?.parse(data?.userAnswer)
    let results = leftItems.map((leftItem: any) => {
      const matchedItems = userAns[leftItem.id] || [];

      // Check if the number of matched items is the same as the number of correct matches
      if (matchedItems.length !== leftItem?.correctMatch?.split(",")?.length) {
        return false;
      }
      // Check if every matched item ID exists in the correct ID array
      const isEveryMatchCorrect = matchedItems.every((matchedItem) =>
        leftItem.correctMatch.includes(matchedItem.id)
      );
      return isEveryMatchCorrect;
    });
    console.log('result', results, results.every((result: any) => result));
    if (results.every((result: any) => result) && data?.earnedPoints > 0) {
      isCorrectAns = true
    }
  }
  else if (data?.questionType === "3") {
    const selectedOption = data?.options?.filter((item: any) => item?.isSelected);
    if (selectedOption?.length > 0) {
      const allSelectedCorrect = selectedOption.every(item => item.isCorrect);
      const correctOptions = data?.options.filter(item => item.isCorrect);
      const correctSelection = selectedOption.length === correctOptions.length;

      if (allSelectedCorrect && correctSelection) {
        isCorrectAns = true
      }
    }
    // console.log("isCorrectAns", isCorrectAns);
  }
  if (isCorrectAns) {
    correctQuestion++;
  }
  else if(isDescAns){
    descriptiveQuestionCount++
  } else {
    incorrectQuestion++;
  }
  }
console.log("isCorrectAns123", correctQuestion);
console.log("incorrectQuestion123", incorrectQuestion);
console.log("descriptiveQuestionCount", descriptiveQuestionCount);
  return (
    <div className="result-analysis px-2">
      <div className="exam-name-holder ms-2 mb-3">
        {props?.title}
          {/* <span className="d-block">{`Attempted on ${  moment(props?.title).format('LL') } |${ moment(props?.title).format('LT')} `}</span> */}
      
      </div>
      <div className="row mb-3">
        <div className="col-xl-7 col-lg-12 mb-md-1 mb-4 p-0">
          <div className="score-div d-flex justify-content-between">
          {ScoreHolder.map((c:any , index:any)=>{
            return(
                  
            <div className="col-md-4 mb-md-0 mb-3 p-2">
            <div className="score-holder py-4">
              <span className="analysis-caption" 
              style={{backgroundColor:c?.backgroundColor}}
              >
                {c?.children}
              </span>
              <div className="heading-holder mt-2">
                <p className="boldfont ms-1">
                  <img src={c?.icon} className="me-1" alt={c?.heading} width="23" />
                  {c?.heading}
                </p>
                   <SemiCircleChart color={c?.color} marks={c?.marks} totalMarks={c?.totalMarks} />    
              </div>
            </div>
          </div>
             )})}
          </div>
        </div>
        <div className="col-xl-5 col-lg-12">
          <div className="performance-holder pt-1">
            <div className="row my-3">
              <div className="col-md-6 ps-0">
                <span
                  className="analysis-caption"
                  style={{ backgroundColor: '#EFEFEF', color: '#4F4F4F' }}>
                  OVERALL PERFORMANCE
                </span>
                 <ul className="ms-md-4 ms-0 my-2">
                  {[
                    {
                      backgroundColor: '#158803',
                      text: 'Percentile',
                      value:  props?.examsStats?.percentile
                    },
                    {
                      backgroundColor: '#FFA40B',
                      text: 'Accuracy',
                      value: parseInt(props?.examsStats?.accuracy)?.toFixed(2)
                    },
                    
                  ].map((item, index) => (
                    <DotListItem key={index} {...item} />
                  ))}
                </ul>  
              </div>
              <div className="col-md-6 p-3">
                 <MultiLayerChart
                  percentile={props?.examsStats?.percentile}
                  accuracy={props?.examsStats?.accuracy}
                  style={{ position: 'relative', width: '18vh' }}
                />  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-md-1 mb-4">
     <div className="col-xl-7 col-lg-12 mb-3">
      <div className="bar-charts">
            <div className="row">
            
              <div className="section-wise">
                <div className="heading-holder mt-2">
                  <p className="boldfont">
                    Question Distribution <span>(In numbers)</span>
                  </p>
                </div>
                <div className="d-flex   indicators px-5">
                  <div className="d-flex me-5">
                    <span className="dot my-1 me-2" style={{ background: '#158833' }}></span>
                    <p>Correct Answers</p>
                  </div>
                  <div className="d-flex">
                    <span className="dot my-1 me-2" style={{ background: '#E23F36' }}></span>
                    <p>Wrong Answers</p>
                  </div>
                  
                </div>
                <div className="px-3 py-2">
                   <BarChart
                    color1={'#158833'}
                    color2={'#E23F36'} 
                    lable1={'Correct Answers'}
                    lable2={'Wrong Answers'} 
                  sectionData={[correctQuestion, incorrectQuestion]}
                  style={{ position: 'relative', height: '60vh' }}
                  />  
                </div>
              </div>
            </div>

          </div>
        </div> 
        <div className="col-xl-5 col-lg-12">
        <div className="leader-board">
            <h3>Leaderboard</h3>
           {props?.leaderboard.length > 0 ? (  
              <div className="table-responsive">
                <table className="table w-100">
                  <thead>
                    <tr className="border-bottom" style={{ fontSize: '13px' }}>
                      <th>Rank</th>
                      <th>Name</th>
                      <th>Total Earn Points</th>
                      <th> Total Points</th>
                    </tr>
                  </thead>
                    <tbody className="rank-table" style={{height:"90px"}}>
                    {props?.leaderboard.slice(0, 51).map((item:any, index:any) => (
                       <tr style={{ fontWeight: item?.isCurrentUser ? 'bold' : 'normal', backgroundColor: item?.isCurrentUser ? '#f0f0f0' : 'transparent' }}>
                       <td className={index == 0 ?"  fw-bold":" "}>{item?.rank}</td>
                       <td>
                        <div className="d-flex">
                        <div className="">
                           {item?.profilePicture ? (
                             <img src={item?.profilePicture} alt="" className="me-2" />
                           ) : (""
                            //  <img src={localImages.user} alt="" className="me-2" />
                           )}
                         </div>
                         <div className={index == 0 ?"text-center fw-bold":"text-center"}>
                           {item?.firstName} {item?.lastName}
                         </div>
                        </div>
                         
                       </td>
                       <td className={index == 0 ?"  fw-bold":" "}>{item?.lectureEarnedPoints}</td>
                       <td className={index == 0 ?"  fw-bold":" "}>
                         { item?.totalPoints}
                       </td>
                     </tr>
                    ))} 
                  </tbody> 
                </table>
              </div>
              ) : (
                ""
            )}  
          </div>
        </div>
      </div>
       
    </div>
  );
}

export default ResultAnalysis;
