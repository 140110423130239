import { useEffect, useState,useRef } from 'react';
import '../App.css';  
import '../Assets/Css/paypal.css'; 
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import localImages from '../Constant/localImages';
import { useSelector } from 'react-redux';

function PayPal(props:any) {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const location = useLocation();
    const { amount} = location.state; 
  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(null);
  const paypalRef:any = useRef();
  const {userCountryCurrency} = useSelector((state:any) => ({
    userCountryCurrency: state?.AuthReducer?.hasActivePaidPlan,
  }));
  function goToHome(): void { 
    navigate('/'); 
}
  useEffect(() => {
   ( window as any).paypal
      .Buttons({
        createOrder: (data:any, actions:any) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Your description",
                amount: {
                  currency_code: userCountryCurrency,
                  value: amount,
                },
              },
            ],
          });
        },
        onApprove: async (data:any, actions:any) => {
          const order = await actions.order.capture();
          setPaid(true);
        },
        onError: (err:any) => {
        //   setError(err),
          console.error("err",err);
        },
      })
      .render(paypalRef.current);
  }, []);
  if (paid) {
}
// If any error occurs
if (error) {
    console.log("ERROR");
}

  return (
    <div>
    <header className="header" id='header'>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <img src={localImages.homelogo} alt="IFAS Logo" className="headerlogo cursor" onClick={goToHome}/>
        </div>
        </div>
  </header>
    <div className='row paypalBox'>
    <div ref={paypalRef} className='col-10 col-sm-4 m-auto boxshadow p-2 p-md-5'></div>
    </div>
    </div>
  );
}

export default PayPal;
