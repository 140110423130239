// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader{
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    color: #FFF;
    animation: rotation 1s linear infinite;
}
.loader:after, .loader:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: #FFF;
    border-radius: 50%;
    animation: animloader38 1s infinite ease-in-out;
}
.loader:before {
    background-color: var(--theme2);
    transform: scale(0.5) translate(-48px, -48px);
}
@keyframes animloader38 {
    50% { transform: scale(1) translate(-50% , -50%)}
  }

  @keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }`, "",{"version":3,"sources":["webpack://./src/Assets/Css/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IAEX,sCAAsC;AAC1C;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,qCAAqC;IACrC,sBAAsB;IACtB,kBAAkB;IAElB,+CAA+C;AACnD;AACA;IACI,+BAA+B;IAC/B,6CAA6C;AACjD;AACA;IACI,MAAM,0CAA0C;EAClD;;EAEA;IACE,KAAK,wBAAwB;IAC7B,OAAO,0BAA0B;EACnC","sourcesContent":[".loader{\n    width: 48px;\n    height: 48px;\n    display: inline-block;\n    position: relative;\n    color: #FFF;\n    -webkit-animation: rotation 1s linear infinite;\n    animation: rotation 1s linear infinite;\n}\n.loader:after, .loader:before {\n    content: \"\";\n    position: absolute;\n    width: 24px;\n    height: 24px;\n    top: 50%;\n    left: 50%;\n    transform: scale(0.5) translate(0, 0);\n    background-color: #FFF;\n    border-radius: 50%;\n    -webkit-animation: animloader38 1s infinite ease-in-out;\n    animation: animloader38 1s infinite ease-in-out;\n}\n.loader:before {\n    background-color: var(--theme2);\n    transform: scale(0.5) translate(-48px, -48px);\n}\n@keyframes animloader38 {\n    50% { transform: scale(1) translate(-50% , -50%)}\n  }\n\n  @keyframes rotation {\n    0% { transform: rotate(0deg) }\n    100% { transform: rotate(360deg) }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
