import "../App.css";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../Assets/Css/dashboard.css";
import moment from "moment";
import { getUserPackageList, getupcomingLect } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import live from "../Assets/Images/json/livesession.json";
import Lottie from "react-lottie";
import BbMeetingPopup from "../Module/bbMeetingPopup";
import NoRecord from "../Component/noRecord";
import { useSelector } from "react-redux";
import DropdownNew from "../Component/dropdownNew";
function ClassSchedule() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [dateList, setdateList] = useState([]);
  const [upcomingLectList, setUpcomingLectList] = useState<any>([]);
  const [openVideo, setopenVideo] = useState(false);
  const [bblink, setbblink] = useState<any>("");
  const [selectedLectureData, setSelectedLectureData] = useState<any>();
  const [packageList, setPackageList] = useState([]);
  const [packageVal, setPackageVal] = useState<any>("");
  const { studentId } = useSelector(
    (state: any) => ({
      studentId: state?.AuthReducer?.studentId,
    })
  );
  useEffect(() => {

    
  }, []);

  useEffect(() => {
    dispatch(
      getUserPackageList(
        studentId,
        (data: any) => {
          setPackageList(data);
          setPackageVal(data[0])
          dispatch(getupcomingLect(
            {packageId:data[0]?._id},
            (data: any) => {
                const filterData=data?.data.filter((item:any)=>{return item.status!==9})
                const groupedData:any = {};
                let labelArr:any=[];
                filterData?.forEach((entry:any) => {
                   const dateLabel = `${new Date(entry.scheduleFrom)}`;
                  if (!groupedData[dateLabel]) {
                    labelArr?.push(dateLabel)
                    groupedData[dateLabel] = [];
                  }
                  groupedData[dateLabel].push(entry);
                });
                setdateList(labelArr)
                setUpcomingLectList(groupedData)
               
            },
            () => { })  );
          
        },
        () => { }
      )
    );

   
  }, []);

  const openBbmeeitng = (link: any) => {
    setopenVideo(true);
    setbblink(link);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: live,
    renderer: "svg",
  };
  const handleBbbVideoClose = () => {
    setopenVideo(false);
  };
 let count=0;
 const openPopup=(val:any)=>{
  setSelectedLectureData(val)
  setOpen(true)
 }

 const getPackageVal = (val: any) => {
  const filterPackageData = packageList?.filter((item: any) => item._id === parseInt(val));
  setPackageVal(filterPackageData[0]);
  const requestData = {
    packageId: val
  };
   dispatch(getupcomingLect(
    requestData,
    (data: any) => {
        const filterData=data?.data.filter((item:any)=>{return item.status!==9})
        const groupedData:any = {};
        let labelArr:any=[];
        filterData?.forEach((entry:any) => {
           const dateLabel = `${new Date(entry.scheduleFrom)}`;
          if (!groupedData[dateLabel]) {
            labelArr?.push(dateLabel)
            groupedData[dateLabel] = [];
          }
          groupedData[dateLabel].push(entry);
        });
        setdateList(labelArr)
        setUpcomingLectList(groupedData)
       
    },
    () => { })  );
};
  return (
    <div className="rightPanel">
      <div className="customcontainer">
      <div className="d-flex">
          <h3 className="fw-bold boldfont me-5">Class Schedule</h3>     
          {/* {packageList?.length > 1 ?  */}
          <div className="dropdowncolor">
          <DropdownNew
            // placeholder="Select Package"
            options={packageList}
            value={packageVal?._id}
            setInputText={(value: any) => getPackageVal(value)}
          />
        </div> 
         {/* : null}    */}
        </div>
        <div className="mt-3">
          {dateList?.length>0?
           dateList?.map((data,index)=>{           
            return(
            <div>
              <div className="fw-bold mb-3 font24">{moment(data).format('dddd')}{", "} {moment(data).format('LL')}</div>
              <div>
                {upcomingLectList[data]?.map((item:any,i:any)=>{
                  // setCount(count+1)
                  count= count+1
                  return(
                    <div className="row mx-0 d-flex mb-4 align-items-center">
                      <div className="alert alert-primary countLecture">{count}</div>
                  <div className="col-8 d-flex ">
                  {item?.status === 2 ? (
                    <div
                      className="row boxshadow rounded p-2 p-md-3 align-items-center cursor bgwhite w-100"
                      onClick={() =>
                        openBbmeeitng(item?.attendeeUrl)
                      }
                    >
                      <div className="col-9">
                        <p className="fw-bold mb-2">{item?.title}</p>
                        {/* <p className="fw-bold mb-1">{item?.teacherName}</p> */}
                        <p className="mb-0 colorblue font12">Join Session Now</p>
                      </div>
                      <div className="col-3">
                        <div className="alert alert-danger  p-1 text-center" role="alert">
                        Live
                            </div>
                        <Lottie options={defaultOptions} height={60} width={60} />
                      </div>
                    </div>
                  ) :
                    (
                      <div
                        className="row boxshadow rounded p-2 p-md-3 align-items-center cursor bgwhite w-100"
                        onClick={() =>
                          openPopup(item)
                        }
                      >
                        <div className="col-9">
                          <p className="fw-bold mb-2">{item?.title}</p>
                          {/* <p className="fw-bold mb-1">{item?.teacherName}</p> */}
                          <p className="mb-0 colorblue font12"> {`Live Start at ${moment
                              .utc(item?.sessionTime, 'hh:mm:ss')
                              .format('LT')}`}</p>
                        </div>
                        <div className="col-3">
                            <div className="alert alert-warning  p-1 text-center" role="alert">
                              Upcoming
                            </div>
                          <Lottie options={defaultOptions} height={60} width={60} />
                        </div>
                      </div>
                    )}
                </div>
                </div>
                )})}
              </div>
            </div>
          )})
          : <NoRecord />}
         
         
            
          </div>
        
      </div>
      <BbMeetingPopup
        open={openVideo}
        handleClose={handleBbbVideoClose}
        link={bblink}
      />

      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv p-4 py-5">
            <div className="m-2  bgwhite">
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon className="colorblue cursor" onClick={() => setOpen(false)} />
              </div>
              <div className="col justify-content-center  py-5 ">
                <Lottie options={defaultOptions} height={100} width={100}></Lottie>
                {moment(selectedLectureData?.scheduleFrom).format('LL')===moment(new Date()).format('LL')
                ?<p className="text-center font16 lecturePopupText">
                <b>Please wait for a while , lecture will start soon</b>
              </p>
              :<p className="text-center font16 lecturePopupText">
              <b>This class is scheduled for {moment(selectedLectureData?.scheduleFrom).format('LL')} {moment
                              .utc(selectedLectureData?.sessionTime, 'hh:mm:ss').format('LT')} , Will update you prior to the scheduled time</b>
            </p>}
                
              </div>
            </div>
          </div>

        </Fade>
      </Modal>
     
    </div>
  );
}

export default ClassSchedule;
