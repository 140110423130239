import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { getFullScreen } from '../Utils/utils';

const ExamWarningDialog = ({ handleRedirect }:any) => {
  const [openWarning, setOpenWarning] = React.useState(false); // handles exam exit warning

  const handleReturnToExam = () => {
    setOpenWarning(false);
    getFullScreen();
  };

  const handleExitExam = (e:any) => {
    setOpenWarning(false);
    if(handleRedirect != null){
      handleRedirect(e);
    }else{
      
    }    
  };

  React.useEffect(() => {
    // getFullScreen();
    const handleWindowResize = (e:any) => {
      if (window.innerHeight == window.screen.height) {
        // pass
      } else {
        setOpenWarning(true);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    return function cleanup() {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Dialog
      open={openWarning}
      // onClose={handleClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Do you want to EXIT your test?
      </DialogTitle>
      {/* <DialogContent>
        <DialogContentText>You will be redirected to the Dashboard.</DialogContentText>
      </DialogContent> */}
      <DialogActions>
        <Button autoFocus onClick={handleReturnToExam}>
          No
        </Button>
        <Button onClick={handleExitExam}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExamWarningDialog;
