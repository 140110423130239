
import '../App.css';
import CourseCard from '../Module/courseCard';
import Header from '../Component/header';
import '../Assets/Css/courseDetail.css';
import DoneIcon from '@mui/icons-material/Done';
import BuyNowCard from '../Module/buyNowCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks';
import { addCart, getPackageData, getPackageDetails, getRecomendedData, getRecomendedList } from '../Redux/Actions/examActions';
import SessionPopup from '../Module/sessionPopup';
import HeaderHome from '../Component/headerHome';

function CourseDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { packageId,levelId } = useParams();
  const [courseData,setcourseData]=useState<any>();
  const [RecomendedData,setRecomendedData]=useState<any>();
  const [sessionData,setsessionData]=useState<any>();
  const [openSession,setopenSession]=useState<boolean>(false)
  const [SelectedSession,setSelectedSession]=useState<string | number>();
  
  function buyNow(){
    if(sessionData && sessionData?.length!=0){
      setopenSession(true)
    }
    else{
      addToCart()
    }
  }
  const addToCart=()=>{
      let PoseData={};
      if(SelectedSession){
        PoseData={
          packages:[{
            packageId:courseData?.packageId,
            packageOptionId:courseData?.packageOptionId,
            sessionId:SelectedSession
          }]
        }
      }
      else{
        PoseData={
          packages:[{
            packageId:courseData?.packageId,
            packageOptionId:courseData?.packageOptionId
          }]
        }
      }
      dispatch(
        addCart(
          PoseData,
          (res: any) => {
            setopenSession(false)
            setSelectedSession("")
            setsessionData([])
            navigate('/MyCart',{state:{isAdditionalPackage:false}});
          },
          () => { }
        )        
    );

        
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(
      getPackageDetails(
        levelId,
        (res: any) => {
          setcourseData(res)
          if(res?.sessions?.length!=0){
            setsessionData(res?.sessions)
          }
          dispatch(
            getRecomendedList(
              {
                "packages":[
                  {
                    "packageId":packageId,
                "levelId":res?.levels?._id
                  }
                ]
                
            },
              (res: any) => {
                setRecomendedData(res?.data)
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
   
  }, [levelId]);
  function gotoCourseDetail(id:any){
    navigate('/CourseDetails/'+id);
  }
  const closeSession=()=>{
    setopenSession(false)
    setSelectedSession("")
    setsessionData([])
  }
  const handleChange=(e:any)=>{
    setSelectedSession(e.target.value)
  }
  return (
    <div >
     <HeaderHome isHidden={false} />
      <div className='container ContainerDiv'>
        {/* <h2 className='boldfont text-center my-3'>{courseData?.examId?.name}</h2> */}
        <div className='row mx-0 mt-5'>
          <div className="col-md-6">
            <img className='w-100 courseBanner' src={courseData?.bannerImage?.bannerImage} alt='Course Banner' />
          </div>
          <div className="col-md-6">
            <div className='detailBox boxshadow rounded p-3'>
              <div className='font24 boldfont mb-2'>{courseData?.name}</div>
              <div className='colorblue mb-2'>{courseData?.courseDescriptionHeader}</div>
              <div>
                {courseData?.courseDescriptions?.map((item:any,index:any)=>(
                  <div className='d-flex mb-2' key={index}>
                  <div><DoneIcon className='tickMark' /></div>
                  <div>{item}</div>
                </div>
                ))}
              </div>
            </div>
          </div>
          <div className='col-12 row mx-0 mb-4 mt-5 d-flex justify-content-end'>
            <div className='col-md-6'>
              <BuyNowCard btnname="Buy Now" setOnClick={buyNow} amount={courseData?.offerPrice}/>
            </div>
          </div>
        </div>
        {/* <div className='position-relative pb-0 mt-5'>
          <h1 className='boldfont text-center textshadow'>The Features That Make <span className='colorblue'>Us Unique</span></h1>
          <Feature data={feature} />
        </div> */}
        <div className='my-4'>
          <p className='boldfont font24'>Recommended Courses</p>

          <div className="row flex-nowrap overflow-auto">
            {RecomendedData?.map((c:any, index:any) => (
              <div className="courseimg col-sm-6 col-lg-5 p-2 me-4" key={index} onClick={()=>gotoCourseDetail(c._id)}>
                <CourseCard
                  course={c?.name}
                  imgpath={c?.bannerImage}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <SessionPopup open={openSession} handleClose={closeSession} sessionData={sessionData} handleChange={handleChange} addToCart={addToCart} SelectedSession={SelectedSession}/>
    </div>
  );
}

export default CourseDetails;
