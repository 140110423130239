import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Buttonnormal from "../Atom/buttonnormal";
import { useNavigate } from "react-router-dom";
import {
  getLectureAmount,
} from "../Redux/Actions/AuthAction";
import { addCart } from "../Redux/Actions/examActions";
import { useAppDispatch } from "../hooks";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import InputField from "../Atom/InputField";
import { useEffect, useState } from "react";
import { validateIsNumberOnly } from "../Utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../Utils/errormsg";

const useDebouncedValue = (inputValue:any, delay:number) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);
    return () => {
      clearTimeout(handler);
    };

  }, [inputValue, delay]);
  return debouncedValue;
};

function ExtentLecture(props: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [count,setCount]=useState<string>("");
  const [amount,setAmount]=useState<string>("");
  const debouncedSearchTerm = useDebouncedValue(count, 500);

  const onChangeCount=(e:string)=>{
    if (!validateIsNumberOnly(e)) {
       setCount(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  }

  useEffect(() => {
    if(debouncedSearchTerm){    
    dispatch(
      getLectureAmount(
        {packageId:props?.packageData?._id,
          isNative:props?.isNative,
          lectureCount:debouncedSearchTerm
        },
        (res: any) => {        
            setAmount(res?.data)
        },
        () => {}
      )
    );
  }

  }, [debouncedSearchTerm]);

  const submit=()=>{
    const postData={
      packages:[{
        packageId:props?.packageData?._id,
        packageOptionId:props?.packageData?.packageOptionId
      }],
      extendPackageOption:true
    }
    dispatch(
      addCart(
        postData,
        (res: any) => {
          navigate('/PlaceOrder',{state:{isExtended:true,lectureCount:count,price:amount}});
        },
        () => { }
      )        
  );
  }
  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={props.openPopup}
        onClose={props.handleClose}
        closeAfterTransition
      >
        <Fade in={props.openPopup}>
          <div className="modeldiv p-4 text-center">
          <div className='d-flex justify-content-between align-items-center'>
              <h5 className='boldfont colorblue mb-0'>Extend Lecture</h5>
            <ClearOutlinedIcon
              className="colorblue fs-3 rounded-circle  cursor float-end"
              onClick={props.handleClose}
            />
            </div>
            <div>
            <div className="d-flex mb-2 align-items-center mt-4 mb-3">
                  <label className="mb-2 fw-bold me-3">Lecture Count :</label>
                  <div className="d-flex">
                    <InputField
                      placeholder="Enter Lecture Count"
                      value={count}
                      maxLength={"2"}
                      //setValue={setfname}
                      setValue={(e:string)=>onChangeCount(e)}
                    />
                  </div>
                </div>
                <div className="d-flex mb-2 align-items-center mt-4 mb-3">
                  <label className="mb-2 fw-bold me-3">Total Amount :</label>
                  <div className="d-flex">
                    <InputField
                      placeholder="Total Amount"
                      value={amount}                    
                      disabled={true}
                      setValue={(e:string)=>setAmount(e)}
                    />
                  </div>
                </div>
            </div>
            
            <div className="text-center mt-5">
              <Buttonnormal name={"Submit"} handleClick={submit} disabled={count===""?true:false}/>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ExtentLecture;
