import { useEffect } from "react";

import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

function NotificationPopUp(props: any) {
  useEffect(() => {}, []);
  let date = new Date(props?.dataCard?.scheduledAt);
  date.toString();

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    props.handleClose();
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.openPopup}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.openPopup}>
        <div className="slotmodeldiv">
          <div className="  mt-0 justify-content-center  ">
            <div className="d-flex">
              <p className="fw-bolder fs-5 text-primary">Notification</p>
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon
                  className="colorblue cursor font28"
                  onClick={closePopup}
                />
              </div>
            </div>

            <div className="row align-items-center boxshadow border border-2 p-4 cursor mb-2 rounded">
              <div className="col-2 col-sm-1 justify-centent-end">
                <img
                  src={props?.dataCard?.notificationIcon}
                  alt="icon"
                  className="notiicon"
                />
              </div>
              <div className="col-10 col-sm-8 ">
                <p className="fw-bold text-success fs-6 m-0 ellipsisNotificationTitle">
                  {props?.dataCard?.notificationTitle}
                </p>

                <p className="  m-0 ellipsisNotification">
                  Dear {props?.dataCard?.userName},
                </p>
                <p className="  m-0 ellipsisNotification mt-1">
                  {props?.dataCard?.notificationText1}
                </p>
                <ul>
                  <li>Topic : {props?.dataCard?.Topic}</li>
                  <li>Date : {props?.dataCard?.Date}</li>
                  <li>Time : {props?.dataCard?.Time}</li>
                </ul>
                <p className="  m-0 ellipsisNotification">
                  {props?.dataCard?.notificationText2}
                </p>
                <p className="  m-0 ellipsisNotification mt-3">Best Regards</p>
                <p className="  m-0 ellipsisNotification">Lurnigo Team</p>
              </div>
              <div className="d-flex justify-content-end">
                <p className="font10 text-secondary">
                  {moment.utc(props?.dataCard?.scheduledAt).format("HH:mm")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default NotificationPopUp;
