// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup{
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: fixed;
    width: 200px;
    z-index: 1;
    right: 10px;
    padding: 10px;
    top: 60px;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/lectureTimer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iDAAiD;IACjD,eAAe;IACf,YAAY;IACZ,UAAU;IACV,WAAW;IACX,aAAa;IACb,SAAS;AACb","sourcesContent":[".popup{\n    background: #fff;\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n    position: fixed;\n    width: 200px;\n    z-index: 1;\n    right: 10px;\n    padding: 10px;\n    top: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
