import React, { useEffect, useState } from 'react';
import localImages from '../Constant/localImagesNew';
import Footer from '../Component/footer';
import HeaderHome from '../Component/headerHome';
import Sidebar from '../Module/sidebar';
import { useSelector } from 'react-redux';
import { SidebarDataFreeUser, SidebarDataPaidUser } from '../Module/sidebarData';
import BookSessionPopup from '../Component/bookSessionPopup';
import BannerImage from '../Module/bannerImage';
import '../Assets/Css/testPrep.css'

export default function CollegePredictor() {
    const homebannerdiv = React.useRef<HTMLDivElement>(null);
    const [openBookSeat, setopenBookSeat] = useState<boolean>(false);
    const closesBooseat = () => {
        setopenBookSeat(false)
    }
    const {ACCESS_TOKEN,hasActivePaidPlan} = useSelector((state:any) => ({
        ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
        hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
      }));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
        
    return (
        <div>
            <HeaderHome isHidden={true} />
            <div className="d-block d-md-none">
            <Sidebar data={hasActivePaidPlan===true?SidebarDataPaidUser:SidebarDataFreeUser}/> 
            </div>
            {/*************Banner************* */}
            <div className='position-relative homeBannerContainer' id='homebanner' ref={homebannerdiv}>
                <div className='row mx-0 '>
                    <div className="col-md-6 homeBannerLeftSide">
                    <div className="homeBannerLeftSide">
                    <img src={localImages?.collegePredictor} style={{width:"60px"}}  className="mb-4" alt='College Predictor'/>
                        <h1 className='homeBannerHeading'>College Predictor</h1>
                        
                        <div className='bannersubtext mt-3 pe-md-5'>We can provide you with a list of recommended universities by trying out School Selector below. Enter your SAT score and take a few minutes to discover your potential!</div>
                        <div className='mt-5'>
                        <button className='signBtn px-4 font16' onClick={()=>setopenBookSeat(true)}>Get recommendations</button>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 position-relative pe-0">
                        <BannerImage src={localImages.collegePredictorBanner}/>
                    </div>
                </div>
            </div>
            
                    {/***************Footer*************** */}
                    <Footer />
                    <BookSessionPopup open={openBookSeat} handleClose={closesBooseat} />
            </div>
            )
}
