import React, { useEffect, useState } from 'react';
import '../Assets/Css/buyNowcard.css'; 
function AcademicSubjectCard(props:any) { 
   
  return (  
      <div className='p-3 h-100'>
        <img src={props?.data?.imgPath} alt={props?.data?.title} className='' />
        <div className='programHeading fw-bold mt-3 mb-2 lh-sm'>{props?.data?.title}</div>
        <div className='font16 text-Justify'>{props?.data?.desc}</div>
      </div>
  );
}

export default AcademicSubjectCard;
