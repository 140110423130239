import { useEffect, useState } from "react";
import "../App.css";
import "../Assets/Css/placeorder.css";
import CartCard from "../Module/cartCard";
import BuyNowCard from "../Module/buyNowCard";
import {
  getCartList,
  paymentRequest,
  placeOrder,
} from "../Redux/Actions/examActions";
import SessionPopup from "../Module/sessionPopup";
import localImages from "../Constant/localImagesNew";
import HeaderHome from "../Component/headerHome";
import { useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import { sendOtp, updateMoreValues } from "../Redux/Actions/AuthAction";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import services from "../Constant/service";
import service from "../Constant/service";

function PaymentReqData() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [totalPrice, settotalPrice] = useState("");
  const [sessionData, setsessionData] = useState<any>();
  const [openSession, setopenSession] = useState<boolean>(false);
  const [SelectedSession, setSelectedSession] = useState<string | number>();
  const [sellingPrice, setSellingPrice] = useState("");
  const [couponName, setCouponName] = useState<any>("");
  const [isCouponApplied, setisCouponApplied] = useState<boolean>(false);
  const [successText, setSuccessText] = useState("");
  const [installmentType, setInstallmentType] = useState("");
  const [installmentData, setinstallmentData] = useState<any>("");
  const [cartData, setCartData] = useState<any>("");
  const [requestedData, setRequestedData] = useState<any>("");
  const [totalLectureCount, setTotalLectureCount] = useState<any>(0);

  const { ACCESS_TOKEN, userCountryCurrency, userCountryCallingCode } =
    useSelector((state: any) => ({
      ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
      userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
      userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode,
    }));

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const reqData: any = query.get("data");
    if (reqData) {
      const ddd = JSON.parse(reqData);
      setRequestedData(ddd);
      const payload = {
        mobile: ddd?.mobileNo,
      };
      if (!ACCESS_TOKEN) {
        dispatch(
          sendOtp(
            payload,
            (data: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              if (data?.statusCode === services?.status_code?.success) {
                if (data?.data?.isRegistered === true) {
                  if (data.data.authToken) {
                    setCouponName(ddd?.couponName);
                    setisCouponApplied(true);
                    setSuccessText("Coupon Applied Successfully");
                    setInstallmentType(ddd?.paymentType?.toString());
                    const postData: any = {
                      countryId: ddd?.countryId,
                      couponName: ddd?.couponName,
                      isCouponApplied: ddd?.isCouponApplied,
                    };
                    if (ddd?.paymentType === 2 || ddd?.paymentType === 3) {
                      postData["customInstallmentOptions"] = true;
                      postData["firstInstallmentPaymentPercentage"] =
                        ddd?.firstInstallmentPaymentPercentage;
                      postData["secondInstallmentPaymentPercentage"] =
                        ddd?.secondInstallmentPaymentPercentage;

                      if (ddd?.thirdInstallmentPaymentPercentage) {
                        postData["thirdInstallmentPaymentPercentage"] =
                          ddd?.thirdInstallmentPaymentPercentage;
                      }
                    }
                    if (ddd?.paymentType === 2) {
                      postData["ratioType"] = ddd?.paymentType;
                      postData["installmentOptions"] = true;
                    }
                    if (ddd?.paymentType === 3) {
                      postData["ratioType"] = ddd?.paymentType;
                      postData["installmentOptions"] = true;
                    }
                    if (ddd?.packageOptionlevelId === 4) {
                      postData["customPackage"] = true;
                    }
                    dispatch(
                      getCartList(
                        postData,
                        (res: any) => {
                          if (res?.cart?.length === 0) {
                            navigate("/MyCart", {
                              state: { isAdditionalPackage: false },
                            });
                          }
                          setTotalLectureCount(
                            res?.cart[0]?.levelId?.offerLectureCount
                          );
                          settotalPrice(res?.fullAmount);
                          setSellingPrice(res?.totalsellingPrice);
                          setinstallmentData(res?.installmentOptions);
                          setCartData(res);
                        },
                        () => {}
                      )
                    );
                  }
                  dispatch(
                    updateMoreValues({ ACCESS_TOKEN: data.data.authToken })
                  );
                  dispatch(updateMoreValues({ email: data.data.email }));
                  dispatch(
                    updateMoreValues({ countryCode: data.data.countryCode })
                  );
                  dispatch(
                    updateMoreValues({ firstName: data.data.firstName })
                  );
                  dispatch(updateMoreValues({ lastName: data.data.lastName }));
                  dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
                  dispatch(
                    updateMoreValues({ examTypeId: data.data.examtypeId?._id })
                  );
                  dispatch(
                    updateMoreValues({ countryName: data.data.countryName })
                  );
                  dispatch(
                    updateMoreValues({
                      examTypeName: data.data.examtypeId?.name,
                    })
                  );
                  dispatch(
                    updateMoreValues({ courseType: data.data.coursetypeId })
                  );
                  dispatch(
                    updateMoreValues({ parentName: data.data.parentName })
                  );
                  dispatch(
                    updateMoreValues({ parentNumber: data.data.parentNumber })
                  );
                  dispatch(
                    updateMoreValues({ parentEmail: data.data.parentEmail })
                  );
                  dispatch(
                    updateMoreValues({ altNumber: data.data.altNumber })
                  );
                  dispatch(updateMoreValues({ userId: data.data.userId }));
                  service.setAuthorizationToken(data.data.authToken);
                  dispatch(
                    updateMoreValues({ studentId: data.data.studentId })
                  );
                  dispatch(
                    updateMoreValues({
                      hasActivePaidPlan: data.data.hasActivePaidPlan,
                    })
                  );
                  dispatch(
                    updateMoreValues({
                      loginFor: "Student",
                    })
                  );
                }
              } else {
                dispatch(showErrorSnackbar("wentWrong"));
              }
            },
            () => {
              dispatch(updateMoreValues({ isLoader: false }));
            }
          )
        );
      } else {
        setCouponName(ddd?.couponName);
        setisCouponApplied(true);
        setSuccessText("Coupon Applied Successfully");
        setInstallmentType(ddd?.paymentType?.toString());
        const postData: any = {
          countryId: ddd?.countryId,
          couponName: ddd?.couponName,
          isCouponApplied: ddd?.isCouponApplied,
        };

        if (ddd?.paymentType === 2 || ddd?.paymentType === 3) {
          postData["customInstallmentOptions"] = true;
          postData["firstInstallmentPaymentPercentage"] =
            ddd?.firstInstallmentPaymentPercentage;
          postData["secondInstallmentPaymentPercentage"] =
            ddd?.secondInstallmentPaymentPercentage;

          if (ddd?.thirdInstallmentPaymentPercentage) {
            postData["thirdInstallmentPaymentPercentage"] =
              ddd?.thirdInstallmentPaymentPercentage;
          }
        }
        if (ddd?.paymentType === 2) {
          postData["ratioType"] = ddd?.paymentType;
          postData["installmentOptions"] = true;
        }
        if (ddd?.paymentType === 3) {
          postData["ratioType"] = ddd?.paymentType;
          postData["installmentOptions"] = true;
        }
        if (ddd?.packageOptionlevelId === 4) {
          postData["customPackage"] = true;
        }
        dispatch(
          getCartList(
            postData,
            (res: any) => {
              if (res?.cart?.length === 0) {
                navigate("/MyCart", { state: { isAdditionalPackage: false } });
              }
              setTotalLectureCount(res?.cart[0]?.levelId?.offerLectureCount);
              settotalPrice(res?.fullAmount);
              setSellingPrice(res?.totalsellingPrice);
              setinstallmentData(res?.installmentOptions);
              setCartData(res);
            },
            () => {}
          )
        );
      }
    } else {
      console.error("No data received");
    }
  }, []);

  const PlaceOrder = (amt: any) => {
    const postData: any = {};
    if (
      isCouponApplied &&
      (installmentType === "2" || installmentType === "3")
    ) {
      postData["couponName"] = couponName;
      postData["additionalAmount"] = cartData?.additionalAmount;
      postData["installmentOptions"] = installmentData;
    } else if (isCouponApplied) {
      postData["couponName"] = couponName;
      postData["sellingPrice"] = sellingPrice;
      postData["offerPrice"] = parseFloat(totalPrice).toFixed(2);
    } else if (installmentType === "2" || installmentType === "3") {
      postData["additionalAmount"] = cartData?.additionalAmount;
      postData["installmentOptions"] = installmentData;
    }
    dispatch(
      placeOrder(
        postData,
        (res: any) => {
          const dataRequest: any = {
            orderNo: res?.orderNo,
            studentId: res?.studentId,
            currency:
              userCountryCurrency === "INR" ? "USD" : userCountryCurrency,
            countryId: requestedData?.countryId,
          };
          // if (isExtended) {
          //   dataRequest["extendPackageOption"] = true;
          //   dataRequest["offerLectureCount"] = lectureCount;
          // }
          if (installmentType === "2" || installmentType === "3") {
            dataRequest["installment"] = "firstInstallment";
          }

          dispatch(
            paymentRequest(
              dataRequest,
              (data: any) => {
                window.location.href = data?.data?.redirect_url;
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  // useEffect(() => {
  //   if (country) {
  //     getCart();
  //   }
  // }, [country]);

  // useEffect(() => {
  //   // if(isCouponApplied){
  //   getCart();
  //   // }
  // }, [isCouponApplied]);

  const removeCart = (id: any) => {
    // dispatch(
    //   deleteCart(
    //     id,
    //     (res: any) => {
    //       getCart();
    //     },
    //     () => {}
    //   )
    // );
  };

  function openSessionPopup(val: any) {
    if (val?.sessions && val?.sessions?.length != 0) {
      setsessionData(val?.sessions);
      setopenSession(true);
    } else {
      addToCart();
    }
  }

  const addToCart = () => {};

  const closeSession = () => {
    setopenSession(false);
    setSelectedSession("");
    setsessionData([]);
  };
  const handleChange = (e: any) => {
    setSelectedSession(e.target.value);
  };

  const getLectureCount = (value: any) => {
    let count: number = 0;
    if (value !== null) {
      count = (totalLectureCount * value) / 100;
    }
    return Math.floor(count);
  };

  return (
    <div>
      <HeaderHome isHidden={false} isPaymentReq={true} />
      {ACCESS_TOKEN ? (
        <div className="ContainerDiv pt-2 d-flex row">
          <div className="col-md-7 ">
            <div className="boxshadow rounded h-100 cartDetailsBox p-3">
              <h4 className="fw-bold boldfont mb-2">Cart Details</h4>
              <div className="">
                <div className="mb-3 p-1">
                  {cartData?.cart?.map((item: any, index: any) => (
                    <CartCard
                      data={item}
                      removeCart={removeCart}
                      openSession={openSessionPopup}
                      isPaymentReq={true}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="placeorderBox  boxshadow rounded p-3">
              <h4 className="fw-bold boldfont mb-2 colorOrange">
                Payment Details
              </h4>
              <div className="">
                <div className="mb-3 placeOrderContentHeight p-1">
                  {installmentType === "1" ? (
                    <div
                      className={`rounded p-2 font12 mb-3 installementCard ${
                        installmentType === "1" ? "active" : ""
                      }`}
                    >
                      <div
                        className={`fw-bold mb-2 d-flex ${
                          installmentType === "1" ? "colorOrange" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          className="me-2"
                          name="installment"
                          // onChange={() => setInstallmentType("1")}
                          checked={installmentType === "1" ? true : false}
                        />{" "}
                        Full Payment
                        {cartData?.isApplicableForInstallment ? (
                          <div className="colorgreen fw-bold ms-3">
                            ( Saved Extra{" "}
                            {getSymbolFromCurrency(userCountryCurrency)}
                            {cartData?.additionalAmount} ){" "}
                          </div>
                        ) : null}
                      </div>
                      <div className="ms-4">
                        <div className="mb-1 fw-bold">
                          Total Payable Amount:{" "}
                          <span className="colorgreen fw-bold">
                            {getSymbolFromCurrency(userCountryCurrency)}
                            {totalPrice ? parseFloat(totalPrice).toFixed(2) : 0}
                          </span>
                        </div>

                        <div>Including all Taxes</div>
                      </div>
                    </div>
                  ) : null}
                  {(installmentType === "2" || installmentType === "3") &&
                  cartData?.isApplicableForInstallment ? (
                    <div
                      className={`boxshadow rounded p-2 py-3 font12 mb-3 installementCard ${
                        installmentType === "2" || installmentType === "3"
                          ? "active"
                          : ""
                      }`}
                    >
                      <div
                        className={`fw-bold ${
                          installmentType === "2" || installmentType === "3"
                            ? "colorOrange"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          className="me-2"
                          name="installment"
                          checked={
                            installmentType === "2" || installmentType === "3"
                              ? true
                              : false
                          }
                        />{" "}
                        Pay fee in {installmentType} EMI
                      </div>

                      <div className="mt-2 ms-4">
                        <p className="mb-1 fw-bold mt-1">
                          Total Payable Amount:{" "}
                          <span className="successcolor fw-bold ms-2">
                            {getSymbolFromCurrency(userCountryCurrency)}
                            {cartData?.netAmount
                              ? parseFloat(cartData?.netAmount).toFixed(2)
                              : 0}
                          </span>
                        </p>
                        <div className="px-2 py-2 rounded w-50 borderBlue rounded border">
                          <p className="mb-1 fw-bold">
                            1st Installment{" "}
                            <span className="successcolor fw-bold ms-2">
                              {getSymbolFromCurrency(userCountryCurrency)}{" "}
                              {installmentData?.installment1
                                ? parseFloat(installmentData?.installment1
                                    ?.firstInstallment).toFixed(2)
                                : 0}
                            </span>
                          </p>
                          <p className="mb-0 font10">Pay by Today</p>
                        </div>
                        <div className="px-2 py-2 rounded border mt-2 w-50">
                          <p className="mb-1 fw-bold">
                            2nd Installment{" "}
                            <span className="successcolor fw-bold ms-2">
                              {getSymbolFromCurrency(userCountryCurrency)}{" "}
                              {installmentData?.installment2
                                ? parseFloat(installmentData?.installment2
                                    ?.secondInstallment).toFixed(2)
                                : 0}
                            </span>
                          </p>
                          <div>
                            <p className="mb-0 font10">
                              pay before{" "}
                              {getLectureCount(
                                installmentData?.installment2
                                  ?.secondLectureRatio
                              )}{" "}
                              lecture
                            </p>
                          </div>
                        </div>
                        {installmentType === "3" ? (
                          <div className="px-2 py-2 border mt-2 w-50 rounded">
                            <p className="mb-1 fw-bold">
                              3rd Installment{" "}
                              <span className="successcolor fw-bold ms-2">
                                {getSymbolFromCurrency(userCountryCurrency)}{" "}
                                {installmentData?.installment3
                                  ? parseFloat(installmentData?.installment3
                                      ?.thirdInstallment).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <div>
                              <p className="mb-0 font10">
                                pay before{" "}
                                {getLectureCount(
                                  installmentData?.installment3
                                    ?.thirdLectureRatio
                                )}{" "}
                                lecture
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="buyNowCarddiv mb-3 ms-2 ">
                  <div className="d-flex ">
                    <div className="position-relative">
                      <img
                        src={localImages?.offerimg}
                        className="offerimg position-absolute ms-1"
                        alt="offerimg"
                      />
                      <input
                        className="border-0 border-bottom border-dark couponinput colorblue fw-bold"
                        type="text"
                        placeholder="Have a Coupon Code?"
                        value={couponName}
                        maxLength={20}
                        disabled={isCouponApplied}
                        style={{ width: "233px" }}
                      />
                    </div>
                  </div>
                  <div>
                    {successText ? (
                      <span className="colorgreen  mt-1  font11 ">
                        {successText}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <BuyNowCard
                  btnname="Place Order"
                  setOnClick={PlaceOrder}
                  amount={
                    installmentType == "2" || installmentType === "3"
                      ? installmentData?.installment1?.firstInstallment
                      : totalPrice
                  }
                  count={cartData?.cart?.length}
                />
                {/* {checkout ? <PayPal total={500} open={checkout} /> : null} */}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <SessionPopup
        open={openSession}
        handleClose={closeSession}
        sessionData={sessionData}
        handleChange={handleChange}
        addToCart={addToCart}
        SelectedSession={SelectedSession}
      />
    </div>
  );
}

export default PaymentReqData;
