import React from 'react';
import { useSelector } from 'react-redux';
import '../Assets/Css/bussinessCard.css';
import  LocalImages  from '../Constant/localImages';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WebIcon from '@mui/icons-material/Web';
import logo from '../Assets/Images/Home/logo.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import RedditIcon from '@mui/icons-material/Reddit';
import TwitterIcon from '@mui/icons-material/Twitter';
  function KailashChoudhary() {
  return (
    <div className='bussinessCardDiv'>
      <div className='bussinessCard'>
        <div>
        <img
            src={logo}
            alt="IFAS Logo"
            className="bcLogo"
          />
        </div>
        <div>
          <img src={LocalImages.bussinessCardKCsir} alt='kc Sir' className='bussinesscardImg'/>
        </div>
        <div className='px-4 mt-3'>
          <h3 className='fw-bold'>Dr. Kailash Choudhary</h3>
          <h6 className='fw-normal'>Managing Director</h6>
          <h6 className='fst-italic fw-normal colorblue'>IFAS Edutech PVT Ltd</h6>
        </div>
        <div className='bssubtext'>India's No. 1 Edtech Company for State PSC, Post Graduate & PhD Entrance Examination</div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><MailIcon style={{color:"#353535"}}/></span>
            <span><a href="mailto:kc@ifasonline.com" style={{color:"#353535", textDecoration:"none"}}>kc@ifasonline.com</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><CallIcon style={{color:"#353535"}}/></span>
            <span><a href="callto:+919460660533" style={{color:"#353535" ,textDecoration:"none" }}>+1 (408) 592-9889</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><LinkedInIcon style={{color:"#353535"}}/></span>
            <span ><a href="https://www.linkedin.com/in/dr-kailash-choudhary-76395522" target='_blank' rel="noreferrer" style={{color:"#353535" ,textDecoration:"none"}}>Dr Kailash Choudhary</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><RedditIcon style={{color:"#353535"}}/></span>
            <span ><a href="https://www.reddit.com/user/Lurnigo_Inc" target='_blank' rel="noreferrer" style={{color:"#353535",textDecoration:"none"}}>Dr Kailash Choudhary</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><FacebookIcon style={{color:"#353535"}}/></span>
            <span><a href="https://www.facebook.com/Lurnigo.US" target='_blank' rel="noreferrer" style={{color:"#353535",textDecoration:"none"}}>IFAS KC</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><InstagramIcon style={{color:"#353535"}}/></span>
            <span><a href="https://www.instagram.com/lurnigo_/" target='_blank' rel="noreferrer" style={{color:"#353535",textDecoration:"none"}}>kailash_lurnigo</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><TwitterIcon style={{color:"#353535"}}/></span>
            <span><a href="https://twitter.com/LurniGo_Inc" target='_blank' rel="noreferrer" style={{color:"#353535",textDecoration:"none"}}>kailash_lurnigo</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><WebIcon style={{color:"#353535"}}/></span>
            <span><a href="https://lurnigo.com/" target='_blank' rel="noreferrer" style={{color:"#353535",textDecoration:"none"}}>lurnigo.com/  </a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><WhatsAppIcon style={{color:"#353535"}}/></span>
            <span><a href="https://api.whatsapp.com/send?phone=9460660533" target='_blank'rel="noreferrer" style={{color:"#353535",textDecoration:"none"}}>94606 60533</a></span>
        </div>
        <div className='d-flex align-items-center bslinkcard'>
            <span className='bcicon'><YouTubeIcon style={{color:"#353535"}}/></span>
            <span><a href="https://www.youtube.com/@Lurnigo" target='_blank' rel="noreferrer" style={{color:"#353535",textDecoration:"none"}}>Lurnigo</a></span>
        </div>
        <div className='d-flex  bslinkcard'>
            <span><span className='bcicon'><LocationOnIcon style={{color:"#353535"}}/></span></span>
            <span><a href="#" style={{color:"#353535", textDecoration:"none"}}>Address - 781 Via Baja Dr. Milpitas, CA 95035, USA</a></span>
        </div>
      </div>
    </div>
  );
};

export default KailashChoudhary;
