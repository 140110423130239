import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
//import { examList } from '../Utils/dataConstants';


function BbMeetingPopup(props: any) {

  const joinLive = () => { 
      window.open(props?.link, '_blank',"width="+window.screen?.availWidth+",height="+window.screen?.availHeight+',dialog=yes,resizable=no'); 

    }

  const endLive = () => {

    //   dispatch(startEndLiveStatus({
    //     "userId": userId,
    //     "liveId": liveVideoData.liveId,
    //     "liveType": liveVideoData.liveType,
    //     "isLive": false,
    //     "attendeeUrl": liveVideoData.attendeeUrl,
    // }))
    props.handleClose()

  }

  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={props.open}>
           <div className="modeldiv p-4 py-5">
           <ClearOutlinedIcon
              className="colorblue fs-1 p-1 shadow rounded-circle border-primary cursor float-end mt-3 me-3"
              onClick={props.handleClose}
            />
              <div>
                <div>
                  <h4 className="ms-3 colorblue fw-bold text-center">BBB Live Video</h4>
                  <p className="ms-3 mt-3 text-center">Click below button to join live</p>
                </div>
                <div className="d-flex justify-content-evenly mt-5">
                  <button type="button" className="btn btn-primary px-5" onClick={joinLive}>
                    Join
                  </button>
                  <button type="button" className="btn btn-primary px-5" onClick={endLive}>
                    End Live
                  </button>
                </div>
              </div>
            </div>
          
        </Fade>
      </Modal>
    </div>
  );
}

export default BbMeetingPopup;
