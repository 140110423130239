import { useEffect, useState } from "react";
import { useAppDispatch } from "../hooks";
import { useLocation } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {  getOrderDetails} from "../Redux/Actions/AuthAction";
import moment from "moment";

function OrderDetails() {
  const dispatch = useAppDispatch();
  const [orderDetails, setOrderDetails] = useState([]);
  const location = useLocation();
  const orderId = location?.state?.orderId;
  const paymentId = location?.state?.paymentId;
  
  useEffect(() => {
    console.log('orderId', orderId,paymentId);
    if(orderId && paymentId){
      dispatch(
        getOrderDetails(
          {orderId,paymentId},
          (data: any) => {
            setOrderDetails(data?.data)
          },
          () => {}
        )
      );
    }
    
 
  }, [orderId,paymentId]);
  console.log('orderDetails', orderDetails);
  return (
    <>

   <div className="rightPanel">
      {/* <div className="d-flex justify-content-between">
        <h3 className="fw-bold  ">Order History #{item?.orderhistoryid}</h3>
        <button className="btn btn-primary fw-bold ">Download Invoice  <ChevronRightIcon /></button>
      </div> */}
       {orderDetails?.map((item:any)=>{ 
    return( <div>
      <div className="row mt-4">
      {item?.packages?.map((c:any)=>{ 
      return(<div className="col-md-4">
          <div className="card boxshadow p-3 h-100 border-0">
            <div className="row">
              <div className="col-md-8">
                <b className="fw-bold">{c?.packageName}</b>
                <p className="  mb-0 text-secondary">{item?.planName} - {item?.lectureCount} Lecture </p>
              </div>
              <div className="col-md-4 p-0 ">
              <img
                       
                        className="img-fluid img-thumbnail border-0 h-100 "
                        src={c?.bannerImage}
                        alt='banner'
                        
                      />
              </div>
            </div>
          </div>
        </div>)})}
        <div className="col-md-4">
          <div className="card boxshadow p-3 h-100 border-0">

            <p className="fw-bold text-primary">Payment Information</p>
            {/* <p className="font12 mb-0 mt-1 ">
              Payment Method
            </p> */}

            <div className=" p-0 ">
              <div className="d-flex justify-content-between">
              <p className="  mb-0  ">
              Payment Method
            </p>
                <p className="mb-0 ">PayPal</p>
              </div></div>

          </div>
        </div>
      </div>
      <div className="row boxshadow mt-4 py-2 p-md-4 bgwhite">
        <div className="col-md-4 justify-content-right">
          <p className="fw-bold text-primary">
            <b>Billing Address</b>
          </p>
          <p className=" ">
            <b>
              {item?.firstname}  {item?.lastname}
            </b>
          </p>
          {/* <p className="font12 mb-0">
          {item?.address}
          </p>
          <p className="font12 mb-0">
          {item?.city} {item?.country_code}
          </p> */}
          <p className="font12 mb-0">
            Phone Number: +1-{item?.mobile}
          </p>

        </div>
        <div className="col-md-4 justify-content-right">
          <p className="fw-bold text-primary">
            <b>Order Details</b>
          </p>
          <p className="text-secondary mb-0">
          Purchase Date :  {moment(item?.createdAt).format('DD MMM YYYY')} 
          </p>
          <p className="text-secondary mb-0">
          Order ID: {item?.orderhistoryid}
          </p>
          <p className="text-secondary mb-0">
          Amount Paid:  {item?.TotalAmount}
          </p>
       
                    <p className="  mb-0 text-secondary">Subscription Start date :{moment(item?.subscriptionStartDate).format('DD MMM YYYY')}  </p>
                    <p className="  mb-0 text-secondary">Subscription End date :{item?.subscriptionEndDate  ?<> {moment(item?.subscriptionEndDate).format('DD MMM YYYY')} </> :" -"} </p>
                    {item?.couponName?<p className="  mb-0 text-secondary">Coupon Code : {item?.couponName}</p>:null}
                    {item?.remainingAmount?<p className="  mb-0 text-secondary">Remaining Amount : {item?.remainingAmount}</p>:null}
                    

        </div>
        <div className="col-md-4 justify-content-right">
          <p className="fw-bold text-primary">
            <b>Order Summary</b>
          </p>
          <div className="d-flex justify-content-between">
                <p className="text-secondary mb-0">Selling Price</p>
                <p className="text-secondary mb-0"> {item?.sellingPrice}</p>
              </div>
               <div className="d-flex justify-content-between">
                <p className="text-secondary mb-0">Coupon Discount</p>
                <p className="text-secondary mb-0">  {item?.discountedAmount}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="text-secondary mb-0">Total Taxable Amount</p>
                <p className="text-secondary mb-0"> {item?.tax}</p>
              </div>
            
              
              <div className="d-flex  justify-content-between border-top">
             <p className="mt-2"><span className="fw-bold"> Total Amount</span> (Including all Taxes)</p>
                <p className="text-secondary fw-bold mt-2">  {item?.TotalAmount}</p>
              </div>
        </div>
      </div>
      </div>)})}
    </div></>
  );
}

export default OrderDetails;
