import React, { useEffect, useState } from "react";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import { useAppDispatch } from "../hooks";
import { useSelector } from "react-redux";
import {
  addMarketingLandingData,
  getCountry,
  getCourseTypelist,
  getGeneralExamList,
  getSubjectList,
  gradeLevelList,
  sendOtp,
  updateMoreValues,
} from "../Redux/Actions/AuthAction";
import { validateFullName, validateIsNumberOnly } from "../Utils/validators";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import InputField from "../Atom/InputField";
import PhoneInputField from "../Atom/phoneInputField";
import Dropdown from "./dropdown";
import DropdownCustom from "./dropdownCustom";
import OTPInput from "react-otp-input";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useNavigate, useParams } from "react-router";
import service from "../Constant/service";

export default function LearnLandingForm(props?: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [firstName, setfirstname] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [email, setemail] = useState("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState<any>([]);
  const [coutryCode, setcoutryCode] = useState<string>("1");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>("");
  const [subjectList, setSubjectList] = useState([]);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [grade, setGrade] = useState<string>("");
  const [exam, setExam] = useState<string>("");
  const [examList, setexamList] = useState([]);
  const [step, setStep] = useState<string>("step1");
  const [otp, setotp] = useState("");
  const [{ inputType }] = React.useState({
    numInputs: 4,
    separator: "-",
    minLength: 0,
    maxLength: 40,
    placeholder: "-",
    inputType: "text" as const,
  });
  const { userCountryCallingCode } = useSelector((state: any) => ({
    userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode,
  })); 
  useEffect(() => {
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 4);
          setGradeList(filterGrade);
        },
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      getCountry(
        (data: any) => {
          const filterCountry = data?.filter(
            (item: any) => item?.phonecode == 1
          );
          setCountryData(filterCountry);
          const postData = {
            countryId: filterCountry[0]?._id,
          };

          dispatch(
            getCourseTypelist(
              postData,
              (res: any) => {
                setCourseList(res?.data);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  }, [userCountryCallingCode]);
  const closePopup = () => {
    setIsSubmit(false);
    clearData();
  };

  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfirstname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };

  function handleSubmit(e: any) {
    e.preventDefault();

    window.confirmationResult
      .confirm(otp)
      .then(async (res: any) => {
        const postData = {
          firstName,
          mobile: mob,
          countryId: "226",
          countryCode: "+" + coutryCode,
          coursetypeId: course?._id,
          sourceId: 5,
          // email: email,
          gradeId: grade,
          otpVerified: true,
          smsourceId: id,
        };
        if (course?.type === 2) {
          postData["subjectId"] = subject;
        }
        if (course?.type === 1) {
          postData["examtypeId"] = exam;
          postData["subjectId"] = subject;
        }
        dispatch(
          addMarketingLandingData(
            postData,
            (res: any) => {
              const payload = {
                mobile: mob,
              };
              dispatch(
                sendOtp(
                  payload,
                  (data: any) => {
                    if (data?.statusCode === service?.status_code?.success) {
                      if (data?.data?.isRegistered === true) {
                        dispatch(
                          updateMoreValues({
                            ACCESS_TOKEN: data.data.authToken,
                          })
                        );
                        dispatch(updateMoreValues({ email: data.data.email }));
                        dispatch(
                          updateMoreValues({
                            countryCode: data.data.countryCode,
                          })
                        );
                        dispatch(
                          updateMoreValues({ firstName: data.data.firstName })
                        );
                        dispatch(
                          updateMoreValues({ lastName: data.data.lastName })
                        );
                        dispatch(
                          updateMoreValues({ mobileNo: data.data.mobile })
                        );
                        dispatch(
                          updateMoreValues({
                            examTypeId: data.data.examtypeId?._id,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            examTypeName: data.data.examtypeId?.name,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            courseType: data.data.coursetypeId,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentName: data.data.parentName,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentNumber: data.data.parentNumber,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentEmail: data.data.parentEmail,
                          })
                        );
                        dispatch(
                          updateMoreValues({ altNumber: data.data.altNumber })
                        );
                        dispatch(
                          updateMoreValues({ userId: data.data.userId })
                        );
                        service.setAuthorizationToken(data.data.authToken);
                        dispatch(
                          updateMoreValues({ studentId: data.data.studentId })
                        );
                        dispatch(
                          updateMoreValues({
                            hasActivePaidPlan: data.data.hasActivePaidPlan,
                          })
                        );

                        navigate("/ThankYou", {
                          state: { pagename: "LearnLandingForm" },
                        });
                      }
                    } else {
                      dispatch(showErrorSnackbar("wentWrong"));
                    }
                  },
                  () => {}
                )
              );
            },
            () => {}
          )
        );
      })
      .catch((err: any) => {});
  }

  function clearData() {
    setfirstname("");
    setemail("");
    setmob("");
    setSubject("");
    setExam("");
    setCourse("");
    setexamList([]);
    setSubjectList([]);
  }

  function validation() {
    return (
      firstName === "" ||
      // email === "" ||
      mob?.length < 10 ||
      (course?.type !== 3 && subject === "")
    );
  }
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
  const changeCategory = (val: any) => {
    console.log("changeCategory", val);
    setCourse(val);
    if (val?.type === 1) {
      dispatch(
        getGeneralExamList(
          {
            courseId: val?._id,
          },
          (res: any) => {
            setexamList(res?.data);
          },
          () => {}
        )
      );
    } else if (val?.type === 2) {
      const postData = {
        coursetypeId: val?._id,
        gradeId: grade,
      };
      dispatch(
        getSubjectList(
          postData,
          (res: any) => {
            setSubjectList(res?.data);
          },
          () => {}
        )
      );
    } else {
    }
  };
  const changeSubject = (val: any) => {
    setSubject(val);
  };

  const onChangeExam = (val: any) => {
    setExam(val);
    const postData = {
      coursetypeId: course?._id,
      examtypeId: val,
    };
    dispatch(
      getSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleOTPChange = (otp: string) => {
    setotp(otp.toString());
  };
  function OTPValidate() {
    return otp?.length < 6;
  }

  const onclickSendotp = () => {
    const formatPh = "+" + coutryCode + mob;
    let verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });

    if (auth && formatPh && verifier) {
      signInWithPhoneNumber(auth, formatPh, verifier)
        .then(function (result: any) {
          if (result) {
            window.confirmationResult = result;
            dispatch(showSuccessSnackbar("OTP sent successfully!"));
            verifier.clear();
            const payload: any = {
              firstName,
              mobile: mob,
              countryCode: "+" + coutryCode,
              coursetypeId: course?._id,
              countryId: "226",
              sourceId: 5,
              // email: email,
              gradeId: grade,
              otpVerified: false,
              smsourceId: id,
            };
            if (course?.type === 2) {
              payload["subjectId"] = subject;
            }
            if (course?.type === 1) {
              payload["examtypeId"] = exam;
              payload["subjectId"] = subject;
            }
            dispatch(
              addMarketingLandingData(
                payload,
                (data: any) => {
                  setStep("step2");
                },
                (error: any) => {
                  dispatch(showErrorSnackbar(error?.data?.msg));
                }
              )
            );
          }
        })
        .catch(function (err: any) {
          dispatch(showErrorSnackbar(err.name));
          verifier.clear();
        });
    }
  };

  return (
    <div className="row mx-0">
      <form className="w-100" onSubmit={handleSubmit}>
        {step === "step1" ? (
          <div className="row mx-0">
            <div className="my-2 col-md-6">
              <label className="mb-2 colorgray707070">Parents Name</label>
              <div className="d-flex">
                <InputField
                  placeholder="Enter Parents Name"
                  value={firstName}
                  setValue={handleFirstInputText}
                  maxLength={100}
                />
                <span className="mendatory ms-2 fs-5">*</span>
              </div>
            </div>
            <div className="my-2 col-md-6">
              <label className="mb-2 colorgray707070">Phone Number</label>
              <div className="d-flex position-relative">
                <select
                  className="form-select cursor coutryCode  rounded-0 rounded-start"
                  aria-label="Default select example"
                  defaultValue={1}
                  value={coutryCode}
                  onChange={onChangeCoutryCode}
                >
                  {countryData?.map((item: any, index) => {
                    return (
                      <option value={item.phonecode}>
                        {item?.isoCode} +{item?.phonecode}
                      </option>
                    );
                  })}
                </select>
                <PhoneInputField
                  placeholder="Enter Mobile Number"
                  value={mob}
                  maxLength={"10"}
                  setValue={setPhoneNumber}
                />
                <span className="mendatory ms-2 fs-5">*</span>
              </div>
            </div>
            {/* <div className="my-2 col-md-6">
              <label className="mb-2 colorgray707070">Email</label>
              <div className="d-flex">
                <input
                  className="form-control p-2"
                  type="email"
                  value={email}
                  placeholder="Enter Your Email Address"
                  onChange={(e) => setemail(e.target.value)}
                />
                <span className="mendatory ms-2 fs-5">*</span>
              </div>
            </div> */}
            <div className="my-2 col-md-6">
              <label className="mb-2 colorgray707070">Student Grade</label>
              <div className="d-flex">
                <Dropdown
                  placeholder="Select Grade"
                  setInputText={(value: any) => setGrade(value)}
                  value={grade}
                  options={gradeList}
                />
                <span className="mendatory ms-2 fs-5">*</span>
              </div>
            </div>
            <div className="my-2 col-md-6">
              <label className="mb-2 colorgray707070">Category</label>
              <div className="d-flex">
                <DropdownCustom
                  placeholder="Select Category"
                  setInputText={(value: any) => changeCategory(value)}
                  value={course}
                  options={courseList}
                />
                <span className="mendatory ms-2 fs-5">*</span>
              </div>
            </div>
            {course?.type === 1 ? (
              <div className="my-2 col-md-6">
                <label className="mb-2 colorgray707070">Exam</label>
                <div className="d-flex">
                  <Dropdown
                    placeholder="Select Exam"
                    setInputText={(value: any) => onChangeExam(value)}
                    value={exam}
                    options={examList}
                  />
                  <span className="mendatory ms-2 fs-5">*</span>
                </div>
              </div>
            ) : null}
            {course?.type !== 3 ? (
              <div className="my-2 col-md-6">
                <label className="mb-2 colorgray707070">Subject</label>
                <div className="d-flex">
                  <Dropdown
                    placeholder="Select Your Subject"
                    setInputText={(value: any) => changeSubject(value)}
                    value={subject}
                    options={subjectList}
                  />
                  <span className="mendatory ms-2 fs-5">*</span>
                </div>
              </div>
            ) : null}
            <div id="recaptcha-container"></div>

            <div className="d-flex justify-content-center mb-5 mt-4 text-center">
                            <button
                              onClick={onclickSendotp}
                              className={`bookSessionButton  col-md-7  rounded fw-bold ${
                                validation() ? "bg-secondary" : "active"
                              }`}
                              // disabled={validation()}
                              type="button"
                            >
                              Send OTP{" "}
                            </button>
                          </div>
          </div>
        ) : step === "step2" ? (
          <div className="row mx-0">
            <div className="">
              <h3 className="boldfont text-center mb-2 bookSessionOrange mt-5">
                Please enter OTP to schedule a live class with your teacher
              </h3>
              <div className="font16 text-center my-1">
                Enter a 6 digit OTP sent to{" "}
                <span className="bookSessionOrange fw-bold">
                  +{coutryCode} {mob}
                </span>
              </div>

              <div className="">
                <div className="otpBox cursor mt-2">
                  <OTPInput
                    inputStyle="inputStyle"
                    numInputs={6}
                    onChange={handleOTPChange}
                    renderSeparator={<span className="mx-2 mx-md-3"></span>}
                    value={otp}
                    inputType={inputType}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mb-5 mt-4 text-center">
                        <button
                          // onSubmit={onOTPVerify}
                          className={`bookSessionButton col-md-7 rounded fw-bold ${
                            OTPValidate() ? "" : "active"
                          }`}
                          disabled={OTPValidate()}
                        >
                          Submit{" "}
                        </button>
                      </div>
          </div>
        ) : null}
      </form>
    </div>
  );
}
