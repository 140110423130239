import { useEffect, useState } from "react";
import { useCountDown } from "./useCountDown";
import DateTimeDisplay from "./dateTimeDisplay";
import '../Assets/Css/countDownTimer.css';
const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};
const ShowCounter=({days,hours,minutes,seconds})=>{
  return(
    <div className="show-counter align-items-center">
      <DateTimeDisplay value={days} type={"Days"} isDanger={days<=2}/>
      {/* <p>:</p> */}
      <DateTimeDisplay value={hours} type={"Hours"} isDanger={days + hours<=0}/>
      {/* <p>:</p> */}
      <DateTimeDisplay value={minutes} type={"Minutes"} isDanger={days + hours +minutes<=0}/>
      {/* <p>:</p> */}
      <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={days + hours +minutes+seconds<=0}/>
    </div>
  )
}
const CountDownTimer = ({targetDate,setIstimeExpire}) => {
  const [days, hours, minutes, seconds] = useCountDown(targetDate);

if(days + hours+ minutes + seconds <=0){
  // return <ExpiredNotice/>
  return setIstimeExpire(true)
}
else{
  return (
    <ShowCounter 
    days={days}
    hours={hours} 
    minutes={minutes}   
    seconds={seconds} />
  )
}
}


export default CountDownTimer;