import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Buttonnormal from "../Atom/buttonnormal";
import Lottie from "react-lottie";
import orderCompleted from "../Assets/Images/json/ordercompleted.json";
import { useNavigate } from "react-router-dom";
import {
  updateMoreValues,
} from "../Redux/Actions/AuthAction";
import { getUserDetails } from "../Redux/Actions/examActions";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hooks"; 
//import { examList } from '../Utils/dataConstants';

function FormSubmitPopUp(props: any) {
  console.log("FormSubmitPopUp",props)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { studentId } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    studentId: state?.AuthReducer?.studentId,
  }));
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderCompleted,
    renderer: "svg",
  };
  const gotoHome = () => {
    dispatch(
      getUserDetails(
        studentId,
        (res: any) => {
          dispatch(
            updateMoreValues({ hasActivePaidPlan: res[0].hasActivePaidPlan })
          );
        },
        () => {}
      )
    );
    navigate("/User/Dashboard");
  };
  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
      >
        <Fade in={props.open}>
          <div className="modeldiv p-5 text-center">
            <Lottie options={defaultOptions} height={130} width={200}></Lottie>
            <p className="text-center colorblue font24">
              <b>Thank You for Applying.. </b>
               
            </p>
            <p className="text-center colorblue font24">
               <b className="mt-2"> Your Form is Under review</b>
            </p>
             
            <div className="text-center mt-5">
              {/* <Buttonnormal name={"Proceed to home"} handleClick={gotoHome} /> */}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default FormSubmitPopUp;
