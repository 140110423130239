import React, { useEffect, useState } from "react";
import localImages from "../Constant/localImages";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import "../Assets/Css/header.css";
import MenuIcon from "@mui/icons-material/Menu";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";
interface props {
  isHidden: boolean;
  pagename?:string
}

export default function HeaderDsat({ isHidden ,pagename }: props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [menu, showMenu] = useState(false);
  function goToLink(val: string): void {
    // navigate('/'+val);
    showMenu(false);
    const section = document.querySelector("#" + val);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  function goToHome(): void {
    navigate("/");
  }

  function toggleMenu(): void {
    showMenu(!menu);
  }

  return (
    <header className={`${isHidden ? "hide" : "show"}`} id="headerHome">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <img
            src={localImages.logo}
            alt="IFAS Logo"
            className="headerlogo cursor"
            // onClick={goToHome}
          />
        </div>
        <div className="d-none d-md-flex flex-fill justify-content-center align-items-center ms-md-5">
          <div className="d-flex">
            <div className="cursor menuDiv" onClick={() => goToLink("Home")}>
              {" "}
              Home{" "}
            </div>
            <div
              className="cursor menuDiv"
              onClick={() => goToLink("OurProgram")}
            >
              {" "}
              Our Program{" "}
            </div>
           {pagename === "College_Counseling"?  "": (<div className="cursor menuDiv" onClick={() => goToLink("WhyUs")}>
              {" "}
              Why Us{" "}
            </div>)}
            <div
              className="cursor menuDiv"
              onClick={() => goToLink("Testimonials")}
            >
              Testimonials
            </div>
            {pagename === "College_Counseling" ?  "": (<div className="cursor menuDiv" onClick={() => goToLink("Awards")}>
              Awards & Certifications
            </div>)}
            <div className="cursor menuDiv" onClick={() => goToLink("FAQs")}>
              FAQs
            </div>
          </div>
          {/* <button className='signBtn px-4' onClick={goToLogin}><WhatsAppIcon className='me-2'/> Chat with us</button> */}
        </div>
        {/* <a
          className="signBtn px-4 text-decoration-none d-none d-md-flex"
          href="//api.whatsapp.com/send?phone=16693056669"
          target="_blank"
          rel="noreferrer"
        >
          <WhatsAppIcon className="me-2" /> Chat with us
        </a> */}
        <div className="signBtn px-4 text-decoration-none d-none d-md-flex cursor" onClick={() => goToLink("chatWithUs")}>
        <WhatsAppIcon className="me-2" /> Chat with us
            </div>
        <div
          className="d-xs-flex d-sm-flex d-md-none me-2 cursor"
          onClick={toggleMenu}
        >
          <MenuIcon />
        </div>
        <div className={`mobileMenuContainer ${menu ? "active" : ""}`}>
          <div className="d-flex align-items-center border-bottom pb-2 mb-3">
            <div>
              <img src={localImages?.logo} alt="Logo" className="headerlogo" />
            </div>
            <div className="d-flex flex-fill justify-content-end">
              <CloseIcon onClick={() => showMenu(false)} />
            </div>
          </div>
          <div>
            <div className="cursor menuDiv" onClick={() => goToLink("Home")}>
              {" "}
              Home{" "}
            </div>
            <div
              className="cursor menuDiv"
              onClick={() => goToLink("OurProgram")}
            >
              {" "}
              Our Program{" "}
            </div>
            {/* <div className="cursor menuDiv" onClick={() => goToLink("WhyUs")}>
              {" "}
              Why Us{" "}
            </div> */}
            <div
              className="cursor menuDiv"
              onClick={() => goToLink("Testimonials")}
            >
              Testimonials
            </div>
            {/* <div className="cursor menuDiv" onClick={() => goToLink("Awards")}>
              Awards & Certifications
            </div> */}
            <div className="cursor menuDiv" onClick={() => goToLink("FAQs")}>
              FAQs
            </div>
            <div className="cursor menuDiv" onClick={() => goToLink("chatWithUs")}>
              Chat with us
              FAQs
            </div>
          </div>
        </div>
      </div>
    </header>

  );
}
