import React, { useState } from 'react';
import '../App.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cart from '../Assets/Images/sidebardata/cart.png';
import bell from '../Assets/Images/sidebardata/bell.png';
import AppleIcon from '../Assets/Images/sidebardata/AppleIcon.png';
import Playstore from '../Assets/Images/sidebardata/playstore.png';
import { AuthReducerModal } from '../Modals';

function Sidebar(props: any) {
  const navigate = useNavigate();
  const {
    ACCESS_TOKEN,
    firstName,
    lastName,
    email,
    mobileNo,
    parentName,
    parentMobile,
    parentEmail,
    alternateNumber,
    dateOfBirth,
    stateName,
    userId,
    profilePicture,
    studentId,
  } = useSelector((state: { AuthReducer: AuthReducerModal }) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    mobileNo: state?.AuthReducer?.mobileNo,
    parentName: state?.AuthReducer?.parentName,
    parentMobile: state?.AuthReducer?.parentNumber,
    parentEmail: state?.AuthReducer?.parentEmail,
    alternateNumber: state?.AuthReducer?.alternateNumber,
    dateOfBirth: state?.AuthReducer?.dateOfBirth,
    stateName: state?.AuthReducer?.stateName,
    userId: state?.AuthReducer?.userId,
    studentId: state?.AuthReducer?.studentId,
    profilePicture: state?.AuthReducer?.profilePicture,
  }));

  const currentURLarr = useLocation().pathname.split('/');
  let link = currentURLarr[currentURLarr.length - 1];
  if (link === 'Course-dashboard') link = 'createParentCourse';

  function goToLogin(event: React.MouseEvent<HTMLButtonElement>): void {
    navigate('/Login');
  }
  function goToLink(val:string): void {
    navigate('/'+val);
  }
  return (
    <div className="sidebar shadow" id="sidebarMenu">
      {ACCESS_TOKEN?
       <ul className="sidebarList">
       <div className="d-flex d-md-none align-items-center border-bottom border-2 pb-2 mb-2">
         <div className="profilePhoto shadow rounded-circle">
           <img src={profilePicture} alt="Profile" className="rounded-circle" style={{ width: '20px' }} />
         </div>
         <div className="ms-3">
           <div className="colorblue">
             {firstName} {lastName}
           </div>
           <div className="color-grey">{mobileNo}</div>
         </div>
       </div>
       <div className="d-flex d-md-none align-items-center" style={{padding:"10px"}}>
         <img className="icon py-1 me-2 cursor position-relative" src={bell} alt="bell" />
         <span>Notification</span>
       </div>
       <div className="d-flex d-md-none align-items-center" style={{padding:"10px"}} >
         <img className="icon py-1 me-2" src={cart} alt="cart" />
         <span>My Cart</span>
       </div>

       {props.data.map((val: any, key: any) => {
         const index = val.extra.findIndex((item: any) => item === link);

         return (
           <li key={key} id={key} className={index > -1 ? 'sideListRow active' : 'sideListRow'} >
             <Link to={val.link}>
               <img className="me-2" src={val.imgpath} alt={val.title} />
               {val.title}
             </Link>
           </li>
         );
       })}


       {/* <div className="mt-3 downloadsection">
         <p className="fw-bold boldfont" style={{ color: 'var(--blue)' }}>
           Download App Now
         </p>
         <div className="mb-3 ">
           <a
             href="https://play.google.com/store/apps/details?id=com.ifasapp"
             target="_blank"
             className="d-flex store align-items-center rounded-3"
             rel="noreferrer">
             <div className="imgdiv">
               <img src={Playstore} style={{ width: '25px' }} alt="Playstore" />
             </div>
             <div className="ms-3 me-2">
               <span className="d-block font11 lh-sm">Get it On</span>
               <span className="d-block storename font12 lh-1">Play Store</span>
             </div>
           </a></div>
         <div>
           <a
             href="https://itunes.apple.com/us/app/ifas-online/id1448199555?ls=1&mt=8"
             target="_blank"
             className="d-flex store align-items-center rounded-3"
             rel="noreferrer">
             <div className="imgdiv">
               <img src={AppleIcon} style={{ width: '25px' }} alt="Playstore" />
             </div>
             <div className="ms-3 me-2">
               <span className="d-block font11 lh-sm">Get it On</span>
               <span className="d-block storename font12 lh-1">App Store</span>
             </div>
           </a>
         </div>
       </div> */}
     </ul>
      :
        <ul className="sidebarList">
          <div className='d-flex d-md-none align-items-center border-bottom border-2' style={{ padding: "10px" }}  onClick={()=>goToLink('Academics')}> Academics </div>
          <div className="d-flex d-md-none align-items-center border-bottom border-2" style={{ padding: "10px" }}  onClick={()=>goToLink('TestPrep')}> Test Prep </div>
          <div className="d-flex d-md-none align-items-center border-bottom border-2" style={{ padding: "10px" }}  onClick={()=>goToLink('CollegeConsulting')}>College Consulting</div>
          {/* <div className="d-flex d-md-none align-items-center border-bottom border-2" style={{ padding: "10px" }}  onClick={()=>goToLink('CollegePredictor')}>College Predictor</div> */}
          <div className="d-flex d-md-none align-items-center border-bottom border-2" style={{ padding: "10px" }}  onClick={()=>goToLink('AlumniNetwork')}>Alumni Network</div>
          <div className="d-flex d-md-none align-items-center border-bottom border-2" style={{ padding: "10px" }}  onClick={()=>goToLink('TutorNetwork')}>Tutor Network</div>
          <div className="d-flex d-md-none align-items-center border-bottom border-2" style={{ padding: "10px" }} >
            <span onClick={goToLogin}>Sign In</span>
          </div>
        </ul>
      }

    </div>
  );
}

export default Sidebar;
