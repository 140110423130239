import { useLayoutEffect, useState, useEffect, useRef } from "react";
import rough from "roughjs/bundled/rough.esm";
import { Tooltip } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Crop32Icon from "@mui/icons-material/Crop32";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import PentagonIcon from "@mui/icons-material/Pentagon";
import HexagonIcon from "@mui/icons-material/Hexagon";
import Icon from "@mdi/react";
import { mdiShape, mdiOctagon, mdiEraser } from "@mdi/js";
import DrawIcon from "@mui/icons-material/Draw";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import DeleteIcon from "@mui/icons-material/Delete";

const generator = rough.generator();

interface Element {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  type: string;
  roughElement: rough.Element;
  textContent?: string;
  textColor?: string;
}

interface Props {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
}

// Function to create different types of elements
function createRoughElement(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  type: string,
  path?: string,
  color?: string,
  textContent?: string,
  textColor?: string
): Element {
  let roughElement;

  switch (type) {
    case "line":
      roughElement = generator.line(x1, y1, x2, y2, { stroke: color });
      break;
    case "rectangle":
      roughElement = generator.rectangle(x1, y1, x2 - x1, y2 - y1, {
        stroke: color,
      });
      break;
    case "circle":
      roughElement = generator.ellipse(
        (x1 + x2) / 2,
        (y1 + y2) / 2,
        Math.abs(x2 - x1) / 2,
        Math.abs(y2 - y1) / 2,
        { stroke: color }
      );
      break;
    case "triangle":
      const cx = (x1 + x2) / 2;
      roughElement = generator.polygon(
        [
          [x1, y2],
          [x2, y2],
          [cx, y1],
        ],
        { stroke: color }
      );
      break;
    case "pentagon":
      roughElement = generator.polygon(
        createPolygonVertices(x1, y1, x2, y2, 5),
        { stroke: color }
      );
      break;
    case "hexagon":
      roughElement = generator.polygon(
        createPolygonVertices(x1, y1, x2, y2, 6),
        { stroke: color }
      );
      break;
    case "octagon":
      roughElement = generator.polygon(
        createPolygonVertices(x1, y1, x2, y2, 8),
        { stroke: color }
      );
      break;
    case "freehand":
      roughElement = generator.path(path || "", { stroke: color });
      break;
    case "text":
      break;
    default:
      roughElement = generator.rectangle(x1, y1, x2 - x1, y2 - y1, {
        stroke: color,
      });
  }

  return { x1, y1, x2, y2, roughElement, type, textContent, textColor };
}

// Utility function to create freehand path
function createFreehandPath(
  x1: number,
  y1: number,
  points: { x: number; y: number }[]
): string {
  return points.reduce(
    (path, point, index) =>
      path +
      (index === 0 ? `M${point.x},${point.y}` : ` L${point.x},${point.y}`),
    ""
  );
}

// Utility function to generate vertices for polygons
function createPolygonVertices(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  sides: number
): [number, number][] {
  const radius = Math.hypot(x2 - x1, y2 - y1) / 2;
  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;
  const angleStep = (2 * Math.PI) / sides;

  return Array.from({ length: sides }).map((_, i) => {
    const angle = i * angleStep;
    return [
      centerX + radius * Math.cos(angle),
      centerY + radius * Math.sin(angle),
    ];
  });
}

const CanvasBox: React.FC<Props> = ({ toggle, setToggle }: Props) => {
  const [elements, setElements] = useState<Element[]>([]);
  const [drawing, setDrawing] = useState(false);
  const [elementType, setElementType] = useState<string>("freehand");
  const [freehandPoints, setFreehandPoints] = useState<
    { x: number; y: number }[]
  >([]);
  const [eraserMode, setEraserMode] = useState(false);
  const [eraserSize, setEraserSize] = useState(20);
  const [undoStack, setUndoStack] = useState<Element[][]>([]);
  const [redoStack, setRedoStack] = useState<Element[][]>([]);
  const [color, setColor] = useState("#000000");
  const [textMode, setTextMode] = useState(false);
  console.log("textMode", textMode);
  console.log("elements", elements);

  const [textPosition, setTextPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [textContent, setTextContent] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  // Track eraser position
  const [eraserPosition, setEraserPosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  useLayoutEffect(() => {
    const canvas = document.getElementById(
      "canvas"
    ) as HTMLCanvasElement | null;
    if (!canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    // Clear the entire canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Create a Rough.js canvas instance
    const roughCanvas = rough.canvas(canvas);

    // Redraw all elements
    elements.forEach((element) => {
      if (element.type !== "text") {
        roughCanvas.draw(element.roughElement);
      } else if (context && element.textContent) {
        context.fillStyle = element.textColor || color;
        context.font = "16px Arial"; // Adjust as needed
        context.fillText(element.textContent, element.x1, element.y1);
      }
    });

    // Draw freehand path if applicable
    if (elementType === "freehand" && freehandPoints.length > 0) {
      const path = createFreehandPath(
        freehandPoints[0].x,
        freehandPoints[0].y,
        freehandPoints
      );
      roughCanvas.draw(generator.path(path, { stroke: color }));
    }

    // Draw the eraser circle if eraserMode is active
    if (eraserMode && drawing) {
      context.beginPath();
      context.arc(
        eraserPosition.x,
        eraserPosition.y,
        eraserSize / 2,
        0,
        2 * Math.PI
      );
      context.strokeStyle = "black"; // Border color
      context.lineWidth = 2; // Border thickness
      context.stroke();
      context.closePath();
    }
  }, [
    elements,
    freehandPoints,
    color,
    elementType,
    eraserMode,
    eraserPosition,
  ]);

  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const { clientX, clientY } = event;

    if (eraserMode) {
      eraseAtPoint(clientX, clientY);
    } else if (textMode) {
      setTextPosition({ x: clientX, y: clientY });
      setInputValue("");
      setTextMode(false);
    } else if (elementType === "freehand") {
      setFreehandPoints([{ x: clientX, y: clientY }]);
    } else if (elementType !== "") {
      const element = createRoughElement(
        clientX,
        clientY,
        clientX,
        clientY,
        elementType,
        undefined,
        color,
        textContent
      );
      pushToUndoStack();
      setElements((prevState) => [...prevState, element]);
    }
    setDrawing(true);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const { clientX, clientY } = event;

    if (eraserMode) {
      setEraserPosition({ x: clientX, y: clientY });
      if (drawing) {
        eraseAtPoint(clientX, clientY);
      }
    } else if (!drawing) {
      return;
    } else if (elementType === "freehand") {
      setFreehandPoints((prevPoints) => [
        ...prevPoints,
        { x: clientX, y: clientY },
      ]);
    } else {
      const index = elements.length - 1;

      if (index < 0) return;

      const { x1, y1, type } = elements[index];
      const updatedElement = createRoughElement(
        x1,
        y1,
        clientX,
        clientY,
        type,
        undefined,
        color,
        textContent
      );

      const elementCopy = [...elements];
      elementCopy[index] = updatedElement;
      setElements(elementCopy);
    }
  };

  const handleMouseUp = () => {
    setDrawing(false);

    if (elementType === "freehand" && freehandPoints.length > 0) {
      const path = createFreehandPath(
        freehandPoints[0].x,
        freehandPoints[0].y,
        freehandPoints
      );
      const element = createRoughElement(
        freehandPoints[0].x,
        freehandPoints[0].y,
        freehandPoints[freehandPoints.length - 1].x,
        freehandPoints[freehandPoints.length - 1].y,
        "freehand",
        path,
        color,
        textContent
      );

      pushToUndoStack();
      setElements((prevState) => [...prevState, element]);
      setFreehandPoints([]);
    } else {
      console.warn("No freehand points available to finalize.");
    }
  };

  const eraseAtPoint = (x: number, y: number) => {
    const radius = eraserSize;

    const filteredElements = elements.filter((element) => {
      if (
        element.type === "line" ||
        element.type === "rectangle" ||
        element.type === "circle" ||
        element.type === "triangle" ||
        element.type === "pentagon" ||
        element.type === "hexagon" ||
        element.type === "octagon" ||
        element.type === "freehand"
      ) {
        const dx = Math.abs((element.x1 + element.x2) / 2 - x);
        const dy = Math.abs((element.y1 + element.y2) / 2 - y);
        const distance = Math.sqrt(dx * dx + dy * dy);

        return distance > radius;
      } else if (element.type === "text") {
        const dx = Math.abs(element.x1 - x);
        const dy = Math.abs(element.y1 - y);
        const distance = Math.sqrt(dx * dx + dy * dy);

        return distance > radius;
      }

      return true;
    });

    setElements(filteredElements);
  };

  const pushToUndoStack = () => {
    setUndoStack((prevStack) => [...prevStack, elements]);
    setRedoStack([]);
  };

  const handleUndo = () => {
    if (undoStack.length === 0) return;
    const lastState = undoStack[undoStack.length - 1];
    setRedoStack((prevStack) => [...prevStack, elements]);
    setElements(lastState);
    setUndoStack((prevStack) => prevStack.slice(0, -1));
  };

  const handleRedo = () => {
    if (redoStack.length === 0) return;
    const lastRedoState = redoStack[redoStack.length - 1];
    setUndoStack((prevStack) => [...prevStack, elements]);
    setElements(lastRedoState);
    setRedoStack((prevStack) => prevStack.slice(0, -1));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === "z") {
      handleUndo();
    } else if (event.ctrlKey && event.key === "y") {
      handleRedo();
    }
  };

  const handleClearCanvas = () => {
    pushToUndoStack();
    setElements([]);
  };

  const handleInputBlur = () => {
    if (textPosition && inputValue.trim() !== "") {
      const { x, y } = textPosition;
      const element = createRoughElement(
        x,
        y,
        x,
        y,
        "text",
        undefined,
        color,
        inputValue,
        color
      );
      pushToUndoStack();
      setElements((prevState) => [...prevState, element]);
      setTextPosition(null); // Clear text position after adding text
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [elements, undoStack, redoStack]);

  return (
    <div
      className={`EditorDiv position-fixed flex-column ${
        toggle ? "active" : ""
      }`}
    >
      {/* <div className="d-flex  align-items-cente position-relative">
     
        <div
          onClick={() => setToggle(!toggle)}
          style={{ cursor: "pointer" }}
          className="sideMenuBtn bgOrangeWithTextWhite"
        >
          <DrawIcon />
        </div>
      </div> */}
      <div className={`EditorDivBox flex-column ${toggle ? "active" : ""}`}>
        <div className="canvasMenu d-flex align-items-center">
          <div className="btn-group dropstart">
            <button
              onClick={() => {
                setEraserMode(false);
              }}
              type="button"
              className={`mt-1 rounded canvasIcon ${
                elementType === "line" ||
                elementType === "rectangle" ||
                elementType === "circle" ||
                elementType === "triangle" ||
                elementType === "pentagon" ||
                elementType === "hexagon" ||
                elementType === "octagon"
                  ? "bg-success"
                  : ""
              }`}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon path={mdiShape} size={1} />
            </button>
            <ul className="dropdown-menu">
              <div className="d-flex">
                <li className="mx-1">
                  <button
                    onClick={() => {
                      setElementType("line");
                      setEraserMode(false);
                      setTextMode(false);
                    }}
                    className={`mt-1 rounded canvasIcon ${
                      elementType === "line" ? "bg-success" : ""
                    }`}
                  >
                    <Tooltip title="Line" placement="bottom">
                      <HorizontalRuleIcon />
                    </Tooltip>
                  </button>
                </li>
                <li className="mx-1">
                  <button
                    onClick={() => {
                      setElementType("rectangle");
                      setEraserMode(false);
                      setTextMode(false);
                    }}
                    className={`mt-1 rounded canvasIcon ${
                      elementType === "rectangle" ? "bg-success" : ""
                    }`}
                  >
                    <Tooltip title="Rectangle" placement="bottom">
                      <Crop32Icon />
                    </Tooltip>
                  </button>
                </li>
                <li className="mx-1">
                  <button
                    onClick={() => {
                      setElementType("circle");
                      setEraserMode(false);
                      setTextMode(false);
                    }}
                    className={`mt-1 rounded canvasIcon ${
                      elementType === "circle" ? "bg-success" : ""
                    }`}
                  >
                    <Tooltip title="Circle" placement="bottom">
                      <PanoramaFishEyeIcon />
                    </Tooltip>
                  </button>
                </li>
                <li className="mx-1">
                  <button
                    onClick={() => {
                      setElementType("triangle");
                      setEraserMode(false);
                      setTextMode(false);
                    }}
                    className={`mt-1 rounded canvasIcon ${
                      elementType === "triangle" ? "bg-success" : ""
                    }`}
                  >
                    {" "}
                    <Tooltip title="Triangle" placement="bottom">
                      <ChangeHistoryIcon />
                    </Tooltip>
                  </button>
                </li>
                <li className="mx-1">
                  <button
                    onClick={() => {
                      setElementType("pentagon");
                      setEraserMode(false);
                      setTextMode(false);
                    }}
                    className={`mt-1 rounded canvasIcon ${
                      elementType === "pentagon" ? "bg-success" : ""
                    }`}
                  >
                    <Tooltip title="Pentagon" placement="bottom">
                      <PentagonIcon />
                    </Tooltip>
                  </button>
                </li>
                <li className="mx-1">
                  <button
                    onClick={() => {
                      setElementType("hexagon");
                      setEraserMode(false);
                      setTextMode(false);
                    }}
                    className={`mt-1 rounded canvasIcon ${
                      elementType === "hexagon" ? "bg-success" : ""
                    }`}
                  >
                    <Tooltip title="Hexagon" placement="bottom">
                      <HexagonIcon />
                    </Tooltip>
                  </button>
                </li>
                <li className="mx-1">
                  <button
                    onClick={() => {
                      setElementType("octagon");
                      setEraserMode(false);
                      setTextMode(false);
                    }}
                    className={`mt-1 rounded canvasIcon ${
                      elementType === "octagon" ? "bg-success" : ""
                    }`}
                  >
                    <Tooltip title="Octagon" placement="bottom">
                      <Icon path={mdiOctagon} size={1} />
                    </Tooltip>
                  </button>
                </li>
              </div>
            </ul>
          </div>

          <div>
            <button
              onClick={() => {
                setElementType("freehand");
                setEraserMode(false);
                setTextMode(false);
              }}
              className={`mt-1 rounded canvasIcon ${
                elementType === "freehand" ? "bg-success" : ""
              }`}
            >
              <Tooltip title="Draw" placement="left">
                <DrawIcon />
              </Tooltip>
            </button>
          </div>
          <button
            onClick={() => {
              setElementType("text");
              setEraserMode(false);
              setTextMode(true);
            }}
            type="button"
            className={`mt-1 rounded canvasIcon ${
              elementType === "text" ? "bg-success" : ""
            }`}
          >
            <Tooltip title="Text" placement="left">
              <TextFieldsIcon />
            </Tooltip>
          </button>
          {textPosition && (
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={handleInputBlur}
              placeholder="Enter text here"
              style={{
                position: "absolute",
                top: textPosition.y,
                left: textPosition.x,
                zIndex: 1000,
                padding: "5px",
                border: "1px solid black",
                backgroundColor: "white",
              }}
            />
          )}
          <div>
            <input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              className="mt-1 rounded"
              style={{ maxWidth: "40px", padding: "3px" }}
            />
          </div>
          <div>
            <button
              onClick={() => {
                setElementType("");
                setEraserMode(!eraserMode);
              }}
              className={`mt-1 rounded canvasIcon ${
                eraserMode ? "bg-success" : ""
              }`}
            >
              <Tooltip title="Eraser" placement="left">
                <Icon path={mdiEraser} size={1} />
              </Tooltip>
            </button>
          </div>
          <div>
            <input
              type="number"
              value={eraserSize}
              onChange={(e) => setEraserSize(Number(e.target.value))}
              className="mt-1 rounded"
              min="5"
              max="100"
              style={{ maxWidth: "40px" }}
            />
          </div>
          <div>
            <button onClick={handleUndo} className="mt-1 rounded canvasIcon">
              <Tooltip title="Undo" placement="left">
                <UndoIcon />
              </Tooltip>
            </button>
          </div>
          <div>
            <button onClick={handleRedo} className="mt-1 rounded canvasIcon">
              <Tooltip title="Redo" placement="left">
                <RedoIcon />
              </Tooltip>
            </button>
          </div>
          <div>
            <button
              onClick={handleClearCanvas}
              className="mt-1 rounded canvasIcon"
            >
              <Tooltip title="Clear Canvas" placement="left">
                <DeleteIcon />
              </Tooltip>
            </button>
          </div>
        </div>

        <div className="h-100 position-relative">
          <canvas
            id="canvas"
            height={window?.innerHeight}
            width={window?.innerWidth}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            className="border position-fixed top-0"
          />
          {textPosition && (
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={handleInputBlur}
              placeholder="Enter text here"
              style={{
                position: "absolute",
                top: textPosition.y,
                left: textPosition.x,
                zIndex: 1000,
                padding: "5px",
                border: "1px solid black",
                backgroundColor: "white",
              }}
            />
          )}

          <div></div>
        </div>
        {/* } */}
      </div>
    </div>
  );
};

export default CanvasBox;
