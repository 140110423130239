import React, { useEffect, useState } from 'react';
import '../App.css';  
import '../Assets/Css/placeorder.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CartCard from '../Module/cartCard';
import Header from '../Component/header';
import BuyNowCard from '../Module/buyNowCard';

function WIP() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  return (
    <div className="rightPanel d-flex justify-content-center align-items-center" style={{background:'var(--transblue)'}}>
      {/* <Header isHidden={false} />       */}
        <div className="rounded">
          {/* <h3 className="fw-bold boldfont mb-4 colorblue text-center">Work In Progress!!!</h3> */}
          <h1 className="letterSpacing mb-0">Please, Visit Again!!</h1>
              <h1 className="colorblue fw-bold" style={{fontSize:'4em'}}>
                {' '}
                Program Coming Soon
              </h1>
        </div>
                        
    </div>
  );
}

export default WIP;
