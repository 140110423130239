import React, { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalImages from '../Constant/localImages';
import moment from 'moment';
import { timeConvert } from '../Utils/utils';


function TopicTile(props: any) {

  return (
    <div
      className="d-flex boxshadow p-3 rounded justify-content-between align-items-center mb-3 bgwhite"
      style={{ cursor: 'pointer' }}
      onClick={props?.onpress}
    >
      <div>
        <p className="text-uppercase mb-2 fw-bold"> {props?.data?.name}</p>
        <span>
          <img src={LocalImages.videoicon} className=" " alt="video Icon" />
          <span className="ms-2 fw-bold">{props?.data?.totalCount}</span>

          <span className="ms-2 border-start ps-2 border-2 border-dark">
            {timeConvert(props?.data?.totalDuration)}
          </span>

        </span>
      </div>

    </div>
  );
}

export default TopicTile;
