import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useAppDispatch } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { getCustomizePackageAmount, getLectureList, saveCustomizePackage } from '../Redux/Actions/AuthAction';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
//import { examList } from '../Utils/dataConstants';
import '../Assets/Css/additionalPackagePopup.css';
import { useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import { addCart } from '../Redux/Actions/examActions';
function AdditionalPackagePopup(props: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [topicList, setTopicList] = useState<any>([]);
  const [dummy, setdummy] = useState<any>("");
  const [totalItemCount, setTotalItemCount] = useState<any>(0);
  const [totalItemAmount, setTotalItemAmount] = useState<any>(0);

  const {userCountryCurrency,studentId} = useSelector((state:any) => ({
    userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
    studentId: state?.AuthReducer?.studentId,
  }));
useEffect(()=>{
  if(props.open){
  const postData:any={
    packageId:props?.packageData?._id
}

dispatch(
  getLectureList(
    postData,
    (res: any) => {
      console.log('res', res);
      if(res?.data.length>0){
        let tempViewDetails = res?.data?.map((item: any) => {
          return {
            _id: item?._id,
            name: item?.name,
            lectureCount: parseInt(item?.lectureCount),
            extraLectureCount: parseInt(item?.extraLectureCount),
            lectures: item?.lectures,
            viewDetails: false,
            isSelected: false,
            addedExtraLectureCount: 0,
          };
        });
        setTopicList(tempViewDetails);
        
      }
    },
    () => {}
  )
) 
  }
},[props.open])

const getAmount = (tempArr: any) => {
  let tempData: any = tempArr?.filter((item: any) => item.isSelected);
  let totalLectureCount: number = 0;
  let totalExtraLectureCount: number = 0;
  tempData?.map((item: any) => {
    totalLectureCount = totalLectureCount + item?.lectureCount;
    totalExtraLectureCount =
      totalExtraLectureCount + item?.addedExtraLectureCount;
  });
  const postData: any = {
    countryId: props?.packageData?.countryId,
    lectureCount: totalLectureCount,
    extraLectureCount: totalExtraLectureCount,
  };
  if (
    postData["extraLectureCount"] === "" ||
    postData["extraLectureCount"] === null ||
    postData["extraLectureCount"] === undefined
  ) {
    delete postData["extraLectureCount"];
  }
  dispatch(
    getCustomizePackageAmount(
      postData,
      (res: any) => {
        if (res !== null) {
          setTotalItemAmount(res?.data);
        } else {
          setTotalItemAmount(0);
        }
      },
      () => {}
    )
  );
};

const onChangeDetails = (index: any, type: any, value: any) => {
  const tempArr = topicList;
  if (type === "onSelectTopic") {
    if (tempArr[index].isSelected) {
      tempArr[index].isSelected = false;
      setTotalItemAmount(0);
      tempArr[index].addedExtraLectureCount = 0;
      setTopicList(tempArr);
      getAmount(tempArr);
    } else {
      tempArr[index].isSelected = true;
      setTopicList(tempArr);
      getAmount(tempArr);
    }
    let count: any = tempArr?.filter((item: any) => item.isSelected);
    setTotalItemCount(count?.length);
    setTopicList(tempArr);
  } else if (type === "onClickView") {
    if (tempArr[index].viewDetails) {
      tempArr[index].viewDetails = false;
    } else {
      tempArr[index].viewDetails = true;
    }
    setTopicList(tempArr);
    const random=Math.random() * 10;
    setdummy(random);
  } else if (type === "addedExtraLectureCount") {
    if (value <= tempArr[index].extraLectureCount) {
      if (value === "") {
        tempArr[index].addedExtraLectureCount = 0;
        getAmount(tempArr);
      } else {
        tempArr[index].addedExtraLectureCount = parseInt(value);
        getAmount(tempArr);
      }
    } else {
      tempArr[index].addedExtraLectureCount = 0;
      dispatch(
        showErrorSnackbar(
          "Please add value less than extra lectures count !!!"
        )
      );
    }
    setTopicList(tempArr);
  }
};
useEffect(()=>{
console.log('topicList123', topicList);
},[topicList])
console.log('totalItemAmount', totalItemAmount);
console.log('topicList', topicList);

const submitPackage=()=>{
  
  let tempData: any = topicList?.filter((item: any) => item.isSelected);
  let totalLectureCount: number = 0;
  tempData?.map((item: any) => {
    totalLectureCount = totalLectureCount + item?.lectureCount + item?.addedExtraLectureCount;
  });
  let topicIds: any = [];
  let lectureIds: any = [];

  tempData?.map((item: any) => {
    topicIds.push(item?._id);
    item?.lectures?.map((subItem: any) => {
      if (!subItem?.extra) {
        lectureIds.push(subItem?.lectureId);
      }
    });
    if (item?.addedExtraLectureCount > 0) {
      let tempExtra = item?.lectures?.filter(
        (subItem: any) => subItem?.extra
      );
      if (tempExtra) {
        for (let i = 0; i < item?.addedExtraLectureCount; i++) {
          lectureIds.push(tempExtra[i].lectureId);
        }
      }
    }
  });
  const filterLevel4=props?.packageData?.packageOptions?.filter((item:any)=>item?.levelId===4);
  console.log('filterLevel4', filterLevel4);
  const postData: any = {
     studentId: studentId,
    countryId: props?.packageData?.countryId,
    packageId: props?.packageData?._id,
    packageOptionId: filterLevel4[0]?.packageOptionId,
    coursetypeId: props?.packageData?.coursetypeId?._id,
    examtypeId: props?.packageData?.examTypeId?._id,
    subjectId: props?.packageData?.subjectId?._id,
    topics: topicIds,
    lectures: lectureIds,
    sellingPrice: totalItemAmount,
    offerPrice: totalItemAmount,
    totalLectureCount: totalLectureCount,
  };
console.log('postData', postData);
  dispatch(
    saveCustomizePackage(
      postData,
      (res: any) => {
       const cartData={
          packages:[{
            packageId:props?.packageData?._id,
            packageOptionId:filterLevel4[0]?.packageOptionId
          }]
        }
      dispatch(
        addCart(
          cartData,
          (res: any) => {
            navigate('/MyCart',{state:{isAdditionalPackage:true}});
          },
          () => { }
        )        
    );
      },
      () => {}
    )
  );
}
  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model additionalPAckageModel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        style={{zIndex:"1000 !important"}}>
        <Fade in={props.open}>
          <div className="card w-100 h-100 additionalPackagePopup">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h3 className='mt-2'>My Plans</h3>
              <ClearOutlinedIcon
                className="colorblue fs-3 p-1 shadow rounded-circle border-primary cursor float-end "
                onClick={props.handleClose}
              />
            </div>
            <div className="card-body overflow-auto">
              <div className='row mx-0'>
                {topicList?.map((item: any, index: number) => {
                  const {
                    _id,
                    name,
                    lectures,
                    lectureCount,
                    extraLectureCount,
                    viewDetails,
                    isSelected,
                    addedExtraLectureCount,
                  } = item;
                  return (
                    <div className='col-md-6 mb-3'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex e-input align-items-center ms-3">
                              <input
                                id={_id}
                                className="check"
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => {
                                  onChangeDetails(index, "onSelectTopic", "");
                                }}
                              />
                              <span className="checkVal ms-3">{name}</span>
                            </div>                            
                          </div>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div className="checkVal ms-3">
                              Lectures : {lectureCount}
                            </div>
                            <div className="mb-2 ">
                              <button
                                type="button"
                                className={`btn btn-link btn-sm fw-bold text-decoration-none cursor ${!viewDetails ? "colorOrange" : "colorOrange"
                                  }`}
                                onClick={() =>
                                  onChangeDetails(index, "onClickView", "")
                                }
                                style={{width:"100px"}}
                              >
                                {!viewDetails ? "View Details" : "Less Details"}
                              </button>
                            </div>
                          </div>
                          {viewDetails
                            ? lectures?.filter((item:any)=>!item?.extra)?.map((subItem: any, subIndex: any) => {
                                const {
                                  lectureId,
                                  lectureName,
                                  levelId,
                                  extra,
                                } = subItem;
                                return (
                                  <div
                                    key={subItem?._id}
                                    className="d-flex center p-0"
                                  >
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex e-input align-items-center ms-3">
                                          <span className="checkVal ms-3">
                                            {subIndex + 1}. {lectureName}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">                                                            
                                <div className="checkVal ms-3">
                                  Extra Lectures : {extraLectureCount}
                                </div>                             
                            </div>
                            <div className='d-flex align-items-center'>
                              <div className='colorOrange me-2'>Additional Extra Classes</div>
                              <div>
                              <input className="form-control form-control-sm" type="text" aria-label=".form-control-sm example" style={{width:"60px"}} onChange={(value: any) =>
                                    onChangeDetails(
                                      index,
                                      "addedExtraLectureCount",
                                      value?.target?.value
                                    )
                                  }
                                  value={addedExtraLectureCount}
                                  disabled={!isSelected}/>
                              </div>
                            </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              
            </div>
            <div className="card-footer text-muted">
              <div className='d-flex justify-content-between'>
                <div className="text-dark fw-bold ms-3">
                  <div className="text-dark fw-bold ms-3">
                    Total Amount
                  </div>
                  <div className="text-primary fw-bold ms-3">
                    {getSymbolFromCurrency(userCountryCurrency)}
                    {totalItemAmount}{" "}
                    <span className="text-secondary">
                      ({totalItemCount} Item)
                    </span>
                  </div>
                </div>
                {totalItemAmount!==0?<button className='btn btn-primary' onClick={submitPackage}>Buy Now</button>:<button className='btn btn-primary' disabled>Buy Now</button>}
              {/* <button className='btn btn-primary' onClick={submitPackage}>Buy Now</button> */}
              </div>
            </div>

          </div>          
        </Fade>
      </Modal>
    </div>
  );
}

export default AdditionalPackagePopup;
