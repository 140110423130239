import React, { useEffect, useRef, useState } from "react";
import localImages from "../Constant/localImagesNew";
import "../Assets/Css/home.css";
import Universities from "../Component/universities";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import Faq from "../Component/faq";
import Footer from "../Component/footer";
import { useNavigate } from "react-router-dom";
import HeaderHome from "../Component/headerHome";
import Sidebar from "../Module/sidebar";
import { useSelector } from "react-redux";
import {
  SidebarDataFreeUser,
  SidebarDataPaidUser,
} from "../Module/sidebarData";
import CountUp from "react-countup";
import Rating from "@mui/material/Rating";
import BookSessionPopup from "../Component/bookSessionPopup";
import BookSession from "../Module/bookSession";
import StudentSayCarousel from "../Component/studentSayCarousel";
import BannerVideo from "../Module/bannerVideo";
import dataConstant from "../Constant/dataConstant";
import { getBlog, getCountry } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import parser from "html-react-parser";
import BlogCard from "../Module/blogCard";
import StatCard from "../Module/statCard";
import CertificateSection from "../Module/certificateSection";
import { Helmet } from "react-helmet";
import Stats from "../Module/stats";
import MetaDataFile from "../Component/metaDataFile";
export default function Home() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const homebannerdiv = React.useRef<HTMLDivElement>(null);
  const [isHidden, setisHidden] = useState<boolean>(true);
  const [openBookSeat, setopenBookSeat] = useState<boolean>(false);
  const [blogData, setblogData] = useState([]);

  const targetRef: any = useRef(null);
  const statRef: any = useRef(null);
  const closesBooseat = () => {
    setopenBookSeat(false);
  };
  function handleOpen(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    setopenBookSeat(true);
  }
  const { ACCESS_TOKEN, hasActivePaidPlan } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
  }));
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getBlog(
        (data: any) => {
          setblogData(data);
        },
        () => {}
      )
    );
  }, []);

  const contactUS = (val: string) => {
    const element = document.getElementById(val);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    if (window.innerWidth >= 768) {
      var y = window.scrollY;
      if (y >= 90) {
        setisHidden(false);
      } else {
        setisHidden(true);
      }
    } else {
      setisHidden(false);
    }
  };
  window.addEventListener("scroll", myScrollFunc);

  function goToLogin(event: React.MouseEvent<HTMLButtonElement>): void {
    navigate("/Login");
  }

  const personalizedProgram = [
    {
      imgPath: localImages?.oneOnOneLive,
      heading: "One-on-One Live Interactive Classes",
      subtext:
        "Receive personalized coaching from expert instructors through individual attention and customized instruction in our interactive classes.",
    },
    {
      imgPath: localImages?.regularAssessment,
      heading: "Regular Assessments",
      subtext:
        "Track your progress and identify areas for improvement with daily, weekly, and monthly assessments, ensuring you stay ahead in your test preparation.",
    },
    {
      imgPath: localImages?.dedicatedDoubt,
      heading: "Dedicated Doubt Clarification Support",
      subtext:
        "Get dedicated support for clarifying doubts, ensuring a thorough understanding of concepts and techniques required for exam success.",
    },
    {
      imgPath: localImages?.grandTest,
      heading: "Grand Tests at the End of the Program",
      subtext:
        "Assess your readiness with five comprehensive grand tests that closely simulate the actual exam experience.",
    },
    {
      imgPath: localImages?.weeklyGroup,
      heading: "Weekly Group Brainstorming Sessions",
      subtext:
        "Sharpen problem-solving skills and gain new insights through collaborative brainstorming sessions with fellow students, facilitated by peer-to-peer interactions.",
    },
    {
      imgPath: localImages?.roadmapStrategy,
      heading: "Personalized Roadmap Strategy",
      subtext:
        "Receive a tailored, step-by-step plan crafted by experts to streamline your preparation and navigate potential challenges, giving you a strategic advantage.",
    },
    {
      imgPath: localImages?.counsellorStrategy,
      heading: "Dedicated Counsellor Support",
      subtext:
        "Assess your readiness with five comprehensive grand tests that closely simulate the actual exam experience.",
    },
    {
      imgPath: localImages?.ongoingAlumni,
      heading: "Ongoing Alumni Network Interaction",
      subtext:
        "Receive a tailored, step-by-step plan crafted by experts to streamline your preparation and navigate potential challenges, giving you a strategic advantage.",
    },
  ];

  function goToLink(val: string): void {
    navigate("/" + val);
  }
  function goToBookSession(): void {
    navigate("/BookSession", {
      state: {
        mobNo: "",
        coutryCodeNo: "",
        isOTPVerify: ACCESS_TOKEN ? true : false,
      },
    });
  }

  return (
    <div>
      <MetaDataFile
        title="LurniGo: Grades 8-12 Tutoring, Test Prep & College Consulting"
        description={
          "LurniGo offers expert online tutoring, test preparation, and college consulting for students in grades 8-12. Achieve academic success and prepare for a bright future!"
        }
        noScript={
          "https://www.facebook.com/tr?id=1750615618629260&ev=PageView&noscript=1"
        }
      />
      <Helmet></Helmet>
      <HeaderHome isHidden={isHidden} />
      <div className="d-block d-md-none">
        <Sidebar
          data={
            hasActivePaidPlan === true
              ? SidebarDataPaidUser
              : SidebarDataFreeUser
          }
        />
      </div>
      {/*************Banner************* */}
      {/* <div className="position-relative homeBannerContainer boxshadow mt-4 mt-md-0">
        <img
          src={localImages?.homeBanner}
          alt="home banner"
          className="w-100 d-none d-md-block"
        />
        <img
          src={localImages?.homeBannerMob}
          alt="home banner"
          className="w-100 d-block d-md-none"
        />
      </div> */}
      <div
        className="position-relative mt-1 homeBannerContainer"
        id="homebanner"
        ref={homebannerdiv}
      >
        <div className="row mx-0 ">
          <div className="col-md-6 homeBannerLeftSide">
            <div className="homeBannerLeftSide">
              <h1 className="homeBannerHeading">Embark your</h1>
              <h1 className="homeBannerHeading">journey to your</h1>
              <h1 className="homeBannerHeading">Dream University</h1>
              <div className="bannersubtext mt-3">
                Our community powers the preparation that
              </div>
              <div className="bannersubtext mb-3 ">
                support holistic learning solutions for every student's success.
              </div>

              <div className="mt-5">
                <button
                  className="signBtn px-4 font16"
                  onClick={goToBookSession}
                >
                  Join Lurnigo Today
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 position-relative pe-md-0">
            <BannerVideo src={localImages.homeBannerVideo} />
          </div>
        </div>
      </div>
      <div className="commonPad">
        <Universities />
      </div>
      <div className="commonPad bgBlue learnSolDiv">
        <img
          src={localImages?.learnSolleft}
          className="learnSolleft"
          alt="learnSolleft"
        />
        <img
          src={localImages?.learnSolright}
          className="learnSolright"
          alt="learnSolright"
        />
        <div className="learnSolHeadDiv">
          <h1>Holistic learning solutions for every student</h1>
          <div className="mb-3 font16">
            Our community powers the preparation that support student success.
          </div>
        </div>
        <div className="learnSolTab">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link active border-radius-start"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                Academics
              </button>
              <button
                className="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Test Prep
              </button>
              <button
                className="nav-link border-radius-end"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                College Consulting
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="row mx-0">
                <div className="col-md-6">
                  <div className="d-flex justify-content-center flex-column h-100 tabMiddleContent">
                    <div className="learntabHead">
                      Top Grades with Best Tutors
                    </div>
                    <div className="learntabContent">
                      Elevate your child's school performance with LurniGo’s
                      hyper personalized 1 on 1 live classes. Our holistic
                      approach focuses on providing guidance to achieve academic
                      success, through our personalized roadmap strategy to
                      build strong fundamentals and staying consistent with
                      improving the scores.
                    </div>
                    <div>
                      <button
                        className="LearnMorebtn px-4"
                        onClick={() => goToLink("AdvanceAcademics")}
                      >
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="tabMiddleContent">
                    <img
                      src={localImages?.academic}
                      alt="Academic"
                      className="w-100 rounded"
                    />
                    <div className="row align-items-baseline bottomStripleft">
                      <div className="col-8 orangeStrip rounded"></div>
                      {/* <div className='col-4 blueStrip'></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="row mx-0">
                <div className="col-md-6">
                  <div className="d-flex justify-content-center flex-column h-100 tabMiddleContent">
                    <div className="learntabHead">
                      Comprehensive Test Prep Coaching
                    </div>
                    <div className="learntabContent">
                      Our comprehensive test preparation services are designed
                      to help students excel in their standardized tests. We
                      provide targeted preparation and conduct rigorous practice
                      tests at regular intervals to thoroughly assess
                      understanding in every topic, ensuring students achieve
                      their desired results. 
                    </div>
                    <div>
                      <button
                        className="LearnMorebtn px-4"
                        onClick={() => goToLink("TestPrep")}
                      >
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="tabMiddleContent">
                    <img
                      src={localImages?.testPrep}
                      alt="Academic"
                      className="w-100 rounded"
                    />
                    <div className="row align-items-baseline bottomStripleft">
                      <div className="col-8 orangeStrip rounded"></div>
                      {/* <div className='col-4 blueStrip'></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              <div className="row mx-0">
                <div className="col-md-6">
                  <div className="d-flex justify-content-center flex-column h-100 tabMiddleContent">
                    <div className="learntabHead">
                      Expert College Admission Counselling
                    </div>
                    <div className="learntabContent">
                      Our advanced college counseling services provide
                      personalized guidance, crafting a customized roadmap to
                      develop a holistic student profile. We engage high school
                      students with admission officers and foster valuable
                      connections through monthly alumni network sessions, all
                      aimed at helping your child secure admission to their
                      dream university. 
                    </div>
                    <div>
                      <button
                        className="LearnMorebtn px-4"
                        onClick={() => goToLink("CollegeConsulting")}
                      >
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="tabMiddleContent">
                    <img
                      src={localImages?.collegeConsulting}
                      alt="Academic"
                      className="w-100 rounded"
                    />
                    <div className="row align-items-baseline bottomStripleft">
                      <div className="col-8 orangeStrip rounded"></div>
                      {/* <div className='col-4 blueStrip'></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="commonPad personalProgram">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            Personalized programs designed to help every student
          </h1>
        </div>
        <div className="row learnSolTab">
          {personalizedProgram?.map((item, index) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="border rounded p-3 h-100">
                <img
                  src={item?.imgPath}
                  alt={item?.heading}
                  className="w-100"
                />
                <div className="programHeading fw-bold mt-3 mb-2 lh-sm">
                  {item?.heading}
                </div>
                <div className="font16">{item?.subtext}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <StatCard buttonName="Explore Programs" handleClick={()=>goToLink('AdvanceAcademics')}/> */}
      <div ref={statRef}>
        <Stats />
      </div>
      <div className="commonPad bgLightGrey">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            Don't just take our word for it
          </h1>
          <div className="bannersubtext mb-3 text-center">
            Hear what our students have to say
          </div>
        </div>
        <div>
          <StudentSayCarousel
            Data={dataConstant?.studentSayData}
            id="carouselStudent"
          />
        </div>
        <div></div>
      </div>
      <div className="commonPad bgBlue collegePredictor position-relative">
        <img
          src={localImages?.collegePredleft}
          className="collegePredleft"
          alt="collegePredleft"
        />
        <img
          src={localImages?.collegePredright}
          className="collegePredright"
          alt="collegePredright"
        />

        <div className="width800 mx-auto mt-5 mb-5 text-center">
          <img
            src={localImages?.collegePredictor}
            className="mb-4"
            alt="College Predictor"
          />
          <h1 className="headingFont text-center mb-4">College Predictor</h1>
          <div className="mb-5 font16 text-center">
            Our GPA calculator can help you calculate your weighted and/or
            unweighted GPA! This allows you to achieve an overall view of where
            your academic candidacy stands in comparison to other students.
          </div>
          {/* <div className='text-center my-4'>
                        <button className='exploreBtn px-4 border' onClick={()=>goToLink('CollegePredictor')}>Learn More</button>
                    </div> */}
        </div>
      </div>
      {blogData?.length > 0 ? (
        <div className="commonPad ">
          <div className="width800 mx-auto mt-5">
            <h1 className="headingFont text-center">
              Explore insightful stories unlocking a wealth of knowledge
            </h1>
          </div>
          <div className="row learnSolTab">
            {blogData?.map((item: any, index) => {
              if (index < 3) {
                return <BlogCard data={item} />;
              }
            })}
          </div>
          <div className="text-center mb-4">
            <button
              className="contactUSbtn px-4"
              onClick={() => goToLink("Blog")}
            >
              See More
            </button>
          </div>
        </div>
      ) : null}
      <div className="commonPad ">
        <div className="row mx-0">
          <div className="col-md-3">
            <h1 className="headingFont mb-4">FAQs</h1>
            <div className="mb-4">
              <button
                className="contactUSbtn px-5"
                onClick={() => contactUS("Footer")}
              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="col-md-9">
            <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
          </div>
        </div>
      </div>

      <div className="commonPad ">
        <BookSession clickEvent={() => goToLink("BookSession")} />
      </div>
      <CertificateSection />
      {/***************Footer*************** */}
      <Footer id="Footer" />
      <BookSessionPopup open={openBookSeat} handleClose={closesBooseat} />
      {!isHidden ? (
        <div className="fixedFooter">
          <div className="row mx-0">
            <div className="col-7 col-md-6 d-flex align-items-center mb-2 mb-md-0">
              <div className="fw-bold font20 colorblue fontLibre">
                Book Your Trial Class With Us Today!
              </div>
            </div>
            <div className="col-5 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end flex-wrap position-relative">
              <button
                className="bookSessionButton rounded fw-bold mt-2 mt-md-0 font16"
                onClick={goToBookSession}
              >
                Book Your Trial Session
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
