import React, { useEffect, useState } from 'react';
import localImages from '../Constant/localImages';
import { useNavigate } from 'react-router-dom';
import { logoutUser, updateMoreValues } from '../Redux/Actions/AuthAction';
import { useAppDispatch } from '../hooks';
import { useSelector } from 'react-redux';
import { menuData,menuDataPaid } from "../Utils/dataConstants";
import { getcartCount } from '../Redux/Actions/examActions';
import '../Assets/Css/header.css';
import MenuIcon from '@mui/icons-material/Menu';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
interface props {
  isHidden: boolean
}

export default function HeaderLandingPage({ isHidden }: props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [IsExam, setIsExam] = useState(false);
  const [CourseExam, setCourseExam] = useState<any>();
  const [selectedmenu, setselectedmenu] = useState('');
  const [menu, showMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [menuArray, setmenuArray] = useState<any>();
  const [cartCount, setCartCount] = useState<any>();
  const { ACCESS_TOKEN, firstName, lastName, email, mobileNo ,hasActivePaidPlan,studentId,countryCode} = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    mobileNo: state?.AuthReducer?.mobileNo,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
    studentId:state?.AuthReducer?.studentId,
    countryCode:state?.AuthReducer?.countryCode
  }));

  useEffect(() => {

    // dispatch(getCourseExam(
    //   (data: any) => {
    //     setCourseExam(data);
    //   },
    //   () => { })
    // );
    if(ACCESS_TOKEN){    
    dispatch(
      getcartCount(
        studentId,
        (data: any) => {
          setCartCount(data[0].total_count)
        },
        () => { }
      )
    );
  }

  }, []);
  function goToLogin(event: React.MouseEvent<HTMLButtonElement>): void {
    navigate('/Login');
  }
  function goToLink(val:string): void {
    // navigate('/'+val);
    const section = document.querySelector( '#'+val );
  section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }
  function goToTestPrep(): void {
    navigate('/TestPrep');
  }
  function goToHome(): void {
    navigate('/');
  }
 
  function gotoNotification(): void {
    navigate("/Notification");
  }
  // const Course = [
  //   {
  //     course: 'Test Prep Coaching',
  //     courseList: ExamList      
  //   },
  //   {
  //     course: 'College Admission Consulting',
  //     courseList: CounsellingExamList      
  //   }   
  // ];

  const handleroute = (basecourseId: any, examData: any) => {
    if (ACCESS_TOKEN) {
      dispatch(updateMoreValues({ examTypeId: examData?._id }));
      dispatch(updateMoreValues({ examTypeName: examData?.name }));
      dispatch(updateMoreValues({ courseType: basecourseId }));
      setIsExam(false);
      navigate("/User/Dashboard");
    }
    else {
      navigate("/Login")
      setIsExam(false);
    }

    //setselectedmenu('')
  };
  function clickProfile() {
    setShow(!show);
  }

  function handleClick(item: any) {
    showMenu(false);
    if (item.title === 'Logout') {
      dispatch(logoutUser(
        (res: any) => {
          //navigate('/User/Dashboard');
        },
        () => { }, () => { })
      );
    } else {
      navigate(item?.link)
      
    }
  };
  function toggleMenu(): void {
    showMenu(!menu);
    const element = document.getElementById("sidebarMenu") as HTMLInputElement;
    if (menu) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
  }

  useEffect(() => {
    if(hasActivePaidPlan===true){
      setmenuArray(menuDataPaid)
    }
    else{
setmenuArray(menuData)
    }

  }, [hasActivePaidPlan]);
  return (
    <header className={`${isHidden ? 'hide' : 'show'}`} id='headerHome'>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <img src={localImages.logo} alt="IFAS Logo" className="headerlogo cursor" onClick={goToHome} />
        </div>
        <div className='d-none d-md-flex flex-fill justify-content-center align-items-center ms-md-5'>
          <div className='d-flex'>
            <div className='cursor menuDiv' onClick={()=>goToLink('Home')}> Home </div>
            <div className='cursor menuDiv' onClick={()=>goToLink('WhyUs')}> Why Us </div>
            <div className='cursor menuDiv' onClick={()=>goToLink('Testimonials')}>Testimonials</div>
            <div className='cursor menuDiv' onClick={()=>goToLink('FAQs')}>FAQs</div>
            
          </div>               
              {/* <button className='signBtn px-4' onClick={goToLogin}><WhatsAppIcon className='me-2'/> Chat with us</button> */}
        </div>
        <a className='signBtn px-4 text-decoration-none' href="//api.whatsapp.com/send?phone=16693056669" target='_blank' rel="noreferrer"><WhatsAppIcon className='me-2'/> Chat with us</a>   
        <div
          className="d-xs-flex d-sm-flex d-md-none me-2 cursor"
          onClick={toggleMenu}
        >
          <MenuIcon />
        </div>
      </div>
    </header>

  )
}
