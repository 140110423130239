// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Images/CollegeConsulting/abstract.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collegeConsultingContainer{
    padding: 10em 5em 2em;
}
.ccRightArrow{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 75px;
    margin: auto;
    width: 23%;
}
.ccLeftArrow{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 16%;
}
.getDreamleft{
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 280px;
}
.getDreamright{
    position: absolute;
    bottom: -100px;
    right: 0;
    width: 290px;
}
.width900 {
    width: 100%;
    max-width: 971px;

}
.stepbystepProcedureDiv{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    position:relative;
    background-repeat: no-repeat;
    text-align: center;
}
@media screen  and (max-width: 767px){
    .ccRightArrow{
        display: none;
    }
    .ccLeftArrow{
        display: none;
    }
    .collegeConsultingContainer{
        padding: 10em 2em 2em;
    }
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/collegeConsulting.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,YAAY;IACZ,YAAY;IACZ,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,SAAS;IACT,YAAY;IACZ,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,OAAO;IACP,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,cAAc;IACd,QAAQ;IACR,YAAY;AAChB;AACA;IACI,WAAW;IACX,gBAAgB;;AAEpB;AACA;IACI,yDAA+D;IAC/D,wBAAwB;IACxB,iBAAiB;IACjB,4BAA4B;IAC5B,kBAAkB;AACtB;AACA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;IACA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".collegeConsultingContainer{\n    padding: 10em 5em 2em;\n}\n.ccRightArrow{\n    position: absolute;\n    right: 0;\n    top: 0;\n    bottom: 75px;\n    margin: auto;\n    width: 23%;\n}\n.ccLeftArrow{\n    position: absolute;\n    left: 0;\n    top: 0;\n    bottom: 0;\n    margin: auto;\n    width: 16%;\n}\n.getDreamleft{\n    position: absolute;\n    bottom: 1px;\n    left: 0;\n    width: 280px;\n}\n.getDreamright{\n    position: absolute;\n    bottom: -100px;\n    right: 0;\n    width: 290px;\n}\n.width900 {\n    width: 100%;\n    max-width: 971px;\n\n}\n.stepbystepProcedureDiv{\n    background-image: url(../Images/CollegeConsulting/abstract.png);\n    background-size: contain;\n    position:relative;\n    background-repeat: no-repeat;\n    text-align: center;\n}\n@media screen  and (max-width: 767px){\n    .ccRightArrow{\n        display: none;\n    }\n    .ccLeftArrow{\n        display: none;\n    }\n    .collegeConsultingContainer{\n        padding: 10em 2em 2em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
