import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import InputField from '../Atom/InputField';
import Dropdown from './dropdown';
import '../Assets/Css/bookSession.css'
import PhoneInputField from '../Atom/phoneInputField';
import { validateFullName, validateIsNumberOnly } from '../Utils/validators';
import { validateFullNameErrMsg, validateIsNumberOnlyErrMsg } from '../Utils/errormsg';
import { useAppDispatch } from '../hooks';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { addJoinLurnigo, getCountry, getCourseExam } from '../Redux/Actions/AuthAction';
import localImages from '../Constant/localImagesNew';

interface props {
  open: boolean,
  handleClose: Function,
  examType?:any
}
export default function JoinLurnigoPopup({ open, handleClose }: props) {
  const dispatch = useAppDispatch();
  const [name, setname] = useState<string>("");
  const [email,setemail]=useState("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("61");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>("");  
  const [subjectList, setSubjectList] = useState([]);
  useEffect(() => {
    dispatch(getCountry(
      (data: any) => {
          setCountryData(data)
      },
      () => { })  );
      dispatch(
        getCourseExam(
          (data: any) => {
            setSubjectList(data);
            
          },
          () => { }
        )
      );
  }, []);
  const closePopup=()=> {
    setIsSubmit(false);
    clearData()
    handleClose();
  }
 
  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  }

  function submitForm(e: { preventDefault: () => void; }) {
    e.preventDefault();    
    const PostData: any = {
      name: name,
      email: email,
      mobile: mob,
      countryCode:"+"+coutryCode,
      subjectId:subject
    };
    dispatch(addJoinLurnigo(
      PostData,
      (res: any) => {
        setIsSubmit(true)
        // clearData()

      }, () => { }))
    // return (true)
  }

  function clearData() {
    setname("");
    setemail("");
    setmob("");
 setSubject("")
  }

  function validation() {
    return ( (coutryCode!=="61" && mob?.length < 10) || (coutryCode==="61" && mob?.length < 9) || name === "" || email === "" ||subject==="");
  }
const onChangeCoutryCode=(e:any)=>{
  setcoutryCode(e.target.value);
}

const changeSubject = (val: any) => {
  setSubject(val)
}
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={closePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="p-0 position-relative">
        
          <div className='bookSession'>
          <div className=" d-flex align-items-center justify-content-between HeadingBookSession">
          {isSubmit?
          <div className='text-success fw-bold font16'>Submitted Successfully</div>:
            <div className='font16 fw-bold'>Join Lurnigo</div>}
            <ClearOutlinedIcon className="colorblue cursor font28" onClick={closePopup} />
          </div>
            <div className='bookSessionMiddleContainer'>   
                {isSubmit?
                <div className='font16 p-3'>
                  <img src={localImages?.thankYou} alt='thank You'/>
                  <div className='my-3'>
                    Dear <span className='fw-bold'>{name}</span>
                  </div>
                  <div>
                  <div>Thank you for your interest in LurniGo!</div>
                  <div> An Academic Consultant from our team will contact you within 24-48 hours.</div>
                  <div>Meanwhile, feel free to explore the resources on our website to know more.</div>
                  <div className='mt-3'>For any immediate queries, reach out at support@lurnigo.com.</div>
                  <div className='mt-2'>Best,</div>
                  <div className='fw-bold'>Team LurniGo</div>
                  </div>
                </div>:
                <form className='w-100'>
                <div className='row mx-0'>
                  <div className='my-2'>
                    <label className='mb-2'>Name</label>
                    <div className='d-flex'>
                    <InputField
                      placeholder="Enter Name"
                      value={name}
                      setValue={handleFirstInputText}
                    />
                    <span className="mendatory ms-2 fs-5">*</span>
                    </div>
                  </div>                    
                  <div className='my-2'>
                    <label className='mb-2'>Email</label>
                    <div className='d-flex'>
                    <input
                      className='form-control p-2'
                      type='email'
                      value={email}
                      placeholder="Enter Your Email Address"
                      onChange={(e) => setemail(e.target.value)} />
                    <span className="mendatory ms-2 fs-5">*</span>
                    </div>                     
                  </div>
                  <div className='my-2'>
                    <label className='mb-2'>Mobile Number</label>
                    <div className='d-flex position-relative'>
                      <select
                        className="form-select cursor coutryCode  rounded-0 rounded-start"
                        aria-label="Default select example"
                        defaultValue={1}
                        value={coutryCode}
                        onChange={onChangeCoutryCode}
                      >
                        {countryData?.map((item: any, index) => {
                          return <option value={item.phonecode}>{item?.isoCode} +{item?.phonecode}</option>;
                        })}
                      </select>
                      <PhoneInputField
                        placeholder="Enter Your Mobile Number"
                        value={mob}
                        maxLength={"10"}
                        setValue={setPhoneNumber} />
                       <span className="mendatory ms-2 fs-5">*</span>
                    </div>
                  </div>                    
                  <div className='my-2'>
                      <label className='mb-2'>Subject</label>
                      <div className='d-flex'>
                        <Dropdown
                          placeholder='Select Your Subject'
                          setInputText={(value: any) => changeSubject(value)}
                          value={subject}
                          options={subjectList} />
                        <span className="mendatory ms-2 fs-5">*</span>
                      </div>
                    </div>
                </div>
              </form>
                }
                
           
            </div>
          <div className='p-3 px-4 border-top'>
          {isSubmit?
          <button onClick={closePopup} className={`signBtn w-100 rounded font16`}>Ok</button>
            :<button onClick={submitForm} className={`signBtn w-100 rounded font16 ${validation()?'bg-secondary':''}`} disabled={validation()}>Submit</button>}
          </div>
            {/* <BookSeat examType={examType}/> */}
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
