import React, { useEffect, useState, useRef } from "react";
import "../App.css";
import "../Assets/Css/placeorder.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addTutor, uploadMedia } from "../Redux/Actions/AuthAction";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { useAppDispatch } from "../hooks";
import InputField from "../Atom/InputField";
import { validateIsNumberOnly } from "../Utils/validators";
import { validateIsNumberOnlyErrMsg } from "../Utils/errormsg";
import { getTutorDetails } from "../Redux/Actions/AuthAction";
import DatePicker from "../Component/datePicker";
import moment from "moment";
import localImages from "../Constant/localImagesNew";
import LocalImages from "../Constant/localImages";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
function OnBoardingForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [adharcardimg, setAdharcardimg] = useState<any>("");
  const [uploadedAdharcardimg, setUploadedAdharcardimg] = useState<any>("");
  const [panCardimg, setPanCardimg] = useState<any>("");
  const [uploadedPanCardimg, setUploadedPanCardimg] = useState<any>("");
  const [form11, setForm11] = useState<any>("");
  const [uploadedForm11, setUploadedForm11] = useState<any>("");
  const [declaration, setDeclaration] = useState<any>("");
  const [uploadedDeclaration, setUploadedDeclaration] = useState<any>("");
  const [assetsDeclaration, setAssetsDeclaration] = useState<any>("");
  const [uploadedAssetsDeclaration, setUploadedAssetsDeclaration] =
    useState<any>("");
  const [dataProtection, setDataProtection] = useState<any>("");
  const [uploadedDataProtection, setUploadedDataProtection] = useState<any>("");
  const [photo, setPhoto] = useState<any>("");
  const [uploadedPhoto, setUploadedPhoto] = useState<any>("");
  const fileInputRef = useRef(null);
  const [mob, setmob] = useState<string>("");
  const [DOJ, setDOJ] = useState<any>("");
  const [email, setemail] = useState<string>("");
  console.log("fileInputRef", fileInputRef);
  const [tutorDetails, setTutorDetails] = useState<any>("");
  const handleChangeAdharcard = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 10000;
    if (fileSizeKiloBytes > 10000)
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setAdharcardimg(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMedia(
          file,
          (res: any) => {
            // delete res?.filePath;
            setUploadedAdharcardimg(res?.file);
          },
          () => {}
        )
      );
    }
  };
  const handleChangePancard = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 10000;
    if (fileSizeKiloBytes > 10000)
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPanCardimg(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMedia(
          file,
          (res: any) => {
            // delete res?.filePath;
            setUploadedPanCardimg(res?.file);
          },
          () => {}
        )
      );
    }
  };
  const handleChangeAssetsDeclaration = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 10000;
    if (fileSizeKiloBytes > 10000)
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setAssetsDeclaration(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMedia(
          file,
          (res: any) => {
            // delete res?.filePath;
            setUploadedAssetsDeclaration(res?.file);
          },
          () => {}
        )
      );
    }
  };
  const handleChangeDataProtection = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 10000;
    if (fileSizeKiloBytes > 10000)
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setDataProtection(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMedia(
          file,
          (res: any) => {
            // delete res?.filePath;
            setUploadedDataProtection(res?.file);
          },
          () => {}
        )
      );
    }
  };
  const handleChangePhoto = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 10000;
    if (fileSizeKiloBytes > 1000)
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMedia(
          file,
          (res: any) => {
            // delete res?.filePath;
            setUploadedPhoto(res?.file);
          },
          () => {}
        )
      );
    }
  };
  const handleChangeDeclaration = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 10000;
    if (fileSizeKiloBytes > 10000)
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setDeclaration(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMedia(
          file,
          (res: any) => {
            // delete res?.filePath;
            setUploadedDeclaration(res?.file);
          },
          () => {}
        )
      );
    }
  };

  const handleChangeForm11 = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 10000;
    if (fileSizeKiloBytes > 10000)
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setForm11(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMedia(
          file,
          (res: any) => {
            setUploadedForm11(res?.file);
          },
          () => {}
        )
      );
    }
  };
  const handleMobileNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
      if (e?.length === 10) {
        dispatch(
          getTutorDetails(
            e,
            (data: any) => {
              if (data) setTutorDetails(data);
            },
            () => {}
          )
        );
      }
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };

  const clearData = () => {
    setmob("");
    setemail("");
    setDOJ("");
    setAdharcardimg("");
    setUploadedAdharcardimg("");
    setPanCardimg("");
    setUploadedPanCardimg("");
    setForm11("");
    setUploadedForm11("");
    setDeclaration("");
    setUploadedDeclaration("");
    setAssetsDeclaration("");
    setUploadedAssetsDeclaration("");
    setDataProtection("");
    setUploadedDataProtection("");
    setPhoto("");
    setUploadedPhoto("");
  };
  const submitData = (e: any) => {
    e.preventDefault();
    const PostData: any = {
      name: tutorDetails[0]?.name,
      mobile: mob,
      email: tutorDetails[0]?.email,
      adhar_card: uploadedAdharcardimg,
      pan_card: uploadedPanCardimg,
      form_11: uploadedForm11,
      declaration_form: uploadedDeclaration,
      asset_declaration: uploadedAssetsDeclaration,
      data_protection_form: uploadedDataProtection,
      photo_id_card: uploadedPhoto,
      status: 2,
    };
    dispatch(
      addTutor(
        PostData,
        (res: any) => {
          clearData();
        },
        () => {}
      )
    );
  };
  const Validation = () => {
    return (
      mob === "" ||
      mob?.length !== 10 ||
      adharcardimg === "" ||
      panCardimg === ""
    );
  };
  return (
    <div className="row d-flex justify-content-between p-5  tutorScreen">
      <div className="col-md-5 d-flex align-items-center">
        <div className="text-center">
          <img
            className="img-fluid rounded  "
            src={LocalImages.logo}
            alt="logo"
          />
          <img
            className="img-fluid rounded  "
            src={LocalImages.bookfreeNew}
            alt="bookfreeNew"
          />
        </div>
      </div>
      <div className="col-md-6 rounded shadow p-3 tutorformDiv  bg-light">
        <h5 className="fw-bold text-center approachheading mt-4">
          Tutor Information
        </h5>
        <form>
          <div className="tutorInnerform row d-flex">
            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">Phone Number</label>
              <div className="d-flex ">
                <InputField
                  placeholder="Enter mobile Name"
                  value={mob}
                  setValue={handleMobileNumber}
                  maxLength={10}
                  minLength={10}
                />
                <span className="mendatory ms-2 fs-5">*</span>
              </div>
            </div>
            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">Full Name</label>
              <div className="d-flex ">
                <input
                  className="form-control p-2"
                  type="text"
                  value={tutorDetails[0]?.name}
                  placeholder="Enter Full Name"
                  disabled
                />

                <span className="mendatory ms-2 fs-5"></span>
              </div>
            </div>
            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">
                {" "}
                Personal Mail ID
              </label>
              <div className="d-flex ">
                <input
                  className="form-control p-2"
                  type="email"
                  value={tutorDetails[0]?.email}
                  placeholder="Enter Email Address"
                  onChange={(e) => setemail(e.target.value)}
                  disabled
                />

                <span className="mendatory ms-2 fs-5"></span>
              </div>
            </div>
            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">
                Date Of Joining
              </label>
              <div className="  ">
                <DatePicker
                  value={tutorDetails[0]?.date_of_joining?.substring(0, 10)}
                  setInputText={(value: string) => setDOJ(value)}
                  disabled
                  //  defaultValue={tutorDetails[0]?.date_of_joining}
                />
              </div>
            </div>

            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">Adhar Card</label>
              <div className="d-flex ">
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-button"
                  className="form-control p-2"
                  onChange={handleChangeAdharcard}
                  ref={fileInputRef}
                />
                <span className="mendatory ms-2 fs-5"></span>
              </div>
            </div>
            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">Pan Card</label>
              <div className="d-flex ">
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-button"
                  className="form-control p-2"
                  onChange={handleChangePancard}
                />
                <span className="mendatory ms-2 fs-5"></span>
              </div>
            </div>
            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">Form 11 </label>
              <div className="d-flex ">
                <input
                  type="file"
                  accept=".pdf"
                  id="upload-button"
                  className="form-control p-2"
                  onChange={handleChangeForm11}
                />
                <span className="mendatory ms-2 fs-5"></span>
              </div>
            </div>
            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">
                Declaration Form
              </label>
              <div className="d-flex ">
                <input
                  type="file"
                  accept=".pdf"
                  id="upload-button"
                  className="form-control p-2"
                  onChange={handleChangeDeclaration}
                />
                <span className="mendatory ms-2 fs-5"></span>
              </div>
            </div>

            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">
                Assets Declaration
              </label>
              <div className="d-flex ">
                <input
                  type="file"
                  accept=".pdf"
                  id="upload-button"
                  className="form-control p-2"
                  onChange={handleChangeAssetsDeclaration}
                  ref={fileInputRef}
                />
                <span className="mendatory ms-2 fs-5"></span>
              </div>
            </div>
            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">
                Data Protection Form
              </label>
              <div className="d-flex ">
                <input
                  type="file"
                  accept=".pdf"
                  id="upload-button"
                  className="form-control p-2"
                  onChange={handleChangeDataProtection}
                />
                <span className="mendatory ms-2 fs-5"></span>
              </div>
            </div>
            <div className="col-md-6 my-2">
              <label className="mb-2 fw-bold  text-start">
                Photo for ID Card
              </label>
              <div className="d-flex ">
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-button"
                  className="form-control p-2"
                  onChange={handleChangePhoto}
                />
                <span className="mendatory ms-2 fs-5"></span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-3">
            {/* { !Validation() ? <button
                className={"sendOTPBtn rounded px-4  "}
                onClick={submitData}
                disabled={Validation()}
              >
                Submit
              </button> :
              <button
                className={"btn btn-outline-secondary"}
                 
              >
                Submit
              </button>} */}

            <button
              className={`sendOTPBtn rounded px-4 ${
                Validation() ? "" : "active"
              }`}
              onClick={submitData}
              disabled={Validation()}
            >
              Next
              <ArrowRightAltIcon
                className={`ms-2 font24${
                  Validation() ? "" : "bookSessionOrange"
                }`}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OnBoardingForm;
