import React, { useEffect,useRef, useState } from "react";
import localImages from "../Constant/localImagesNew";
import "../Assets/Css/home.css";
import "../Assets/Css/dsatLandingPage.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import Faq from "../Component/faq";
import Footer from "../Component/footer";
import { useNavigate ,Link, useLocation } from "react-router-dom";
import HeaderHome from "../Component/headerHome";
import Sidebar from "../Module/sidebar";
import { useSelector } from "react-redux";
import {
  SidebarDataFreeUser,
  SidebarDataPaidUser,
} from "../Module/sidebarData";
import BookSessionPopup from "../Component/bookSessionPopup";
import BookSession from "../Module/bookSession";
import StudentSayCarousel from "../Component/studentSayCarousel";
import dataConstant from "../Constant/dataConstant";
import {
  addAffilatemarketingPage,
  getCountry,
  gradeLevelList,
  register,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import CertificateSection from "../Module/certificateSection";
import InputField from "../Atom/InputField";
import PhoneInputField from "../Atom/phoneInputField";
import { validateFullName, validateIsNumberOnly } from "../Utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import BookDemoSessionPopup from "../Component/bookDemoSessionPopUp";
import Dropdown from "../Component/dropdown";
import DsatPopup from "../Component/dsatPopup";
import HeaderDsat from "../Component/headerDsat";
import Stats from '../Module/stats';
import CloseIcon from "@mui/icons-material/Close"; 
import AffilateMarketingPopup from "../Component/affilateMarketingPopup";
import AffilateMarketingPopup2 from "../Component/affilateMarketingPopup2";
export default function AffilateMarketing() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const homebannerdiv = React.useRef<HTMLDivElement>(null);
  const [isHidden, setisHidden] = useState<boolean>(true);
  const [openBookSeat, setopenBookSeat] = useState<boolean>(false);
  const [firstName, setfirstname] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("1");
  const [openDemoSession, setOpenDemoSession] = useState<boolean>(false);
  const [gradeList, setGradeList] = useState([]);
  const [openDsat, setopenDsat] = useState<boolean>(false);
  const [openpopup, setopenpopup] = useState<boolean>(false);  
  const [openpopup1, setopenpopup1] = useState<boolean>(false); 
  const [popupShown, setPopupShown] = useState(false); // To track if the popup has been shown before
  const [grade, setGrade] = useState<any>("");
  const [country, setCountry] = useState("");
  const statRef:any = useRef(null); 
  const targetRef:any = useRef(null);
  const { userCountryCurrency, userCountryCallingCode } = useSelector((state: any) => ({
    userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
    userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode
  }));
 
  const currentURLarr = useLocation().pathname.split('/');
  let referalid = currentURLarr[currentURLarr.length - 1];
  console.log("https://devadmin.lurnigo.com/" ,referalid)
  const closesBooseat = () => {
    setopenBookSeat(false);
  };
  const closesDemosession = (e: any) => {
    e.preventDefault();
    setOpenDemoSession(false);
  };

  const { ACCESS_TOKEN, hasActivePaidPlan } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
  }));
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 4);

          setGradeList(filterGrade);
        },
        () => {}
      )
    );
  }, []);
  useEffect(() => {
    dispatch(getCountry(
      (data: any) => {
        const filterCountry = data?.filter((item: any) => item?.phonecode == userCountryCallingCode?.substring(1))
        setCountry(filterCountry[0]?._id)
      },
      () => { })
    );

  }, [userCountryCallingCode]);

  const contactUS = (val: string) => {
    const element = document.getElementById(val);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  var myScrollFunc = function () {
    // let offsetHeight = stageCanvasRef.current.offsetHeight;
    // if (window.innerWidth >= 768) {
    var y = window.scrollY;
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    if (y >= 500 && y < maxScroll - 300) {
      setisHidden(false);
    } else {
      setisHidden(true);
    }
    // } else {
    //   setisHidden(false);
    // }
  };
  window.addEventListener("scroll", myScrollFunc);
 

  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfirstname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputText = (e: any) => {
    if (validateFullName(e)) setlastName(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !popupShown) {
          setopenpopup1(true);
          setPopupShown(true);
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [popupShown]);
  const getGrade = (val: any) => {
    setGrade(val);
  }; 
  
  const offerData=[
    {
      title:"Academic Tutoring",
      imgPath:localImages?.learnAcademicTutoring,
      desc:"Elevate your child's school performance with LurniGo’s hyper personalized 1 on 1 live classes. Our holistic approach focuses on providing guidance to achieve academic success, through our personalized roadmap strategy to build strong fundamentals and staying consistent with improving the scores."
    },
    {
      title:"Test Prep",
      imgPath:localImages?.learnTestPrep,
      desc:"Our comprehensive test preparation services are designed to help students excel in their standardized tests. We provide targeted preparation and conduct rigorous practice tests at regular intervals to thoroughly assess understanding in every topic, ensuring students achieve their desired results. "
    },
    {
      title:"College Counseling",
      imgPath:localImages?.learnCollegeCounseling,
      desc:"Our advanced college counseling services provide personalized guidance, crafting a customized roadmap to develop a holistic student profile. We engage high school students with admission officers and foster valuable connections through monthly alumni network sessions, all aimed at helping your child secure admission to their dream university. "
    },
  ]
  const learnOurProgramData=[
    {
      title:"Interactive one on one Lessons",
      imgPath:localImages?.learnInteractiveoneononeLessons,
      desc:"Engage with hyper personalized interactive lessons that make learning outcome effective."
    },
    {
      title:"Live Online Classes",
      imgPath:localImages?.learnLiveOnlineClasses,
      desc:"Join our live classes and interact with instructors and peers in real-time"
    },
    {
      title:"Detailed Performance Analysis",
      imgPath:localImages?.learnDetailedPerformanceAnalysis,
      desc:"Receive in-depth feedback on your practice tests to identify areas for improvement"
    },
    {
      title:"On-Demand Resources",
      imgPath:localImages?.learnOnDemandResources,
      desc:"Access a wealth of resources, including video lessons, practice questions, and study guides"
    },
    {
      title:"Full-Length Practice Tests",
      imgPath:localImages?.learnFullLengthPracticeTests,
      desc:"Simulate the real test environment with our full-length practice tests to make the learner exam ready"
    },
  ]
  return (
    <div>
      <HeaderDsat isHidden={false} />
      {/* <div className="d-block d-md-none">
        <Sidebar
          data={
            hasActivePaidPlan === true
              ? SidebarDataPaidUser
              : SidebarDataFreeUser
          }
        />
      </div> */}
      {/*************Banner************* */}
       

<div className='position-relative  bgBlue' id='Home' ref={homebannerdiv}>
          <div className='row mx-0 '>
            <div className="col-md-7">
              <div className="learnBannerBox">
                <h1 className='homeBannerHeading font48'>Your Single Stop destination for</h1>
                <div className='bgwhite p-2 px-4 d-inline-block mb-4 mt-2'>
                <h1 className='homeBannerHeading font48 colorOrange mb-0'>Academic Excellence !</h1>
                </div>
                <div className='bgwhite' style={{height:"1px"}}></div>
                <div className='font20 fw-bold mt-4'>Hyper personalized learning for students between Grade 4 - 12 !</div>
                <div className='bannersubtext mt-3'>Assisting your children with their course work to complex test prep and guiding them with the right choice for their college admission process as a parent can be quite daunting. We got your back in guiding your children in turning them into academic champ.</div>
                <div className="mb-5 mt-4 ">
                  <button className="btn btn-primary"  onClick={() => setopenpopup(true)} >
                    Book Your Trial Session
                  </button>
                </div>
                
              </div>
            </div>
            <div className="col-md-5 position-relative pe-0 pb-0 landingPageRigthDiv text-center d-flex align-items-end  justify-content-center">
              <img
                src={localImages?.learnBanner}
                alt="Digital sat Banner"
                className="w-100"
                style={{maxWidth:"500px"}}
              />
            </div>
          </div>
        </div>
        <div className='commonPad position-relative wow fadeIn' data-wow-duration="2s" data-wow-delay="0s" id='offer'>
        <img src={localImages?.award} className='learnSolleft' alt='learnSolleft' style={{maxWidth:"130px"}} />
        <div className='width800 mx-auto my-5'>
            <h1 className='headingFont text-center'>What we Offer ?</h1>           
          </div>
          <div className='d-flex row mx-0 mx-md-5 wow fadeIn' data-wow-duration="2s" data-wow-delay="0s">
              {offerData?.map((item:any,index:number)=>(
                <div className='col-md-4 mb-3 mb-md-0 wow fadeIn' data-wow-duration="2s" data-wow-delay={`${index * 0.5}s`} key={index}>
                  <div className='learnWeOfferCard overflow-hidden'>
                      <div className='learnWeOfferTitle text-center font20'>{item?.title}</div>
                      <div>
                        <img src={item?.imgPath} alt={item?.title} className='w-100'/>
                      </div>
                      <div className='p-4 font16'>
                        {item?.desc}
                      </div>
                  </div>
                </div>
              ))}
            </div>
        </div>
        <div id='OurProgram' style={{height:"65px"}} ></div>
        <div className='commonPad position-relative bgLightGrey'  ref={targetRef}>
        <h1 className='headingFont text-center'>Our Program consists of?</h1> 
        <div className='row mx-0 d-flex justify-content-center mt-5'>
              {learnOurProgramData?.map((item,index)=>(
                <div className='col-md-4 mb-4 wow fadeIn' data-wow-duration="2s" data-wow-delay={`${index * 0.5}s`} key={index}>
                  <div className='learnourProgram'>
                    <div className='icon-container'>
                      <div className='learnourProgramImgCard'>
                        <img src={item?.imgPath} alt={item?.title} className='learnourProgramImg' />
                      </div>
                    </div>
                    <div className='fw-bold font20 my-3 text-center'>{item?.title}</div>
                    <div className='text-center font16'>{item?.desc}</div>
                  </div>
                </div>
              ))}
        </div>  
        </div>
        <div id='WhyUs'></div>
        <div ref={statRef}>
        <Stats />
        </div>
    
      <div id="Testimonials"></div>
      <div className="commonPad bgLightGrey">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            Don't just take our word for it
          </h1>
          <div className="bannersubtext mb-3 text-center">
            Hear what our students have to say
          </div>
        </div>
        <div>
          <StudentSayCarousel
            Data={dataConstant?.studentSayData}
            id="carouselStudent"
          />
        </div>
        <div></div>
      </div>
      <div id="Awards"></div>
      <CertificateSection />

      <div className="commonPad bglightSkyblue ">
        <div className="bgwhite p-5 boxshadow rounded position-relative">
          <div className="row">
            <div className="col-md-8">
              <div className="bookSessionHeading">Book your session now</div>
              <div className="bookSessionSubText">
                One of our Academic Counsellor will get in touch with you to
                discuss further.
              </div>
              <div className="mt-5">
                <button
                  className="signBtn px-5 rounded font16"
                  onClick={() => setopenpopup(true)}
                >
                  Start your Academic Journey Today
                </button>
              </div>
            </div>
            <div className="col-md-4">
              {/* <img src={localImages?.collegePredleft} className='collegePredleft' alt='collegePredleft'/> */}
              {/* <img src={localImages?.collegePredright} className='collegePredright' alt='collegePredright'/> */}
            </div>
          </div>
        </div>
        {/* <BookSession clickEvent={() => goToLink("BookSession")} /> */}
      </div>
      <div id="FAQs"></div>
      <div className="commonPad ">
        <div className="row mx-0">
          <div className="col-md-3">
            <h1 className="headingFont mb-4">FAQs</h1>
            <div className="mb-4">
              <button
                className="contactUSbtn px-5"
                onClick={() => contactUS("Footer")}
              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="col-md-9">
            <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"} />
          </div>
        </div>
      </div>
      {/***************Footer*************** */}
       
      <Footer id="Footer" />
      <AffilateMarketingPopup2
        open={openpopup1}
        handleClose={() => setopenpopup1(false)}
      />
      <AffilateMarketingPopup
        open={openpopup}
        handleClose={() => setopenpopup(false)}
      />

      {!isHidden ? (
        <div className="fixedFooter">
          <div className="row mx-0">
            <div className="col-7 col-md-6 d-flex align-items-center mb-2 mb-md-0">
              <div className="fw-bold font20 colorblue">
              Book Your Trial Class With Us Today!
              </div>
            </div>
            <div className="col-5 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end flex-wrap position-relative">
              <div className="d-none d-md-flex position-relative me-2">
                <select
                  className="form-select cursor coutryCode  rounded-0 rounded-start d-none d-md-flex"
                  aria-label="Default select example"
                  defaultValue={1}
                  value={coutryCode}
                  onChange={onChangeCoutryCode}
                >
                  {countryData?.map((item: any, index) => {
                    return (
                      <option value={item.phonecode}>
                        {item?.isoCode} +{item?.phonecode}
                      </option>
                    );
                  })}
                </select>
                <PhoneInputField
                  placeholder="Enter Your Mobile Number"
                  value={mob}
                  maxLength={"10"}
                  setValue={setPhoneNumber}
                />
              </div>
              <button
                className="bookSessionButton rounded fw-bold mt-2 mt-md-0 font16"
                onClick={() => setopenpopup(true)}
              >
                Book Your Trial Session
              </button>
              
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
