import { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
import logo from '../Assets/Images/Home/logo.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function PdfViewer() {
    const link = sessionStorage.getItem('pdfLink');

    useEffect(() => {
        const handleContextmenu = (e: { preventDefault: () => void; }) => {
            e.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextmenu);
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu);
        };
    }, []);

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (numPages: number) => {
        setNumPages(numPages);
    };

    const nextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const prevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };
    return (
        <div>
            <header
                className={'d-flex justify-content-between position-sticky'}>
                <div className="d-flex align-items-center">
                    <img
                        src={logo}
                        alt="IFAS Logo"
                        className="headerlogo"
                        style={{ width: '120px' }}
                    />

                </div>


            </header>
            <div className="pdfDiv">
                {/* <div className="controls">
        <button className={`btn me-2 ${pageNumber === 1 ? 'btn-secondary':'btn-primary'}`} onClick={prevPage} disabled={pageNumber === 1}>
          Prev
        </button>
        <button  className={`btn ${pageNumber === numPages ? 'btn-secondary':'btn-primary'}`} onClick={nextPage} disabled={pageNumber === numPages}>
          Next
        </button>
      </div> */}
                <Document
                    file={link}
                    onLoadSuccess={() => onDocumentLoadSuccess(numPages)}
                    onContextMenu={(e: { preventDefault: () => any; }) => e.preventDefault()}
                    className="pdf-container">
                    {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map((page) => (
                            <Page pageNumber={page} />
                        ))}
                    <Page pageNumber={pageNumber} />
                </Document>
            </div>
        </div>
    );
}

export default PdfViewer;