import React, { useEffect, useState } from "react";
import deleteimg from "../Assets/Images/courses/Delete.png";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import DatePicker from "../Component/datePicker";
import { AuthReducerModal } from "../Modals";

import Dropdown from "../Component/dropdown";
import { getCountry, getTeacher, gettimezones, scheduleClass } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import { teacherId } from "../Utils/appConstants";
import moment from "moment";
function SlotPopUp(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [teachers, setTeachers] = useState("");
  const [teachersList, setTeachersList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [classEndDate, setClassEndDate] = useState("");
  const [timeZoneList, settimeZoneList] = useState([]);
  const [timezone, settimezone] = useState<any>("");
  const [countryData, setCountryData] = useState([]);
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [lectureCount, setlectureCount] = useState<number>(props?.packageData?.offerLectureCount);
  const [time, settime] = useState<string>("");
 
  const initdayArr = [
    {
      _id: "Sunday",
      name: "S",
      isSelected: false,
    },
    {
      _id: "Monday",
      name: "M",
      isSelected: false,
    },
    {
      _id: "Tuesday",
      name: "T",
      isSelected: false,
    },
    {
      _id: "Wednesday",
      name: "W",
      isSelected: false,
    },
    {
      _id: "Thursday",
      name: "T",
      isSelected: false,
    },
    {
      _id: "Friday",
      name: "F",
      isSelected: false,
    },
    {
      _id: "Saturday",
      name: "S",
      isSelected: false,
    },
  ];
  const [dayArray,setDayArray]=useState(initdayArr)
  const { firstName,countryCode } = useSelector(
    (state: { AuthReducer: AuthReducerModal }) => ({
      firstName: state?.AuthReducer?.firstName,
      countryCode:state?.AuthReducer?.countryCode,
    })
  );
  console.log('props?.packageData', props?.packageData);
  useEffect(()=>{
    dispatch(getCountry(
      (data: any) => {
          setCountryData(data)
      },
      () => { })
  );
  },[])
  useEffect(()=>{
  dispatch(getTeacher(
    {subjectId:props?.packageData?.subjectId},
    (data: any) => {
      setTeachersList(data)
    },
    () => { })
);
  },[props?.packageData?.subjectId])

  useEffect(()=>{
    if(countryCode!=="" && countryData?.length>0){
      const filter:any=countryData?.filter((item:any)=>item?.phonecode==countryCode.substring(1));
      dispatch(gettimezones(
          filter[0]?._id,
        (data: any) => {
            settimeZoneList(data)
        },
        () => { })  );
  }
  },[countryData,countryCode,dispatch])
// const calculateEndDate = () => {

//   const start = new Date(startDate);
//   let totalDays = Math.ceil(41 / 3) * 7;
//   let end = new Date(start.getTime());
//   end.setDate(start.getDate() + totalDays);

//   // setEndDate(end.toISOString().split('T')[0]);
//   return end
// };


const calculateEndDate = () => {
if (!startDate || lectureCount <= 0 || selectedDays.length === 0) {
  setEndDate('')
  setClassEndDate('')
  return '';}

let date = new Date(startDate);
let count = 0;

while (count < lectureCount) {
  date.setDate(date.getDate() + 1); // Increment the date by one day

  if (selectedDays.includes(date.getDay())) {
    count++;
  }
}
console.log('date', date);
setClassEndDate(date.toISOString().split('T')[0])
date.setDate(date.getDate() + 30); 
setEndDate(date.toISOString().split('T')[0])
return date.toISOString().split('T')[0];
};
useEffect(()=>{
  calculateEndDate();
},[selectedDays])

  const onChangeTeachers=(val:any)=>{
    setTeachers(val.target.value)
  }
  

  const onClickDay = (val:any, index:any) => {

    const day = parseInt(index);
    const newSelectedDay:any=[...selectedDays];
    if(val?.isSelected===false){
      newSelectedDay?.push(day)
    }
    else{
      let timeindex = newSelectedDay?.findIndex(
        (obj: any) => obj === day
      );
      newSelectedDay?.splice(timeindex, 1);
    }
    setSelectedDays(newSelectedDay)
    const newArr=[...dayArray]
    newArr[index]["isSelected"]=!val?.isSelected;
    setDayArray(newArr)
 
  };

  const classScheduleArr = [
    {
      _id: "9",
      name: "NineAM",
      time: "9.00AM",
      isSelected: false,
    },
    {
      _id: "10",
      name: "TenAM",
      time: "10.00AM",
      isSelected: false,
    },
    {
      _id: "11",
      name: "ElevenAM",
      time: "11.00AM",
      isSelected: false,
    },
    {
      _id: "12",
      name: "TwelveAM",
      time: "12.00AM",
      isSelected: false,
    },
    {
      _id: "13",
      name: "OnePM",
      time: "1.00PM",
      isSelected: false,
    },
    {
      _id: "14",
      name: "TwoPM",
      time: "2.00PM",
      isSelected: false,
    },
    {
      _id: "15",
      name: "ThreePM",
      time: "3.00PM",
      isSelected: false,
    },
    {
      _id: "16",
      name: "FourPM",
      time: "4.00PM",
      isSelected: false,
    },
    {
      _id: "17",
      name: "FivePM",
      time: "5.00PM",
      isSelected: false,
    },
    {
      _id: "18",
      name: "SixPM",
      time: "6.00PM",
      isSelected: false,
    },
    {
      _id: "19",
      name: "SevenPM",
      time: "7.00PM",
      isSelected: false,
    },
    {
      _id: "20",
      name: "EightPM",
      time: "8.00PM",
      isSelected: false,
    },
    {
      _id: "21",
      name: "NinePM",
      time: "9.00PM",
      isSelected: false,
    },
  ];
  const onClickTime = (val:any) => {
    settime(val?._id)
  
  };

  const changeTimeZone = (val: any) => {  
    // settimezone(val)
    const timezoneData=timeZoneList?.filter((item:any) => item.name === val.target.value);
    settimezone(timezoneData[0])
  }
  const handleSubmit=()=>{
    const postData={
      timezoneId: timezone?.timezoneId,
      teacherId:teachers,
      packageId:""+props?.packageData?._id,
      startDate:startDate,
      endDate:endDate,
      days:selectedDays,
      time:time
    }
    if(props?.packageData?.levelId===4){
      postData["levelId"]=4
    }
    console.log('postData', postData);
    dispatch(scheduleClass(
      postData,
        (res: any) => {
            props.handleClose()
        },
        () => { })
    )

  }
  function validate() {
    return (
        time==="" || teachers===""||startDate==="" ||endDate===""||selectedDays?.length===0
    );
}

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.openPopup}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.openPopup}>
        <div className="slotmodeldiv">
          <div className=" d-flex mt-0 justify-content-center  ">
            <p className="fw-bolder fs-5 text-primary">Select Time & Date</p>
          </div>
          <div className="">
            <div className="row d-flex ">
              <div className="col-md-3">
                <label className="mt-1 fw-bold">Teacher</label>
              </div>
              <div className="col-md-6">
                <select
                  className="form-select cursor form-select-sm"
                  aria-label="Select teacher"
                  value={teachers}
                  onChange={onChangeTeachers}
                >
                  <option value="">Select teacher</option>
                  {teachersList?.map((item: any, index) => {
                    return <option value={item.teacherId}>{item.name ? item.name : item.Name}</option>;
                  })}
                </select>

              </div>
            </div>
            <div className='row d-flex align-items-center mt-3'>
              <div className="col-md-3">
                <label className="mt-1 fw-bold">Select Time Zone</label>
              </div>
              <div className="col-md-6">
                <select
                  className="form-select cursor form-select-sm"
                  aria-label="Default select example"
                  value={timezone?.name}
                  onChange={changeTimeZone}
                >
                  <option value="">Select Time Zone</option>
                  {timeZoneList?.map((item: any, index) => {
                    return <option value={item.name}>{item.name ? item.name : item.Name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="row d-flex mt-3">
              <div className="col-md-3">
                <label className="mt-1 fw-bold">Start Date</label>
              </div>
              <div className="col-md-6">

                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                  defaultValue={startDate} mindate={moment(new Date()).format("YYYY-MM-DD")}/>
                  
              </div>

            </div>
            <div className="row d-flex mt-3">
              <div className="col-md-3">
                <label className="mt-1 fw-bold">Select Day</label>
              </div>
              <div className="col-md-9 d-flex flex-wrap">

                {dayArray?.map((value: any, index: any) => {
                  return (
                    <div
                      className={`border rounded-circle slotdaydiv text-center cursor ${value?.isSelected === true
                          ? "bg-primary text-white"
                          : ""
                        } me-3 `}
                      onClick={() => onClickDay(value, index)}
                    >
                      <p className="p-2  mb-0 text-center">{value?.name}</p>
                    </div>
                  );
                })}
              </div>

            </div>
            <div className="row d-flex mt-3">
              <div className="col-md-3">
                <label className="mt-1 fw-bold">Time Slot</label>
              </div>
              <div className="col-md-8 ">
                <div className="d-flex flex-wrap">
                  {classScheduleArr?.map((value: any, index: any) => {
                    return (
                      <div
                        className={`border rounded p-3 cursor  m-2 timeSlot ${time === value?._id ? 'bg-primary text-white' : ''} `}

                        onClick={() => onClickTime(value)}
                      >
                        {value?.time}
                      </div>
                    );
                  })}
                </div>
              </div>


            </div>
            <div className="row d-flex mt-3">
              <div className="col-md-3">
                <label className="mt-1 fw-bold">Last Live Class Date</label>
              </div>
              <div className="col-md-6">
                <DatePicker
                  value={classEndDate}
                  setInputText={(value: string) => setClassEndDate(value)}
                  defaultValue={endDate}
                  disabled={true} />
              </div>
            </div>
            <div className="row d-flex mt-3">
              <div className="col-md-3">
                <label className="mt-1 fw-bold">Subscription End Date</label>
              </div>
              <div className="col-md-6">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                  defaultValue={endDate}
                  disabled={true} />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <button className="btn btn-secondary me-2 " onClick={props.handleClose}>Cancel</button>
              <button className={`btn  ${validate()?'btn-secondary':'btn-primary'}`} disabled={validate()}  onClick={handleSubmit}>Save</button>

            </div>

          </div>

        </div>
      </Fade>
    </Modal>
  );
}

export default SlotPopUp;
