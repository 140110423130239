import React, { useEffect, useState } from 'react';
import localImages from '../Constant/localImagesNew';
import Footer from '../Component/footer';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderHome from '../Component/headerHome';
import Sidebar from '../Module/sidebar';
import { useSelector } from 'react-redux';
import { SidebarDataFreeUser, SidebarDataPaidUser } from '../Module/sidebarData';
import '../Assets/Css/blog.css';
import { getBlog, getBlogDetails } from '../Redux/Actions/AuthAction';
import { useAppDispatch } from '../hooks';
import parser from "html-react-parser";
export default function BlogDetails() {
    const navigate = useNavigate();
    const {id}=useParams()
    const dispatch = useAppDispatch();
    const [isHidden, setisHidden] = useState<boolean>(true);
    const [blogData, setblogData] = useState([]);
    const [blogDetails, setblogDetails] = useState<any>();
  
    const {ACCESS_TOKEN,hasActivePaidPlan} = useSelector((state:any) => ({
        ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
        hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
      }));
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getBlogDetails(
            id,
            (data: any) => {                
                setblogDetails(data[0])
                // setblogData(blogArr)
            },
            () => { })
        );
        dispatch(getBlog(
            (data: any) => {
                const blogArr=data?.filter((item:any)=>item?.blogId!=id)  ;
                setblogData(blogArr)
            },
            () => { })
        );
    }, []);
    const goToBlogDetails=(id:string)=>{
        navigate( `/BlogDetails/${id}`)
      }  
      const gotoLink=(link:string)=>{
        navigate('/'+link)
      }
    return (
        <div>
            <HeaderHome isHidden={isHidden} />
            <div className="d-block d-md-none">
                <Sidebar data={hasActivePaidPlan === true ? SidebarDataPaidUser : SidebarDataFreeUser} />
            </div>
            {/*************Banner************* */}
            <div className='blogBannerContainer commonPad mt-4 pe-md-3'> 
                <div className='row'>
                    <div className="col-md-9">
                    <h1 className='headingFont mb-3'>{blogDetails?.title}</h1>
                   <img src={blogDetails?.blogImage} alt={blogDetails?.title} className='blogImg'/>
                  {blogDetails?.content? <div className='font16'>
                    {parser(blogDetails?.content)}
                   </div>:null}
                    </div>
                    <div className="col-md-3 bgLightGrey">
                        <div className='d-flex px-4 justify-content-between py-3 border-bottom'>
                            <div className='fw-bold font16'>Recommended Blogs</div>
                            <div className='fw-bold colorblue font16 cursor' onClick={()=>gotoLink('Blog')}>See All</div>
                            </div>
                        <div className='row'>
                        {
                            blogData?.map((item: any, index) => {
                                return (
                                    <div className='col-12' key={item?.blogId}>
                                        <div className='p-3 h-100'>
                                            <img src={item?.blogImage} alt={item?.title} className='w-100' />
                                            <div className='fw-bold mt-3 mb-2 lh-sm'>{item?.title}</div>
                                            <div className="mt-4">
                                                <button className="contactUSbtn py-2 px-4 rounded font14" onClick={() => goToBlogDetails(item?.blogId)}>Know more</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>               
                    
                </div>

            

            {/***************Footer*************** */}
            <Footer />

        </div>
            )
}
