import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);
const DppMultilayerChart = ({ percentile, accuracy, style,attempted }) => { 
  const totalAccuracy = 100 - accuracy;
  const totalPercentile = 100 - percentile;
  const totalAttempted = 100 - attempted;
  const data = {
    labels: [''],
    datasets: [
        {
            data: [attempted, totalAttempted],
            backgroundColor: ['#F23E36', '#EFEFEF'],
            borderColor: ['#F23E36', '#EFEFEF'],
            borderWidth: 1
            // cutout: "80%"
          },
    
          {
            // dummmy data to maintain a gap between circle
            data: [0, 0],
            backgroundColor: ['#fff', '#fff'],
            borderColor: ['#fff', '#fff'],
            borderWidth: 1
          },
          {
            data: [accuracy, totalAccuracy],
            backgroundColor: ['#FFA40B', '#EFEFEF'],
            borderColor: ['#FFA40B', '#EFEFEF'],
            borderWidth: 1
            // cutout: "80%"
          },
    
          {
            // dummmy data to maintain a gap between circle
            data: [0, 0],
            backgroundColor: ['#fff', '#fff'],
            borderColor: ['#fff', '#fff'],
            borderWidth: 1
          },
          {
            data: [percentile, totalPercentile],
            backgroundColor: percentile ? ['#158803', '#EFEFEF'] : ['#fff', '#fff'],
            borderColor: percentile ? ['#158803', '#EFEFEF'] : ['#fff', '#fff'],
            borderWidth: 1
            // cutout: "10%",
          }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false
      },
      tooltip: {
        display: false
      }
    }
  };
  return (
    <div className="mx-auto" style={style}>
      <Doughnut data={data} />
    </div>
  );
};

export default DppMultilayerChart;
