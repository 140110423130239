// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thank-you-message {
    position: relative;
    height: 100vh;
    background-color: rgb(235, 242, 255);
  }
  
  .thank-you-message .card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: auto;
    border-radius: 15px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  }
  
  .thank-you-message .card .card-body .text-holder {
    padding: 150px 0;
  }
  
  .thank-you-message .card .card-body .text-holder h1 {
    font-size: 60px !important;
  }
  
  .thank-you-message .card .card-body .text-holder p {
    font-size: 20px;
  }

  .thank-you-message .clickbtn{
    background-color: #ff5f2f;
    padding: 9px;
    font-size: 16px;
  }`, "",{"version":3,"sources":["webpack://./src/Assets/Css/thankYou.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,oCAAoC;EACtC;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,4CAA4C;EAC9C;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,eAAe;EACjB","sourcesContent":[".thank-you-message {\n    position: relative;\n    height: 100vh;\n    background-color: rgb(235, 242, 255);\n  }\n  \n  .thank-you-message .card {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 60%;\n    height: auto;\n    border-radius: 15px;\n    border: none;\n    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;\n  }\n  \n  .thank-you-message .card .card-body .text-holder {\n    padding: 150px 0;\n  }\n  \n  .thank-you-message .card .card-body .text-holder h1 {\n    font-size: 60px !important;\n  }\n  \n  .thank-you-message .card .card-body .text-holder p {\n    font-size: 20px;\n  }\n\n  .thank-you-message .clickbtn{\n    background-color: #ff5f2f;\n    padding: 9px;\n    font-size: 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
