import React, { useEffect, useState } from 'react';
import '../App.css';
import '../Assets/Css/videoplayer.css';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import ShakaPlayer from 'shaka-player-react';
import { useLocation } from 'react-router-dom';

function VideoPlayer() {
  const location = useLocation();
  const [play, setPlay] = useState(false);
  const [volume, setVolume] = useState(1);
  const [mute, setmute] = useState(false);
  const [playbacksetting, setPlaybacksetting] = useState(false);
  const [temp, setTemp] = useState(1);
  const [lectureDetails, setLectureDetails] = useState();
  const { recordedData, lectureId } = location.state
  const ref = React.useRef(null);
  React.useEffect(() => {
    window.getShakaInst = () => ref.current;
  }, []);
  useEffect(() => {
    if (lectureId) {
      setLectureDetails(lectureId)
    }
  }, [lectureId]);
  useEffect(() => {
    if (recordedData) {
      setLectureDetails(recordedData)
    }
  }, [recordedData]);

 
  const handleMute = () => {
    setmute((prev) => !prev);
    if (volume) setVolume(0);
    else setVolume(1);
  };

  const handleplaybackSettings = () => {
    setPlaybacksetting((prev) => !prev);
  };


  const handlePlayPause = () => {
    let cTime = ref?.current?.videoElement?.currentTime;
    const video = document.querySelector('.shaka-video-container video');
    if (video) {
      video.currentTime = cTime;
    }
    if (ref.current.videoElement?.paused) {
      setPlay(false);
      ref.current.videoElement?.play();
    } else {
      setPlay(true);
      ref.current.videoElement?.pause();
    }
  };

  const [playbackRate, setPlaybackRate] = useState(1);

  useEffect(() => {
    ref.current.videoElement.playbackRate = playbackRate;
  }, [playbackRate]);

  useEffect(() => {
    if (ref.current) {
      ref.current.videoElement.volume = volume;
    }
  }, [volume]);


  const handleVolume = (e) => {
    setVolume(e.target.valueAsNumber);
    if (e.target.valueAsNumber === 1) {
      setmute(false);
    } else if (e.target.valueAsNumber === 0) {
      setmute(true);
    }
  };
  const setPlayBack = (e, key) => {
    setPlaybacksetting(!playbacksetting);
    setTemp(key);
    setPlaybackRate(key);

  };
  const playbackspeeds = [0.5, 1, 1.25, 1.5, 1.75, 2];

  return (
    <div className="d-flex">
      <div className={`align-item-center col-md-12`}>
        <ShakaPlayer
          ref={ref}
          autoPlay
          src={lectureDetails?.lectureLink?lectureDetails?.lectureLink:lectureDetails?.m3u8Link}
        />
        <div
          className={` videobottom p-2 d-flex justify-content-between align-items-center position-fixed bottom-0 col-md-12`} style={{ zIndex: "1" }}>
          <div>
            <span className="ms-3" onClick={handlePlayPause}>
              {play ? <PlayArrowIcon /> : <PauseIcon />}
            </span>
          </div>
          <div>
            <span className="me-2" onClick={handleMute}>
              {mute === false ? <VolumeUpRoundedIcon /> : <VolumeOffRoundedIcon />}
            </span>
            <span>
              <input
                style={{ height: "5px" }}
                type="range"
                min={0}
                max={1}
                step={0.02}
                value={volume}
                onChange={(event) => {
                  handleVolume(event);
                }}
              />
            </span>
            <span className="position-relative mx-2">
              <SlowMotionVideoIcon className="cursor" onClick={handleplaybackSettings} />
              {playbacksetting ? (
                <div className="playbacksetting bgwhite boxshadow position-absolute rounded">
                 
                  {playbackspeeds.map(function (key) {
                    return (
                      <div
                        id={key}
                        className="qualityno py-2 border-bottom"
                        style={{ cursor: 'pointer', background: temp === key ? '#c2c2a3' : '' }}
                        onClick={(e) => {
                          setPlayBack(e, key);
                        }}>
                        {key}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
