import { useEffect, useState } from "react";
import "../App.css";
import "../Assets/Css/placeorder.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import { AuthReducerModal } from "../Modals";
import HeaderDsat from "../Component/headerDsat";
import InputField from "../Atom/InputField";
import {
  hasWhiteSpace,
  length_Hundred,
  length_OneHundredFifty,
  length_Ten,
  length_Two,
  startsWithSpace,
  validateEmailAddress,
  validateFullName,
  validateIsLettersAndDotOnly,
  validateIsNumberOnly,
  validatePanCard,
} from "../Utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateEmailAddressErrMsg,
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../Utils/errormsg";
import DropdownCustom from "../Component/dropdownCustom";
import { bloodGroupList, GenderList } from "../Utils/dataConstants";
import DatePicker from "../Component/datePicker";
import { maritalStatus } from "../Utils/dataConstants";
import { addTutor, uploadMedia } from "../Redux/Actions/AuthAction";
import localImages from "../Constant/localImagesNew";
import FormSubmitPopUp from "../Module/formSubmitPopUp";
import LocalImages from "../Constant/localImages";
import { gradeLevelList } from "../Redux/Actions/AuthAction";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
function TutorForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [fullname, setFullname] = useState<string>("");
  const [mob, setmob] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [gender, setGender] = useState<any>("");
  const [birthDate, setBirthDate] = useState<any>("");
  const [bloodGroup, setBloodGroup] = useState<any>("");
  const [fathername, setFathername] = useState<any>("");
  const [mStatus, setMStatus] = useState<any>("");
  const [birthPlace, setBirthPlace] = useState<any>("");
  const [adharcard, setAdharcard] = useState<any>("");
  const [pancard, setPancard] = useState<any>("");
  const [qualification, setQualification] = useState<any>("");
  const [college, setCollege] = useState<any>("");
  const [DOJ, setDOJ] = useState<any>("");
  const [grade, setGrade] = useState<any>("");
  const [joiningDepartment, setJoiningDepartment] = useState<any>("");
  const [presentaddress, setPresentaddress] = useState<any>("");
  const [emergencyContact, setEmergencyContact] = useState<any>("");
  const [emergencyContactName, setEmergencyContactName] = useState<any>("");
  const [emergencyContactrel, setEmergencyContactNameRel] = useState<any>("");
  const [bankAccNo, setBankAccNo] = useState<any>("");
  const [ifscCode, setIfscCode] = useState<any>("");
  const [step, setStep] = useState<any>(1);
  const [offerLetter, setOfferLetter] = useState<any>("");
  const [uploadedOfferLetter, setUploadedOfferLetter] = useState<any>("");
  const [adharcardimg, setAdharcardimg] = useState<any>("");
  const [uploadedAdharcardimg, setUploadedAdharcardimg] = useState<any>("");
  const [panCardimg, setPanCardimg] = useState<any>("");
  const [uploadedPanCardimg, setUploadedPanCardimg] = useState<any>("");
  const [form11, setForm11] = useState<any>("");
  const [uploadedForm11, setUploadedForm11] = useState<any>("");
  const [declaration, setDeclaration] = useState<any>("");
  const [uploadedDeclaration, setUploadedDeclaration] = useState<any>("");
  const [assetsDeclaration, setAssetsDeclaration] = useState<any>("");
  const [uploadedAssetsDeclaration, setUploadedAssetsDeclaration] =
    useState<any>("");
  const [dataProtection, setDataProtection] = useState<any>("");
  const [uploadedDataProtection, setUploadedDataProtection] = useState<any>("");
  const [photo, setPhoto] = useState<any>("");
  const [uploadedPhoto, setUploadedPhoto] = useState<any>("");
  const [genderList, setGenderList] = useState([]);
  const [policy, setPolicy] = useState<any>("");
  const [uploadedPolicy, setUploadedPolicy] = useState<any>("");
  const [open, setopen] = useState<boolean>(false);
  const [gradeList,setGradeList]= useState<any>([]);
  const [pinCode, setPinCode]=useState<any>('')
  const { ACCESS_TOKEN, examTypeId, studentId } = useSelector(
    (state: { AuthReducer: AuthReducerModal }) => ({
      ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
      examTypeId: state?.AuthReducer?.examTypeId,
      userId: state?.AuthReducer?.userId,
      studentId: state?.AuthReducer?.studentId,
    })
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(gradeLevelList(
        (data: any) => {
          setGradeList(data)
          setGrade(data[0]?._id)
        },
        () => { })
      );
}, []);
  const handleFullName = (e: any) => {
    if (validateFullName(e)) setFullname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleMobileNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const changeGender = (val: any) => {
    setGender(val);
  };
  const onchangeMail = (val: any) => { 
    if (!validateEmailAddress(val) && !hasWhiteSpace(val) && !startsWithSpace(val))  setemail(val);
    else {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg));
    } 
  };
  const handlefatherName = (val: any) => {
    if (validateFullName(val)) setFathername(val);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const changeMaritalStatus = (val: any) => {
    setMStatus(val);
  };
  const handleBirthPalce = (val: any) => {
     setBirthPlace(val);
  
  };
  const handleadharcard = (val: any) => {
    if (!validateIsNumberOnly(val)) {
      setAdharcard(val);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const handlePancard = (val: any) => {
    // if (validatePanCard(val)) {
    //   setPancard(val);
    // } else {
    //   dispatch(showErrorSnackbar("Please enter Valid number"));
    // }
    setPancard(val);
    
  };
  const handleQualification = (val: any) => {
    if (validateIsLettersAndDotOnly(val)) {
      setQualification(val);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const handleCollege = (val: any) => {
    setCollege(val);
  };
  const handleGrade = (val: any) => {
    setGrade(val);
  };
  const handleJoiningDepartment = (val: any) => {
    setJoiningDepartment(val);
  };
  const handlepresentaddress = (val: any) => {
    setPresentaddress(val);
  };
  const handlepincode=(val:any)=>{
    if (!validateIsNumberOnly(val)) {
      setPinCode(val);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
    
  }
  const handleEmergencyContactName = (val: any) => {
    if (validateFullName(val)) setEmergencyContactName(val);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleEmergencyContact = (val: any) => {
    if (!validateIsNumberOnly(val)) {
      setEmergencyContact(val);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const handleEmergencyContactRel = (val: any) => {
    if (validateFullName(val)) setEmergencyContactNameRel(val);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleBankAccNo = (val: any) => {
    if (!validateIsNumberOnly(val)) {
      setBankAccNo(val);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const OnchangeBloodGroup=(val:any)=>{
    setBloodGroup(val)
  }
  const handleIFSCCode = (val: any) => {
    setIfscCode(val);
  };
  const handleNext = (val: any) => {
    setStep(2);
    const PostData: any = {
      name: fullname,
      mobile: mob,
      email: email,
      gender: gender?.name,
      date_of_birth: birthDate,
      blood_group: bloodGroup?.name,
      father_name: fathername,
      marital_status: mStatus?.name,
      place_of_birth: birthPlace,
      aadhaar_card_number: adharcard,
      pan_card_number: pancard,
      qualification: qualification,
      college_university_name: college,
      date_of_joining: DOJ,
      grade: grade?.name,
      department: joiningDepartment,
      present_address: presentaddress,
      present_address_pincode:pinCode,
      emergency_contact_name: emergencyContactName,
      emergency_contact_number: emergencyContact,
      emergency_contact_relationship: emergencyContactrel,
      bank_account_number: bankAccNo,
      ifsc_code: ifscCode,
      offer_letter: uploadedOfferLetter,
      form_11: uploadedForm11,
      declaration_form: uploadedDeclaration,
      asset_declaration: uploadedAssetsDeclaration,
      data_protection_form: uploadedDataProtection,
    };
    dispatch(
      addTutor(
        PostData,
        (res: any) => {},
        () => {}
      )
    );
  };
  const handleEducationNext = () => {
    setStep(3);
    const PostData: any = {
      name: fullname,
      mobile: mob,
      email: email,
      gender: gender?.name,
      date_of_birth: birthDate,
      blood_group: bloodGroup?.name,
      father_name: fathername,
      marital_status: mStatus?.name,
      place_of_birth: birthPlace,
      aadhaar_card_number: adharcard,
      pan_card_number: pancard,
      qualification: qualification,
      college_university_name: college,
      date_of_joining: DOJ,
      grade: grade?.name,
      department: joiningDepartment,
      present_address: presentaddress,
      present_address_pincode:pinCode,
      emergency_contact_name: emergencyContactName,
      emergency_contact_number: emergencyContact,
      emergency_contact_relationship: emergencyContactrel,
      bank_account_number: bankAccNo,
      ifsc_code: ifscCode,
      offer_letter: uploadedOfferLetter,
      form_11: uploadedForm11,
      declaration_form: uploadedDeclaration,
      asset_declaration: uploadedAssetsDeclaration,
      data_protection_form: uploadedDataProtection,
    };
    dispatch(
      addTutor(
        PostData,
        (res: any) => {},
        () => {}
      )
    );
  };
  const clearData = () => {
    setFullname("");
    setmob("");
    setemail("");
    setGender("");
    setBirthDate("");
    setBloodGroup("");
    setFathername("");
    setMStatus("");
    setBirthPlace("");
    setAdharcard("");
    setPancard("");
    setQualification("");
    setCollege("");
    setDOJ("");
    setGrade("");
    setJoiningDepartment("");
    setPresentaddress("");
    setEmergencyContact("");
    setEmergencyContactName("");
    setEmergencyContactNameRel("");
    setBankAccNo("");
    setIfscCode("");
    setOfferLetter("");
    setUploadedOfferLetter("");
    setAdharcardimg("");
    setUploadedAdharcardimg("");
    setPanCardimg("");
    setUploadedPanCardimg("");
    setForm11("");
    setUploadedForm11("");
    setDeclaration("");
    setUploadedDeclaration("");
    setAssetsDeclaration("");
    setUploadedAssetsDeclaration("");
    setDataProtection("");
    setUploadedDataProtection("");
    setPhoto("");
    setUploadedPhoto("");
    setGenderList([]);
    setPinCode("")
  };
  const handlePrevious = (val: any) => {
    setStep(1);
  };
  const handlePrevious2 = (val: any) => {
    setStep(2);
  };

  const handleChange = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 10000;
    if (fileSizeKiloBytes > 10000)
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setOfferLetter(reader.result);
      };
      console.log("file", file);
      reader.readAsDataURL(file);
      dispatch(
        uploadMedia(
          file,
          (res: any) => {
            console.log("uploadMedia", res);
            // delete res?.filePath;
            setUploadedOfferLetter(res?.file);
          },
          () => {}
        )
      );
    }
  };

  const handleChangePolicy = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 10000;
    if (fileSizeKiloBytes > 10000)
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPolicy(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMedia(
          file,
          (res: any) => {
            // delete res?.filePath;
            setUploadedPolicy(res?.file);
          },
          () => {}
        )
      );
    }
  };

  const submitData = (e: any) => {
    e.preventDefault();
    const PostData: any = {
      name: fullname,
      mobile: mob,
      email: email,
      gender: gender?.name,
      date_of_birth: birthDate,
      blood_group: bloodGroup?.name,
      father_name: fathername,
      marital_status: mStatus?.name,
      place_of_birth: birthPlace,
      aadhaar_card_number: adharcard,
      pan_card_number: pancard,
      qualification: qualification,
      college_university_name: college,
      date_of_joining: DOJ,
      grade: grade?.name,
      department: joiningDepartment,
      present_address: presentaddress,
      present_address_pincode:pinCode,
      emergency_contact_name: emergencyContactName,
      emergency_contact_number: emergencyContact,
      emergency_contact_relationship: emergencyContactrel,
      bank_account_number: bankAccNo,
      ifsc_code: ifscCode,
      offer_letter: uploadedOfferLetter,
      tutor_policy: uploadedPolicy,
      status: 1,
    };
    dispatch(
      addTutor(
        PostData,
        (res: any) => {
          clearData();
          setopen(true);
          setStep(1)
        },
        () => {}
      )
    );
  };

  const Validation = () => {
    return (
      qualification === "" ||
      college === "" || 
      DOJ === "" ||
      grade === "" ||
      joiningDepartment === ""  
    );
  };
  const Validation1 = () => {
    return (
      fullname === "" ||
      email === "" || 
      mob?.length < 10 ||
      gender === "" ||
      birthDate === "" ||
      bloodGroup === "" ||
      qualification === "" ||
      fathername === "" ||
      mStatus === "" ||
      college === "" ||
      birthPlace === "" ||
      adharcard === "" ||
      pancard === "" ||
      DOJ === "" ||
      grade === "" ||
      joiningDepartment === "" ||
      presentaddress === "" ||
      emergencyContactName === "" ||
      emergencyContact === "" ||
      emergencyContactrel === "" ||
      bankAccNo === "" ||
      ifscCode === "" ||
      uploadedOfferLetter === "" ||
      uploadedPolicy === ""
    );
  };

  const Validation2 = () => {
    return (
      fullname === "" ||
      email === "" ||
      mob?.length < 10 ||
      gender === "" ||
      birthDate === "" ||
      bloodGroup === "" ||
      // qualification === "" ||
      fathername === "" ||
      mStatus === "" ||
      // college === "" ||
      birthPlace === "" ||
      // adharcard === "" ||
      // pancard === "" ||
      // DOJ === "" ||
      // grade === "" ||
      // joiningDepartment === "" ||
      presentaddress === "" ||
      emergencyContactName === "" ||
      emergencyContact === "" ||
      emergencyContactrel === ""
      // bankAccNo === "" ||
      // ifscCode === ""
    );
  };

  return (
    <div className="row d-flex justify-content-between p-5  pb-0 tutorScreen">
      <div className="col-md-5 d-flex align-items-center">
        <div className="text-center">
        <img
      className="img-fluid rounded  "
          src={LocalImages.logo}
        />
        <img
          className="img-fluid rounded  "
          src={LocalImages.bookfreeNew}
        /></div>
      </div>
      <div className="col-md-6 rounded shadow p-3  tutorformDiv  bg-light">
        <h5 className="fw-bold text-center approachheading mt-4">Tutor Information</h5>
        <div className="mb-4 mt-5">
          <div className="col-md-12 d-flex align-items-center mt-4 justify-content-center  ">
            <div
              className={`TutorCard text-center border mb-2 d-flex align-items-center justiify-content-between me-md-0 mx-1 ${
                step === 1 || step === 2 || step === 3 ? "active  " : ""
              }`}
            >
              <div className="col-md-2 d-md-block d-none">
                <div className="flowdiv"> 1 </div>
              </div>
              <div className="col-md-10">Personal Details</div>
            </div>
            <div
              className={`flowline d-none d-md-block mb-2 ${
                step === 2 || step === 3 || !Validation2() ? "active  " : ""
              }`}
            ></div>
            <div
              className={`TutorCard text-center border mb-2 d-flex align-items-center justiify-content-between me-md-0   ${
                step === 2 || step === 3 ? "active  " : ""
              }`}
            >
              <div className="col-md-2 d-md-block d-none">
                <div className="flowdiv"> 2 </div>
              </div>
              <div className="col-md-10">Education Details</div>
            </div>
            <div
              className={`flowline d-none d-md-block mb-2 ${
                step === 3 || !Validation2() ? "active  " : ""
              }`}
            ></div>
            <div
              className={`TutorCard text-center border mb-2 d-flex align-items-center justiify-content-between me-md-0   ${
                step === 3 ? "active  " : ""
              }`}
            >
              <div className="col-md-2 d-md-block d-none">
                <div className="flowdiv"> 3 </div>
              </div>
              <div className="col-md-10">Documents</div>
            </div>
          </div>
        </div>

        <form className="">
          <div className="tutorInnerform">
            {step === 1 && (
              <div className="row d-flex ">
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    Personal Mobile Number
                  </label>
                  <div className="d-flex">
                    <InputField
                      placeholder="Enter mobile Name"
                      value={mob}
                      setValue={handleMobileNumber}
                      maxLength={10}
                      minLength={10}
                    />
                    <span className="mendatory ms-2 fs-5">*</span>
                  </div>
                </div>
                <div className=" col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">Full Name</label>
                  <div className="d-flex">
                    <InputField
                      placeholder="Enter full Name"
                      value={fullname}
                      setValue={handleFullName}
                      minLength={length_Two}
                      maxLength={length_OneHundredFifty-1}
                    />
                    <span className="mendatory ms-2 fs-5">*</span>
                  </div>
                </div>

                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">
                    Personal Mail ID
                  </label>
                  <div className="d-flex">
                  <InputField
                      placeholder="Enter Email Address"
                      value={email}
                      setValue={onchangeMail}
                      minLength={length_Two}
                      maxLength={length_OneHundredFifty-1}
                    />
                    {/* <input
                      className="form-control p-2"
                      type="email"
                      value={email}
                      pattern=".+@example\.com"
                      placeholder="Enter Email Address"
                      onChange={(e) => setemail(e.target.value)}
                      maxLength={length_OneHundredFifty-1}
                    /> */}
                    <span className="mendatory ms-2 fs-5">*</span>
                  </div>
                </div>
                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">Gender</label>
                  <div className="d-flex">
                    <DropdownCustom
                      placeholder="Select Gender"
                      setInputText={(value: any) => changeGender(value)}
                      value={gender}
                      options={GenderList}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">Birth Date</label>
                  <div className=" ">
                    <DatePicker
                      value={birthDate}
                      setInputText={(value: string) => setBirthDate(value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">
                    Blood Group
                  </label>
                  <div className="d-flex ">
                    {/* <InputField
                      placeholder="Enter Blood Group"
                      value={bloodGroup}
                      setValue={handleBloodGroup}
                      minLength={2}
                      maxLength={3}
                    /> */}
                     <DropdownCustom
                      placeholder="Select  Blood Group"
                      setInputText={(value: any) => OnchangeBloodGroup(value)}
                      value={bloodGroup}
                      options={bloodGroupList}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">
                    Father Name
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Father name"
                      value={fathername}
                      setValue={handlefatherName}
                      minLength={length_Two}
                      maxLength={length_Hundred-1}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">
                    Marital Status
                  </label>
                  <div className="d-flex ">
                    <DropdownCustom
                      placeholder="Select Marital Status"
                      setInputText={(value: any) => changeMaritalStatus(value)}
                      value={mStatus}
                      options={maritalStatus}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">
                    Place of Birth
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Place Of birth"
                      value={birthPlace}
                      setValue={handleBirthPalce}
                      minLength={length_Two}
                      maxLength={length_Hundred-1}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>

                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">
                    Present Address
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Address & Pincode"
                      value={presentaddress}
                      setValue={handlepresentaddress}
                      minLength={length_Two}
                      maxLength={length_Hundred-1}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">
                    Pincode Number
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Address & Pincode"
                      value={pinCode}
                      setValue={handlepincode}
                      minLength={length_Two}
                      maxLength={length_Ten}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">
                    Emergency Contact Name
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Contact Name"
                      value={emergencyContactName}
                      setValue={handleEmergencyContactName}
                      minLength={length_Two}
                      maxLength={length_Hundred-1}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2 pe-4">
                  <label className="mb-2 fw-bold  text-start">
                    Emergency Contact Number
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Contact Number"
                      value={emergencyContact}
                      setValue={handleEmergencyContact}
                      maxLength={10}
                      minLength={10}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    Emergency Contact Relationship
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Contact Relationship"
                      value={emergencyContactrel}
                      setValue={handleEmergencyContactRel}
                      minLength={length_Two}
                      maxLength={length_Hundred-1}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="row d-flex">
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    Qualification(For Example B.E , B.Tech)
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter your response"
                      value={qualification}
                      setValue={handleQualification}
                      minLength={length_Two}
                      maxLength={length_Hundred-1}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    College/University Name
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter College/University Name"
                      value={college}
                      setValue={handleCollege}
                      minLength={length_Two}
                      maxLength={length_Hundred-1}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    Date Of Joining
                  </label>
                  <div className="  ">
                    <DatePicker
                      value={DOJ}
                      setInputText={(value: string) => setDOJ(value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">Grade</label>
                  <div className="d-flex ">
                    {/* <InputField
                      placeholder="Enter Garde"
                      value={grade}
                      setValue={handleGrade}
                    /> */}
                         <DropdownCustom
                      placeholder="Select Grade"
                      setInputText={(value: any) => handleGrade(value)}
                      value={grade}
                      options={gradeList}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    Department Of Joining
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Department Of Joining"
                      value={joiningDepartment}
                      setValue={handleJoiningDepartment}
                      minLength={length_Two}
                      maxLength={length_Hundred-1}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="row d-flex">
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    Aadhaar card number
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Aadhaar card number"
                      value={adharcard}
                      setValue={handleadharcard}
                      maxLength={12}
                      minLength={12}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    Pan card number
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Pan card number"
                      value={pancard}
                      setValue={handlePancard}
                      minLength={10}
                      maxLength={10}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    Bank account number
                  </label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter Bank account number"
                      value={bankAccNo}
                      setValue={handleBankAccNo}
                      maxLength={20}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">IFSC Code</label>
                  <div className="d-flex ">
                    <InputField
                      placeholder="Enter IFSC Code"
                      value={ifscCode}
                      setValue={handleIFSCCode}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    Offer Letter{" "}
                  </label>
                  <div className="d-flex ">
                    <input
                      type="file"
                      accept=".pdf"
                      id="upload-button"
                      className="form-control p-2"
                      onChange={handleChange}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>

                <div className="col-md-6 my-2">
                  <label className="mb-2 fw-bold  text-start">
                    {" "}
                    Tutor Policy
                  </label>
                  <div className="d-flex ">
                    <input
                      type="file"
                      accept=".pdf"
                      id="upload-button"
                      className="form-control p-2"
                      onChange={handleChangePolicy}
                    />
                    <span className="mendatory ms-2 fs-5"></span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {step === 1 ? (
            <div className="d-flex justify-content-end ">
              <button
                className={`sendOTPBtn rounded px-4 ${
                  Validation2() ? "" : "active"
                }`}
                onClick={handleNext}
                  disabled={Validation2()}
              >
                Next
                <ArrowRightAltIcon
                          className={`ms-2 font24${
                            Validation2() ? "" : "bookSessionOrange"
                          }`}
                        />
              </button>
            </div>
          ) : step === 2 ? (
            <div className="d-flex justify-content-between d-flex just">
              <p
                className="fw-bolder text-primary cursor"
                onClick={handlePrevious}
              >
                Previous
              </p>
              <button
                className={`sendOTPBtn rounded px-4 ${
                  Validation() ? "" : "active"
                }`}
                onClick={handleEducationNext}
                  disabled={Validation()}
              >
                Next
                <ArrowRightAltIcon
                          className={`ms-2 font24${
                            Validation() ? "" : "bookSessionOrange"
                          }`}
                        />
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <p
                className="fw-bolder text-primary cursor"
                onClick={handlePrevious2}
              >
                Previous
              </p>
              <button
                className={"sendOTPBtn rounded px-4  "}
                onClick={submitData}
                disabled={Validation1()}
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
      <FormSubmitPopUp
        open={open}
        handleClose={() => setopen(false)}
        fullname={fullname}
      />
    </div>
  );
}

export default TutorForm;
