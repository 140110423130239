import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { clearSnackbar } from '../Redux/Actions/snackbarAction';
import { useAppDispatch, useAppSelector } from '../hooks';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props}/>;
});
function SuccessSnackbar() {
  const dispatch = useAppDispatch();
  const { successSnackbarOpen, errorSnackbarOpen, infoSnackbarOpen, message } = useAppSelector(
    (state:any) => ({
      successSnackbarOpen: state.uiReducer.successSnackbarOpen,
      errorSnackbarOpen: state.uiReducer.errorSnackbarOpen,
      infoSnackbarOpen: state.uiReducer.infoSnackbarOpen,
      message: state.uiReducer.message
    })
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }
  return (
    <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
    
  );
}

export default SuccessSnackbar;
