// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Images/footer/footerimg 2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerlogo{
    width: 150px;
}
.footerLink{
    font-size: 16px;
    margin: 10px 0;
    /* font-weight: 600; */
    cursor: pointer;
}
.footer{
    background-color: #00112F; 
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) ;  
    background-size: cover;
    position:relative;
    background-repeat: no-repeat;
    /* -webkit-filter: blur(8px); */

}
.footerSubtext{
    color: #B2C3E1;
}
.footer_div{
    background-color: rgb(0 17 47 / 86%); 
   position: absolute;
   height: 100%;
   width: 100%;
   
}
.footerIcon{
    color: #FF5F2F;
}
/* .footerLink::after{
    content: '';
    display: block;
    width: 0;  
    height: 2px;
    background:var(--theme1);
    transition: width 0.3s ease;
 }
 .footerLink:hover::after{
    width: 100%;  
 } */

 @media screen and (max-width:567px) {
    
    .footer {
        background-image: none ; 
  }
  }`, "",{"version":3,"sources":["webpack://./src/Assets/Css/footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,eAAe;IACf,cAAc;IACd,sBAAsB;IACtB,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,0DAA2D;IAC3D,sBAAsB;IACtB,iBAAiB;IACjB,4BAA4B;IAC5B,+BAA+B;;AAEnC;AACA;IACI,cAAc;AAClB;AACA;IACI,oCAAoC;GACrC,kBAAkB;GAClB,YAAY;GACZ,WAAW;;AAEd;AACA;IACI,cAAc;AAClB;AACA;;;;;;;;;;IAUI;;CAEH;;IAEG;QACI,uBAAuB;EAC7B;EACA","sourcesContent":[".footerlogo{\n    width: 150px;\n}\n.footerLink{\n    font-size: 16px;\n    margin: 10px 0;\n    /* font-weight: 600; */\n    cursor: pointer;\n}\n.footer{\n    background-color: #00112F; \n    background-image: url('../Images/footer/footerimg\\ 2.png') ;  \n    background-size: cover;\n    position:relative;\n    background-repeat: no-repeat;\n    /* -webkit-filter: blur(8px); */\n\n}\n.footerSubtext{\n    color: #B2C3E1;\n}\n.footer_div{\n    background-color: rgb(0 17 47 / 86%); \n   position: absolute;\n   height: 100%;\n   width: 100%;\n   \n}\n.footerIcon{\n    color: #FF5F2F;\n}\n/* .footerLink::after{\n    content: '';\n    display: block;\n    width: 0;  \n    height: 2px;\n    background:var(--theme1);\n    transition: width 0.3s ease;\n }\n .footerLink:hover::after{\n    width: 100%;  \n } */\n\n @media screen and (max-width:567px) {\n    \n    .footer {\n        background-image: none ; \n  }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
