import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import localImages from "../Constant/localImages";
import "../Assets/Css/myProfile.css";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { useAppDispatch } from "../hooks";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  validateEmailAddress,
  validateFullName,
  validateIsNumberOnly,
} from "../Utils/validators";
import { validateIsNumberOnlyErrMsg } from "../Utils/errormsg";
import {
  getUserData,
  updateMoreValues,
  updateProfile,
  uploadProfileImage,
} from "../Redux/Actions/AuthAction";
import { AuthReducerModal } from "../Modals";
import DeleteAccountPopUp from "../Module/deleteAccountPopUp";
import { deleteAccount } from "../Redux/Actions/AuthAction";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SlotPopUp from "../Module/slotPopUp";
function MyProfile() {
  const {
    ACCESS_TOKEN,
    firstName,
    lastName,
    email,
    mobileNo,
    parentName,
    parentMobile,
    parentEmail,
    alternateNumber,
    dateOfBirth,
    stateName,
    userId,
    profilePicture,
    studentId,
    countryName,
  } = useSelector((state: { AuthReducer: AuthReducerModal }) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
    email: state?.AuthReducer?.email,
    mobileNo: state?.AuthReducer?.mobileNo,
    parentName: state?.AuthReducer?.parentName,
    parentMobile: state?.AuthReducer?.parentNumber,
    parentEmail: state?.AuthReducer?.parentEmail,
    alternateNumber: state?.AuthReducer?.alternateNumber,
    dateOfBirth: state?.AuthReducer?.dateOfBirth,
    stateName: state?.AuthReducer?.stateName,
    userId: state?.AuthReducer?.userId,
    studentId: state?.AuthReducer?.studentId,
    profilePicture: state?.AuthReducer?.profilePicture,
    countryName: state?.AuthReducer?.countryName,
  }));
  console.log("parentName", parentName);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<any>("");
  const [UploadImage, setUploadImage] = useState<any>("");
  const [openPopup, SetopenPopup] = useState(false);
  const [userFirstName, setFirstName] = useState<any>(firstName);
  const [userLastName, setLastName] = useState<any>(lastName);
  const [userMobile, setUserMobile] = useState<any>(mobileNo);
  const [userEmail, setUserEmail] = useState<any>(email);
  const [userDateOfBirth, setUserDateOfBirth] = useState<any>(dateOfBirth);
  const [userParentName, setUserParentName] = useState<any>(parentName);
  const [userParentMobile, setUserParentMobile] = useState<any>(parentMobile);
  const [userParentEmail, setUserParentEmail] = useState<any>(parentEmail);
  const [userAlternativeMobile, setUserAlternativeMobile] =
    useState<any>(alternateNumber);
  const [userState, setUserState] = useState<any>(stateName);
  const [isEdit, setIsEdit] = useState(false);
  const [schedulePopUp, setSchedulePopup] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(
      getUserData(
        studentId,
        (data: any) => {
          setOpen(false);
          setIsEdit(false);
          if (data) {
            const {
              email,
              parentName,
              parentNumber,
              parentEmail,
              altNumber,
              dob,
              state,
              profilepicture,
            } = data?.data[0];
            dispatch(updateMoreValues({ firstName: firstName }));
            dispatch(updateMoreValues({ lastName: lastName }));
            dispatch(updateMoreValues({ email: email }));
            dispatch(updateMoreValues({ parentName: parentName }));
            dispatch(updateMoreValues({ parentNumber: parentNumber }));
            dispatch(updateMoreValues({ parentEmail: parentEmail }));
            dispatch(updateMoreValues({ alternateNumber: altNumber }));
            dispatch(
              updateMoreValues({
                dateOfBirth: moment(dob).format("YYYY-MM-DD"),
              })
            );
            dispatch(updateMoreValues({ stateName: state }));
            dispatch(updateMoreValues({ profilePicture: profilepicture }));
          }
        },
        () => {}
      )
    );
  };

  const twelveYearFromNow = new Date();
  twelveYearFromNow.setFullYear(twelveYearFromNow.getFullYear() - 12);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setImage("");
  };
  const handleChange = (e: any) => {
    const fileSizeKiloBytes = e.target.files[0].size / 1024;
    if (fileSizeKiloBytes > 1024)
      dispatch(showErrorSnackbar("File size should be less than 1 MB!!!"));
    else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadProfileImage(
          file,
          userId,
          (res: any) => {
            delete res?.filePath;
            setUploadImage(res);
          },
          () => {}
        )
      );
    }
  };

  const handleOnChangeEmailText = (prevEmailInputText: any) => {
    setUserEmail(prevEmailInputText.target.value);
  };
  const onChangeParentName = (val: string) => {
    if (validateFullName(val)) {
      setUserParentName(val);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Parent Name"));
    }
  };

  const handleParentNameInputLnameText = (e: any) => {
    if (validateFullName(e)) setUserParentName(e);
    else {
      dispatch(showErrorSnackbar("Please enter valid Parent Name"));
    }
  };

  function changepMob(e: React.ChangeEvent<HTMLInputElement>): void {
    if (!validateIsNumberOnly(e.target.value)) {
      setUserParentMobile(e.target.value);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  }
  function changealtMob(e: React.ChangeEvent<HTMLInputElement>): void {
    if (!validateIsNumberOnly(e.target.value)) {
      setUserAlternativeMobile(e.target.value);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  }
  const onChangeState = (e: any) => {
    if (validateFullName(e)) {
      setUserState(e);
    } else {
      dispatch(showErrorSnackbar("Please enter valid state Name"));
    }
  };
  const opendeleteAccount = () => {
    SetopenPopup(true);
  };
  const handleDeleteProfile = () => {
    SetopenPopup(false);
    SetopenPopup(false);
  };
  const deleteUserAccount = () => {
    dispatch(
      deleteAccount(
        studentId,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          dispatch(updateMoreValues({ ACCESS_TOKEN: "" }));
          navigate("/");
          setTimeout(() => {
            // dispatch(showSuccessSnackbar(res?.msg));
          }, 1000);
        },
        () => {}
      )
    );
  };
  function validations() {
    return (
      userEmail === "" ||
      userParentName === "" ||
      userParentMobile === "" ||
      userParentMobile?.length != 10 ||
      userParentEmail === "" ||
      (userAlternativeMobile?.length > 0 &&
        userAlternativeMobile?.length < 10) ||
      !validateEmailAddress(userEmail) ||
      !validateEmailAddress(userParentEmail) ||
      userDateOfBirth === ""
    );
  }

  const onclickRegister = () => {
    // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    const payload: any = {
      email: userEmail,
      parentEmail: userParentEmail,
      parentName: userParentName,
      parentNumber: userParentMobile,
      altNumber: userAlternativeMobile,
      dob: userDateOfBirth,
      state: userState,
      profilepicture: UploadImage,
    };
    if (userAlternativeMobile !== "" || userAlternativeMobile !== "null") {
      delete payload.altNumber;
    }
    if (userState === "") {
      delete payload.state;
    }
    if (UploadImage === "") {
      delete payload.profilepicture;
    }
    dispatch(
      updateProfile(
        payload,
        studentId,
        (data: any) => {
          dispatch(
            getUserData(
              studentId,
              (data: any) => {
                setOpen(false);
                getData();
                setIsEdit(false);
              },
              () => {}
            )
          );
          setOpen(false);
          setIsEdit(false);
        },
        () => {}
      )
    );
  };
  const handleEdit = () => {
    setIsEdit(true);
  };
  const CancelupdateProfile = () => {
    setIsEdit(false);
  };
  const handleSchedulePopUp = () => {
    setSchedulePopup(true);
  };
  const handleCloseSlotPopUp = () => {
    setSchedulePopup(false);
  };
  return (
    <div className="rightPanel">
      <h4 className="fw-bold mb-5">My Profile</h4>
      {/* <div className="row boxshadow col-md-7 mt-4 bgwhite  ms-1 mt-5">
        <div className="col-sm-12">
          <div className="row mt-1">
            <div className="col d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-outline-danger py-1 border-0 font12 "
                onClick={handleOpen}
              >
                Edit Profile
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="row ms-4">
            <div className="col-sm-3 col-xl-2 d-flex align-items-center ">
              {profilePicture ? (
                <img
                  src={profilePicture}
                  loading="lazy"
                  key={Date.now()}
                  className="profImg "
                  alt="profile"
                />
              ) : (
                <img
                  src={localImages.ulpoad}
                  key={Date.now()}
                  className="profImg "
                  alt="profile"
                />
              )}
            </div>
            <div className="col-sm-9 col-xl-10">
              <p className="mt-2 mb-1">
                {`${firstName} ${lastName}`}
              </p>
              <p className=" mb-1"> {userState ? `${userState}, ` : ""}USA</p>
            </div>
          </div>
          <div className="row m-5 d-flex">
            <div className="col-sm-6 mb-2 ">
              <div>
                <p className="mb-1">
                  {" "}
                  <b>Mobile Number</b>
                </p>
                <p className="mb-1"> {userMobile}</p>
              </div>
            </div>
            <div className="col-sm-6 mb-2">
              <div>
                <p className="mb-1">
                  {" "}
                  <b>Email Address</b>
                </p>
                <p className="mb-1"> {userEmail}</p>
              </div>
            </div>
            <div className="col-sm-6 mb-2 ">
              <div>
                <p className="mb-1">
                  {" "}
                  <b>Parent Name</b>
                </p>
                <p className="mb-1"> {userParentName}</p>
              </div>
            </div>
            <div className="col-sm-6 mb-2 ">
              <div>
                <p className="mb-1">
                  {" "}
                  <b>Parent Number</b>
                </p>
                <p className="mb-1"> {userParentMobile}</p>
              </div>
            </div>
            <div className="col-sm-6 mb-2">
              <div>
                <p className="mb-1">
                  {" "}
                  <b>Parent Email</b>
                </p>
                <p className="mb-1"> {userParentEmail}</p>
              </div>
            </div>
            <div className="col-sm-6 mb-2">
              <div>
                <p className="mb-1">
                  {" "}
                  <b>Alternative Number</b>
                </p>
                <p className="mb-1">
                  {" "}
                  {userAlternativeMobile && userAlternativeMobile !== "null"
                    ? userAlternativeMobile
                    : "---"}
                </p>
              </div>
            </div>
            <div className="col-sm-6 mb-2 ">
              <div className="mt-3">
                <p className="mb-1">
                  {" "}
                  <b>State</b>
                </p>
                <p className="mb-1">{userState ? userState : "--"}</p>
              </div>
            </div>
            <div className="col-sm-6 mb-2">
              <div className="mt-3">
                <p className="mb-1">
                  {" "}
                  <b>Date Of Birth</b>
                </p>
                <p className="mb-1"> {moment(userDateOfBirth).format("YYYY-MM-DD")}</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <button type="button" className="btn btn-primary  my-4 px-4" onClick={handleSchedulePopUp} >Book Slot</button> */}
      <div className="row d-flex">
        <div className="col-md-4 p-1">
          <div className="border border-2 rounded p-3 h-100">
            <div className="col-sm-12 text-center position-relative mt-5">
              <label htmlFor="upload-button">
                {image ? (
                  <div className="d-flex align-items-center cursor position-relative ">
                    {/* <img src={image} key={Date.now()} className="profImg" alt="profile"/> */}
                    <img
                      className="cursor profImg border  rounded-circle"
                      src={image}
                      alt="dummy"
                      width="100"
                    />
                    <img
                      src={localImages.edit}
                      className="cursor"
                      alt="Edit"
                      style={{
                        width: "15px",
                        position: "absolute",
                        right: "2px",
                        bottom: "12px",
                      }}
                    />
                  </div>
                ) : profilePicture ? (
                  <div className="d-flex align-items-center cursor position-relative ">
                    {/* <img src={image} key={Date.now()} className="profImg" alt="profile"/> */}
                    <img
                      className="cursor profImg border  rounded-circle"
                      src={profilePicture}
                      alt="dummy"
                      width="100"
                    />
                    <img
                      src={localImages.edit}
                      className="cursor"
                      alt="Edit"
                      style={{
                        width: "15px",
                        position: "absolute",
                        right: "2px",
                        bottom: "12px",
                      }}
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center cursor position-relative ">
                    <img
                      src={localImages.defaultProfileImg}
                      className="cursor profImg border  rounded-circle"
                      alt="dummy"
                      width="50"
                      height="50"
                    />
                    {/* <p className="text-center mb-0 ms-5">Upload photo</p> */}
                  </div>
                )}
              </label>

              <input
                type="file"
                accept="image/png, image/jpeg"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange}
                disabled={isEdit === false}
              />
              <br />
              <div className="  mt-3 ">
                <p className="mb-1 fs-4">
                  <b>
                    {firstName} {lastName}
                  </b>{" "}
                </p>
                <p className=" mb-1 fs-5">{countryName}</p>
              </div>
              <div className="d-flex justify-content-center align-items-end mt-3">
                <button
                  className="btn btn-danger btn-sm mt-3  "
                  onClick={opendeleteAccount}
                >
                  <DeleteIcon fontSize="small" />
                  Delete Profile
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 p-1">
          <div className="border border-2 rounded p-3 h-100">
            <div className="m-2 d-flex justify-content-end addressdiv">
              {isEdit === true ? (
                <CloseIcon
                  className="colorred cursor"
                  onClick={CancelupdateProfile}
                />
              ) : (
                ""
              )}
            </div>
            <div className="row d-flex justify-content-center p-3 font12">
              <div className="col-sm-6 ps-0 mt-2">
                <label className="mb-2 d-block">First Name</label>
                <div className="d-flex">
                  <input
                    type="text"
                    placeholder="Enter First Name "
                    className="form-control "
                    value={userFirstName}
                    disabled={true}
                  />
                  <span className="text-danger">*</span>
                </div>
              </div>
              <div className="col-sm-6 ps-0 mt-2">
                <label className="mb-2 d-block">Last Name</label>
                <div className="d-flex ">
                  <input
                    type="text"
                    placeholder="Enter Last Name"
                    className="form-control "
                    value={userLastName}
                    disabled={true}
                  />
                  <span className="text-danger">*</span>
                </div>
              </div>
              <div className="col-sm-6 ps-0 mt-2">
                <label className="mb-2 d-block">Mobile Number</label>
                <div className="d-flex ">
                  <input
                    type="text"
                    placeholder="Enter Mobile Number"
                    className=" form-control "
                    value={userMobile}
                    disabled={true}
                  />
                  <span className="text-danger">*</span>
                </div>
              </div>
              <div className="col-sm-6 ps-0 mt-2 position-relative">
                <label className="mb-2 d-block">Email Address</label>
                <div className="d-flex">
                  <input
                    type="email"
                    placeholder="Enter Mail ID"
                    //ref={(ref) => (inputRefs.current[0] = ref)}
                    className="form-control "
                    value={userEmail}
                    // maxLength="40"
                    onChange={(value) => handleOnChangeEmailText(value)}
                    disabled={isEdit === false}
                  />
                  <img
                    src={localImages.edit}
                    className="cursor"
                    // onClick={() => inputRefs?.current[0].focus()}
                    alt="Edit"
                    style={{
                      width: "12px",
                      position: "absolute",
                      right: "20px",
                      bottom: "11px",
                    }}
                  />
                  <span className="text-danger">*</span>
                </div>
              </div>
              <div className="col-sm-6 ps-0 mt-2">
                <label className="mb-2 d-block">Parent Name</label>
                <div className=" d-flex">
                  <input
                    type="text"
                    placeholder="Enter Parent Name"
                    className=" form-control "
                    value={userParentName}
                    onChange={(e: any) => {
                      handleParentNameInputLnameText(e.target.value);
                    }}
                    disabled={isEdit === false}
                  />
                  <span className="text-danger">*</span>
                </div>
              </div>
              <div className="col-sm-6 ps-0 mt-2">
                <label className="mb-2 d-block">Parent Number</label>
                <div className=" d-flex">
                  <input
                    type="text"
                    placeholder="Enter Parent Number"
                    className="form-control "
                    value={userParentMobile}
                    maxLength={10}
                    onChange={changepMob}
                    disabled={isEdit === false}
                  />
                  <span className="text-danger">*</span>
                </div>
              </div>
              <div className="col-sm-6 ps-0 mt-2 position-relative">
                <label className="mb-2 d-block">Parent Email</label>
                <div className=" d-flex">
                  <input
                    type="email"
                    placeholder="Enter Parent Email"
                    //ref={(ref) => (inputRefs.current[0] = ref)}
                    className="form-control "
                    value={userParentEmail}
                    // maxLength="40"
                    onChange={(e) => setUserParentEmail(e.target.value)}
                    disabled={isEdit === false}
                  />
                  <img
                    src={localImages.edit}
                    className="cursor"
                    // onClick={() => inputRefs?.current[0].focus()}
                    alt="Edit"
                    style={{
                      width: "12px",
                      position: "absolute",
                      right: "20px",
                      bottom: "11px",
                    }}
                  />
                  <span className="text-danger">*</span>
                </div>
              </div>
              <div className="col-sm-6 mt-2 ps-0">
                <label className="mb-2 d-block">Date Of Birth</label>
                <div className="d-flex">
                  <input
                    type="date"
                    max={moment(twelveYearFromNow).format("YYYY-MM-DD")}
                    placeholder="dv@gmail.com"
                    className=" form-control "
                    value={userDateOfBirth}
                    onChange={(e) => setUserDateOfBirth(e.target.value)}
                    disabled={isEdit === false}
                  />
                  <span className="text-danger">*</span>
                </div>
              </div>
              <div className="col-sm-6 ps-0 mt-2">
                <label className="mb-2 d-block">Alternative Number</label>
                <input
                  type="text"
                  placeholder="Enter Alternative Number"
                  className="form-control "
                  value={userAlternativeMobile}
                  maxLength={10}
                  onChange={changealtMob}
                  disabled={isEdit === false}
                />
              </div>
              <div className="col-sm-6 ps-0 mt-2">
                <label className="mb-2 d-block">State</label>
                <input
                  type="text"
                  placeholder="Enter State"
                  className="form-control "
                  value={userState}
                  onChange={(e: any) => {
                    onChangeState(e.target.value);
                  }}
                  disabled={isEdit === false}
                />
              </div>
            </div>
            {isEdit === true ? (
              <div className=" d-flex  ">
                <button
                  type="button"
                  className="btn btn-primary my-4 px-4 me-3"
                  onClick={onclickRegister}
                >
                  Save
                </button>
              </div>
            ) : (
              ""
            )}
            {isEdit === false ? (
              <div className=" ">
                <button
                  type="button"
                  className="btn btn-primary  my-4 px-4"
                  onClick={handleEdit}
                >
                  Edit
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldiv editprofEdit">
            <div className="col d-flex mt-0 justify-content-between ">
              <h6 className="colorblue fw-bold">Edit Personal Details</h6>
              <div className="d-flex">
                <ClearOutlinedIcon
                  className="colorblue cursor"
                  onClick={handleClose}
                />
              </div>
            </div>
            <div className="row mb-5 p-3 mt-3 boxshadow ">
              <div className="col-sm-12 text-center position-relative ">
                <label htmlFor="upload-button">
                  {image ? (
                    <div className="d-flex align-items-center cursor position-relative ">
                       <img src={image} key={Date.now()} className="profImg" alt="profile"/> 
                      <img
                        className="cursor"
                        src={image}
                        alt="dummy"
                        width="100"
                      />
                      <img
                        src={localImages.edit}
                        className="cursor"
                        alt="Edit"
                        style={{
                          width: "13px",
                          position: "absolute",
                          right: "0",
                          bottom: "3px",
                        }}
                      />
                    </div>
                  ) : profilePicture ? (
                    <div className="d-flex align-items-center cursor position-relative ">
                  <img src={image} key={Date.now()} className="profImg" alt="profile"/>  
                      <img
                        className="cursor"
                        src={profilePicture}
                        alt="dummy"
                        width="100"
                      />
                      <img
                        src={localImages.edit}
                        className="cursor"
                        alt="Edit"
                        style={{
                          width: "13px",
                          position: "absolute",
                          right: "0",
                          bottom: "3px",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center cursor position-relative ">
                      <img
                        src={localImages.ulpoad}
                        className="cursor"
                        alt="dummy"
                        width="50"
                        height="50"
                      />
                <p className="text-center mb-0 ms-5">Upload photo</p>  
                    </div>
                  )}
                </label>

                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
                <br />
              </div>

              <div className="col-sm-12 p-3 font12">
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">First Name</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      placeholder="Enter First Name "
                      className="border-0 border-bottom w-100"
                      value={userFirstName}
                      disabled={true}
                    />
                    <span className="text-danger">*</span></div>
                </div>
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">Last Name</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      className="border-0 border-bottom w-100"
                      value={userLastName}
                      disabled={true}
                    />
                    <span className="text-danger">*</span></div>
                </div>
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">Mobile Number</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      placeholder="Enter Mobile Number"
                      className="border-0 border-bottom w-100"
                      value={userMobile}
                      disabled={true}
                    />
                    <span className="text-danger">*</span></div>
                </div>
                <div className="col-sm-12 ps-0 mt-2 position-relative">
                  <label className="mb-2 d-block">Email Address</label>
                  <div className="d-flex">
                    <input
                      type="email"
                      placeholder="Enter Mail ID"
                      //ref={(ref) => (inputRefs.current[0] = ref)}
                      className="border-0 border-bottom w-100 "
                      value={userEmail}
                      // maxLength="40"
                      onChange={(value) => handleOnChangeEmailText(value)}
                      disabled={true}
                    />
                    <img
                      src={localImages.edit}
                      className="cursor"
                      // onClick={() => inputRefs?.current[0].focus()}
                      alt="Edit"
                      style={{
                        width: "10px",
                        position: "absolute",
                        right: "17px",
                        bottom: "4px",
                      }}
                    />
                    <span className="text-danger">*</span></div>
                </div>
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">Parent Name</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      placeholder="Enter Parent Name"
                      className="border-0 border-bottom w-100"
                      value={userParentName}
                      onChange={(e: any) => {
                        handleParentNameInputLnameText(e.target.value);
                      }}
                    />
                    <span className="text-danger">*</span></div>
                </div>
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">Parent Number</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      placeholder="Enter Parent Number"
                      className="border-0 border-bottom w-100"
                      value={userParentMobile}
                      maxLength={10}
                      onChange={changepMob}
                    />
                    <span className="text-danger">*</span></div>
                </div>
                <div className="col-sm-12 ps-0 mt-2 position-relative">
                  <label className="mb-2 d-block">Parent Email</label>
                  <div className="d-flex">
                    <input
                      type="email"
                      placeholder="Enter Parent Email"
                      //ref={(ref) => (inputRefs.current[0] = ref)}
                      className="border-0 border-bottom w-100 "
                      value={userParentEmail}
                      // maxLength="40"
                      onChange={(e) => setUserParentEmail(e.target.value)}
                    />
                    <img
                      src={localImages.edit}
                      className="cursor"
                      // onClick={() => inputRefs?.current[0].focus()}
                      alt="Edit"
                      style={{
                        width: "10px",
                        position: "absolute",
                        right: "17px",
                        bottom: "4px",
                      }}
                    />
                    <span className="text-danger">*</span></div>
                </div>
                <div className="col-sm-12 mt-2 ps-0">
                  <label className="mb-2 d-block">Date Of Birth</label>
                  <div className="d-flex">
                    <input
                      type="date"
                      max={moment(twelveYearFromNow).format("YYYY-MM-DD")}
                      placeholder="dv@gmail.com"
                      className="border-0 border-bottom w-100"
                      value={userDateOfBirth}
                      onChange={(e) => setUserDateOfBirth(e.target.value)}
                    />
                    <span className="text-danger">*</span></div>
                </div>
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">Alternative Number</label>
                  <input
                    type="text"
                    placeholder="Enter Alternative Number"
                    className="border-0 border-bottom w-100"
                    value={userAlternativeMobile}
                    maxLength={10}
                    onChange={changealtMob}
                  />
                </div>
                <div className="col-sm-12 ps-0 mt-2">
                  <label className="mb-2 d-block">State</label>
                  <input
                    type="text"
                    placeholder="Enter State"
                    className="border-0 border-bottom w-100"
                    value={userState}
                    onChange={(e: any) => {
                      onChangeState(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-grid mx-auto">
              <button
                className={`w-100 rounded px-4 py-1 font24 border-0 ${validations() ? "btnDisable" : "btnActive"
                  }`}
                disabled={validations()}
                onClick={onclickRegister}
              >
                Save and Update
              </button>
              <button
                className="btn btn-danger btn-sm mt-2 font24"
                onClick={opendeleteAccount}
              >
                {" "}
                Delete Profile
              </button>
            </div>
          </div>
        </Fade>
      </Modal> */}
      {openPopup ? (
        <DeleteAccountPopUp
          openPopup={openPopup}
          handleClose={handleDeleteProfile}
          deleteAccount={deleteUserAccount}
        />
      ) : (
        ""
      )}

      {schedulePopUp ? (
        <SlotPopUp
          openPopup={schedulePopUp}
          handleClose={handleCloseSlotPopUp}
        />
      ) : (
        ""
      )}

      {/* </div> */}
    </div>
  );
}

export default MyProfile;
