import React, { useEffect, useState } from 'react';
import '../App.css';
import '../Assets/Css/policy.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LocalImages from '../Constant/localImages';

function Terms() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
const gotohome=()=>{
  window.location.href = '../'
}
  return (
    <div>
      <header className='privacyheader d-flex align-items-center bgwhite'>
        <div className=' '>
          <img src={LocalImages.logo} alt=" Lurnigo Logo" className='policylogo me-2' onClick={gotohome}/>
        </div>
        <h3 className='text-dark'>Terms & Conditions</h3>
      </header>

      <div className='col-md-12 instcontainer  privacy  '>
        <div className='d-flex justify-content-center'>
        <div className='m-5 p-3 insttxtDiv bgwhite'>
          <p>These terms and conditions ("Terms") apply to your use and access of web sites owned and
operated by LurniGo LLC, DBA LurniGo ("LurniGo" and/or the "Company"), including, but not
limited to our website (together the “LurniGo Sites”). By using a LurniGo Site and/or creating an
account on a LurniGo Site, you accept these Terms. LurniGo may revise these Terms at any time
by updating them as they are displayed on the LurniGo Sites. We are not required to contact
you prior to changing these Terms. You should visit this page periodically to review these
Terms, because they are binding on you. The terms "You" and "User" as used herein refer to all
individuals or entities accessing this LurniGo Site.</p>
           
          <p>PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. THESE TERMS AND
CONDITIONS MAY HAVE CHANGED SINCE YOUR LAST VISIT TO THIS WEBSITE. BY USING THIS
WEBSITE OR OUR SERVICES, YOU INDICATE YOUR ACCEPTANCE OF THESE TERMS AND
CONDITIONS.</p>
          <p className='fw-bold  '>USE OF LURNIGO SITES</p>
          <p className='fw-bold  '>UPERMITTED USE AND USE RESTRICTIONS</p>
          <p>LurniGo authorizes you to view and access LurniGo Sites solely for your personal, non-
commercial use to aid in your college application journey ("Permitted Use"). Your Permitted
Use of the LurniGo Sites is conditioned upon the following restrictions:</p>
<p>You agree that you will not, under any circumstance,</p>
<p>post any information that is abusive, threatening, obscene, defamatory, libelous, or racially,
sexually, religiously, or otherwise objectionable and offensive;</p>
<p>use any LurniGo Site for any purpose that violates any applicable U.S. federal, state or foreign
law;</p>
<p>attempt to, or harass, abuse or harm another person or group;</p>
<p>make any automated use of the website, or take any action that we deem to impose or to
potentially impose an unreasonable or disproportionately large load on our servers or network
infrastructure;</p>
<p>bypass any robot exclusion headers or other measures we take to restrict access to the website
or use any software, technology, or device to scrape, spider, or crawl the website or harvest or
manipulate data;</p>
<p>copy or adapt any of the Web Site Content (defined below) or the underlying code with respect
to any portion of any LurniGo Site or the content appearing therein;</p>
<p>use any LurniGo Site to violate the privacy (e.g., by improperly accessing Personal Information)
or infringe the intellectual property rights of any third party;</p>
<p>use any device, software or routine to interfere or attempt to interfere with the proper working
of any LurniGo Site;</p>
<p>use or attempt to use any engine, software, tool, agent or other device or mechanism
(including, customized browsers, spiders, robots, avatars or intelligent agents) to navigate or
search any LurniGo Site other than the search engine and software available from LurniGo on
such LurniGo Site and other than generally available third party web browsers;</p>
<p>attempt to decipher, decompile, disassemble or reverse engineer any of the software or
algorithm related to any LurniGo Site;</p>
<p>attempt to compromise the security or privacy of another LurniGo user's personal information;</p>
<p>aggregate, copy or duplicate any of the Web Site Content or information available from any
LurniGo Site;</p>
<p>intercept or access data the user is not authorized to access;</p>
<p>attempt to scan the vulnerability of a system or network or to breach security or authentication
measures without LurniGo's prior express written authorization;</p>
<p>create a false identity, misrepresent your identity, impersonate another person, create a profile
for anyone other than yourself (a real person), or use or attempt to use another person’s
account; or</p>
<p>attempt to interfere with service to any user, host or network, including transmitting a virus to
any LurniGo Site or similar malicious activity.</p>
<p className='fw-bold insttext mb-0'>PROPRIETARY RIGHTS</p>
<p>You acknowledge and agree that LurniGo, and our licensors, retain ownership of all intellectual
property rights of any kind related to the LurniGo Sites (other than with respect to User
Content). This shall include applicable copyrights (including text, images, graphics, logos, links,
button icons, software and other content), trademarks and other proprietary rights
(collectively, excluding User Content, "Web Site Content"). Other product, company or
educational institution names that are mentioned on the LurniGo Sites may be trademarks of
their respective owners. Use of any LurniGo trademark or any other trademark listed is
prohibited without express written consent.</p>
<p className='fw-bold insttext mb-0'>ONLINE CONTENT DISCLAIMER</p>
<p>Opinions, advice, statements, offers, or other information or content made available through
the LurniGo Sites, whether orally, graphically, in writing or otherwise, should not necessarily be
relied upon. Authors of such information are solely responsible for such content. The Company
does not guarantee the accuracy, completeness, or usefulness of any information on theLurniGo Sites and neither does the Company adopt nor endorse, nor is the Company
responsible for, the accuracy or reliability of any opinion, advice, statement or information
made or provided by parties other than the Company. The Company takes no responsibility and
assumes no liability for any content on the website. Under no circumstances will the Company
be responsible for any loss or damage resulting from your reliance on information or other
content posted on the website or transmitted to you.</p>
<p className='fw-bold insttext mb-0'>PRIVACY POLICY</p>
<p>LurniGo respects the privacy of website users. Please refer to the Company’s Privacy Policy
(found here) which explains how we collect, use, and disclose information that pertains to your
privacy. When you access or use the LurniGo Sites, you acknowledge your receipt of this Privacy
Policy and understanding of its contents.</p>
<p className='fw-bold insttext mb-0'>REFUND POLICY</p>
<p>If in case at any given point of a time if you are not liking our services, LurniGo offers a pro-rata refund, where the no. of classes taken till that day will be charged and the rest amount will be refunded back to the payment source.</p>
<p className='fw-bold insttext mb-0'>USER CONTENT</p>
<p>Through the use of LurniGo Sites, you may, from time to time, submit, upload, publish,
generate, or otherwise make available to LurniGo, content, reviews, feedback, and other
information (collectively, including Output (as defined below) "User Content"). Any User
Content provided by you remains your property, and any Personal Information that you submit
as part of User Content shall be subject to LurniGo’s Privacy Policy, referenced above.</p>
<p>You represent and warrant that: (i) you either are the sole and exclusive owner of all User
Content or you have all rights, licenses, consents and releases necessary to deliver the User
Content to LurniGo and to grant LurniGo the license to the User Content as set forth below; and
(ii) neither the User Content, nor your submission, uploading, publishing or otherwise making
available of such User Content, nor LurniGo’s use of the User Content as permitted herein will
infringe, misappropriate, or violate a third party's intellectual property or proprietary rights, or
rights of personality, publicity or privacy, or any moral rights, or result in the violation of any
applicable law or regulation.</p>
<p>You agree not to provide User Content that (i) is defamatory, libelous, hateful, violent, obscene,
pornographic, unlawful, deceptive, or otherwise offensive, as determined by LurniGo in its sole
discretion, whether or not such material may be protected by law, (ii) advertises funds, goods
or services or contains viruses or other material that may damages another's computer, (iii)
infringes any patent, trademark, trade secret, copyright, right of privacy or publicity, or other
proprietary rights of any person or entity; or (iv) otherwise violates any of the restrictions set
forth in the section of these Terms titled "Permitted Use and Use Restrictions". LurniGo may, at
its sole discretion, review, monitor, or remove User Content, at any time and for any reason,
without notice to you. However, LurniGo is under no obligation to review, monitor or remove
User Content.</p>
<p>Finally, by providing User Content, you hereby grant LurniGo and its affiliates a perpetual,
royalty-free, irrevocable and unrestricted worldwide right and license to use, reproduce,
modify, adapt, publish, record, translate, create derivative works from and distribute the UserContent or incorporate the User Content into any form, medium, or technology now known or
later developed, for any purposes.</p>
<p className='fw-bold insttext mb-0'>AGE REQUIREMENT</p>
<p>LurniGo Sites and the services offered on them are available to individuals age 13 and over. If
you are between the ages of 13 and the age of majority where you live, you must review these
Terms with your parent or guardian to confirm that you and your parent or guardian
understand and agree to it.</p>
<p className='fw-bold insttext mb-0'>PASSWORD</p>
<p>When you register at certain LurniGo Sites, you may be asked to create a password for future
access to your LurniGo Site account. You must maintain the confidentiality of your password as
you are responsible for all activity that occurs under your account when authenticated with
your password. You agree to notify LurniGo immediately of any unauthorized use of your
account or password, or any other breach of security. You will be able to permit others to
access your account and information, such as a school counselor or advisor, and each person
will set their own password the first time they access your account. Your granting of permission
to these other users is at your own risk and LurniGo is not responsible for any action taken by
those other users. If you lose your password, LurniGo may email your password to the email
address in our records and associated with your account. LurniGo is not liable for any misuse as
a result thereof.</p>
<p className='fw-bold insttext mb-0'>VIOLATION OF THESE TERMS</p>
<p>Any violation of these Terms as determined by LurniGo in its sole discretion may result in the
immediate denial of access to the LurniGo Sites. LurniGo may take these actions at any time, in
our sole discretion, with or without notice, and without any liability to the account holder or
user whose access is blocked or terminated.</p>
<p className='fw-bold insttext mb-0'>INTERACTION WITH OTHER USERS</p>
<p>The LurniGo Sites may contain chat rooms, public posting forums, interactive live events, and
other forums and features permitting direct communications with other users and LurniGo
advisors (each a "Public Access Area" and collectively the "Public Access Areas"). Any
information provided by you in Public Access Areas is public. LurniGo is not responsible for any
information posted and/or viewed in Public Access Areas. You may not post content to Public
Access Areas that violates any applicable law, contains offensive or abusive content, infringes
any third party intellectual property rights, advertises or solicits funds, goods or services,
contains viruses or other material that may damages another's computer, or otherwise violates
any of the restrictions set forth in the section of these Terms titled "Permitted Use and Use
Restrictions".</p>
<p >LurniGo may remove any content from any Public Access Area at any time, without notice. By
posting in Public Access Areas any content authored or owned by you, you automatically waive
all rights to any intellectual property that may be contained in such posting and, if for anyreason that waiver is determined to be invalid, you grant to LurniGo and its affiliates a
perpetual, royalty-free, irrevocable and unrestricted worldwide right and license to use,
reproduce, modify, adapt, publish, translate, create derivative works from and distribute the
posted content or incorporate the posted content into any form, medium, or technology now
known or later developed, for any purposes.</p>
<p>The LurniGo Sites may also contain features permitting you to post content to the Sites for
private review and feedback by other users and by LurniGo personnel (each a "Private Review
Area" and collectively the “Private Review Areas”). LurniGo is not responsible for any
information posted, viewed, reviewed or commented on using the Private Review Areas. You
may not post content to the Private Review Areas that violates any applicable law, contains
offensive or abusive content, infringes any third party intellectual property rights, advertises or
solicits funds, goods or services, contains viruses or other material that may damages another's
computer, or otherwise violates any of the restrictions set forth in the section of these Terms
titled "Permitted Use and Use Restrictions". All content posted to the Private Review Areas
must be your original materials and ideas and must not infringe the intellectual property rights
of others.</p>
<p>You understand that by posting your content to either or both the Public Access Areas and
Private Review Areas, a user could plagiarize your materials. While this is a violation of our
community guidelines and these Terms, LurniGo has no control over such activity and disclaims
any obligation to police, monitor or take any action relating to such plagiarism. Further, while
submission of content to the Public Access Areas and/or Private Review Areas does not require
you to provide any personal information, you acknowledge that your content may include
information that might expose your identity as a result of such content. If you are concerned
about such exposure you should take efforts to modify your content so you are not potentially
subject to such exposure or you should consider whether or not use of the Public Access Areas
and/or Private Review Areas is appropriate for you.</p>
<p>You further acknowledge that additional terms and conditions may apply to the use of the
Public Access Areas and Private Review Areas, including frequency of use of the Public Access
Areas or Private Review Areas and community guidelines relating to plagiarism, appropriate
content, and helpful feedback. We reserve the right to restrict your ability to access the Public
Access Areas and Private Review Areas for any reason, with or without notice, including for
violation of these Terms or the community guidelines.</p>
<p>By using the Public Access Areas and Private Review Areas, you grant to LurniGo the right to use
your content to improve the services and features offered by LurniGo. We may, among other
uses, utilize your content in an aggregated, anonymized manner to implement and develop
features for the site and to develop metrics based on your usage of these features.
</p>
<p>LurniGo disclaims all responsibility for the quality, timeliness, appropriateness and value of any
content, information or review provided to you through the Public Access Areas and PrivateReview Areas and the use of your content by reviewers of content. You agree that you are using
the Public Access Areas and Private Review Areas at your sole risk.</p>
<p className='fw-bold insttext mb-0'> LURNIGO ALUMNI NETWORK</p>
<p>The LurniGo Sites may also contain features that facilitate your connection and communication
with selected educational institutions, and/or representatives from any of these institutions
(“LurniGo Alumni Network”). LurniGo Alumni Network serves as an intermediary in order to
facilitate these connections. When you opt to connect with an educational institution, you
agree to share your profile information with that educational institution.</p>
<p>You agree that you will only submit true, correct, and original profile information. You
acknowledge that, subject to the restrictions set forth herein, it is your decision as to whether
or not to share your profile. You may not post content that violates any applicable law, or
infringes any patent, trademark, trade secret, copyright, right of privacy or publicity, or other
proprietary rights of any person or entity.</p>
<p>LurniGo only serves as the coordination platform to connect you to representatives from other
educational institutions to engage in discussions with admissions officers and other
stakeholders in the admission process. The decisions surrounding your admittance, enrollment,
and financial aid packages, are entirely at the discretion of you. LurniGo provides no guarantee
that you will be accepted, that you will enroll, or that the final tuition bill will be reduced at the
institution at which you choose to matriculate. LurniGo has no liability if your correspondence
with the educational institution is not received or is only partially received. It is your
responsibility to confirm with the educational institution that your correspondence has been
received.</p>
<p>You further acknowledge that additional terms and conditions may apply to the use of LurniGo
Alumni Network, including community guidelines, appropriate behavior, and helpful feedback.
We reserve the right to restrict your ability to access LurniGo Alumni Network for any reason,
with or without notice, including for violation of these Terms or the community guidelines.</p>
<p>By using LurniGo Alumni Network, you grant to LurniGo the right to use your content to
improve the services and features offered by LurniGo. We may, among other uses, utilize your
content in an aggregated, anonymized manner to implement and develop features for the site
and to develop metrics associated with LurniGo Alumni Network.</p>
<p className='fw-bold insttext mb-0'>NO WARRANTIES</p>
<p>LurniGo Sites are a venue for students and their parents to learn about colleges and
universities, the admissions process, test preparation coaching, financial aid alternatives,
research paper guidance and other college admissions topics and for representatives of colleges
and universities to learn about and interact with students. LurniGo does not control, and is not
involved in, admissions or financial aid decisions or negotiations or the admission process
between colleges, universities and students. LurniGo is not responsible for transactions withadvertisers appearing on a LurniGo Site or in newsletters or between students and
representatives from colleges and universities.</p>
<p>The Web Site Content may contain inaccuracies or typographical errors. LurniGo makes no
representations about the accuracy, reliability or completeness, of any LurniGo Site or the Web
Site Content or the timeliness of any services therein provided. The use of the LurniGo Sites and
the Web Site Content is at your own risk. Changes are periodically made to the LurniGo Sites.
While we will endeavor to make these changes at a time least likely to interfere with your use
of any LurniGo Site, such changes may be made at any time.</p>

<p>Certain Web Site Content may contain information about paying for college, financial aid and
related tax or legal consequences. You understand that this content is provided for
informational purposes only and is not a substitute for professional advice from qualified
practitioners. You should always seek professional advice tailored to your individual
circumstances.</p>
<p>Certain portions of the Web Site Content may indicate services which are for directional
purposes only and should not be relied upon in your college search process. Always talk to
experts such as college admissions personnel and high school counselors if you are unsure of
your chances of admission to a particular college or university. You assume all risks regarding
your use and understand that any decision to apply or not to apply to any particular college or
university and the resulting consequences are your sole responsibility.</p>
<p>THE LURNIGO SITES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT
LIMITING THE FOREGOING, LURNIGO EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER
EXPRESS, IMPLIED OR STATUTORY, REGARDING THE LURNIGO SITES INCLUDING WITHOUT
LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
TITLE, SECURITY, ACCURACY AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING,
LURNIGO MAKES NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF
THE LURNIGO SITES WILL BE UNINTERRUPTED OR ERROR FREE. YOU ASSUME FULL
RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR DOWNLOADING AND/OR USE OF
FILES, INFORMATION, CONTENT OR OTHER MATERIAL OBTAINED FROM THE LURNIGO SITES.
SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF WARRANTY, SO THIS
PROVISION MAY NOT APPLY TO YOU.</p>
<p className='fw-bold insttext mb-0'>LIMITATION OF DAMAGES</p>
<p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL LURNIGO, ITS AFFILIATES,
DIRECTORS, OR EMPLOYEES, OR ITS LICENSORS OR PARTNERS, BE LIABLE TO YOU FOR ANY LOSS
USE OR DATA, OR FOR ANY DIRECT, INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR
EXEMPLARY DAMAGES, HOWEVER ARISING, THAT RESULT FROM (A) YOUR USE OR INABILITY
TO USE THE LURNIGO SITES OR THE WEB SITE CONTENT; (B) THE LURNIGO SITES GENERALLY OR
THE SOFTWARE OR SYSTEMS THAT MAKE THE LURNIGO SITES AVAILABLE; OR (C) ANY OTHER
INTERACTIONS WITH LURNIGO OR BETWEEN YOU AND ANY THIRD PARTY THROUGH THELURNIGO SITES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE)
OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT LURNIGO HAS BEEN INFORMED OF THE
POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE
FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT
DISCLAIMERS OF LIABILITY, SO THIS PROVISION MAY NOT APPLY TO YOU</p>
<p className='fw-bold insttext mb-0'> LINKS TO OTHER SITES AND/OR MATERIALS</p>
<p>On the LurniGo Sites, LurniGo may provide you with convenient links to third party website(s)
(“Third Party Sites”) as well as content or items belonging to or originating from third parties
(the “Third Party Applications, Software or Content”). These links are provided as a courtesy to
LurniGo Site users. LurniGo has no control over Third Party Sites and Third Party Applications,
Software or Content or the promotions, materials, information, goods or services available on
these Third Party Sites or Third Party Applications, Software or Content. Such Third Party Sites
and Third Party Applications, Software or Content are not investigated, monitored or checked
for accuracy, appropriateness, or completeness by LurniGo, and LurniGo is not responsible for
any Third Party Sites accessed through the LurniGo Sites or any Third Party Applications,
Software or Content posted on, available through or installed from the Third Party Site,
including the content, accuracy, offensiveness, opinions, reliability, privacy practices or other
policies of or contained in the Third Party Sites or the Third Party Applications, Software or
Content. Inclusion of, linking to or permitting the use or installation of any Third Party Site or
any Third Party Applications, Software or Content does not imply approval or endorsement
thereof by LurniGo. If you decide to leave the LurniGo Sites and access the Third Party Sites or
to use or install any Third Party Applications, Software or Content, you do so at your own risk
and you should be aware that our terms and policies no longer govern. You should review the
applicable terms and policies, including privacy and data gathering practices, of any site to
which you navigate from the LurniGo Sites or relating to any applications you use or install from
the site.</p>
<p className='fw-bold insttext mb-0'>NO RESALE OR UNAUTHORIZED COMMERCIAL USE</p>
<p>You agree not to resell or assign your rights or obligations under these Terms and any
purported assignment is null and void. You also agree not to make any unauthorized
commercial use of any LurniGo Site</p>
<p  className='fw-bold insttext mb-0'>INDEMNITY</p>
<p>You agree to defend, indemnify, and hold harmless LurniGo, its members, officers, directors,
employees and agents, from and against any claims, actions or demands, including reasonable
legal and accounting fees, arising out of (a) the use of any LurniGo Site by you or any account
you created or (b) the User Content. LurniGo will provide notice to you promptly of any such
claim, suit, or proceeding and will assist you, at your expense, in defending any such claim, suit
or proceeding. LurniGo reserves the right to take over the exclusive defense of any claim for
which we are entitled to indemnification under this section. In such event, you must provide
LurniGo with any cooperation LurniGo reasonably requests.</p>
<p className='fw-bold insttext mb-0'>MODIFICATION OF TERMS</p>
<p >These Terms may be amended at any time and from time to time, by a revised posting on this
page and updating the Last Updated date. We do not undertake any obligation to otherwise
notify you of such amendment. If you continue to use the LurniGo Sites, you signify your
agreement to our revisions to these Terms. All provisions of these Terms that by their nature
must survive the termination of your use of a LurniGo Site will survive, including the Limitation
on Damages and Indemnity.</p>
<p className='fw-bold insttext mb-0'>DISPUTE RESOLUTION BY BINDING ARBITRATION</p>
<ol type="a" className="ps-5">
            <li className="  mb-2">
             <span className='fw-bold '>Agreement to Arbitrate.</span>
              This Dispute Resolution by Binding Arbitration section is
referred to in these Terms as the “Arbitration Agreement.” You agree that any and all
disputes or claims that have arisen or may arise between you and Company, whether
arising out of or relating to these Terms (including any alleged breach thereof), any
advertising, or any aspect of the relationship or transactions between us, will be
resolved exclusively through final and binding arbitration, rather than a court, in
accordance with the terms of this Arbitration Agreement, except that you may assert
individual claims in small claims court, if your claims qualify. Further, this Arbitration
Agreement does not preclude you from bringing issues to the attention of federal, state,
or local agencies, and such agencies can, if the law allows, seek relief against us on your
behalf. You agree that, by entering into these Terms, you and Company are each
waiving the right to a trial by jury or to participate in a class action. Your rights will be
determined by a neutral arbitrator, not a judge or jury. The Federal Arbitration Act
governs the interpretation and enforcement of this Arbitration Agreement.
            </li>
            <li className="  mb-2">
             <span className='fw-bold '>Prohibition of Class and Representative Actions and Non-Individualized Relief. </span>
              YOU
AND COMPANY AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER
ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU
AND COMPANY AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN
MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE
OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING.
ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND
ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S
INDIVIDUAL CLAIM(S), EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE
ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE
EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.
            </li>
            <li className="  mb-2">
             <span className='fw-bold '>Pre-Arbitration Dispute Resolution. </span>
              Company is always interested in resolving disputes
amicably and efficiently, and most customer concerns can be resolved quickly and to the
customer’s satisfaction by emailing customer support at [support@lurnigo.com]. If such
efforts prove unsuccessful, a party who intends to seek arbitration must first send to the
other, by certified mail, a written Notice of Dispute (“Notice”). The Notice to Companyshould be sent to [support@Lurnigo.com] (“Notice Address”). The Notice must (i)
describe the nature and basis of the claim or dispute and (ii) set forth the specific relief
sought. If Company and you do not resolve the claim within sixty (60) calendar days
after the Notice is received, you or Company may commence an arbitration proceeding.
During the arbitration, the amount of any settlement offer made by Company or you
will not be disclosed to the arbitrator until after the arbitrator determines the amount, if
any, to which you or Company is entitled.
            </li>
            <li className="  mb-2">
             <span className='fw-bold '>Arbitration Procedures.  </span>
             Arbitration will be conducted by a neutral arbitrator in
accordance with the American Arbitration Association’s (“AAA”) rules and procedures,
including the AAA’s Consumer Arbitration Rules (collectively, the “AAA Rules”), as
modified by this Arbitration Agreement. For information on the AAA, please visit its
website, http://www.adr.org. Information about the AAA Rules and fees for consumer
disputes can be found at the AAA’s consumer arbitration page,
https://www.adr.org/consumer. If there is any inconsistency between any term of the
AAA Rules and any term of this Arbitration Agreement, the applicable terms of this
Arbitration Agreement will control unless the arbitrator determines that the application
of the inconsistent Arbitration Agreement terms would not result in a fundamentally fair
arbitration. The arbitrator must also follow the provisions of these Terms as a court
would. All issues are for the arbitrator to decide, including issues relating to the scope,
enforceability, and arbitrability of this Arbitration Agreement. Although arbitration
proceedings are usually simpler and more streamlined than trials and other judicial
proceedings, the arbitrator can award the same damages and relief on an individual
basis that a court can award to an individual under these Terms and applicable law.
Decisions by the arbitrator are enforceable in court and may be overturned by a court
only for very limited reasons. Unless Company and you agree otherwise, any arbitration
hearings will take place in a reasonably convenient location for both parties with due
consideration of their ability to travel and other pertinent circumstances. If the parties
are unable to agree on a location, the determination will be made by AAA. If your claim
is for $10,000 or less, Company agrees that you may choose whether the arbitration will
be conducted solely on the basis of documents submitted to the arbitrator, through a
telephonic hearing, or by an in-person hearing as established by the AAA Rules. If your
claim exceeds $10,000, the right to a hearing will be determined by the AAA Rules.
Regardless of the manner in which the arbitration is conducted, the arbitrator will issue
a reasoned written decision sufficient to explain the essential findings and conclusions
on which the award is based.
            </li>
            <li className="  mb-2">
             <span className='fw-bold '> Costs of Arbitration.</span>
              Payment of all filing, administration, and arbitrator fees
(collectively, the “Arbitration Fees”) will be governed by the AAA Rules, unless
otherwise provided in this Arbitration Agreement. If the value of the relief sought is
$75,000 or less, at your request, Company will pay all Arbitration Fees. If the value of
relief sought is more than $75,000 and you are able to demonstrate to the arbitrator
that you are economically unable to pay your portion of the Arbitration Fees or if thearbitrator otherwise determines for any reason that you should not be required to pay
your portion of the Arbitration Fees, Company will pay your portion of such fees. In
addition, if you demonstrate to the arbitrator that the costs of arbitration will be
prohibitive as compared to the costs of litigation, Company will pay as much of the
Arbitration Fees as the arbitrator deems necessary to prevent the arbitration from being
cost-prohibitive. Any payment of attorneys’ fees will be governed by the AAA Rules.
            </li>
            <li className="  mb-2">
             <span className='fw-bold '> Confidentiality. </span>
             All aspects of the arbitration proceeding, and any ruling, decision, or
award by the arbitrator, will be strictly confidential for the benefit of all parties.
            </li>
            <li className="  mb-2">
             <span className='fw-bold '> Severability. </span>
             If a court or the arbitrator decides that any term or provision of this
Arbitration Agreement (other than the subsection (b) above titled “Prohibition of Class
and Representative Actions and Non-Individualized Relief” above) is invalid or
unenforceable, the parties agree to replace such term or provision with a term or
provision that is valid and enforceable and that comes closest to expressing the
intention of the invalid or unenforceable term or provision, and this Arbitration
Agreement will be enforceable as so modified. If a court or the arbitrator decides that
any of the provisions of subsection (b) above titled “Prohibition of Class and
Representative Actions and Non-Individualized Relief” are invalid or unenforceable, then
the entirety of this Arbitration Agreement will be null and void, unless such provisions
are deemed to be invalid or unenforceable solely with respect to claims for public
injunctive relief. The remainder of these Terms will continue to apply.
            </li>
            <li className="  mb-2">
             <span className='fw-bold '>   Future Changes to Arbitration Agreement.</span>
            Notwithstanding any provision in these
Terms to the contrary, Company agrees that if it makes any future change to this
Arbitration Agreement (other than a change to the Notice Address) while you are a
LurniGo Site user, you may reject any such change by sending Company written notice
within thirty (30) calendar days of the change to the Notice Address provided above. By
rejecting any future change, you are agreeing that you will arbitrate any dispute
between us in accordance with the language of this Arbitration Agreement as of the
date you first accepted these Terms (or accepted any subsequent changes to these
Terms).
            </li>
            
          </ol>
          <p className='fw-bold insttext mb-0'>GENERAL TERMS</p>
<p>If any part of these Terms is held invalid or unenforceable, that portion of the Terms will be
construed consistent with applicable law. The remaining portions will remain in full force and
effect. Any failure on the part of LurniGo to enforce any provision of these Terms will not be
considered a waiver of our right to enforce such provision. Our rights under these Terms will
survive any termination of these Terms.</p>
<p>You agree that any cause of action related to or arising out of your relationship with LurniGo
must commence within one year after the cause of action accrues. Otherwise, such cause of
action is permanently barred.</p>
<p>These Terms and your use of the LurniGo Sites are governed by the laws, without regard to
conflict of law provisions. With respect to any disputes or claims not subject to arbitration, as
set forth above, you and Company submit to the personal and exclusive jurisdiction of the state
and federal courts located within State of California or any other court of competent jurisdiction.</p>
<p>LurniGo makes no claims that any LurniGo Site or the Web Site Content may be lawfully viewed
or accessed outside of the United States. If you access a LurniGo Site from outside of the United
States, you do so at your own risk and are responsible for compliance with the laws of your
jurisdiction.</p>
<p>LurniGo may assign or delegate these Terms and/or LurniGo’s Privacy Policy, in whole or in part,
to any person or entity at any time with or without your consent. You may not assign or delegate
any rights or obligations under the Terms or Privacy Policy without LurniGo’s prior written
consent, and any unauthorized assignment and delegation by you is void.</p>
<p>YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS, UNDERSTAND THE TERMS, AND
WILL BE BOUND BY THESE TERMS. YOU FURTHER ACKNOWLEDGE THAT THESE TERMS
TOGETHER WITH THE PRIVACY POLICY AT WWW.LURNIGO.COM/LEGAL/PRIVACY REPRESENT
THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT
SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER
COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THESE TERMS.</p>
        </div></div>
      </div>




    </div>
  );
}

export default Terms;
