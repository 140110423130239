import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Screen/home";
import Login from "./Screen/login";
import SuccessSnackbar from "./Component/successSnackbar";
import ErrorSnackbar from "./Component/errorSnackbar";
import FreeUser from "./Screen/freeUser";
import Sat from "./Screen/sat";
import OtpVerification from "./Screen/otpVerification";
import Act from "./Screen/act";
import Psat from "./Screen/psat";
import Ap from "./Screen/ap";
import CreateAccount from "./Screen/createAccount";
import MyCart from "./Screen/myCart";
import CourseDetails from "./Screen/courseDetails";
import PlaceOrder from "./Screen/placeOrder";
import PaymentReqData from "./Screen/paymentReqData";
import WIP from "./Screen/wip";
import PayPal from "./Screen/payPal";
import PaymentSuccess from "./Screen/paymentSuccess";
import PaymentError from "./Screen/paymentError";
import PrivacyPolicy from "./Screen/privacyPolicy";
import Terms from "./Screen/terms";
import Notification from "./Screen/notification";
import KailashChoudhary from "./Screen/kailashChoudhary";
import Loader from "./Component/Loader";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "./Modals";
import VideoPlayer from "./Component/VideoPlayer";
import Test from "./Screen/test";
import ReviewExam from "./Screen/reviewExam";
import DetailResult from "./Screen/detailResult";
import ExamInstruction from "./Screen/examInstruction";
import PdfViewer from "./Component/PdfViewer";
import AdvanceAcademics from "./Screen/advanceAcademics";
import TestPrep from "./Screen/testPrep";
import CollegeConsulting from "./Screen/collegeConsulting";
import CollegePredictor from "./Screen/collegePredictor";
import AlumniNetwork from "./Screen/alumniNetwork";
import TutorNetwork from "./Screen/tutorNetwork";
import Blog from "./Screen/blog";
import BlogDetails from "./Screen/blogDetails";
import Address from "./Screen/address";
import Marketinglandingpage from "./Screen/marketinglandingpage";
import Lsatlandingpage from "./Screen/lsatlandingpage";
import BookSession from "./Screen/bookSession";
import VisitorCountry from "./Module/visitorCountry";
import Parent from "./Screen/parent";
import BasicAcademics from "./Screen/basicAcademics";
import Academics from "./Screen/academics";
import Feedback from "./Screen/feedback";
import LectureDashboard from "./Screen/lectureDashboard";
import LectureIndex from "./Screen/lectureIndex";
import LetureDetails from "./Screen/letureDetails";
import DSAT from "./Screen/digitalSAT";
import DetailsAnalysis from "./Screen/detailAnalysis";
import TopicDashboard from "./Screen/topicDashboard";
import StudentFeedback from "./Screen/studentFeedback";
import Canvas from "./Module/canvas";
import AffilateMarketing from "./Screen/affilateMarketing";
// import WOW from 'wowjs';
import MatchThePair from "./Screen/matchThePair";
import PythonCodeEditor from "./Screen/pythonCodeEditor";
import MatchThePairWithLine from "./Screen/matchThePairWithLine";
import MatchingPairs from "./Screen/MatchingPairs";
import CanvasBox from "./Component/canvasBox";
import { useEffect } from "react";
import CodeEditor from "./Screen/codeEditor";
import JitSiConference from "./Screen/JitSiConference";
import TutorForm from "./Screen/tutorForm";
import OnBoardingForm from "./Screen/onBoardingForm";
import CollegeCounseling from "./Screen/collegeCounseling";
import ThankYou from "./Screen/thankYou";
import Country from "./Screen/Country";
import PageNotFound from "./Screen/pageNotFound";
import CourseTypeSeo from "./Screen/courseTypeSeo";
import ExamTypeSeo from "./Screen/examTypeSeo";
import SubjectSeo from "./Screen/subjectSeo";
function App() {
  const { loader } = useSelector(
    (state: { AuthReducer: AuthReducerModal }) => ({
      loader: state?.AuthReducer?.isLoader,
    })
  );

  // useEffect(() => {
  //   new WOW.WOW({
  //     live: false
  //   }).init();
  // }, []);

  return (
    <div className="App">
      <VisitorCountry />
      <SuccessSnackbar />
      <ErrorSnackbar />
      {loader ? <Loader loader={loader} /> : null}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AdvanceAcademics" element={<AdvanceAcademics />} />
        <Route path="/TestPrep" element={<TestPrep />} />
        <Route path="/CollegeConsulting" element={<CollegeConsulting />} />
        <Route path="/CollegePredictor" element={<CollegePredictor />} />
        <Route path="/AlumniNetwork" element={<AlumniNetwork />} />
        <Route path="/TutorNetwork" element={<TutorNetwork />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/BlogDetails/:id" element={<BlogDetails />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/OTPVerification" element={<OtpVerification />} />
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/BookSession" element={<BookSession />} />
        <Route path="/Feedback" element={<Feedback />} />
        <Route path="/StudentFeedback" element={<StudentFeedback />} />
        <Route path="/ACT/:id" element={<Act />} />
        <Route path="/CollegeCounseling/:id" element={<CollegeCounseling />} />
        {/* <Route path="/SAT" element={<Sat />} />
       
        <Route path="/PSAT" element={<Psat />} />
        <Route path="/AP" element={<Ap />} /> */}
        {/* <Route path="/TestPreparation" element={<TestPrepCoaching />} />
         */}
        <Route path="/User/*" element={<FreeUser />} />
        <Route path="/Parent/*" element={<Parent />} />
        <Route path="/MyCart" element={<MyCart />} />
        <Route path="/Address" element={<Address />} />
        <Route
          path="/CourseDetails/:packageId/:levelId"
          element={<CourseDetails />}
        />
        <Route path="/PlaceOrder" element={<PlaceOrder />} />
        <Route path="paymentReqData" element={<PaymentReqData />} />
        <Route path="/WorkInProgress" element={<WIP />} />
        <Route path="/PayPal" element={<PayPal />} />
        <Route path="/VideoPlayer" element={<VideoPlayer />} />
        <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
        <Route path="/PaymentError" element={<PaymentError />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Terms" element={<Terms />} />
        {/* <Route path="/Notification" element={<Notification />} />
        <Route path="/Notification" element={<Notification />} /> */}
        <Route path="DrKailashChoudhary" element={<KailashChoudhary />} />
        <Route path="ExamInstruction" element={<ExamInstruction />} />
        <Route path="Test" element={<Test />} />
        <Route path="ReviewExam/*" element={<ReviewExam />} />
        <Route path="DetailResult/*" element={<DetailResult />} />
        <Route path="PdfViewer/*" element={<PdfViewer />} />
        <Route path="/LSAT/:id" element={<Lsatlandingpage />} />
        <Route path="/learn/:id" element={<Marketinglandingpage />} />
        <Route path="/BasicAcademics" element={<BasicAcademics />} />
        <Route path="/Academics" element={<Academics />} />
        <Route path="/LectureDashboard" element={<LectureDashboard />} />
        <Route path="/LectureIndex" element={<LectureIndex />} />
        <Route path="/LetureDetails" element={<LetureDetails />} />
        <Route path="/Module" element={<TopicDashboard />} />
        <Route path="/DSAT/:id" element={<DSAT />} />
        <Route path="/detailsAnalysis" element={<DetailsAnalysis />} />
        <Route path="/Canvas" element={<Canvas />} />
        <Route path="/CodeEditor" element={<CodeEditor />} />
        <Route path="/PythonCodeEditor" element={<PythonCodeEditor />} />
        <Route path="/marketing/:id" element={<AffilateMarketing />} />
        <Route path="/MatchThePair" element={<MatchThePair />} />
        <Route
          path="/MatchThePairWithLine"
          element={<MatchThePairWithLine />}
        />
        <Route path="/MatchingPairs" element={<MatchingPairs />} />
        <Route path="/direct/:id" element={<AffilateMarketing />} />
        <Route path="/JitSiConference" element={<JitSiConference />} />
        <Route path="/tutorForm" element={<TutorForm />} />
        <Route path="/onBoardingForm" element={<OnBoardingForm />} />
        <Route path="/:seoSlug" element={<Country />} />
        <Route path="/:seoSlug/:courseSeoSlug/" element={<CourseTypeSeo />} />
        <Route
          path="/:seoSlug/:courseSeoSlug/:examSeoSlug"
          element={<ExamTypeSeo />}
        />
        <Route
          path="/:seoSlug/:courseSeoSlug/:examSeoSlug/:subjectSeoSlug"
          element={<SubjectSeo />}
        />
        <Route path="/thankYou" element={<ThankYou />} />
        <Route path="/PageNotFound" element={<PageNotFound />} />
        {/* <Route path="/canvasBox" element={<CanvasBox />} /> */}
        <Route path="*" element={<WIP />} />
      </Routes>
    </div>
  );
}

export default App;
