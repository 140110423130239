import React, { useEffect, useState } from 'react';
import '../Assets/Css/bannerVideo.css';
function BannerVideo(props:any) { 
   
  return (
    <div className="position-relative">
      <video muted autoPlay loop className="videotag m-auto">
        <source src={props?.src} type="video/mp4" />
      </video>
      <div className='row align-items-baseline bottomStripleft'>
        <div className='col-6 videoOrangeStrip'></div>
        <div className='col-6 videoBlueStrip'></div>
      </div>
    </div>
  );
}

export default BannerVideo;
