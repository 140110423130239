import "../App.css";
import localImages from "../Constant/localImages";
import { liveCourses } from "../Utils/dataConstants";
import CourseCard from "../Module/courseCard";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  getExamDescription,
  getPackageList,
} from "../Redux/Actions/examActions";
import { useSelector } from "react-redux";
import "../Assets/Css/dashboard.css";
import MenuButton from "../Component/menuButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { getCountry, getCourseExam, getCourseTypelist, getGeneralExamList, getSubjectList, gradeLevelList, updateMoreValues } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import Dropdown from "../Component/dropdown";
import DropdownNew from "../Component/dropdownNew";
import SubscriptionPlan from "../Module/subscriptionPlan";

function FreeDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [CourseExam, setCourseExam] = useState<any>();
  const [gradeList, setGradeList] = useState<any>([]);
  const [packageData, setpackageData] = useState("");
  
  const { courseType,examTypeId,userCountryCallingCode } = useSelector(
    (state: any) => ({
      courseType: state?.AuthReducer?.courseType,
      examTypeId: state?.AuthReducer?.examTypeId,
      userCountryCallingCode:state?.AuthReducer?.userCountryCallingCode
    })
  );


  const [examList, setExamList] = useState([]);
  const [exam, setExam] = useState<any>(""); 
  const [grade, setGrade] = useState<any>("");
  const [course, setCourse] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState("");
  // useEffect(() => {
  //   const filterCourse=CourseExam?.filter((item:any)=>item?._id===parseInt(courseType?._id));
  //   if(filterCourse?.length>0){
  //     setCourse(filterCourse[0])
  //   }

  // }, [courseType,CourseExam]);
  useEffect(() => {
    dispatch(gradeLevelList(
      (data: any) => {
        setGradeList(data)
      },
      () => { })
    );

  }, []);

  useEffect(() => {
    dispatch(getCountry(
      (data: any) => {
        const filterCountry = data?.filter((item: any) => item?.phonecode == userCountryCallingCode?.substring(1))

        const postData = {
          countryId: filterCountry[0]?._id,
        };

        dispatch(getCourseTypelist(
          postData,
          (res: any) => {
            setCourseExam(res?.data)
            setCourse(res?.data[0])
          },
          () => { }))
      },
      () => { })
    );

  }, [userCountryCallingCode, dispatch]);


  useEffect(() => {
    setSubjectList([])
    if(course.type===3){
      const postData={
        coursetypeId:course?._id,
    }
      dispatch(getSubjectList(
        postData,
          (res: any) => {
              setSubjectList(res?.data)
          },
          () => { })
      )
    }
    else if(course?.type===1){
      dispatch(
        getGeneralExamList(
          {
            courseId: course._id,
          },
          (res: any) => {
            setExamList(res?.data);
            setExam(res?.data[0]?._id)
          },
          () => {}
        )
      );
    }
    else{
      setGrade(gradeList[0]?._id)
    } 
  }, [course]);

  const getCourseType=(val:any)=>{
    const filterCourse=CourseExam?.filter((item:any)=>item?._id===parseInt(val));
    setCourse(filterCourse[0])
     
  }
  const getGrade=(val:any)=>{
    setGrade(val)
    setSubjectList([])
  }
  
 console.log('grade', grade);

 useEffect(() => {
  const postData:any={
    coursetypeId:course?._id,
}

if(course?.type===2){
  postData["gradeId"]=grade;
}
if(course?.type===1){
  postData["examtypeId"]=exam;
}
  dispatch(getSubjectList(
    postData,
      (res: any) => {
        if(res?.data?.length>0){
          setSubjectList(res?.data)
         setSubject(res?.data[0]?._id)
        }
      },
      () => { })
  )  
}, [grade,exam]);

const getExam=(val:any)=>{
  setSubjectList([])
  setExam(val)
}

const onChangeSubject=(val:any)=>{
  setpackageData("")
  setSubject(val)  
}

useEffect(() => {
  const postData:any={
    coursetypeId:course?._id,
    subjectId:subject,
}
if(course?.type===2){
  postData["gradeId"]=grade;
}
if(course?.type===1){
  postData["examtypeId"]=exam;
}
dispatch(
  getPackageList(
    postData,
    (res: any) => {
      if(res?.data.length>0){
      setpackageData(res?.data[0]);
      }
    },
    () => {}
  )
) 
}, [subject]);
  return (
    <div className="rightPanel">
      <div className="customcontainer">
      <div className="p-4 pt-0 shadow-sm ">
                <h2 className="fw-bold my-2 text-center">Our pricing & subscription plans</h2>
                <div className="text-center mx-auto subscriptionSubHeading mt-3">Elevate your learning experience with Lurnigo's Advanced subjects with challenging engaging content designed to stimulate critical thinking and foster a love for learning.</div>

          <div className="d-flex row justify-content-center mt-4">
          <div className="col-md-3">
            <DropdownNew
             placeholder="Select Course Type"
              options={CourseExam}
              value={course?._id}
              setInputText={(value: any) => getCourseType(value)}
            />
          </div>
           {course?.type===2? <div className="col-md-3 mb-2 mb-md-0">
              <DropdownNew
                placeholder="Select Grade"
                options={gradeList}
                value={grade}
                setInputText={(value: any) => getGrade(value)}
              />
            </div>:null}
            {course?.type===1?<div className="col-md-3 mb-2 mb-md-0">
              <DropdownNew
                placeholder="Select Exam"
                options={examList}
                value={exam}
                setInputText={(value: any) => getExam(value)}
              />
            </div>:null}
            <div className="col-md-3 mb-2 mb-md-0">
              <DropdownNew
                placeholder="Select Subject"
                options={subjectList}
                value={subject}
                setInputText={(value: any) => onChangeSubject(value)}
              />
            </div>

          </div>
                <div className="featureDiv border rounded p-4 mt-5">
                    <SubscriptionPlan priceData={packageData} />
                </div>
            </div>
      </div>
    </div>
  );
}

export default FreeDashboard;
