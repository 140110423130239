import endpoint from "../../Constant/endpoint";
import services from "../../Constant/service";
import { SET, RESET_STORE } from "../../Types/authTypes";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbarAction";

export const setInitialState = () => async (dispatch: any) => {
  dispatch({
    type: RESET_STORE,
  });
};

export const updateMoreValues = (values: any) => (dispatch: any) => {
  dispatch({
    type: SET,
    payload: values,
  });
};

export const setParentCourse = (key: any, value: any) => (dispatch: any) => {
  dispatch({
    type: SET,
    payload: {
      [key]: value,
    },
  });
};
export const addBookSession =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.academic.addBookSession,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          // if (res?.data) {
          //   callback(res);
          // } 
          if (res?.statusCode === services?.status_code?.success) {
            // dispatch(showSuccessSnackbar(res?.msg));
            callback(res);
          }
          else ErrorCallback(res);

        },
        (error: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const gradeLevelList =
  (callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.getApiCall(
        endpoint.academic.gradeLevelList,
        '',
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res.status === services.status_code.success)
            callback(res?.data?.data);
          else {
            ErrorCallback();
          }
        },
        (error: Error) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const findAboutUsList =
  (callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.getApiCall(
        endpoint.academic.findAboutUsList,
        '',
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res.status === services.status_code.success)
            callback(res?.data?.data);
          else {
            ErrorCallback();
          }
        },
        (error: Error) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const registerUser =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.academic.registerUser,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res?.statusCode === services?.status_code?.success) {
            dispatch(showSuccessSnackbar(res?.msg));
            callback(res);
          }
          else ErrorCallback(res);

        },
        (error: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const getRolesList =
  (callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.getApiCall(
        endpoint.academic.getRolesList,
        '',
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res.status === services.status_code.success)
            callback(res?.data?.data);
          else {
            ErrorCallback();
          }
        },
        (error: Error) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const getExamList = (callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.exam.getExamList,
      '',
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success)
          callback(res?.data?.data);
        else {
          ErrorCallback();
        }
      },
      (error: Error) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };

  export const getTimezoneList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.auth.getTimezoneList,
      params,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      }, (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error)
      }
    )
  };

export const getCounsellingExamList =
  (callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.getApiCall(
        endpoint.academic.getCounsellingExamList,
        '',
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res.status === services.status_code.success)
            callback(res?.data?.data);
          else {
            ErrorCallback();
          }
        },
        (error: Error) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const sendOtp =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.auth.sendOtp,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res?.data) {
            callback(res);
          } else ErrorCallback(res);

        },
        (error: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const verifyOtp =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.auth.verifyOtp,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res?.data) {
            callback(res);
          } else ErrorCallback(res);

        },
        (error: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const register =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.auth.register,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res?.data) {
            callback(res);
          } else ErrorCallback(res);

        },
        (error: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const logoutUser =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.getApiCall(
        endpoint.auth.logout,
        '',
        (res: any) => {
          dispatch(setInitialState())
          dispatch(updateMoreValues({ isLoader: false }));
          dispatch(updateMoreValues({ ACCESS_TOKEN: '' }));
          setTimeout(() => {
            // dispatch(showSuccessSnackbar(res?.msg));
            window.location.href = '/';
          }, 1000);

        },
        (error: any) => {
          dispatch(updateMoreValues({ ACCESS_TOKEN: '' }));
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };
export const getCourseExam =
  (callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.getApiCall(
        endpoint.exam.getCourseExam,
        '',
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res.status === services.status_code.success)
            callback(res?.data?.data);
          else {
            ErrorCallback();
          }
        },
        (error: Error) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const updateProfile = (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.patchApiCall(
      endpoint.auth.updateProfile + id,
      params,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) { 
          callback(res?.data); 
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getUserData =
  (id: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.getApiCall(
        endpoint.auth.getUserData + id,
        '',
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res.status === services.status_code.success)
            callback(res?.data);
          else {
            ErrorCallback();
          }
        },
        (error: Error) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

export const getOrderHistory =
  (id: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.getApiCall(
        endpoint.order.getOrderHistory + id,
        '',
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res.status === services.status_code.success)
            callback(res?.data);
          else {
            ErrorCallback();
          }
        },
        (error: Error) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

// export const getOrderDetails =
//   (id: any, callback: Function, ErrorCallback: Function) =>
//     async (dispatch: any, getState: any) => {
//       dispatch(updateMoreValues({ isLoader: true }));
//       services.getApiCall(
//         endpoint.order.getOrderDetails + id,
//         '',
//         (res: any) => {
//           dispatch(updateMoreValues({ isLoader: false }));
//           if (res.status === services.status_code.success)
//             callback(res?.data);
//           else {
//             ErrorCallback();
//           }
//         },
//         (error: Error) => {
//           dispatch(updateMoreValues({ isLoader: false }));
//           ErrorCallback(error);
//         }
//       );
//     };

    export const getOrderDetails =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.order.getOrderDetails,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res?.data) {
            callback(res);
          } else ErrorCallback(res);

        },
        (error: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };


export const uploadProfileImage = (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    let formData = new FormData();
    formData.append("file", params);
    services.postUploadApiCall(
      endpoint.auth.uploadProfileImage,
      formData,
      (res: { statusCode: number; data: any; msg: string; }) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };

export const getUserPackageList = (param: any, callback: Function, ErrorCallback: Function) => async (dispatch: any, getState: any) => {
  dispatch(updateMoreValues({ isLoader: true }));
  services.getApiCall(
    endpoint.auth.getUserPackageList,
    param,
    (res: any) => {
      dispatch(updateMoreValues({ isLoader: false }));
      if (res.status === services.status_code.success)
        callback(res?.data?.data);
      else {
        ErrorCallback();
      }
    },
    (error: Error) => {
      dispatch(updateMoreValues({ isLoader: false }));
      ErrorCallback(error);
    }
  );
};

export const getRcordedVideos = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.postApiCall(
      endpoint.recordedVideo.getRcordedLecture,
      params,
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data) {
          callback(res);
        } else errorCallback(res);

      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };
  export const deleteAccount = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.deleteApiCall(
      endpoint.auth.deleteAccount + params,
      {},
      (res: { data: { statusCode: number; msg: any; }; }) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        errorCallback(error);
      }
    );
  };
  export const getBlog = (callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.blog.getBlog,
      '',
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success)
          callback(res?.data?.data);
        else {
          ErrorCallback();
        }
      },
      (error: Error) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };
  
  export const getCountry = (callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.auth.getCountry,
      '',
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success)
          callback(res?.data?.data);
        else {
          ErrorCallback();
        }
      },
      (error: Error) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };
  
  export const getBlogDetails = (id:any,callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.blog.getBlog+"/"+id,
      '',
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success)
          callback(res?.data?.data);
        else {
          ErrorCallback();
        }
      },
      (error: Error) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };
  
  export const gettimezones = (id:any,callback: Function, ErrorCallback: Function) =>
  async (dispatch: any, getState: any) => {
    dispatch(updateMoreValues({ isLoader: true }));
    services.getApiCall(
      endpoint.auth.gettimezones+"/"+id,
      '',
      (res: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        if (res.status === services.status_code.success)
          callback(res?.data?.data);
        else {
          ErrorCallback();
        }
      },
      (error: Error) => {
        dispatch(updateMoreValues({ isLoader: false }));
        ErrorCallback(error);
      }
    );
  };

  export const addTalkToAdvisor =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.collegeCosulting.addTalkToAdvisor,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          // if (res?.data) {
          //   callback(res);
          // } 
          if (res?.statusCode === services?.status_code?.success) {
            dispatch(showSuccessSnackbar(res?.msg));
            callback(res);
          }
          else ErrorCallback(res);

        },
        (error: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

    export const addJoinLurnigo =
    (data: any, callback: Function, ErrorCallback: Function) =>
      async (dispatch: any, getState: any) => {
        dispatch(updateMoreValues({ isLoader: true }));
        services.postApiCall(
          endpoint.collegeCosulting.addJoinLurnigo,
          data,
          (res: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            // if (res?.data) {
            //   callback(res);
            // } 
            if (res?.statusCode === services?.status_code?.success) {
              dispatch(showSuccessSnackbar(res?.msg));
              callback(res);
            }
            else ErrorCallback(res);
  
          },
          (error: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            ErrorCallback(error);
          }
        );
      };

      export const getupcomingLect =
      (data: any, callback: Function, ErrorCallback: Function) =>
        async (dispatch: any, getState: any) => {
          dispatch(updateMoreValues({ isLoader: true }));
          services.postApiCall(
            endpoint.live.getupcomingLect,
            data,
            (res: any) => {
              dispatch(updateMoreValues({ isLoader: false })); 
              if (res?.statusCode === services?.status_code?.success) {
                // dispatch(showSuccessSnackbar(res?.msg));
                callback(res);
              }
              else ErrorCallback(res);
    
            },
            (error: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              ErrorCallback(error);
            }
          );
        };


    export const getSchoolList =
    (data: any, callback: Function, ErrorCallback: Function) =>
      async (dispatch: any, getState: any) => {
        dispatch(updateMoreValues({ isLoader: true }));
        services.postApiCall(
          endpoint.landingPage.getSchoolList,
          data,
          (res: any) => {
            dispatch(updateMoreValues({ isLoader: false })); 
            if (res?.statusCode === services?.status_code?.success) {
              dispatch(showSuccessSnackbar(res?.msg));
              callback(res);
            }
            else ErrorCallback(res);
  
          },
          (error: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            ErrorCallback(error);
          }
        );
      };

    export const addLsatLandingData =
    (data: any, callback: Function, ErrorCallback: Function) =>
      async (dispatch: any, getState: any) => {
        dispatch(updateMoreValues({ isLoader: true }));
        services.postApiCall(
          endpoint.landingPage.addLsatLandingData,
          data,
          (res: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            if (res?.statusCode === services?.status_code?.success) {
              dispatch(showSuccessSnackbar(res?.msg));
              callback(res);
            }
            else ErrorCallback(res);
  
          },
          (error: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            ErrorCallback(error);
          }
        );
      };

      export const addMarketingLandingData =
      (data: any, callback: Function, ErrorCallback: Function) =>
        async (dispatch: any, getState: any) => {
          dispatch(updateMoreValues({ isLoader: true }));
          services.postApiCall(
            endpoint.landingPage.addMarketingLandingData,
            data,
            (res: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              if (res?.statusCode === services?.status_code?.success) {
                dispatch(showSuccessSnackbar(res?.msg));
                callback(res);
              }
              else ErrorCallback(res);
    
            },
            (error: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              ErrorCallback(error);
            }
          );
        };

        export const addbookSession =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.auth.addbookSession,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res?.data) {
            callback(res);
          } else ErrorCallback(res);

        },
        (error: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };

      export const getSubjectList =
  (data: any, callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.postApiCall(
        endpoint.auth.getSubjectList,
        data,
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res?.data) {
            callback(res);
          } else ErrorCallback(res);

        },
        (error: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };


      export const getTeacher =
      (data: any, callback: Function, ErrorCallback: Function) =>
        async (dispatch: any, getState: any) => {
          dispatch(updateMoreValues({ isLoader: true }));
          services.postApiCall(
            endpoint.auth.getTeacher,
            data,
            (res: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              if (res?.data) {
                callback(res?.data);
              } else ErrorCallback(res);
    
            },
            (error: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              ErrorCallback(error);
            }
          );
        };
      export const scheduleClass =
      (data: any, callback: Function, ErrorCallback: Function) =>
        async (dispatch: any, getState: any) => {
          dispatch(updateMoreValues({ isLoader: true }));
          services.postApiCall(
            endpoint.package.scheduleClass,
            data,
            (res: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              if (res?.data) {
                callback(res);
              } else ErrorCallback(res);
    
            },
            (error: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              ErrorCallback(error);
            }
          );
        };

      
        export const getCourseTypelist =
      (data: any, callback: Function, ErrorCallback: Function) =>
        async (dispatch: any, getState: any) => {
          dispatch(updateMoreValues({ isLoader: true }));
          services.postApiCall(
            endpoint.exam.getCourseTypelist,
            data,
            (res: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              if (res?.data) {
                callback(res);
              } else ErrorCallback(res);
    
            },
            (error: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              ErrorCallback(error);
            }
          );
        };        

        export const getGeneralExamList =
        (data: any, callback: Function, ErrorCallback: Function) =>
          async (dispatch: any, getState: any) => {
            dispatch(updateMoreValues({ isLoader: true }));
            services.postApiCall(
              endpoint.exam.getGeneralExamList,
              data,
              (res: any) => {
                dispatch(updateMoreValues({ isLoader: false }));
                if (res?.data) {
                  callback(res);
                } else ErrorCallback(res);
      
              },
              (error: any) => {
                dispatch(updateMoreValues({ isLoader: false }));
                ErrorCallback(error);
              }
            );
          };

          export const getLectureAmount =
        (data: any, callback: Function, ErrorCallback: Function) =>
          async (dispatch: any, getState: any) => {
            dispatch(updateMoreValues({ isLoader: true }));
            services.postApiCall(
              endpoint.live.getLectureAmount,
              data,
              (res: any) => {
                dispatch(updateMoreValues({ isLoader: false }));
                if (res?.data) {
                  callback(res);
                } else ErrorCallback(res);
      
              },
              (error: any) => {
                dispatch(updateMoreValues({ isLoader: false }));
                ErrorCallback(error);
              }
            );
          };


          export const reScheduleClass =
          (data: any, callback: Function, ErrorCallback: Function) =>
            async (dispatch: any, getState: any) => {
              dispatch(updateMoreValues({ isLoader: true }));
              services.postApiCall(
                endpoint.package.reScheduleClass,
                data,
                (res: any) => {
                  dispatch(updateMoreValues({ isLoader: false }));
                  if (res?.data) {
                    callback(res);
                    dispatch(showSuccessSnackbar(res?.msg));
                  } else ErrorCallback(res);
        
                },
                (error: any) => {
                  dispatch(showErrorSnackbar(error?.data?.msg));
                  dispatch(updateMoreValues({ isLoader: false }));
                  ErrorCallback(error);
                }
              );
            };

            export const addDSATlandingpage =
            (data: any, callback: Function, ErrorCallback: Function) =>
              async (dispatch: any, getState: any) => {
                dispatch(updateMoreValues({ isLoader: true }));
                services.postApiCall(
                  endpoint.landingPage.addDSATlandingpage,
                  data,
                  (res: any) => {
                    dispatch(updateMoreValues({ isLoader: false }));
                    if (res?.statusCode === services?.status_code?.success) {
                      // dispatch(showSuccessSnackbar(res?.msg));
                      callback(res);
                    }
                    else ErrorCallback(res);
          
                  },
                  (error: any) => {
                    dispatch(updateMoreValues({ isLoader: false }));
                    ErrorCallback(error);
                  }
                );
              };


              export const getInstallmentData =
  (callback: Function, ErrorCallback: Function) =>
    async (dispatch: any, getState: any) => {
      dispatch(updateMoreValues({ isLoader: true }));
      services.getApiCall(
        endpoint.payment.getInstallmentData,
        '',
        (res: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
          if (res.status === services.status_code.success)
            callback(res?.data);
          else {
            ErrorCallback();
          }
        },
        (error: Error) => {
          dispatch(updateMoreValues({ isLoader: false }));
          ErrorCallback(error);
        }
      );
    };
    
    export const addAffilatemarketingPage =
    (data: any, callback: Function, ErrorCallback: Function) =>
      async (dispatch: any, getState: any) => {
        dispatch(updateMoreValues({ isLoader: true }));
        services.postApiCall(
          endpoint.landingPage.addAffilatemarketingPage,
          data,
          (res: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            if (res?.statusCode === services?.status_code?.success) {
              dispatch(showSuccessSnackbar(res?.msg));
              callback(res);
            }
            else ErrorCallback(res);
  
          },
          (error: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            ErrorCallback(error);
          }
        );
      };

      
      export const getLectureList =
    (data: any, callback: Function, ErrorCallback: Function) =>
      async (dispatch: any, getState: any) => {
        dispatch(updateMoreValues({ isLoader: true }));
        services.postApiCall(
          endpoint.customisePackage.getLectureList,
          data,
          (res: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            if (res?.statusCode === services?.status_code?.success) {
              callback(res);
            }
            else ErrorCallback(res);  
          },
          (error: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            ErrorCallback(error);
          }
        );
      };
      
      export const getCustomizePackageAmount =
      (data: any, callback: Function, ErrorCallback: Function) =>
        async (dispatch: any, getState: any) => {
          dispatch(updateMoreValues({ isLoader: true }));
          services.postApiCall(
            endpoint.customisePackage.getCustomizePackageAmount,
            data,
            (res: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              if (res?.statusCode === services?.status_code?.success) {
                callback(res);
              }
              else ErrorCallback(res);  
            },
            (error: any) => {
              dispatch(updateMoreValues({ isLoader: false }));
              ErrorCallback(error);
            }
          );
        };

        

        export const saveCustomizePackage =
        (data: any, callback: Function, ErrorCallback: Function) =>
          async (dispatch: any, getState: any) => {
            dispatch(updateMoreValues({ isLoader: true }));
            services.postApiCall(
              endpoint.customisePackage.saveCustomizePackage,
              data,
              (res: any) => {
                dispatch(updateMoreValues({ isLoader: false }));
                if (res?.statusCode === services?.status_code?.success) {
                  callback(res);
                }
                else ErrorCallback(res);  
              },
              (error: any) => {
                dispatch(updateMoreValues({ isLoader: false }));
                ErrorCallback(error);
              }
            );
          };
          

          export const verifyPassword =
          (data: any, callback: Function, ErrorCallback: Function) =>
            async (dispatch: any, getState: any) => {
              dispatch(updateMoreValues({ isLoader: true }));
              services.postApiCall(
                endpoint.auth.verifyPassword,
                data,
                (res: any) => {
                  dispatch(updateMoreValues({ isLoader: false }));
                  if (res?.statusCode === services?.status_code?.success) {
                    callback(res);
                  }
                  else ErrorCallback(res);  
                },
                (error: any) => {
                  dispatch(updateMoreValues({ isLoader: false }));
                  ErrorCallback(error);
                }
              );
            };

            export const uploadMedia = (params: any , callback: Function, errorCallback: Function): any =>
              async (dispatch: Function, getState: Function) => {
                dispatch(updateMoreValues({ isLoader: true }));
                let formData = new FormData();
                formData.append("file", params);
                services.postUploadApiCall(
                  endpoint.Tutor.uploadMedia,
                  formData,
                  (res: { statusCode: number; data: any; msg: string; }) => {
                    dispatch(updateMoreValues({ isLoader: false }));
                    if (res?.statusCode === services?.status_code?.success) {
                      // dispatch(showSuccessSnackbar(res?.msg));
                      callback(res.data);
                    } else {
                      errorCallback(res);
                      dispatch(showErrorSnackbar(res?.msg));
                    }
                  },
                  (error: any) => {
                    dispatch(updateMoreValues({ isLoader: false }));
                    errorCallback(error);
                  }
                );
              };
      
              export const addTutor =
              (data: any, callback: Function, ErrorCallback: Function) =>
                async (dispatch: any, getState: any) => {
                  dispatch(updateMoreValues({ isLoader: true }));
                  services.postApiCall(
                    endpoint.Tutor.addTutor,
                    data,
                    (res: any) => {
                      dispatch(updateMoreValues({ isLoader: false }));
                      if (res?.statusCode === services?.status_code?.success) {
                        dispatch(showSuccessSnackbar(res?.msg));
                        callback(res);
                      }
                      else ErrorCallback(res);
            
                    },
                    (error: any) => {
                      dispatch(updateMoreValues({ isLoader: false }));
                      ErrorCallback(error);
                    }
                  );
                };

    export const getTutorDetails = (id:any,callback: Function, ErrorCallback: Function) =>
      async (dispatch: any, getState: any) => {
        dispatch(updateMoreValues({ isLoader: true }));
        services.getApiCall(
          endpoint.Tutor.getTutorDetails +id,
          '',
          (res: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            if (res.status === services.status_code.success)
              callback(res?.data?.data);
            else {
              ErrorCallback();
            }
          },
          (error: Error) => {
            dispatch(updateMoreValues({ isLoader: false }));
            ErrorCallback(error);
          }
        );
      };