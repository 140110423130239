import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAppDispatch } from '../hooks';
import { updateMoreValues } from '../Redux/Actions/AuthAction';

const VisitorCountry = () => {
  const dispatch = useAppDispatch();
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get user's IP address using a third-party service
        const response = await axios.get('https://api64.ipify.org?format=json');
        const ipAddress = response.data.ip;

        // Use IP address to get geolocation information
        const geoResponse = await axios.get(`https://ipapi.co/${ipAddress}/json/`);
        // console.log("geoResponse",geoResponse)
        setCountry(geoResponse.data.country_name);
        setCountryCode(geoResponse.data.country_code);
        dispatch(updateMoreValues({ userCountryCallingCode: geoResponse.data.country_calling_code }));
        dispatch(updateMoreValues({ userCountryCurrency: geoResponse.data.currency }));
        dispatch(updateMoreValues({ userCountryCode: geoResponse.data.country_code }));
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run effect only once

  return (
    <div>
      {/* {country && <p>Country: {country}</p>}
      {countryCode && <p>Country Code: {countryCode}</p>} */}
    </div>
  );
};

export default VisitorCountry;
