import { useEffect, useState } from "react";
import { useAppDispatch } from "../hooks";
import { useSelector } from "react-redux";
import {
  getCountry,
  getInstallmentData,
} from "../Redux/Actions/AuthAction";
import Lottie from "react-lottie";
import emptyorder from '../Assets/Images/json/order history.json';
import { useNavigate } from "react-router-dom";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import moment from "moment";
import { AuthReducerModal } from "../Modals"; 
import { paymentRequest } from "../Redux/Actions/examActions";
function Installment() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [installment, setinstallment] = useState([]);
  const [country, setCountry] = useState("");
//   useEffect(()=>{
//     const res:any=[
//       {
//           "orderId": 386,
//           "orderedPackages": [
//               {
//                   "_id": 145,
//                   "name": "4TH SCIENCE - ONE ON ONE"
//               },
//               {
//                   "_id": 144,
//                   "name": "SAT MATH - ONE ON ONE"
//               }
//           ],
//           "remainingAmount": "18000",
//           "installmentId": 17,
//           "installmentAmount": "9000",
//           "emiStartDate": "2024-08-08",
//           "emiEndDate": "2024-08-09",
//           "installment": "secondInstallment",
//           "orderDate": "2024-08-08T07:14:43.126Z"
//       },
//       {
//           "orderId": 391,
//           "orderedPackages": [
//               {
//                   "_id": 146,
//                   "name": "DIGITAL SAT ENGLISH - ONE ON ONE"
//               },
//               {
//                   "_id": 150,
//                   "name": "DIGITAL SAT PHYSICS - ONE ON ONE"
//               }
//           ],
//           "remainingAmount": "9250",
//           "installmentId": 20,
//           "installmentAmount": "9250",
//           "emiStartDate": "2024-08-16",
//           "emiEndDate": "2024-08-20",
//           "installment": "secondInstallment",
//           "orderDate": "2024-08-12T00:35:00.527Z"
//       },
//       {
//           "orderId": 384,
//           "orderedPackages": [
//               {
//                   "_id": 143,
//                   "name": "SAT ENGLISH - ONE ON ONE"
//               }
//           ],
//           "remainingAmount": "5000",
//           "installmentId": 11,
//           "installmentAmount": "2500",
//           "emiStartDate": null,
//           "emiEndDate": null,
//           "installment": "secondInstallment",
//           "orderDate": "2024-08-07T03:03:13.540Z"
//       }
//   ]
// setinstallment(res)
//   },[])

  const { userCountryCurrency, userCountryCallingCode } = useSelector((state: any) => ({
    userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
    userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode
  }));
  useEffect(() => {
    dispatch(getCountry(
      (data: any) => {
        const filterCountry = data?.filter((item: any) => item?.phonecode == userCountryCallingCode?.substring(1))
        setCountry(filterCountry[0]?._id)
      },
      () => { })
    );

  }, [userCountryCallingCode]);
  console.log('installment', installment);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyorder,
    renderer: 'svg'
  };
  const {
    studentId
  } = useSelector((state: { AuthReducer: AuthReducerModal }) => ({
    studentId: state?.AuthReducer?.studentId,

  }));

  useEffect(() => {
    dispatch(
      getInstallmentData(
        (data: any) => {
          setinstallment(data?.data)
        },
        () => { }
      )
    );
  }, []);

  const routeOrderHistory = (data: any) => {
    
    const dataRequest: any = {
      orderNo: data?.orderNo,
      studentId: data?.studentId,
      currency: "USD",
      countryId: country,
      installment:data?.installment
    }

    dispatch(
      paymentRequest(
        dataRequest,
        (data: any) => {
          window.location.href = data?.data?.redirect_url
        },
        () => { }
      )
    );
  };
  
  function goToLink(val:string): void {
    navigate('/'+val);
  }
  return (
    <div className="rightPanel">
      <h4 className="fw-bold mb-5">Installment</h4>
      <div className="row">
        {installment?.length !== 0 ?
          installment?.map((c: any, index: any) => {
           
            return (
              <div key={index.toString()} className="col-md-5 ">
                {
                  <div
                    className="card boxshadow p-3 mt-2 me-md-3 cursor border-0 mb-2"
                    >
<div className="fw-bold font16 mb-2">{c?.installment==="secondInstallment"?"Second Installment":c?.installment==="thirdInstallment"?"Third Installment":null}</div>
                    <div className="d-flex">
                     
                        <span className="successcolor">
                          <TaskAltIcon />
                        </span>
            
                      <div className="ms-2 ellipsis" style={{ lineHeight: 1.2 }}>
                        {c?.orderedPackages?.map((item: any, index: number) => (
                          <span key={index.toString()} className="">
                            {/* <b className="font12">{item.courseId.name}</b> */}
                            <span className=''>{index > 0 ? '\u002C ' : ''}{item?.name}</span>
                          </span>
                        ))}
                      </div>
                    </div>                    
                    <p className="font12 mb-0 mt-3 text-secondary">
                    Purchase Date {moment(c?.orderDate).format('DD MMM YYYY')} 
                    </p>
                    <p className="font12 mb-0 text-secondary">Order No: {c?.orderId}</p>
                    <p className="font12 mb-0 text-secondary">Installment Amount:  {c?.installmentAmount}</p>
                    <p className="font12 mb-0 text-secondary">Due date :{c?.emiEndDate  ?<> {moment(c?.emiEndDate).format('DD MMM YYYY')} </> :"-"}</p>
                    <p className="font12 mb-0 text-secondary">Reaming Amount :{c?.remainingAmount}</p>
                    <div className="d-flex flex-column align-items-end">
                    
                      <button
                          type="button"
                          onClick={() => {
                            routeOrderHistory(c);
                          }}
                          className="btn btn-primary font12">
                          Pay Installment
                        </button>
                    </div>
                  </div>
                }
              </div>
            )
          })
          :
          <div className="col-md-10 justify-content-center bgwhite boxshadow py-5 ">
            <Lottie options={defaultOptions} height={130} width={200}></Lottie>
            <p className="text-center">
              <b>No orders Yet</b>
            </p>
            <div className='d-flex justify-content-center'>
              <button
                type="button"
                className="btn btn-primary py-2 px-4 font12 me-md-1"
                onClick={()=>goToLink('User/Course')}
              >
                Browse Courses
              </button>
            </div>
          </div>}
      </div>


    </div>
  );
}

export default Installment;
