import { useEffect, useState } from "react";
import "../App.css";
import "../Assets/Css/placeorder.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown from "../Component/dropdown";
import Lottie from "react-lottie";
import live from "../Assets/Images/json/livesession.json";
import localImages from "../Constant/localImages";
import { useAppDispatch } from "../hooks";
import BbMeetingPopup from "../Module/bbMeetingPopup";
import moment from "moment";
import { getLiveLecture } from "../Redux/Actions/examActions";
import { AuthReducerModal } from "../Modals";

import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { getAllExamList } from "../Redux/Actions/examActions";
function Learn() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [examList, setExamList] = useState([]);
  const [examval, setExamval] = useState("");
  const [openVideo, setopenVideo] = useState(false);
  const [deafultexamid, setdeafultexamid] = useState("");
  const [scheduleData, setScheduleData] = useState<any>([]);
  const [date, setDate] = useState(new Date());
  const [bblink, setbblink] = useState<any>("");
  const [counsellingExamList, setCounsellingExamList] = useState([]);
  const [counsellingExamval, setCounsellingExamval] = useState("");
  const [CourseExam, setCourseExam] = useState<any>([ ]);
  const [open, setOpen] = useState(false);
  const { ACCESS_TOKEN, examTypeId, studentId } = useSelector(
    (state: { AuthReducer: AuthReducerModal }) => ({
      ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
      examTypeId: state?.AuthReducer?.examTypeId,
      userId: state?.AuthReducer?.userId,
      studentId: state?.AuthReducer?.studentId,
    })
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: live,
    renderer: "svg",
  };
  useEffect(() => {
    // dispatch(
    //   getUserPackageList(
    //     studentId,
    //     (data: any) => {
    //       setExamList(data);
    //     },
    //     () => { }
    //   )
    // );
    dispatch(
      getAllExamList(
        (data: any) => {
          setCourseExam(data);
          // dispatch(updateMoreValues({ courseTypeId: data[0]?._id }));
          setdeafultexamid(data[0]?._id);
        },
        () => { }
      )
    );

    const requestData = {
      date: moment(date).format("YYYY-MM-DD"),
    };
    dispatch(
      getLiveLecture(
        requestData,
        (res: any) => {
          const liveIndex = res?.data?.findIndex((item: any) => item?.status === 2)
          const upcomingIndex = res?.data?.findIndex((item: any) => item?.status === 1)
          if (liveIndex != -1) {
            setScheduleData(res?.data[liveIndex]);
          }
          else if (upcomingIndex != -1) { setScheduleData(res?.data[upcomingIndex]); }
          else {
            setScheduleData(res?.data);
          }
        },
        () => { }
      )
    );
  }, []);
  // useEffect(() => {
  //   setExamval(examTypeId)
  // }, [examTypeId]);

  useEffect(() => {
    setExamval(examTypeId);
    setdeafultexamid(examTypeId);
  }, [examList]);

  const getExamVal = (val: any) => {
    setExamval(val);
  };
  const getCounsellingExamval = (val: any) => {
    setCounsellingExamval(val);
  };
  const LectureScheduleRoute = () => {
    navigate("/User/LectureSchedule", { state: { packageID: '' } });

  };

  const gotoRecorded = () => {

    navigate("/User/DemoLecture", { state: { examID: counsellingExamval } });
  };

  const openBbmeeitng = (link: any) => {
    setopenVideo(true);
    setbblink(link);
  };
  const handleBbbVideoClose = () => {
    setopenVideo(false);
  };
  const gotoOnlineTest = () => {
    navigate("/User/ScholershipTest", { state: { examID: counsellingExamval } });
  }
  return (
    <div className="rightPanel">
      <div className="">
        <div className="d-flex">
          <h3 className="fw-bold boldfont me-5">Trial Session</h3>
          <div className="dropdowncolor">
            {/* <Dropdown
              placeholder="Select Exam"
              options={examList}
              value={examval}
              defaultValue={deafultexamid}
              setInputText={(value: any) => getExamVal(value)}
            /> */}
            <Dropdown
              // placeholder="Select Exam"
              options={CourseExam}
              value={counsellingExamval}
               defaultValue={deafultexamid}
              setInputText={(value: any) => getCounsellingExamval(value)}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="row d-flex justify-content-between width75">
            <div className="col-sm-6 d-flex mb-4">
              {scheduleData?.status === 2 ? (
                <div
                  className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100"
                  onClick={() =>
                    openBbmeeitng(scheduleData?.attendeeUrl)
                  }
                >
                  <div className="col-9">
                    <p className="fw-bold mb-2">Live Session</p>
                    <p className="mb-0 colorblue font12">Join Session Now</p>
                  </div>
                  <div className="col-3">
                    <Lottie options={defaultOptions} height={60} width={60} />
                  </div>
                </div>
              ) :
                scheduleData?.status === 1 ? (
                  <div
                    className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100"
                    onClick={() =>
                      setOpen(true)
                    }
                  >
                    <div className="col-9">
                      <p className="fw-bold mb-2">Live Session</p>
                      <p className="mb-0 colorblue font12"> {moment.utc(scheduleData?.sessionTime, 'hh:mm:ss').format('LT') <
                        moment(new Date()).format('LT')
                        ? `Live session starting soon`
                        : `Live Start at ${moment
                          .utc(scheduleData?.sessionTime, 'hh:mm:ss')
                          .format('LT')}`}</p>
                    </div>
                    <div className="col-3">
                      <Lottie options={defaultOptions} height={60} width={60} />
                    </div>
                  </div>
                ) : (
                  <div
                    className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100"
                    onClick={LectureScheduleRoute}
                  >
                    <div className="col-9">
                      <p className="fw-bold mb-2">Live Session</p>
                      <p className="mb-0 colorblue font12">
                        no live session available
                      </p>
                    </div>

                    <div className="col-3">
                      <Lottie options={defaultOptions} height={60} width={60} />
                    </div>
                  </div>
                )}
            </div>
            <div className="col-sm-6 d-flex mb-4">
              <div
                className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100"
                onClick={gotoRecorded}
              >
                <div className="col-9">
                  <p className="fw-bold mb-2">Demo Lecture</p>
                </div>
                <div className="col-3">
                  <img src={localImages.allabout} alt="allAbout" />
                </div>
              </div>
            </div>
            <div className="col-sm-6 d-flex mb-4">
              <div className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100" onClick={gotoOnlineTest}>
                <div className="col-9 d-flex align-item-center">
                  <p className="fw-bold mb-2">Online Test</p>
                </div>
                <div className="col-3">
                  <img src={localImages.onlineTest} className="img-fluid w-50" alt="allAbout" />
                </div>
              </div>
            </div>
            {/* <div className="col-sm-6 d-flex mb-4">
              <div className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100">
                <div className="col-9">
                  <p className="fw-bold mb-2">All About SAT</p>
                </div>
                <div className="col-3">
                  <img src={localImages.allabout} alt="allAbout" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <BbMeetingPopup
        open={openVideo}
        handleClose={handleBbbVideoClose}
        link={bblink}
      />
      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className="modeldiv p-4 py-5">
            <div className="m-2  bgwhite">
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon className="colorblue cursor" onClick={() => setOpen(false)} />
              </div>
              <div className="col justify-content-center  py-5 ">
                <Lottie options={defaultOptions} height={100} width={100}></Lottie>
                <p className="text-center">
                  <b>Please wait for a while , lecture will start soon</b>
                </p>
              </div>
            </div>
          </div>

        </Fade>
      </Modal>
    </div>
  );
}

export default Learn;
