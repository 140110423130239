import "../App.css";
import "../Assets/Css/lecture.css"
import { useLocation, useNavigate } from "react-router-dom";
import TopicTile from "../Component/topicTile";
import LocalImages from "../Constant/localImages";
import { _retrieveData, timeConvert } from "../Utils/utils";
import { useState } from "react";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useSelector } from "react-redux";
import { getExam, notSyncedDataAction } from '../Redux/Actions/examActions';
import { useDispatch } from "react-redux";

function Topics(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [openInnerAccordionId, setOpenInnerAccordionId] = useState(null);
  const { Data } = location.state;
  let selectedQuestionIndex = 0;
  const {
    notSyncedDataFromStore,
    examIdDataFromStoreObject,
  } = useSelector((state) => ({
    notSyncedDataFromStore: state?.ExamSelectReducer?.notSyncedData,
    examIdDataFromStoreObject: state?.ExamSelectReducer?.examIdData
  }));

const gotovideoplayer = (val) =>{
  navigate("/VideoPlayer", {
    state: { lectureId: val},
  });
}

const handleAccordionClick = (accordionId) => {
  setOpenAccordionId(accordionId === openAccordionId ? null : accordionId);
};

const handleInnerAccordionClick = (accordionId) => {
  setOpenInnerAccordionId(
    accordionId === openInnerAccordionId ? null : accordionId
  );
};
const onStartExam = (examId) => {
  const examIdDataFromStore = examIdDataFromStoreObject[examId];
  const args = {
    examId,
    dispatch,
    getExam,
    notSyncedDataFromStore,
    selectedQuestionIndex,
    notSyncedDataAction,
    examIdDataFromStore,
  };
  _retrieveData(args);
}
  return (
    <div className="rightPanel">
      <div className="d-flex">
        <h3 className="fw-bold me-5">{Data?.name}</h3>
      </div>
      <div className="row mt-3">
        <div className="col-12">          
            <div
              className="accordion w-100 row mx-0 LectureAccordion"
              id={"courseAccordion"}
            >
              {Data?.Topics?.map((item, topicindex) => {
                return (
                  <div className="col-sm-10 px-0">
                    <div
                      className="accordion-item rounded-top"
                      key={topicindex}
                    >
                      <h2
                        className="accordion-header"
                        id={"heading" + item?._id}
                      >
                        <button
                          className={`accordion-button ${openAccordionId === item._id ? "" : "collapsed"
                            }`}
                          type="button"
                          onClick={() => handleAccordionClick(item._id)}
                          aria-expanded={openAccordionId === item._id}
                          aria-controls={"faqcollapse" + item?._id}
                        >
                          <div>
                            <div className="fontsize20 fw-bold">
                              {item?.name}
                            </div>
                            <div className="d-flex mt-2">
                              <div className="me-4 d-flex">
                                <div className="font12 mt-2">
                                  {' '}
                                  <img src={LocalImages.videoicon} style={{ width: '15px' }} alt='Video' />
                                  <span className="ms-2">{item?.totalCount}  Video |   {timeConvert(item?.totalDuration)}{ }
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={"faqcollapse" + item?._id}
                        className={`accordion-collapse collapse${openAccordionId === item._id ? " show" : ""
                          }`}
                        aria-labelledby={"heading" + item?._id}
                        data-bs-parent={"#courseAccordion"}
                      >
                        <div className="accordion-body">
                          <div
                            className="accordion w-100 row mx-0"
                            id={"ModuleAccordion" + item._id}
                          >
                            {item?.SubTopics?.map((subtopicdata, subTopicindex) => {
                              if(subtopicdata?.liveRecordedData){
                              return (
                                <div className="col-sm-12 px-0">
                                  <div
                                    className="accordion-item "
                                    key={subTopicindex}
                                  >
                                    <h2
                                      className="accordion-header"
                                      id={"heading" + subtopicdata?._id}
                                    >
                                      <button
                                        className={`accordion-button ${openInnerAccordionId === subtopicdata._id
                                            ? ""
                                            : "collapsed"
                                          }`}
                                        type="button"
                                        onClick={() =>
                                          handleInnerAccordionClick(
                                            subtopicdata._id
                                          )
                                        }
                                        aria-expanded={
                                          openInnerAccordionId === subtopicdata._id
                                        }
                                        aria-controls={
                                          "faqcollapse" + subtopicdata?._id
                                        }
                                      >
                                        <div className="me-4 d-flex">
                                          <DescriptionOutlinedIcon className="me-2" />
                                          <span> {subtopicdata?.name}</span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id={"faqcollapse" + subtopicdata?._id}
                                      className={`accordion-collapse collapse${openInnerAccordionId === subtopicdata._id
                                          ? " show"
                                          : ""
                                        }`}
                                      aria-labelledby={
                                        "heading" + subtopicdata?._id
                                      }
                                      data-bs-parent={
                                        "#ModuleAccordion" + item._id
                                      }
                                    >
                                      <div className="accordion-body">
                                        
                                          {subtopicdata?.liveRecordedData?.map((data, i) => {
                                            return <div
                                            // key={e?.lectureId}
                                            className="boxshadow rounded p-3 px-4 mb-3 cursor d-flex justify-content-between align-items-center row mx-0"                                            
                                           >
                                           <div className="colorblue col-md-7">{data?.name}</div>
                                              <div className="d-flex  col-md-5 justify-content-end">
                                                <button className={` rounded me-2 ${data?.lectureLink?'orangeBtn':'btn btn-secondary font12'}`} disabled={data?.lectureLink === "" ? true : false} onClick={() => gotovideoplayer(data)}>Play</button>
                                                {data?.lectureLink?
                                                <>
                                                 {data?.dppStatus === 2 ?
                                                  <button className="orangeBtn rounded" disabled={data?.dppId ? false : true} onClick={() => onStartExam(data?.dppId)}>DPP Analysis</button>
                                                  :
                                                  <button className={` rounded ${data?.dppId?'orangeBtn':'btn btn-secondary font12'}`} disabled={data?.dppId ? false : true} onClick={() => onStartExam(data?.dppId)}>DPP</button>
                                                }

                                                <button className={`orangeBtn rounded ms-2`} disabled={data?.lectureLink === "" ? true : false}>Feedback</button>
                                                </>
                                                :
                                                null
                                          }
                                               

                                              </div>
                                           </div>;
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>      
        </div>
      </div>
    </div>
  );
}

export default Topics;
