import { useEffect, useState } from "react";
// import beep from '../Assets/Audio/beep.mp3';
import localImages from "../Constant/localImages";
import useSound from "use-sound";
import BlinkText from "./blinkText";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import localImagesNew from "../Constant/localImagesNew";

interface props {
  onTimerFinished: any;
  countdown: any;
  counttime: any;
  isNewTimer: boolean;
  pauseTimer: boolean;
}

function TimerCountDown({
  onTimerFinished,
  countdown = 30,
  counttime,
  isNewTimer,
  pauseTimer,
}: props) {
  const [timerClock, setTimerClock] = useState(countdown);
  const [cnt, setcnt] = useState(counttime);
  const [isResendReady, setIsResendReady] = useState(false);
  const timer = timerClock < 10 ? `0${timerClock}` : timerClock;
  const [playbackRate, setPlaybackRate] = useState(1);
  const [play, { stop, duration }] = useSound(localImages?.beep, {
    volume: 1,
    playbackRate,
    interrupt: true,
  });
  const playSoundOnTime = [10 * 60, 5 * 60, 5 * 60 - 1];
  console.log("pauseTimer", pauseTimer);
  useEffect(() => {
    if (isNewTimer) {
      setTimerClock(countdown);
    }
  }, [isNewTimer]);

  const returnTimerValue = (time: number) => {
    const sec = Math.floor(time % 60);
    const min = Math.floor((time / 60) % 60);
    const hours = Math.floor(time / 60 / 60);
    const strSec = sec < 10 ? `0${sec}` : `${sec}`;
    const strMin = min < 10 ? `0${min}` : `${min}`;
    const strHour = hours < 10 ? `0${hours}` : `${hours}`;
    const finalTimeStr = `${strHour}:${strMin}:${strSec}`;

    if (time === 0) {
      onTimerFinished();
    }
    if (time >= 0 && time <= 10) {
      return <BlinkText key={finalTimeStr} text={finalTimeStr} fontSize={14} />;
    }
    return finalTimeStr;
  };

  useEffect(() => {
    let clockIntervalId: string | number | NodeJS.Timeout;
    if (!isResendReady && timerClock > 0 && !pauseTimer) {
      if (playSoundOnTime.includes(timerClock)) {
        play();
      }
      if (timerClock >= 0 && timerClock <= 10) {
        setPlaybackRate((prevState) => prevState + 0.1);
        play();
      }
      clockIntervalId = setInterval(() => {
        setTimerClock((prevValue: number) => prevValue - 1);
        setcnt((prevValue: number) => prevValue + 1);
      }, 1000);
    } else {
      onTimerFinished();
    }
    return () => clearInterval(clockIntervalId);
  }, [timerClock, isResendReady, pauseTimer]);

  return (
    <div className="d-flex align-items-center">
      {" "}
      <img
        src={localImagesNew?.timerIconOrange}
        alt="calculator"
        className="me-2"
        style={{ width: "25px" }}
      />{" "}
      {returnTimerValue(timer)}{" "}
    </div>
  );
}

export default TimerCountDown;
