import { Rating } from "@mui/material";
import localImages from "../Constant/localImagesNew";
import StarRateIcon from '@mui/icons-material/StarRate';
interface props {
  Data: {
    title?: string;
    desc?: string;
    videoPath?: string;
    rating?: number;
    img?: string;
    name?: string;
    date?: string;
    link?: string;
  }[];
  id: string;
}
export default function StudentSayCarousel({ Data, id }: props) {
  return (
    <div id={id} className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        {/* <div className="carouselvideoContainer border">
          <video muted autoPlay controls className="studentVideo m-auto">
            <source
              src={localImages.ParentCollabTestemonial}
              type="video/mp4"
            />
          </video>
        </div> */}
        {Data?.map((item, index) => (
          <div className={`carousel-item ${index === 0 ? "active" : ""}`}>
            {/* <div className='carouselvideoContainer border'>
          <video muted autoPlay controls className="studentVideo m-auto">
            <source src={localImages.ParentCollabTestemonial} type="video/mp4" />
          </video>
        </div> */}
            <div className="studentSayContentBox p-5 pt-0">
              <div className="satudentSayinnercontainer p-4 ">
                {item?.rating ? (
                  <Rating
                    className="ratingStar"
                    value={item?.rating}
                    disabled={true}
                  />
                ) : null}
                <h3>{item?.title}</h3>
                <p>{item?.desc}</p>
                <div className="col-md-12 d-flex justify-content-center">
                  {/* <div className='col-md-1'>
                <img src={item?.img} className='img-fluid'/>
              </div> */}
                  <div>
                    
                    {/* <a className="starPilotBox rounded-pill d-flex align-items-center justify-content-center mb-2"
                      href={item?.link}
                      target="_blank"
                      rel="noreferrer">
                      <StarRateIcon style={{ color: "#04da8d", fontSize: "18px" }} className="me-1" />
                      Trustpilot
                    </a> */}
                    <h6>{item?.name}</h6>
                    <h6>{item.date}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div className='  navigationIndicator'> */}

      <div className="d-flex">
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={"#" + id}
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={"#" + id}
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="carousel-indicators ">
        {Data?.map((item, index) => (
          <button
            key={index}
            type="button"
            data-bs-target={"#" + id}
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current={index === 0 ? "true" : undefined}
            aria-label={"Slide " + (index + 1)}
          ></button>
        ))}
      </div>
      {/* </div> */}
    </div>
  );
}
