import React from 'react';
import { useDrag } from 'react-dnd';
import parser from "html-react-parser";
const RightItem = ({items, item, disabledQues,index }) => {
  console.log('RightItem1232', item);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'ITEM',
    item: { id: item.id,description:item?.description,descType:item?.descType },
    canDrag: () => !disabledQues,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (

    <div className={`d-flex align-items-center `}>
      <div className="me-2">{index + 1}.</div>
      <div ref={drag} className={`right-item w-100 `} style={{ opacity: isDragging ? 0.5 : 1 }}>
        {item?.descType === "text" ?
          <div>{parser(item?.description)}</div> : 
          <div className=""><img src={item?.description} alt="dndImg" className="dndImg"/></div>
        }
      </div>
    </div>
  );
};

const DragndropRight = ({ items,disabledQues,index }) => {
  console.log('rightItem', items);
  return (
    <div className="right-side">
      {/* {items.map((item:any, index:number) => ( */}
      {items?<RightItem items={items}  item={items} disabledQues={disabledQues} index={index}/>:null}
        
      {/* ))} */}
    </div>
  );
};

export default DragndropRight;
