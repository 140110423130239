// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogBannerContainer{
    padding: 8em 5em 7em;
    background: #FAFAFA;
    margin-top: 3em;
}
.ccRightArrow{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 75px;
    margin: auto;
    width: 23%;
}
.ccLeftArrow{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 16%;
}
/* .getDreamleft{
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 280px;
}
.getDreamright{
    position: absolute;
    bottom: -100px;
    right: 0;
    width: 290px;
} */

/********************Blog Detail**************************/
.blogImg{
    aspect-ratio: 3/2;
    width: 100%;
    object-fit: contain;
}
.blogContent{
        font-size: 1.2em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        height: 100px;
}

@media screen  and (max-width: 767px){
    .ccRightArrow{
        display: none;
    }
    .ccLeftArrow{
        display: none;
    }
    .collegeConsultingContainer{
        padding: 10em 2em 2em;
    }
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/blog.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,YAAY;IACZ,YAAY;IACZ,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,SAAS;IACT,YAAY;IACZ,UAAU;AACd;AACA;;;;;;;;;;;GAWG;;AAEH,0DAA0D;AAC1D;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;AACA;QACQ,gBAAgB;QAChB,gBAAgB;QAChB,oBAAoB;QACpB,qBAAqB;QACrB,4BAA4B;QAC5B,aAAa;AACrB;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;IACA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".blogBannerContainer{\n    padding: 8em 5em 7em;\n    background: #FAFAFA;\n    margin-top: 3em;\n}\n.ccRightArrow{\n    position: absolute;\n    right: 0;\n    top: 0;\n    bottom: 75px;\n    margin: auto;\n    width: 23%;\n}\n.ccLeftArrow{\n    position: absolute;\n    left: 0;\n    top: 0;\n    bottom: 0;\n    margin: auto;\n    width: 16%;\n}\n/* .getDreamleft{\n    position: absolute;\n    bottom: -100px;\n    left: 0;\n    width: 280px;\n}\n.getDreamright{\n    position: absolute;\n    bottom: -100px;\n    right: 0;\n    width: 290px;\n} */\n\n/********************Blog Detail**************************/\n.blogImg{\n    aspect-ratio: 3/2;\n    width: 100%;\n    object-fit: contain;\n}\n.blogContent{\n        font-size: 1.2em;\n        overflow: hidden;\n        display: -webkit-box;\n        -webkit-line-clamp: 4;\n        -webkit-box-orient: vertical;\n        height: 100px;\n}\n\n@media screen  and (max-width: 767px){\n    .ccRightArrow{\n        display: none;\n    }\n    .ccLeftArrow{\n        display: none;\n    }\n    .collegeConsultingContainer{\n        padding: 10em 2em 2em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
