
import '../Assets/Css/alumniCard.css'; 
import localImages from '../Constant/localImagesNew';
function AlumniCard(props:any) { 
   
  return (
    <div className='col-12 col-sm-6 col-md-4 col-lg-4 mb-3 px-3' key={props?.index}>
      <div className='row border p-2 alumniCard'>
        <div className='col-4 py-2'>
          <img src={props?.data?.imgPath} alt={props?.data?.title} className='w-100' />
        </div>
        <div className='col-8 d-flex flex-column justify-content-center'>
          <div className='fw-bold mb-2 lh-sm font16'>{props?.data?.name}</div>
          <div className=''>{props?.data?.university}</div>
          {props?.socialMediaLink?<div className='mt-4 d-flex'>
            <img src={localImages?.linkedin} alt="linkedin" className='me-3' />
            <img src={localImages?.twitter} alt="twitter" className='' />
          </div>:null}
        </div>
      </div>
    </div>
  );
}

export default AlumniCard;
