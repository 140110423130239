import React, { useEffect, useState } from 'react';
import '../Assets/Css/bannerVideo.css';
function BannerImage(props:any) { 
   
  return (
    props?.align==="Left"?
    <div className="position-relative">
    <img src={props?.src} alt='Banner' className='bannerImg'/>
    <div className='row align-items-baseline bottomStripright'>
      <div className='col-6 videoBlueStrip'></div>
      <div className='col-6 videoOrangeStrip'></div>
    </div>
  </div>
  :
    <div className="position-relative">
      <img src={props?.src} alt='Banner' className='bannerImg'/>
      <div className='row align-items-baseline bottomStripleft'>
        <div className='col-6 videoOrangeStrip'></div>
        <div className='col-6 videoBlueStrip'></div>
      </div>
    </div>
  );
}

export default BannerImage;
