import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Assets/Css/thankYou.css";
import localImages from "../Constant/localImages";

const ThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pagename } = location.state;
  const clickHome = () => {
    if (pagename === "CollegeCounseling" || pagename === "DigitalSAT" ||
      pagename === "LearnLandingForm" ||
      pagename === "ACT" || pagename === "LSAT") {
      navigate("/User/demoDashboard");
    }  
  };

  return (
    <>
      <div className="thank-you-message">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div>
                    <img
                      src={localImages?.logo}
                      alt="customer-execuitv"
                      width="200px"
                    ></img>
                  </div>
                  <div className="col-md-6">
                    <div className="image-holder text-center">
                      <img
                        src={localImages?.thanks}
                        alt="customer-execuitv"
                        className="img-fluid"
                      ></img>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-holder ms-md-4 ms-1">
                      <h1>Thank You!</h1>
                      <p className="mb-1 colorBlue">
                        Your Submission has been Recived..
                      </p>
                      <p className="colorBlue">
                        <small>We will be in touch with you soon..</small>
                      </p>
                      <button
                        type="button"
                        className="rounded clickbtn my-4 px-4"
                        onClick={clickHome}
                      >
                        Start Your Journey With LURNIGO
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
