import React, { useEffect, useState } from "react";
import "../App.css";
import norecord from "../Assets/Images/json/no_record_found.json";
import Lottie from "react-lottie";
import "../Assets/Css/courses.css";
import { useAppDispatch } from "../hooks";
import {
  getExam,
  getOnlineTestList,
  notSyncedDataAction,
} from "../Redux/Actions/examActions";
import { useSelector } from "react-redux";
import { _retrieveData } from "../Utils/utils";
import TestBox from "../Component/testBox";
import { useLocation, useNavigate } from "react-router-dom";

function OnlineTest(index: any) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [examList, setExamList] = useState<any>({
    mockTest: [],
    unitTest: [],
  });
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState("");
  const { packageID } = location.state;

  let selectedQuestionIndex = 0;
  const {
    notSyncedDataFromStore,
    examIdDataFromStoreObject,
    examTypeId,
    courseType,
  } = useSelector((state: any) => ({
    notSyncedDataFromStore: state?.ExamSelectReducer?.notSyncedData,
    examIdDataFromStoreObject: state?.ExamSelectReducer?.examIdData,
    examTypeId: state?.AuthReducer?.examTypeId,
    examTypeName: state?.AuthReducer?.examTypeName,
    courseType: state?.AuthReducer?.courseType,
  }));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: norecord,
    renderer: "svg",
  };
  useEffect(() => {
    const postData = {
      coursetypeId: courseType?._id,
      examtypeId: examTypeId,
      packageId: packageID,
    };
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getOnlineTestList(
        postData,
        (res: any) => {
          if (res) {
            setExamList(res);
          } else {
            setExamList({
              mockTest: [],
              unitTest: [],
            });
          }
          setSelectedTab(Object.keys(res)[0]);
        },
        () => { }
      )
    );
  }, []);

  const onStartExamClick = (examId: string, status: number) => {
    if (status === 2) {
      navigate("/DetailResult", { state: { examSetId: examId } });
    } else {
      onStartExam(examId);
    }
  };

  const onStartExam = (examId: any) => {
    const examIdDataFromStore = examIdDataFromStoreObject[examId];
    const args = {
      examId,
      dispatch,
      getExam,
      notSyncedDataFromStore,
      selectedQuestionIndex,
      notSyncedDataAction,
      examIdDataFromStore,
    };
    _retrieveData(args);
  };

  const onTabChange = (
    index: React.SetStateAction<number>,
    selectedTab: React.SetStateAction<string>
  ) => {
    setSelectedTabIndex(index);
    setSelectedTab(selectedTab);
  };

  return (
    <div className="rightPanel">
      <h3 className="fw-bold boldfont me-5">Online Test</h3>
      <div className="customtab mt-4">
        <ul
          className="nav nav-pills mb-3 boxshadow p-2  rounded bgwhite"
          id="pills-tab"
          role="tablist"
        >
          {Object.keys(examList)?.map((c: any, index: any) => (
            <li
              key={index.toString()}
              className="nav-item m-1"
              role="presentation"
            >
              <button
                className={`nav-link text-lowercase text-capitalize px-3 me-3 ${index === 0 ? "active" : ""
                  }`}
                id={"pills-" + index + "-tab"}
                data-bs-toggle="pill"
                data-bs-target={"#pills-" + index}
                type="button"
                role="tab"
                aria-controls={"pills-" + index}
                onClick={(e) => onTabChange(index, c)}
                aria-selected="true"
              >
                {c === "unitTest"
                  ? "Unit Test"
                  : c === "mockTest"
                    ? "Mock Test"
                    : c}
              </button>
            </li>
          ))}
        </ul>
        {examList[selectedTab]?.length > 0 ? (
          <div className="tab-content" id="pills-tabContent">
            <div
              key={selectedTabIndex}
              className={`tab-pane fade ${selectedTabIndex >= 0 ? "show active" : ""
                }`}
              id={"pills-" + selectedTabIndex}
              role="tabpanel"
              aria-labelledby={"pills-" + selectedTabIndex + "-tab"}
            >
              {examList[selectedTab]?.map((c: any, index: any) => (
                <div className="row lectureheight">
                  <div className="col-md-6  ">
                    <TestBox
                      id={index}
                      data={c}
                      index={index}
                      onPress={(examId: string, status: number) =>
                        onStartExamClick(examId, status)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="row mt-5 bgwhite">
            <div className="col justify-content center">
              <Lottie
                options={defaultOptions}
                height={130}
                width={200}
              ></Lottie>
              <p className="text-center">
                <b>No Result Found</b>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OnlineTest;
