import React, { useEffect, useState } from "react";
import deleteimg from "../Assets/Images/courses/Delete.png";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import downarrow from "../Assets/Images/courses/downarrow.png";
import { deleteCart, getSessionData } from "../Redux/Actions/examActions";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthReducerModal } from "../Modals";
import localImages from "../Constant/localImages";
function DeleteAccountPopUp(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { firstName } = useSelector(
    (state: { AuthReducer: AuthReducerModal }) => ({
      firstName: state?.AuthReducer?.firstName,
    })
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.openPopup}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.openPopup}>
        <div className="modeldiv editprofEdit ">
          <div className=" d-flex mt-0 justify-content-center  ">
            <div className="col-md-2 bg-light p-4 border rounded-circle shadow">
              <img src={localImages?.delete} className="img-fluid"></img>
            </div>
          </div>

          <div className="text-center py-5 pt-0 ">
            <p className="fw-bold  " style={{fontSize:"2.2em" }}>
               Are you sure ?  
             
            </p>
            <p className="text-secondary">
              {" "}
              you will permenently lose all your data, self-notes, and profile
              info. After this , there is no turning back{" "}
            </p>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-primary rounded-pill me-5 fs-5 fw-bold"
                onClick={props.handleClose}
              >
                No, Cancel
              </button>
              <button
                className="btn btn-danger rounded-pill fs-5 fw-bold"
                onClick={props.deleteAccount }
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default DeleteAccountPopUp;
