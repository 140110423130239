import { useEffect, useState } from "react";
import "../App.css";
import "../Assets/Css/placeorder.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown from "../Component/dropdown";
import {
  getUserPackageList,
  updateMoreValues,
} from "../Redux/Actions/AuthAction";
import Lottie from "react-lottie";
import live from "../Assets/Images/json/livesession.json";
// import localImages from "../Constant/localImages";
import { useAppDispatch } from "../hooks";
import BbMeetingPopup from "../Module/bbMeetingPopup";
import moment from "moment";
import { getLiveLecture, getUserDetails } from "../Redux/Actions/examActions";
import { AuthReducerModal } from "../Modals";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import localImages from "../Constant/localImagesNew";
import DropdownNew from "../Component/dropdownNew";

function MyLearning() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [packageList, setPackageList] = useState([]);
  const [packageVal, setPackageVal] = useState("");
  const [openVideo, setopenVideo] = useState(false);
  const [deafultexamid, setdeafultexamid] = useState("");
  const [scheduleData, setScheduleData] = useState<any>([]);
  const [date, setDate] = useState(new Date());
  const [bblink, setbblink] = useState<any>("");
  const [open, setOpen] = useState(false);
  const { ACCESS_TOKEN, examTypeId, studentId } = useSelector(
    (state: { AuthReducer: AuthReducerModal }) => ({
      ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
      examTypeId: state?.AuthReducer?.examTypeId,
      userId: state?.AuthReducer?.userId,
      studentId: state?.AuthReducer?.studentId,
    })
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: live,
    renderer: "svg",
  };
  useEffect(() => {
    sessionStorage.clear();
    dispatch(
      getUserDetails(
        studentId,
        (res: any) => {
          dispatch(
            updateMoreValues({ hasActivePaidPlan: res[0].hasActivePaidPlan })
          );
        },
        () => {}
      )
    );
    dispatch(
      getUserPackageList(
        studentId,
        (data: any) => {
          setPackageList(data);
          // setPackageVal(data[0]?._id);
          setdeafultexamid(data[0]?._id);
        },
        () => {}
      )
    );

    const requestData = {
      date: moment(date).format("YYYY-MM-DD"),
    };
    dispatch(
      getLiveLecture(
        requestData,
        (res: any) => {
          const newArray = res?.data?.filter(function (el: any) {
            return el.status === 2;
          });
          setScheduleData(newArray);
        },
        () => {}
      )
    );
  }, []);
  // useEffect(() => {
  //   setPackageVal(examTypeId)
  // }, [examTypeId]);

  // useEffect(() => {
  //   setPackageVal(examTypeId);
  //   setdeafultexamid(examTypeId);
  // }, [packageList]);

  const getPackageVal = (val: any) => {
    setPackageVal(val);
    const requestData = {
      date: moment(date).format("YYYY-MM-DD"),
      packageId: val,
    };
    dispatch(
      getLiveLecture(
        requestData,
        (res: any) => {
          // const newArray = res?.data?.filter(function (el: any) {
          //   return el.status === 2;
          // });
          const liveIndex = res?.data?.findIndex(
            (item: any) => item?.status === 2
          );
          const upcomingIndex = res?.data?.findIndex(
            (item: any) => item?.status === 1
          );
          if (liveIndex != -1) {
            setScheduleData(res?.data[liveIndex]);
          } else if (upcomingIndex != -1) {
            setScheduleData(res?.data[upcomingIndex]);
          } else {
            setScheduleData(res?.data);
          }
        },
        () => {}
      )
    );
  };
  const LectureScheduleRoute = () => {
    navigate("/User/LectureSchedule", { state: { packageID: packageVal } });
  };

  const gotoRecorded = () => {
    navigate("/Module", { state: { packageID: packageVal } });
  };
  const gotoOnlineTest = () => {
    navigate("/User/OnlineTest", { state: { packageID: packageVal } });
  };
  const openBbmeeitng = (link: any) => {
    setopenVideo(true);
    setbblink(link);
  };
  const handleBbbVideoClose = () => {
    setopenVideo(false);
  };

  return (
    <div className="rightPanel">
      <div className="">
        <div className="d-flex">
          <h3 className="fw-bold boldfont me-5">My Learning</h3>
          <div className="dropdowncolor">
            <DropdownNew
              // placeholder="Select Package"
              options={packageList}
              value={packageVal}
              defaultValue={deafultexamid}
              setInputText={(value: any) => getPackageVal(value)}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="row d-flex justify-content-between width75">
            {/* <div className="col-sm-6 d-flex mb-4">
              {scheduleData?.status === 2 ? (
                <div
                  className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100"
                  onClick={() =>
                    openBbmeeitng(scheduleData?.attendeeUrl)
                  }
                >
                  <div className="col-9">
                    <p className="fw-bold mb-2">Live Session</p>
                    <p className="mb-0 colorblue font12">Join Session Now</p>
                  </div>
                  <div className="col-3">
                    <Lottie options={defaultOptions} height={60} width={60} />
                  </div>
                </div>
              ) :
                scheduleData?.status === 1 ? (
                  <div
                    className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100"
                    onClick={() =>
                      setOpen(true)
                    }
                  >
                    <div className="col-9">
                      <p className="fw-bold mb-2">Live Session</p>
                      <p className="mb-0 colorblue font12"> {moment.utc(scheduleData?.sessionTime, 'hh:mm:ss').format('LT') <
                        moment(new Date()).format('LT')
                        ? `Live session starting soon`
                        : `Live Start at ${moment
                          .utc(scheduleData?.sessionTime, 'hh:mm:ss')
                          .format('LT')}`}</p>
                    </div>
                    <div className="col-3">
                      <Lottie options={defaultOptions} height={60} width={60} />
                    </div>
                  </div>
                ) : (
                  <div
                    className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100"
                    onClick={LectureScheduleRoute}
                  >
                    <div className="col-9">
                      <p className="fw-bold mb-2">Live Session</p>
                      <p className="mb-0 colorblue font12">
                        no live session available
                      </p>
                    </div>

                    <div className="col-3">
                      <Lottie options={defaultOptions} height={60} width={60} />
                    </div>
                  </div>
                )}
            </div>
            <div className="col-sm-6 d-flex mb-4">
              <div
                className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100"
                onClick={gotoRecorded}
              >
                <div className="col-9">
                  <p className="fw-bold mb-2">Recorded Lecture</p>
                </div>
                <div className="col-3">
                  <img src={localImages.allabout} alt="allAbout" />
                </div>
              </div>
            </div>
            <div className="col-sm-6 d-flex mb-4">
              <div className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100" onClick={gotoOnlineTest}>
                <div className="col-9 d-flex align-item-center">
                  <p className="fw-bold mb-2">Online Test</p>
                </div>
                <div className="col-3">
                  <img src={localImages.onlineTest} className="img-fluid w-50" alt="allAbout" />
                </div>
              </div>
            </div> */}
            {/* <div className="col-sm-6 d-flex mb-4">
              <div className="row boxshadow rounded p-2 p-md-4 align-items-center cursor bgwhite w-100">
                <div className="col-9">
                  <p className="fw-bold mb-2">All About SAT</p>
                </div>
                <div className="col-3">
                  <img src={localImages.allabout} alt="allAbout" />
                </div>
              </div>
            </div> */}

            <div className="dashboardcardDiv pe-0">
              <div className="d-flex row mx-0">
                <div className="col-md-4 ps-0 mb-2 mb-md-0">
                  <div className="bgwhite dashboardcard">
                    <h6 className="text-start fw-bold mb-2 cardLebel">
                      All Lectures
                    </h6>
                    <img
                      src={localImages?.demoImg}
                      alt="dashboardcardImg"
                      className="dashboardcardImg"
                    />
                    <div className="text-center fw-bold my-2 cardtext">
                      Explore All Lectures
                    </div>
                    <button
                      className="btn btnbg px-4 font14 w-100 mt-3"
                      onClick={gotoRecorded}
                    >
                      View
                    </button>
                  </div>
                </div>
                <div className="col-md-4 ps-0 mb-2 mb-md-0">
                  <div className="bgwhite dashboardcard">
                    <h6 className="text-start fw-bold mb-2 cardLebel">
                      Online Test
                    </h6>
                    <img
                      src={localImages?.demoImg}
                      alt="dashboardcardImg"
                      className="dashboardcardImg"
                    />
                    <div className="text-center fw-bold my-2 cardtext">
                      Module Test & Analysis{" "}
                    </div>
                    <button
                      className="btn btnbg px-4 font14 w-100 mt-3"
                      onClick={gotoOnlineTest}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BbMeetingPopup
        open={openVideo}
        handleClose={handleBbbVideoClose}
        link={bblink}
      />

      <Modal
        aria-labelledby="transition-modal-title "
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldiv p-4 py-5">
            <div className="m-2  bgwhite">
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon
                  className="colorblue cursor"
                  onClick={() => setOpen(false)}
                />
              </div>
              <div className="col justify-content-center  py-5 ">
                <Lottie
                  options={defaultOptions}
                  height={100}
                  width={100}
                ></Lottie>
                <p className="text-center">
                  <b>Please wait for a while , lecture will start soon</b>
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default MyLearning;
