import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function TestBox(props: { onPress: Function, data: any, index: any, id: any }) {
  const {
    onPress,
    data,
    index,
    id
  } = props
  const percentage = 66;
  return (
    <div
      className="  boxshadow p-3 rounded   align-items-center mb-3 bgwhite"
      style={{ cursor: 'pointer' }}
      id={id + index}
      onClick={() => onPress(data?._id, data?.status)}
    >
      <div className='d-flex justify-content-between'>
        <p className="text-uppercase mb-2 fw-bold"> {data?.name}</p>
        {/* <div className='col-md-1'>
          {data?.status === "Completed" ? <CheckCircleIcon fontSize='large' color='success' /> :
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              strokeWidth={5}
            />}
        </div> */}
      </div>
      <div className='d-flex justify-content-between align-items-center mt-2'>
        <div className='d-flex'>
          <div className='me-3'>
            <p className='text-secondary mb-0'>Question </p>
            <p className='text-primary mb-0' > {data?.totalQuestions} Q       </p>
          </div>
          <div>
            <p className='text-secondary mb-0'>Duration </p>
            <p className='text-primary mb-0'> {data?.duration} min    </p>
          </div>
        </div>
        <div>
          {data?.status === 2 ? <button className='btn btn-success py-1 px-5 '>Test Analysis</button> : <button className='btn btn-primary py-1 px-5 '>Start Now </button>}
        </div>
      </div>
    </div>
  );
}

export default TestBox;
