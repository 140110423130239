import "../App.css";
import "../Assets/Css/lecture.css"
import { useLocation, useNavigate } from "react-router-dom";
import {   useDispatch } from "react-redux";
import LectureTile from "../Component/lectureTile";
import NoRecord from '../Component/noRecord';
function Lecture(props: any) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {subjectData} = location.state

  const gotoTopic = (c:any) =>{
    navigate('/User/Topics', { state:{Data: c}});
}

 
 
  return (
    <div className="rightPanel">
      <div className="d-flex">
        <h3 className="fw-bold boldfont me-5">{subjectData?.name}</h3>
      </div>
      <div className="col-md-6 lectureheight p-2">
        {subjectData?.Subjects?.length !== 0 ? (subjectData?.Subjects?.map((c: any, index: any) => {
          return (
            <LectureTile
              data={c}
              index={index}
            onpress={() => gotoTopic(c)}
            />)
        })) : (<NoRecord />)}


      </div>

    </div>
  );
}

export default Lecture;
