import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import InputField from '../Atom/InputField';
import Dropdown from './dropdown';
import '../Assets/Css/bookSession.css'
import PhoneInputField from '../Atom/phoneInputField';
import { validateEmailAddress, validateFullName, validateIsLettersAndDotOnly, validateIsNumberOnly } from '../Utils/validators';
import { validateFullNameErrMsg, validateIsNumberOnlyErrMsg, validateLastNameErrMsg, validateSchoolNameErrMsg } from '../Utils/errormsg';
import { useAppDispatch } from '../hooks';
import { showErrorSnackbar } from '../Redux/Actions/snackbarAction';
import { addBookSession, findAboutUsList, getCountry, getCourseExam, gradeLevelList } from '../Redux/Actions/AuthAction';
import localImages from '../Constant/localImagesNew';

interface props {
  open: boolean,
  handleClose: Function,
  examType?:any
}
export default function BookSessionPopup({ open, handleClose,examType }: props) {
  const dispatch = useAppDispatch();
  const [selectedType,setSelectedType]=useState("Gaurdian");
  const [fname, setfname] = useState<string>("");
  const [lname, setlname] = useState<string>("");
  const [pemail, setpemail] = useState<string>("");
  const [pmob, setpmob] = useState<string>("");
  const [school, setschool] = useState<string>("");
  const [classval, setclassval] = useState<string>("");
  const [subject, setSubject] = useState<string>("");  
  const [gradeList, setGradeList] = useState([]);
  const [email,setemail]=useState("");
  const [mob, setmob] = useState<string>("");
  const [IAgree, setIAgree] = useState<boolean>(false);
  const [guidance, setguidance] = useState<boolean>(false);
  const [aboutUsval, setaboutUsval] = useState<string>("");
  const [desc, setdesc] = useState<string>("");
  const [aboutUsList, setAboutUsList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("61");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  useEffect(() => {
    dispatch(findAboutUsList(
      (data: any) => {
        setAboutUsList(data)
      },
      () => { })
    );
    dispatch(
      getCourseExam(
        (data: any) => {
          setSubjectList(data);
          
        },
        () => { }
      )
    );
    dispatch(gradeLevelList(
      (data: any) => {
        setGradeList(data)
      },
      () => { })
    );
    dispatch(getCountry(
      (data: any) => {
          setCountryData(data)
      },
      () => { })
  );
  }, []);

  const closePopup=()=>{
    handleClose();
    clearData();
  }
 
  function changeAboutLecture(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    setdesc(event.target.value)
  }
  const changeAboutus = (val: any) => {
    setaboutUsval(val)
  }
  const changeClass = (val: any) => {
    setclassval(val)
  }
  const changeSubject = (val: any) => {
    setSubject(val)
  }
  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputLnameText = (e: any) => {
    if (validateFullName(e)) setlname(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }

  };
  const setParentsPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setpmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }

  };

  const onChnageSchoolName = (e: any) => {
    if (validateIsLettersAndDotOnly(e)) setschool(e);
    else {
      dispatch(showErrorSnackbar(validateSchoolNameErrMsg));
    }
  }

  function submitForm(e: { preventDefault: () => void; }) {
    e.preventDefault();

    const PostData: any = {
      firstName: fname,
      lastName: lname,
      email: email,
      mobile: mob,
      currentSchool: school,
      parentEmail: pemail,
      parentMobile: pmob,
      gradeId: classval,
      remarks: desc,
      refId: aboutUsval,
      terms_condition: IAgree,
      admission_guidance: guidance,
      role: selectedType,
      countryCode:coutryCode
    }
    if (subject) {
      PostData["examtypeId"] = subject
    }
    if (pemail === "") {
      delete PostData["parentEmail"];
    }
    if (pmob === "") {
      delete PostData["parentMobile"];
    }
    if (email === "") {
      delete PostData["email"];
    }
    if (mob === "") {
      delete PostData["mobile"];
    }
    if (desc === "") {
      delete PostData["concernArea"];
    }
    if (aboutUsval === "") {
      delete PostData["findUsAt"];
    }
    if (classval === "") {
      delete PostData["gradeLevel"];
    }
    dispatch(addBookSession(
      PostData,
      (res: any) => {
        setIsSubmit(true)
        // clearData();

      }, () => { }))
    return (true)
  }
  function clearData() {
    setfname("");
    setlname("");
    setemail("");
    setmob("");
    setpemail("");
    setpmob("");
    setschool("");
    setclassval("");
    setaboutUsval("");
    setdesc("");
    setIAgree(false);
    setguidance(false);
    setSubject("")
    // const ele = document.getElementById("IAgree") as HTMLInputElement;
    // ele.checked = false;
    // const ele1 = document.getElementById("flexCheckDefault") as HTMLInputElement;
    // ele1.checked = false;
    setIsSubmit(false)
  }
  function clearParentData() {
    setpemail("");
    setpmob("");
  }

  function validation() {
    return (
      IAgree === false ||
      (selectedType === "Student" && pemail === "") ||
      (selectedType === "Student" && pmob === "") ||
      (selectedType === "Student" && (email!=="" && !validateEmailAddress(email))) ||
      (selectedType === "Student" && !validateEmailAddress(pemail)) ||
      (selectedType === "Guardian" && mob === "") ||
      (selectedType === "Guardian" && email === "") ||
      (selectedType === "Guardian" && !validateEmailAddress(email)) ||
      (selectedType === "Student" && pmob.length != 10) ||
      (selectedType === "Student" && coutryCode!=="61" && pmob?.length < 10) || (selectedType === "Student" && coutryCode==="61" && pmob?.length < 9) ||
      // (selectedType === "Student" && (mob.length > 0 && mob.length<10)) ||
      (selectedType === "Guardian" && mob.length != 10) ||
      (selectedType === "Guardian" && coutryCode!=="61" && mob?.length < 10) || (selectedType === "Guardian" && coutryCode==="61" && mob?.length < 9)||
      fname === "" || lname === "" || school === "" || classval === "" || desc === "" || aboutUsval === "");
  }
const onChangeCoutryCode=(e:any)=>{
  setcoutryCode(e.target.value);
}
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={closePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="p-0 position-relative">
        
          <div className='bookSession'>
          <div className=" d-flex align-items-center justify-content-between HeadingBookSession">
           
            {isSubmit?
          <div className='text-success fw-bold font16'>Submitted Successfully</div>:
          <div className='font16 fw-bold'>Book a Session</div>}
            <ClearOutlinedIcon className="colorblue cursor font28" onClick={closePopup} />
          </div>
            <div className='bookSessionMiddleContainer'>
            {isSubmit?
                <div className='font16 p-3'>
                  <img src={localImages?.thankYou} alt='thank You'/>
                  <div className='my-3'>
                    Dear {fname},
                  </div>
                  <div>
                  <div>Thank you for your interest in LurniGo!</div>
                  <div> An Academic Consultant from our team will contact you within 24-48 hours.</div>
                  <div>Meanwhile, feel free to explore the resources on our website to know more.</div>
                  <div className='mt-3'>For any immediate queries, reach out at support@lurnigo.com.</div>
                  <div className='mt-2'>Best,</div>
                  <div className='fw-bold'>Team LurniGo</div>
                  </div>
                </div>:
              <><div className='sessionTab'>
                <div className={`${selectedType==="Gaurdian"?'active':''}`} onClick={()=>{setSelectedType("Gaurdian");clearParentData() }}>Guardian</div>
                <div className={`${selectedType==="Student"?'active':''}`} onClick={()=>{setSelectedType("Student");clearParentData() }}>Student</div>
              </div>
              <div>
                <form className='w-100'>
                  <div className='row mx-0'>
                    <div className='my-2'>
                      <label className='mb-2'>First Name</label>
                      <div className='d-flex'>
                      <InputField
                        placeholder="Enter First Name"
                        value={fname}
                        setValue={handleFirstInputText}
                      />
                      <span className="mendatory ms-2 fs-5">*</span>
                      </div>
                    </div>
                    <div className='my-2'>
                      <label className='mb-2'>Last Name</label>
                      <div className='d-flex'>
                      <InputField
                        placeholder="Enter Last Name"
                        value={lname}
                        setValue={handleLastInputLnameText}
                      />
                      <span className="mendatory ms-2 fs-5">*</span>
                      </div>                     
                    </div>
                    
                    <div className='my-2'>
                      <label className='mb-2'>Email</label>
                      <div className='d-flex'>
                      <input
                        className='form-control p-2'
                        type='email'
                        value={email}
                        placeholder="Enter Your Email Address"
                        onChange={(e) => setemail(e.target.value)} />
                      {selectedType === "Gaurdian" ? <span className="mendatory ms-2 fs-5">*</span> : null}
                      {/* <span className="mendatory ms-2 fs-5">*</span> */}
                      </div>                     
                    </div>
                    <div className='my-2'>
                      <label className='mb-2'>Mobile Number</label>
                      <div className='d-flex position-relative'>
                        {/* <span className="bookSeatcountryCode boldfont cursor me-1"> + 1 </span> */}
                        <select
                          className="form-select cursor coutryCode  rounded-0 rounded-start"
                          aria-label="Default select example"
                          // placeholder={defaultValue}
                            defaultValue={1}
                           value={coutryCode}
                           onChange={onChangeCoutryCode}
                        >
                          {/* {placeholder ? <option value="">{placeholder}</option> : null} */}
                          {countryData?.map((item:any, index) => {
                            return <option value={item.phonecode}>{item?.isoCode} +{item?.phonecode}</option>;
                          })}
                        </select>
                        <PhoneInputField
                          placeholder="Enter Your Mobile Number"
                          value={mob}
                          maxLength={"10"}
                          setValue={setPhoneNumber} />
                          {selectedType === "Gaurdian" ? <span className="mendatory ms-2 fs-5">*</span> : null}
                          {/* <span className="mendatory ms-2 fs-5">*</span> */}
                      </div>
                    </div>
                    {selectedType === "Student" ?
                      <div className='my-2'>
                        <label className='mb-2'>Parent Email</label>
                        <div className='d-flex'>
                          <input
                            className='form-control p-2'
                            type='email'
                            value={pemail}
                            placeholder="Enter Parent Email Address"
                            onChange={(e) => setpemail(e.target.value)} />
                          <span className="mendatory ms-2 fs-5">*</span>
                        </div>
                      </div>
                      : null
                    }
                    {selectedType === "Student" ?
                      // <div className='my-2'>
                      //   <label className='mb-2'>Parent Mobile Number</label>
                      //   <div className='d-flex position-relative'>
                      //     <span className="bookSeatcountryCode boldfont cursor me-1"> + 1 </span>
                      //     <PhoneInputField
                      //       placeholder="Enter Parent Mobile Number"
                      //       value={pmob}
                      //       maxLength={"10"}
                      //       setValue={setParentsPhoneNumber} />
                      //     <span className="mendatory ms-2 fs-5">*</span>
                      //   </div>
                      // </div>
                      <div className='my-2'>
                      <label className='mb-2'>Parent Mobile Number</label>
                      <div className='d-flex position-relative'>
                        {/* <span className="bookSeatcountryCode boldfont cursor me-1"> + 1 </span> */}
                        <select
                          className="form-select cursor coutryCode  rounded-0 rounded-start"
                          aria-label="Default select example"
                            defaultValue={1}
                           value={coutryCode}
                           onChange={onChangeCoutryCode}
                        >
                          {/* {placeholder ? <option value="">{placeholder}</option> : null} */}
                          {countryData?.map((item:any, index) => {
                            return <option value={item.phonecode}>{item?.isoCode} +{item?.phonecode}</option>;
                          })}
                        </select>
                        <PhoneInputField
                          placeholder="Enter Parent Mobile Number"
                          value={pmob}
                          maxLength={"10"}
                          setValue={setParentsPhoneNumber} />
                          {/* {selectedType === "Gaurdian" ? <span className="mendatory ms-2 fs-5">*</span> : null} */}
                          <span className="mendatory ms-2 fs-5">*</span>
                      </div>
                    </div>
                      : null
                    }
                    
                    <div className='my-2'>
                      <label className='mb-2'>Current School</label>
                      <div className='d-flex'>
                      <InputField
                        placeholder="Enter School"
                        value={school}
                        setValue={onChnageSchoolName}
                  maxLength={"100"}
                      />
                      <span className="mendatory ms-2 fs-5">*</span>
                      </div>
                    </div>
                    <div className='my-2'>
                      <label className='mb-2'>Class</label>
                      <div className='d-flex'>
                        <Dropdown
                          placeholder='Select Your Class'
                          setInputText={(value: any) => changeClass(value)}
                          value={classval}
                          options={gradeList} />
                        <span className="mendatory ms-2 fs-5">*</span>
                      </div>
                    </div>
                    <div className='my-2'>
                      <label className='mb-2'>Subject</label>
                      <div className='d-flex'>
                        <Dropdown
                          placeholder='Select Your Subject'
                          setInputText={(value: any) => changeSubject(value)}
                          value={subject}
                          options={subjectList} />
                        <span className="mendatory ms-2 fs-5">*</span>
                      </div>
                    </div>
                    
                    <div className='my-2'>
                    <label className='mb-2'>Explain your biggest areas of concern in a few sentences</label>
                      <textarea
                        className='form-control'
                        placeholder='Please explain your biggest areas of concern in a few sentences'
                        onChange={changeAboutLecture}
                        value={desc}>
                      </textarea>
                    </div>
                    <div className='my-2'>
                      <label className='mb-2'>How did you find out about us?</label>
                      <Dropdown
                        placeholder='How did you find out about us?'
                        setInputText={(value: any) => changeAboutus(value)}
                        value={aboutUsval}
                        options={aboutUsList} />
                    </div>
                    <div className="d-flex my-2">
                    <div className='col-1 d-flex align-items-center'>
                      <input
                        className="iagreeCheck"
                        type="checkbox"
                        onChange={(e) => { setIAgree(e.target.checked) }}
                        value=""
                        id="IAgree"
                        checked={IAgree}
                      />
                      </div>
                      <label className="form-check-label font16 col-11">
                        I Understand And Agree To The Terms & Conditions And Privacy Policy Below
                      </label>
                    </div>
                    <div className="d-flex my-2">
                    <div className='col-1 d-flex align-items-center'>
                      <input
                        className=" iagreeCheck"
                        type="checkbox"
                        value=""
                        id="IAgree"
                        checked={guidance}
                        onChange={(e) => { setguidance(e.target.checked) }}
                      />
                      </div>
                      
                      <label className="form-check-label font16 col-11">
                      I Want To Receive Study Pathways, Free Resources And Admissions Guidance From Lurnigo
                      </label>
                    </div>
                  </div>
                </form>
            </div></>}
            </div>
          <div className='p-3 px-4 border-top'>
          {isSubmit?
          <button onClick={closePopup} className={`signBtn w-100 rounded font16`}>Ok</button>
            : <button onClick={submitForm} className={`signBtn w-100 rounded ${validation()?'bg-secondary':null}`} disabled={validation()}>Book Now</button>}
           
          </div>
            {/* <BookSeat examType={examType}/> */}
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
