export default {
  logo: require("../Assets/Images/Home/logo.png"),
  icon: require("../Assets/Images/myProfile/logo512.png"),
  dummyUser: require("../Assets/Images/Home/user.png"),
  bannerimg1: require("../Assets/Images/Home/homeBanner1.png"),
  bannerimg2: require("../Assets/Images/Home/homeBanner2.png"),
  bannerimg3: require("../Assets/Images/Home/homeBanner3.png"),
  bannerimg4: require("../Assets/Images/Home/homeBanner4.png"),
  bannerimg5: require("../Assets/Images/Home/homeBanner5.png"),
  landingBanner1: require("../Assets/Images/LandingPage/landingBanner1.png"),
  landingBanner2: require("../Assets/Images/LandingPage/landingBanner2.png"),
  homelogo: require("../Assets/Images/Home/homelogo.png"),
  ourCourse: require("../Assets/Images/Home/ourCourse.png"),
  satExam: require("../Assets/Images/Home/satExam.png"),
  psatExam: require("../Assets/Images/Home/psatExam.png"),
  actExam: require("../Assets/Images/Home/actExam.png"),
  apExam: require("../Assets/Images/Home/apExam.png"),
  wuwlbg: require("../Assets/Images/Home/wuwlbg.png"),
  weeklyOnlineTest: require("../Assets/Images/Home/weeklyOnlineTest.png"),
  liveOnlineClass: require("../Assets/Images/Home/liveOnlineClass.png"),
  doubtSolving: require("../Assets/Images/Home/doubtSolving.png"),
  practice: require("../Assets/Images/Home/practice.png"),
  insightSummary: require("../Assets/Images/Home/insightSummary.png"),
  curriculum: require("../Assets/Images/Home/curriculum.png"),
  jumpingboy: require("../Assets/Images/Home/jumpingboy.png"),
  bookfree: require("../Assets/Images/Home/bookfree.png"),
  bookfreeNew: require("../Assets/Images/Home/bookfreeNew.png"),

  University1: require("../Assets/Images/CollegeLogo/logo1.png"),
  University2: require("../Assets/Images/CollegeLogo/logo2.png"),
  University3: require("../Assets/Images/CollegeLogo/logo3.png"),
  University4: require("../Assets/Images/CollegeLogo/logo4.png"),
  University5: require("../Assets/Images/CollegeLogo/logo5.png"),
  University6: require("../Assets/Images/CollegeLogo/logo6.png"),
  University7: require("../Assets/Images/CollegeLogo/logo7.png"),
  University8: require("../Assets/Images/CollegeLogo/logo8.png"),
  University9: require("../Assets/Images/CollegeLogo/logo9.png"),

  doublequotesend: require("../Assets/Images/Home/double-quotes.png"),
  wave: require("../Assets/Images/Home/wave.png"),
  playstore: require("../Assets/Images/Home/playstore.png"),
  appstore: require("../Assets/Images/Home/appstore.png"),
  classroominpocket: require("../Assets/Images/Home/classroominpocket.png"),
  blog1: require("../Assets/Images/Home/blog1.png"),
  blog2: require("../Assets/Images/Home/blog2.png"),
  blog3: require("../Assets/Images/Home/blog3.png"),
  service1: require("../Assets/Images/Home/service1.png"),
  service2: require("../Assets/Images/Home/service2.png"),

  /**********************Landing Page******************** */
  guide: require("../Assets/Images/LandingPage/guide.png"),
  bookseat: require("../Assets/Images/LandingPage/bookseat.png"),
  feature1: require("../Assets/Images/LandingPage/feature1.png"),
  feature2: require("../Assets/Images/LandingPage/feature2.png"),
  feature3: require("../Assets/Images/LandingPage/feature3.png"),
  feature4: require("../Assets/Images/LandingPage/feature4.png"),
  feature5: require("../Assets/Images/LandingPage/feature5.png"),
  feature6: require("../Assets/Images/LandingPage/feature6.png"),
  smalllogo: require("../Assets/Images/LandingPage/smalllogo.png"),
  guideNew: require("../Assets/Images/LandingPage/guideNew.png"),

  /****************************************Test Preparation*************************************/
  testpreparationbanner: require("../Assets/Images/Testprep/test-prep.png"),
  ACTexam: require("../Assets/Images/Testprep/ACT.png"),
  APexam: require("../Assets/Images/Testprep/AP.png"),
  PSATexam: require("../Assets/Images/Testprep/PSAT.png"),
  SATexam: require("../Assets/Images/Testprep/SAT.png"),

  edit: require("../Assets/Images/myProfile/edit-grey.png"),
  ulpoad: require("../Assets/Images/myProfile/upload-image.png"),
  defaultProfileImg: require("../Assets/Images/myProfile/defaultProfileImg.png"),

  courseDemo: require("../Assets/Images/courses/coursedemo@2x.png"),
  profile: require("../Assets/Images/Home/profile@3x@2x.png"),
  examBanner: require("../Assets/Images/courses/exambanner.png"),
  coueseDetails: require("../Assets/Images/courses/coueseDetails.png"),

  /***********************My cart*************************** */
  videoicon: require("../Assets/Images/courses/videoicon.png"),
  timeCircle: require("../Assets/Images/courses/timeCircle.png"),

  /***********************Sign In*************************** */
  signInbg: require("../Assets/Images/SignIn/signInbg.png"),
  mobOTPVerificationimg: require("../Assets/Images/SignIn/mobOTPVerificationimg.png"),
  createAccimg: require("../Assets/Images/SignIn/createAccountimg.png"),

  /*************************Learn************************* */
  allabout: require("../Assets/Images/courses/allabout.png"),
  quora: require("../Assets/Images/Home/Icon awesome-quora.png"),
  onlineTest: require("../Assets/Images/courses/onlineTest.png"),
  //Bussiness card
  bussinessCardKCsir: require("../Assets/Images/BussinessCard/KCSir.jpg"),
  bussinessCardDJsir: require("../Assets/Images/BussinessCard/DigambarSir.png"),

  //HomePage video
  roadmapvideo: require("../Assets/Video/RoadmapAnimation.mp4"),
  webanim: require("../Assets/Video/WebsiteAnimation.mp4"),

  /*************************************** */
  duration: require("../Assets/Images/TestExam/duration.png"),
  language: require("../Assets/Images/TestExam/language.png"),
  maxMarks: require("../Assets/Images/TestExam/maximum-Marks.png"),
  totalQuestions: require("../Assets/Images/TestExam/totalQuestions.png"),
  notVisited: require("../Assets/Images/TestExam/notVisited.png"),
  notAnswered: require("../Assets/Images/TestExam/notAnswered.png"),
  saveAns: require("../Assets/Images/TestExam/saveAns.png"),
  marked: require("../Assets/Images/TestExam/marked.png"),
  marknsave: require("../Assets/Images/TestExam/marknsave.png"),
  savebtn: require("../Assets/Images/TestExam/save_Next.png"),
  clearbtn: require("../Assets/Images/TestExam/clearResponse.png"),
  listbtn: require("../Assets/Images/TestExam/listView.png"),
  beep: require("../Assets/Audio/beep.mp3"),
  coinFlipSound: require("../Assets/Audio/coinFlipSound.mp3"),

  about: require("../Assets/Images/LectureVideo/about.png"),
  dppNew: require("../Assets/Images/LectureVideo/dppNew.png"),
  teacherHandout: require("../Assets/Images/LectureVideo/teacherHandout.png"),
  dpp: require("../Assets/Images/LectureVideo/dpp.png"),
  dppFull: require("../Assets/Images/LectureVideo/dppFull.png"),
  rate: require("../Assets/Images/LectureVideo/rate.png"),
  /*******************Footer Image******************/
  footerImg: require("../Assets/Images/Home/footerimg.png"),
  delete: require("../Assets/Images/SignIn/delete.png"),
  collegeconsultingimg: require("../Assets/Images/CollegeConsulting/Step process background.png"),
  /*************************Json Files************************** */
  correctFireCracker: require("../Assets/Images/json/correctFireCracker.json"),
  coin: require("../Assets/Images/json/coin.json"),
  coinsAdd: require("../Assets/Images/json/coinsAdd.json"),
  // coinsAdd1: require("../Assets/Images/json/coinsAdd1.json"),
  flipCoin: require("../Assets/Images/json/flipCoin.json"),
  // coinsAdd3: require("../Assets/Images/json/coinsAdd3.json"),
  fallenCoin: require("../Assets/Images/json/fallenCoin.json"),

  /*************************Thank You************************** */
  thanks: require("../Assets/Images/LandingPage/customer-exicative.webp"),
};
