// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videoBlueStrip{
    height: 30px;
    background: var(--theme2);
  }
  .videoOrangeStrip{
    height: 55px;
    background-color: #ff5f2f;
  }
  .bannerImg{
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
  }`, "",{"version":3,"sources":["webpack://./src/Assets/Css/bannerVideo.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;EAC3B;EACA;IACE,YAAY;IACZ,yBAAyB;EAC3B;EACA;IACE,WAAW;IACX,iBAAiB;IACjB,iBAAiB;EACnB","sourcesContent":[".videoBlueStrip{\n    height: 30px;\n    background: var(--theme2);\n  }\n  .videoOrangeStrip{\n    height: 55px;\n    background-color: #ff5f2f;\n  }\n  .bannerImg{\n    width: 100%;\n    aspect-ratio: 3/2;\n    object-fit: cover;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
