import React from 'react'

interface props{
    placeholder:string,
    value:string,
    setValue:Function,
    maxLength?:any
    minLength?:any,
    className?:string
    disabled?:boolean
}
export default function InputField({placeholder,value,setValue,maxLength,minLength,className,disabled}:props) {
  return (
    <input 
    className="form-control p-2" 
    type="text" 
    placeholder={placeholder} 
    aria-label="default input example" 
    value={value} 
    onChange={(e)=>setValue(e.target.value)} 
    maxLength={maxLength}
    minLength={minLength}
    disabled={disabled}/>
  )
}
