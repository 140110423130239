import { useEffect, useState } from 'react';
import '../App.css';
import { liveCourses, onlineCourses } from '../Utils/dataConstants';
import norecord from '../Assets/Images/json/no_record_found.json';
import Lottie from 'react-lottie';
import '../Assets/Css/courses.css';
import NoRecord from '../Component/noRecord';
import { useAppDispatch } from '../hooks';
import Dropdown from '../Component/dropdown';
import { getCounsellingExamList, getExamList } from '../Redux/Actions/AuthAction';
import { getExam, getPackageList, notSyncedDataAction } from '../Redux/Actions/examActions';
import { useSelector } from 'react-redux';
import { _retrieveData } from '../Utils/utils';
import CourseCard from '../Module/courseCard';


function OnlineCourse(index: any) {
  const dispatch = useAppDispatch();
  const [examList, setExamList] = useState([]);
  const [examval, setExamval] = useState("1");
  const [counsellingExamList, setCounsellingExamList] = useState([]);
  const [counsellingExamval, setCounsellingExamval] = useState("");
  let selectedQuestionIndex = 0;
  const {
    notSyncedDataFromStore,
    examIdDataFromStoreObject,
    examTypeId,
    examTypeName,
    courseType
  } = useSelector((state: any) => ({
    notSyncedDataFromStore: state?.ExamSelectReducer?.notSyncedData,
    examIdDataFromStoreObject: state?.ExamSelectReducer?.examIdData,
    examTypeId: state?.AuthReducer?.examTypeId,
    examTypeName: state?.AuthReducer?.examTypeName,
    courseType: state?.AuthReducer?.courseType,
  }));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: norecord,
    renderer: 'svg'
  };

  useEffect(() => {
    dispatch(getExamList(
      (data: any) => {
        setExamList(data);
      },
      () => { })
    );
    dispatch(getCounsellingExamList(
      (data: any) => {
        setCounsellingExamList(data);
      },
      () => { })
    );

    dispatch(getExam(
      "48",
      (data: any) => {
      },
      () => { })
    );

  }, []);

  useEffect(() => {
    dispatch(getPackageList(
      {
        coursetypeId: courseType?._id,
        examTypeId: examTypeId
      },
      (data: any) => {
      },
      () => { })
    );

  }, []);

  const getExamVal = (val: any) => {
    setExamval(val)
  }

  const getCounsellingExamval = (val: any) => {
    setCounsellingExamval(val)
  }

  const onStartExam = (examId: any) => {
    const examIdDataFromStore = examIdDataFromStoreObject[examId];
    const args = {
      examId,
      dispatch,
      getExam,
      notSyncedDataFromStore,
      selectedQuestionIndex,
      notSyncedDataAction,
      examIdDataFromStore
    };
    _retrieveData(args);
  };

  return (
    <div className="rightPanel">
      <div className='d-flex'>
        <div className='dropdowncolor'>
          <Dropdown
            placeholder='Select Course'
            options={examList}
            value={examval}
            setInputText={(value: any) => getExamVal(value)}
          />
        </div>
        <div className='ms-5 dropdowncolor'>
          <Dropdown
            placeholder='Select Exam'
            options={counsellingExamList}
            value={counsellingExamval}
            setInputText={(value: any) => getCounsellingExamval(value)}
          />
        </div>

      </div>


      {liveCourses.length !== 0 ? (
        <div className="customtab mt-4">
          <ul
            className="nav nav-pills mb-3 boxshadow p-2  rounded bgwhite"
            id="pills-tab"
            role="tablist">
            {onlineCourses?.map((c: any, index: any) => (
              <li key={index.toString()} className="nav-item m-1" role="presentation">
                <button
                  className={`nav-link text-lowercase text-capitalize px-3 me-3 ${index === 0 ? 'active' : ''
                    }`}
                  id={'pills-' + index + '-tab'}
                  data-bs-toggle="pill"
                  data-bs-target={'#pills-' + index}
                  type="button"
                  role="tab"
                  aria-controls={'pills-' + index}
                  aria-selected="true">
                  {c?.name}
                </button>
              </li>
            ))}
          </ul>
          <div className="tab-content" id="pills-tabContent">
            {liveCourses?.map((c, index) => (
              <div
                key={index.toString()}
                className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                id={'pills-' + index}
                role="tabpanel"
                aria-labelledby={'pills-' + index + '-tab'}
              //tabIndex="0"
              >
                <div className="row">
                  {liveCourses.length !== 0 ? (

                    <div className="courseimg col-sm-5 col-lg-4 col-xl-3 mx-2 mx-lg-3 mb-3 ">
                      <CourseCard
                        course={c?.name}
                        imgpath={c?.imgpath} />
                    </div>

                  ) : (
                    <div className="row mt-5 bgwhite">
                      <div className="col justify-content center">
                        <Lottie options={defaultOptions} height={130} width={200}></Lottie>
                        <p className="text-center">
                          <b>No Result Found</b>
                        </p>
                      </div>
                    </div>
                  )}
                  <button className='btn btn-primary' onClick={() => onStartExam("48")}>test</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) :
        <NoRecord />}
    </div>
  );
}

export default OnlineCourse;
