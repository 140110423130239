import React, { useState } from "react";
import "../Assets/Css/FlipCardTemplate.css";
import parser from "html-react-parser";
import { parseHtmlWithTargetBlank } from "../Utils/utils";

export default function FlipCardTemplate(props: any) {
  console.log("props", props);

  const [selectedCard, setselectedCard] = useState<any>("");

  const handleFlip = (i: any) => {
    if (i === parseInt(selectedCard)) {
      setselectedCard("");
    } else {
      setselectedCard(i);
    }
    // setIsFlipped(!isFlipped);
  };

  return (
    <div className="card-container">
      {JSON.parse(props?.data?.desc)?.map((card, index) => (
        <div
          className={`flip-card ${
            index === parseInt(selectedCard) ? "flipped" : ""
          }`}
          onClick={() => handleFlip(index)}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front border boxshadow">
              <div className="mb-5 font18 fw-bold colorOrange">
                {card?.Heading}
              </div>
              <div className="px-2">
                <img
                  src={card?.img}
                  alt={card?.Heading}
                  className="w-100 border flipImg"
                />
              </div>
            </div>
            <div className="flip-card-back text-start border boxshadow font16">
              <div className="flipCardBackDesc px-2">
                {parser(parseHtmlWithTargetBlank(card?.Text))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
