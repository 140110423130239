import { useEffect, useRef } from "react";
interface JitsiComponentProps {
  displayName?: string;
  roomName?: string;
  domain?: string;
  onCallEnd?: Function;
}

const JitsiComponent = ({
  displayName,
  roomName,
  domain,
  onCallEnd,
}: JitsiComponentProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const api = new window.JitsiMeetExternalAPI(domain, {
        roomName,
        width: "100%",
        height: "100%",
        parentNode: containerRef.current,
        configOverwrite: {
          prejoinPageEnabled: false, // Hides Pre Join Page
          hideConferenceSubject: true, // Hides room name (subject)
          // hideConferenceTimer: true, // Hides the timer
          // disableVideoQualityLabel: true, // Hides video quality label
          startWithVideoMuted: true, // Mutes video when joining
          startWithAudioMuted: true, // Optional: Mutes audio if desired
          disablePolls: true, // Hides the polls option in chat
        },
        interfaceConfigOverwrite: {
          // SHOW_JITSI_WATERMARK: false,
          // SHOW_WATERMARK_FOR_GUESTS: false,
          // SHOW_BRAND_WATERMARK: false,
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "fullscreen",
            "hangup",
            "chat",
            "raisehand",
            "settings",
            "filmstrip",
            // "videoquality",
            // "tileview",
            // "desktop",
            // "invite",
          ],
        },
        userInfo: {
          displayName: displayName,
        },
      });

      api.addEventListener("videoConferenceJoined", () => {
        console.log("Video conference joined");
        api.executeCommand("resizeFilmStrip", {
          width: 340, // The desired filmstrip width
        });
        // api.executeCommand("subject", "");
      });

      api.addEventListener("videoConferenceLeft", () => {
        console.log("Video conference ended.");
        if (onCallEnd) {
          onCallEnd();
        }
      });

      api.on("toolbarButtonClicked", (event) => {
        if (event.key == "fullscreen") {
          setTimeout(() => {
            api.executeCommand("resizeFilmStrip", { width: 340 });
            api.executeCommand("resizeLargeVideo", {
              width: 1200, // The desired width
              height: 650, // The desired height
            });
          }, 100); // Delay of 500ms (you can adjust this value)
        }
      });

      api.addEventListener("chatUpdated", (event: any) => {
        const isChatOpen = event.isOpen;
        if (isChatOpen) {
          // Chat is opened: resize accordingly
          console.log("Chat window is open");
          api.executeCommand("resizeFilmStrip", { width: 340 }); // Adjust the filmstrip size when chat is open
          api.executeCommand("resizeLargeVideo", {
            width: 1200, // Adjust the video size when chat is open
            height: 650,
          });
        } else {
          // Chat is closed: reset sizes
          console.log("Chat window is closed");
          api.executeCommand("resizeFilmStrip", { width: 340 }); // Default size when chat is closed
          api.executeCommand("resizeLargeVideo", {
            width: 1200, // Default video size when chat is closed
            height: 650,
          });
        }
      });

      return () => {
        api.dispose();
      };
    }
  }, [domain, roomName, displayName, onCallEnd]);

  return (
    <div
      ref={containerRef}
      style={{
        height: "100%",
        width: "100%",
      }}
    />
  );
};

export default JitsiComponent;
