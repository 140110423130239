import localImages from "../Constant/localImages";
import home from "../Assets/Images/sidebardata/home.png";
import course from "../Assets/Images/sidebardata/course.png";
import learn from "../Assets/Images/sidebardata/learn.png";
import profile from "../Assets/Images/sidebardata/profile.png";
import orderhistory from "../Assets/Images/sidebardata/orderhistory.png";
import logout from "../Assets/Images/sidebardata/logout.png";

type featureobj = {
  id: number;
  name: string;
  subname: string;
  imgpath: string;
};
export const onlineCourses: Array<featureobj> = [
  {
    id: 0,
    name: "Course",
    subname: "",
    imgpath: "",
  },
  {
    id: 1,
    name: "Online Test Series",
    subname: "",
    imgpath: "",
  },
  {
    id: 2,
    name: "Lecture Series",
    subname: "",
    imgpath: "",
  },
  {
    id: 3,
    name: "Recorded Course",
    subname: "",
    imgpath: "",
  },
];

export const liveCourses: Array<featureobj> = [
  {
    id: 1,
    name: "CSIR Net Life Science",
    subname: "Live Batch Starts: ",
    imgpath: localImages.courseDemo,
  },
  {
    id: 2,
    name: "Online Test Series- Mock Test",
    subname: "05 Mock Test ",
    imgpath: localImages.courseDemo,
  },
  {
    id: 1,
    name: "CSIR Net Life Science",
    subname: "Live Batch Starts: ",
    imgpath: localImages.courseDemo,
  },
  {
    id: 2,
    name: "Online Test Series- Mock Test",
    subname: "05 Mock Test ",
    imgpath: localImages.courseDemo,
  },
];

type recommendedCourse = {
  id: number;
  name: string;
  subname: string;
  Price: string;
  duration: string;
};
export const recommendedCourse: Array<recommendedCourse> = [
  {
    id: 1,
    name: "LIVE 3000 PRACTICE QUESTION:",
    subname: "CSIR-NET ",
    Price: "₹1,49,999",
    duration: "6 Months",
  },
  {
    id: 2,
    name: "LIVE 3000 PRACTICE QUESTION:",
    subname: "CSIR-NET ",
    Price: "₹1,49,999",
    duration: "6 Months",
  },
  {
    id: 3,
    name: "LIVE 3000 PRACTICE QUESTION:",
    subname: "CSIR-NET ",
    Price: "₹1,49,999",
    duration: "6 Months",
  },
  {
    id: 4,
    name: "LIVE 3000 PRACTICE QUESTION:",
    subname: "CSIR-NET ",
    Price: "₹1,49,999",
    duration: "6 Months",
  },
  {
    id: 5,
    name: "LIVE 3000 PRACTICE QUESTION:",
    subname: "CSIR-NET ",
    Price: "₹1,49,999",
    duration: "6 Months",
  },
  {
    id: 6,
    name: "LIVE 3000 PRACTICE QUESTION:",
    subname: "CSIR-NET ",
    Price: "₹1,49,999",
    duration: "6 Months",
  },
  {
    id: 7,
    name: "LIVE 3000 PRACTICE QUESTION:",
    subname: "CSIR-NET ",
    Price: "₹1,49,999",
    duration: "6 Months",
  },
  {
    id: 8,
    name: "LIVE 3000 PRACTICE QUESTION:",
    subname: "CSIR-NET ",
    Price: "₹1,49,999",
    duration: "6 Months",
  },
];

export const leadsExamid = {
  PSAT: 25, // dev 46, QA 89
  SAT: 22, // dev 48,  QA 90
  ACT: 23, // dev 43, QA 91
  AP: 24, // dev 49 QA 92
};

export const qaExamid = {
  PSAT: 46,
  SAT: 48,
  ACT: 43,
  AP: 49,
};

export const preprodExamid = {
  PSAT: 46,
  SAT: 48,
  ACT: 43,
  AP: 49,
};

export const liveExamid = {
  PSAT: 46,
  SAT: 48,
  ACT: 43,
  AP: 49,
};

type notification = {
  id: number;
  name: string;
  badge: number;
};
export const notification: Array<notification> = [
  {
    id: 0,
    name: "Class Schedule",
    badge: 9,
  },
  {
    id: 1,
    name: "Test Series",
    badge: 5,
  },
  {
    id: 2,
    name: "Lecture Series",
    badge: 7,
  },
  {
    id: 3,
    name: "Recorded Course",
    badge: 4,
  },
];
type classshedule = {
  id: number;
  type: number;
  teacher: string;
  subject: string;
  time: any;
};
export const classNotification: Array<classshedule> = [
  {
    id: 0,
    type: 1,
    teacher: "Dr. kailash Choudhary",
    subject: "Cell Biology,",
    time: "10 Min ago",
  },
  {
    id: 1,
    type: 2,
    teacher: "Dr. kailash Choudhary",
    subject: "Cell Biology,",
    time: "10 Min ago",
  },
  {
    id: 2,
    type: 3,
    teacher: "Dr. kailash Choudhary",
    subject: "Cell Biology,",
    time: "10 Min ago",
  },
  {
    id: 2,
    type: 4,
    teacher: "Dr. kailash Choudhary",
    subject: "Cell Biology,",
    time: "10 Min ago",
  },
];

export const menuData = [
  {
    _id: 8,
    title: "Demo Session",
    imgpath: home,
    link: "/demoDashboard",
  },
  // {
  //   _id: 1,
  //   title: "Course",
  //   imgpath: home,
  //   link: "/Course",
  // },
  // {
  //   _id: 5,
  //   title: "Trial Session",
  //   imgpath: learn,
  //   link: "/Learn",
  // },
  {
    _id: 2,
    title: "My Profile",
    imgpath: profile,
    link: "/MYProfile",
  },
  {
    _id: 6,
    title: "Order History",
    imgpath: orderhistory,
    link: "/OrderHistory",
  },
  {
    _id: 7,
    title: "Logout",
    imgpath: logout,
    link: "/",
  },
];
export const menuDataPaid = [
  {
    _id: 1,
    title: "Dashboard",
    imgpath: home,
    link: "/Dashboard",
  },
  {
    _id: 8,
    title: "Demo Session",
    imgpath: home,
    link: "/demoDashboard",
  },
  {
    _id: 2,
    title: "My Learning",
    imgpath: profile,
    link: "/MyLearning",
  },
  // {
  //   _id: 3,
  //   title: "Courses",
  //   imgpath: course,
  //   link: "/Course",
  // },
  // {
  //   _id: 4,
  //   title: "Trial Session",
  //   imgpath: learn,
  //   link: "/Learn",
  // },
  {
    _id: 5,
    title: "My Profile",
    imgpath: profile,
    link: "/MYProfile",
  },
  {
    _id: 6,
    title: "Order History",
    imgpath: orderhistory,
    link: "/OrderHistory",
  },
  {
    _id: 7,
    title: "Logout",
    imgpath: logout,
    link: "/",
  },
];

type lectureSchedule = {
  id: number;
  title: string;
  lectures: number;
  time: any;
  topic: [
    {
      _id: number;
      title: any;
      time: any;
      lectures: any;
      subtopic: [
        {
          _id: number;
          title: any;
          time: any;
          lectures: any;
        },
        {
          _id: number;
          title: any;
          time: any;
          lectures: any;
        }

      ]
    },
    {
      _id: number;
      title: any;
      time: any;
      lectures: any;
      subtopic: [
        {
          _id: number;
          title: any;
          time: any;
          lectures: any;
        },
        {
          _id: number;
          title: any;
          time: any;
          lectures: any;
        }

      ]
    },
    {
      _id: number;
      title: any;
      time: any;
      lectures: any;
      subtopic: [
        {
          _id: number;
          title: any;
          time: any;
          lectures: any;
        },
        {
          _id: number;
          title: any;
          time: any;
          lectures: any;
        }

      ]
    },
    {
      _id: number;
      title: any;
      time: any;
      lectures: any;
      subtopic: [
        {
          _id: number;
          title: any;
          time: any;
          lectures: any;
        },
        {
          _id: number;
          title: any;
          time: any;
          lectures: any;
        }

      ]
    },


  ];
};
export const lectureSchedule: Array<lectureSchedule> = [
  {
    id: 1,
    title: "MOLECULES AND THEIR INTERACTION RELAVENT TO BIOLOGY",
    lectures: 8,
    time: "22h 25m",
    topic: [
      {
        _id: 1,
        title: "Goc",
        time: "2h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "Goc 1",
            time: "02h 25m",
            lectures: 3,
          },
          {
            _id: 2,
            title: "GOC 2",
            time: "01h 25m",
            lectures: 3,
          }

        ]

      },
      {
        _id: 2,
        title: "Stereochemistry",
        time: "1h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "Stereochemistry 1",
            time: "02h 25m",
            lectures: 6,
          },
          {
            _id: 2,
            title: "Stereochemistry 2",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 3,
        title: "Organic Spectroscopy",
        time: "0h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "Organic Spectroscopy 1",
            time: "02h 25m",
            lectures: 6,
          },
          {
            _id: 2,
            title: "Organic Spectroscopy 2",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 4,
        title: "Stereochemistry",
        time: "3h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "Stereochemistry 1",
            time: "02h 25m",
            lectures: 7,
          },
          {
            _id: 2,
            title: "Stereochemistry 2",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      }

    ]
  },
  {
    id: 2,
    title: "CELLULAR ORGANIZATION",
    lectures: 2,
    time: "22h 25m",
    topic: [
      {
        _id: 1,
        title: "CELLULAR ORGANIZATION 1",
        time: "2h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "CELLULAR ORGANIZATION 1",
            time: "02h 25m",
            lectures: 8,
          },
          {
            _id: 2,
            title: "CELLULAR ORGANIZATION 2",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 2,
        title: "CELLULAR ORGANIZATION 2",
        time: "1h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "CELLULAR ORGANIZATION II",
            time: "02h 25m",
            lectures: 6,
          },
          {
            _id: 2,
            title: "CELLULAR ORGANIZATION II",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 3,
        title: "CELLULAR ORGANIZATION 3",
        time: "0h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "CELLULAR ORGANIZATION III",
            time: "02h 25m",
            lectures: 2,
          },
          {
            _id: 2,
            title: "CELLULAR ORGANIZATION III",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 4,
        title: "CELLULAR ORGANIZATION 4",
        time: "3h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "CELLULAR ORGANIZATION IV",
            time: "02h 25m",
            lectures: 1,
          },
          {
            _id: 2,
            title: "CELLULAR ORGANIZATION IV",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      }

    ]
  },
  {
    id: 4,
    title: "FUNDAMENTAL PROCESSES",
    lectures: 5,
    time: "22h 25m",
    topic: [
      {
        _id: 1,
        title: "FUNDAMENTAL PROCESSES 1",
        time: "2h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "FUNDAMENTAL PROCESSES I ",
            time: "02h 25m",
            lectures: 3,
          },
          {
            _id: 2,
            title: "FUNDAMENTAL PROCESSES I ",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 2,
        title: "FUNDAMENTAL PROCESSES 2",
        time: "1h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "FUNDAMENTAL PROCESSES II",
            time: "02h 25m",
            lectures: 5,
          },
          {
            _id: 2,
            title: "FUNDAMENTAL PROCESSES II ",
            time: "01h 25m",
            lectures: 3,
          }

        ]

      },
      {
        _id: 3,
        title: "FUNDAMENTAL PROCESSES 3",
        time: "0h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "FUNDAMENTAL PROCESSES III",
            time: "02h 25m",
            lectures: 2,
          },
          {
            _id: 2,
            title: "FUNDAMENTAL PROCESSES III",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 4,
        title: "FUNDAMENTAL PROCESSES 4",
        time: "3h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "FUNDAMENTAL PROCESSES IV",
            time: "02h 25m",
            lectures: 4,
          },
          {
            _id: 2,
            title: "FUNDAMENTAL PROCESSES IV",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      }

    ]
  },
  {
    id: 5,
    title: "CELL COMMUNICATION AND CELL SIGNALING",
    lectures: 6,
    time: "22h 25m",
    topic: [
      {
        _id: 1,
        title: "CELL COMMUNICATION AND CELL SIGNALING 1",
        time: "2h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "CELL COMMUNICATION AND CELL SIGNALING I ",
            time: "02h 25m",
            lectures:2,
          },
          {
            _id: 2,
            title: "CELL COMMUNICATION AND CELL SIGNALING I ",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 2,
        title: "CELL COMMUNICATION AND CELL SIGNALING 2",
        time: "1h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "CELL COMMUNICATION AND CELL SIGNALING II",
            time: "02h 25m",
            lectures: 1,
          },
          {
            _id: 2,
            title: "CELL COMMUNICATION AND CELL SIGNALING II",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 3,
        title: "CELL COMMUNICATION AND CELL SIGNALING 3",
        time: "0h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "CELL COMMUNICATION AND CELL SIGNALING III",
            time: "02h 25m",
            lectures: 3,
          },
          {
            _id: 2,
            title: "CELL COMMUNICATION AND CELL SIGNALING III",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 4,
        title: "CELL COMMUNICATION AND CELL SIGNALING 4",
        time: "3h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "CELL COMMUNICATION AND CELL SIGNALING IV",
            time: "02h 25m",
            lectures: 4,
          },
          {
            _id: 2,
            title: "CELL COMMUNICATION AND CELL SIGNALING IV",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      }

    ]
  },
  {
    id: 6,
    title: "DEVELOPMENTAL BIOLOGY",
    lectures: 6,
    time: "22h 25m",
    topic: [
      {
        _id: 1,
        title: "DEVELOPMENTAL BIOLOGY 1",
        time: "2h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "DEVELOPMENTAL BIOLOGY I",
            time: "02h 25m",
            lectures: 5,
          },
          {
            _id: 2,
            title: "DEVELOPMENTAL BIOLOGY I",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 2,
        title: "DEVELOPMENTAL BIOLOGY 2",
        time: "1h 25m",
        lectures: 3,
        subtopic: [
          {
            _id: 1,
            title: "DEVELOPMENTAL BIOLOGY II",
            time: "02h 25m",
            lectures: 2,
          },
          {
            _id: 2,
            title: "DEVELOPMENTAL BIOLOGY II",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 3,
        title: "DEVELOPMENTAL BIOLOGY 3",
        time: "0h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "DEVELOPMENTAL BIOLOGY III",
            time: "02h 25m",
            lectures: 1,
          },
          {
            _id: 2,
            title: "DEVELOPMENTAL BIOLOGY III",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      },
      {
        _id: 4,
        title: "DEVELOPMENTAL BIOLOGY 4",
        time: "3h 25m",
        lectures: 1,
        subtopic: [
          {
            _id: 1,
            title: "DEVELOPMENTAL BIOLOGY IV",
            time: "02h 25m",
            lectures: 4,
          },
          {
            _id: 2,
            title: "DEVELOPMENTAL BIOLOGY IV",
            time: "01h 25m",
            lectures: 3,
          }

        ]
      }

    ]
  },
];

export const onlineTest: Array<featureobj> = [
  {
    id: 0,
    name: "Course",
    subname: "",
    imgpath: "",
  },
  {
    id: 1,
    name: "Mock Test",
    subname: "",
    imgpath: "",
  },

];

type testobj = {
  id: number;
  name: string;
  status: string;
  
};

export const test: Array<testobj> = [
  {
    id: 1,
    name: "CSIR Net Life Science",
    status:"Completed",
  },
  {
    id: 2,
    name: "Online Test Series- Mock Test",
    status:"New",
  },
  {
    id:2 ,
    name: "CSIR Net Life Science",
    status:"Resume"
  },
  
];
type gender={
  _id:number,
  Name:string
}
export const GenderList:Array<any>=[
  {
    _id:1,
    Name:"Male"
  },
  {
    _id:2,
    Name:"Female"
  }
]
 
 export const maritalStatus:Array<any>=[
  {
    _id:1,
    name:"Single"
  },
  {
    _id:2,
    name:"Married"
  },
  
]
export const bloodGroupList:Array<any>=[
  {
    _id:1,
    name:"A+"
  },
  {
    _id:2,
    name:"A-"
  },
  {
    _id:3,
    name:"B+"
  },
  {
    _id:4,
    name:"B-"
  },
  {
    _id:5,
    name:"AB+"
  },
  {
    _id:6,
    name:"AB-"
  },
  {
    _id:5,
    name:"O+"
  },
  {
    _id:6,
    name:"O-"
  },
]

