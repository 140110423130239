import React, { useEffect, useState } from 'react';
import '../App.css';
import '../Assets/Css/policy.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LocalImages from '../Constant/localImages';

function PrivacyPolicy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gotohome=()=>{
    window.location.href = '../'
  }
  return (
    <div>
      <header className='privacyheader d-flex align-items-center bgwhite'>
        <div className=' '>
          <img src={LocalImages.logo} alt=" Lurnigo Logo" className='policylogo me-2' onClick={gotohome}/>
        </div>
        <h3 className='text-dark'>Privacy Policy</h3>
      </header>

      <div className='col-md-12 instcontainer privacy  '>
        <div className='d-flex justify-content-center'>
        <div className='m-5 p-3 insttxtDiv bgwhite'>
          <p>LurniGo and our affiliated companies (“LurniGo” or “we”) are dedicated to safeguarding the privacy of
            all individuals who make use of our site or services. This document provides an understanding of how
            we collect, use, and protect the information we may gather via this website (the “Site”). We may revise
            this statement periodically, so please regularly revisit this page to stay fully updated.</p>
          <p className='fw-bold insttext'>Information We Collect</p>
          <p className='fw-bold insttext'>a. Personally Identifiable Information (PII)</p>
          <p className='fw-bold mb-0'>Children:</p>
          <p>In line with the Children’s Online Privacy Protection Act U.S.C. §§6501-06 and 16 C.F.R. §§ 312.1-312.12,
            LurniGo does not register children under the age of 13 without the explicit consent of a parent or
            guardian. Usage of our platform affirms that you are either above 13 or are registered under our
            Advanced Learner Program, where your guardian provides their details on your behalf.</p>
          <p className='fw-bold mb-0'>General Site Visitors:</p>
          <p>Visitors can browse nonrestricted areas of the website anonymously without divulging any PII.</p>
          <p className='fw-bold mb-0'>General Subscribers:</p>
          <p>Registering as a general subscriber requires sharing certain details like name, nationality, contact
            information, academic data, and a preferred username and password. Registration may result in
            promotional communications from our partners.</p>
          <p className='fw-bold mb-0'>Paying Subscribers:</p>
          <p>Access to premium content or services demands detailed personal, academic, and payment information.
            Financial aid applicants must additionally share specific family financial data.</p>
          <p className='fw-bold mb-0'>Newsletter Subscribers:</p>
          <p>
            Our newsletter is available to all users. Nonsubscribers can also opt-in by providing basic personal
            details
          </p>
          <p className='fw-bold mb-0'>Contacting Us:</p>
          <p>The contact form captures essential communication data, including name, email, contact number, and
            message content.</p>
          <p className='fw-bold insttext'>b. Technical and Ancillary Data</p>
          <p>
            Our system automatically logs user IP addresses and onsite behavior. We employ 'cookies' to enrich the
            user experience by recognizing and catering to user preferences. Users have the option to disable
            cookies via browser settings, potentially affecting some site functionalities.
          </p>
          <p className='fw-bold '>Purpose and Method of Data Collection</p>
          <p ><span className='fw-bold mb-0'>Collection Channels: </span>Personal data is primarily sourced when users contact us or opt for our services.</p>
          <p className='fw-bold mb-0'>Utilization:</p>
          <p>Acquired information aids LurniGo in numerous capacities, including service provision, user
experience enhancement, correspondence, promotional undertakings, legal compliance, and user
accommodation.</p>
          <p className='fw-bold mb-0'>Information Disclosure</p>
          <p>
          Your data remains confidential. LurniGo never sells or rents personal data. Promotions or offers from
our partners may be conveyed to you. Default on payments may necessitate sharing your details with
collection agencies. Certain legal mandates might necessitate data disclosure.
          </p>
          <p className='fw-bold mb-0'>Third party Information Use</p>
          <p>
          LurniGo offers features like referrals and performance monitoring by designated individuals. Using these
functions necessitates sharing these third parties' contact details.
          </p>
          <p className='fw-bold mb-0'>Data Accuracy and Modification</p>
          <p>
          Users can access and modify their data to ensure it remains accurate and current.
          </p>
          <p className='fw-bold mb-0'>Communication Preferences</p>
          <p>
          Users retain the discretion to opt out of specific communications or our newsletter by adjusting their
profile settings.
          </p>
          <p className='fw-bold mb-0'>Data Sharing Limitations</p>
          <p>
          Nondisclosure of specific personal data might limit access to LurniGo's comprehensive service offerings.
          </p>

          <p className='fw-bold mb-0'>Data Security</p>
          <p>
          For users affiliated with institutional programs, pertinent account information might be shared with the
respective institution. Users are responsible for maintaining password confidentiality. Although no
digital transmission is foolproof, LurniGo employs stringent security protocols.
          </p>
          <p className='fw-bold mb-0'>Business Transitions</p>
          <p>
          In scenarios like mergers or asset transfers, user data might be among the shared or transferred assets.
          </p>
          <p className='fw-bold mb-0'>Consent and Acceptance</p>
          <p>
          Interacting with and providing data to our platform implies your acceptance of this Privacy Policy.
          </p>
          <p className='fw-bold mb-0'>Policy Effective Date and Amendments</p>
          <p>
          This Privacy Policy becomes effective on May 1st, 2023. LurniGo reserves the right to modify this policy.
Continued engagement with our platform post amendments indicates your acceptance of any changes
made.
          </p>
          <p>Users are encouraged to review this policy periodically for any changes.</p>
        </div></div>
      </div>




    </div>
  );
}

export default PrivacyPolicy;
