import React from 'react'; 
import home from '../Assets/Images/sidebardata/HomeWorkIcon.webp';
import course from '../Assets/Images/sidebardata/courseIcon.webp';
import learn from '../Assets/Images/sidebardata/MyLearningIcon.webp';
import profile from '../Assets/Images/sidebardata/MyLearningIcon.webp'; 
import orderhistory from '../Assets/Images/sidebardata/OrderHistoryIcon.webp'; 
import trialSession from '../Assets/Images/sidebardata/ClassScheduleIcon.webp'; 
import trialComplete from '../Assets/Images/sidebardata/DemoSessionIcon.webp'; 
import bell from '../Assets/Images/sidebardata/NotificationIcon.webp'; 
export const SidebarDataFreeUser = [
  {
    title: 'Demo Session',
    link: 'demoDashboard',
    extra: ['demoDashboard'],
    imgpath: trialComplete
  },
    {
      title: 'Course',
      link: 'Course',
      extra: ['Course'],
      imgpath: course
    },
    {
      title: 'My Profile',
      link: 'myProfile',
      extra: ['myProfile', 'MyProfile'],
      imgpath: profile
    },    
    {
      title: 'Order History',
      link: 'OrderHistory',
      extra: ['OrderHistory', 'OrderDetails'],
      imgpath: orderhistory
    },
    {
      title: 'Notification',
      link: 'Notification',
      extra: [' ', ' '],
      imgpath: bell
    },
    
  ];

  export const SidebarDataPaidUser = [
    {
      title: 'Dashboard',
      link: 'Dashboard',
      extra: ['Dashboard'],
      imgpath: home
    },
    {
      title: 'Demo Session',
      link: 'demoDashboard',
      extra: ['demoDashboard'],
      imgpath: trialComplete
    },
    {
      title: 'My Learning',
      link: 'MyLearning',
      extra: ['MyLearning',],
      imgpath: learn
    },
    {
      title: 'Class Schedule',
      link: 'ClassSchedule',
      extra: ['ClassSchedule'],
      imgpath: trialSession
    },
    {
      title: 'Course',
      link: 'Course',
      extra: ['Course'],
      imgpath: course
    },
    {
      title: 'My Profile',
      link: 'myProfile',
      extra: ['myProfile', 'MyProfile'],
      imgpath: profile
    },    
    {
      title: 'Order History',
      link: 'OrderHistory',
      extra: ['OrderHistory', 'OrderDetails'],
      imgpath: orderhistory
    },
    {
      title: 'Installment',
      link: 'Installment',
      extra: ['Installment'],
      imgpath: orderhistory
    },
    {
      title: 'Notification',
      link: 'Notification',
      extra: [' ', ' '],
      imgpath: bell
    },
    
  ];
