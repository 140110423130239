import { useEffect, useState } from "react";
import "../App.css";
import "../Assets/Css/placeorder.css";
import CartCard from "../Module/cartCard";
import BuyNowCard from "../Module/buyNowCard";
import {
  applyCoupon,
  deleteCart,
  getCartList,
  getExtendedCartList,
  paymentRequest,
  placeOrder,
} from "../Redux/Actions/examActions";
import SessionPopup from "../Module/sessionPopup";
import localImages from "../Constant/localImagesNew";
import HeaderHome from "../Component/headerHome";
import DeleteIcon from "@mui/icons-material/Delete";
import { getCountry } from "../Redux/Actions/AuthAction";
import { useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
function PlaceOrder() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isExtended, lectureCount, price ,isAdditionalPackage} = location?.state;
  const [country, setCountry] = useState("");
  const [cartList, setCartList] = useState<any>([]);
  const [totalPrice, settotalPrice] = useState("");
  const [sessionData, setsessionData] = useState<any>();
  const [openSession, setopenSession] = useState<boolean>(false);
  const [SelectedSession, setSelectedSession] = useState<string | number>();
  const [sellingPrice, setSellingPrice] = useState("");
  const [couponName, setCouponName] = useState<any>("");
  const [offerPrice, setOfferprice] = useState("");
  const [isCouponApplied, setisCouponApplied] = useState<boolean>(false);
  const [showRemoveCoupon, setShowRemoveCoupon] = useState<boolean>(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [installmentType, setInstallmentType] = useState("1");
  const [installmentData, setinstallmentData] = useState<any>("");
  const [cartData, setCartData] = useState<any>("");

  const { userCountryCurrency, userCountryCallingCode } = useSelector(
    (state: any) => ({
      userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
      userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode,
    })
  );
  useEffect(() => {
    dispatch(
      getCountry(
        (data: any) => {
          const filterCountry = data?.filter(
            (item: any) =>
              item?.phonecode == userCountryCallingCode?.substring(1)
          );
          setCountry(filterCountry[0]?._id);
        },
        () => {}
      )
    );
  }, [userCountryCallingCode]);

  useEffect(() => {
    if (installmentType) {
      console.log("installmentType", installmentType);
      // if (installmentType === '2' || installmentType === '3') {
      getCart();
      // }
    }
  }, [installmentType]);
  console.log("isExtended", location.state);
  const PlaceOrder = (amt: any) => {
    const postData: any = {};
    if (
      isCouponApplied &&
      (installmentType === "2" || installmentType === "3")
    ) {
      postData["couponName"] = couponName;
      postData["additionalAmount"] = cartData?.additionalAmount;
      postData["installmentOptions"] = installmentData;
    } else if (isCouponApplied) {
      postData["couponName"] = couponName;
      postData["sellingPrice"] = sellingPrice;
      postData["offerPrice"] = parseFloat(totalPrice).toFixed(2);
    } else if (installmentType === "2" || installmentType === "3") {
      postData["additionalAmount"] = cartData?.additionalAmount;
      postData["installmentOptions"] = installmentData;
    }
    if (isExtended) {
      postData["extendPackageOption"] = true;
      postData["offerPrice"] = parseFloat(totalPrice).toFixed(2);
    }
    if(isAdditionalPackage){
      postData["customPackage"]=true;
      postData["offerPrice"]=parseFloat(totalPrice).toFixed(2);
    }
    console.log("postData", postData);
    dispatch(
      placeOrder(
        postData,
        (res: any) => {
          const dataRequest: any = {
            orderNo: res?.orderNo,
            studentId: res?.studentId,
            currency:
              userCountryCurrency === "INR" ? "USD" : userCountryCurrency,
            countryId: country,
          };
          if (isExtended) {
            dataRequest["extendPackageOption"] = true;
            dataRequest["offerLectureCount"] = lectureCount;
          }
          if (installmentType === "2" || installmentType === "3") {
            dataRequest["installment"] = "firstInstallment";
          }

          dispatch(
            paymentRequest(
              dataRequest,
              (data: any) => {
                window.location.href = data?.data?.redirect_url;
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  useEffect(() => {
    if (country) {
      getCart();
    }
  }, [country]);
  useEffect(() => {
    // if(isCouponApplied){
    getCart();
    // }
  }, [isCouponApplied]);

  function getCart() {
    if (isExtended) {
      const postData = {
        extendPackageOption: true,
        offerLectureCount: lectureCount,
        offerPrice: price,
        sellingPrice: price,
      };
      dispatch(
        getExtendedCartList(
          postData,
          (res: any) => {
            console.log("getExtendedCartList", res);
            if (res?.cart?.length === 0) {
              navigate('/MyCart',{state:{isAdditionalPackage:false}});
            }
            setCartList(res?.cart);
            settotalPrice(res?.netAmount);
            setSellingPrice(res?.totalsellingPrice);
            setOfferprice(res?.netAmount);
          },
          () => {}
        )
      );
    } else {
      const postData: any = { countryId: country };
      if(isAdditionalPackage){
        postData["customPackage"]=true;
      }
      if (isCouponApplied) {
        postData["couponName"] = couponName?.toUpperCase();
        postData["isCouponApplied"] = true;
      }
      if (installmentType == "2" || installmentType === "3") {
        // postData["countryId"] = country;
        postData["ratioType"] = parseInt(installmentType);
        postData["installmentOptions"] = true;
      }
      dispatch(
        getCartList(
          postData,
          (res: any) => {
            console.log("getCartList", res);
            if (res?.cart?.length === 0) {
              navigate('/MyCart',{state:{isAdditionalPackage:false}});
            }
            setCartList(res?.cart);
            settotalPrice(res?.fullAmount);
            setSellingPrice(res?.totalsellingPrice);
            setOfferprice(res?.netAmount);
            setinstallmentData(res?.installmentOptions);
            setCartData(res);
          },
          () => {}
        )
      );
    }
  }
  const removeCart = (id: any) => {
    dispatch(
      deleteCart(
        id,
        (res: any) => {
          getCart();
        },
        () => {}
      )
    );
  };

  function openSessionPopup(val: any) {
    if (val?.sessions && val?.sessions?.length != 0) {
      setsessionData(val?.sessions);
      setopenSession(true);
    } else {
      addToCart();
    }
  }

  const addToCart = () => {};

  const closeSession = () => {
    setopenSession(false);
    setSelectedSession("");
    setsessionData([]);
  };
  const handleChange = (e: any) => {
    setSelectedSession(e.target.value);
  };
  const onchangeCouponName = (value: any) => {
    setCouponName(value);
  };

  const handleClick = () => {
    dispatch(
      applyCoupon(
        {
          offerPrice: totalPrice,
          sellingPrice: sellingPrice,
          couponName: couponName?.toUpperCase(),
        },
        (res: any) => {
          setisCouponApplied(true);
          settotalPrice(res?.offerPrice);
          setShowRemoveCoupon(true);
          setSuccessText("Coupon Applied Successfully");
        },
        () => {
          setShowRemoveCoupon(true);
          setErrorText("is invalid ! Please use valid code");
        }
      )
    );
  };
  const onChangeRemoveCoupon = () => {
    setCouponName("");
    setErrorText("");
    setSuccessText("");
    setShowRemoveCoupon(false);
    settotalPrice(offerPrice);
    setisCouponApplied(false);
  };
  console.log("cartData", cartData);
  return (
    <div>
      <HeaderHome isHidden={false} />
      <div className="ContainerDiv pt-2 d-flex row">
        <div className="col-md-7 ">
          <div className="boxshadow rounded h-100 cartDetailsBox p-3">
            <h4 className="fw-bold boldfont mb-2">Cart Details</h4>
            <div className="">
              <div className="mb-3 p-1">
                {cartList?.map((item: any, index: any) => (
                  <CartCard
                    data={item}
                    removeCart={removeCart}
                    openSession={openSessionPopup}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="placeorderBox  boxshadow rounded p-3">
            <h4 className="fw-bold boldfont mb-2 colorOrange">
              Payment Details
            </h4>
            <div className="">
              <div className="mb-3 placeOrderContentHeight p-1">
                <div
                  className={`rounded p-2 font12 mb-3 installementCard ${
                    installmentType === "1" ? "active" : ""
                  }`}
                >
                  <div
                    className={`fw-bold mb-2 d-flex ${
                      installmentType === "1" ? "colorOrange" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      className="me-2"
                      name="installment"
                      onChange={() => setInstallmentType("1")}
                      checked={installmentType === "1" ? true : false}
                    />{" "}
                    Full Payment
                    {cartData?.isApplicableForInstallment ? (
                      <div className="colorgreen fw-bold ms-3">
                        ( Saved Extra{" "}
                        {getSymbolFromCurrency(userCountryCurrency)}
                        {cartData?.additionalAmount} ){" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="ms-4">
                    <div className="mb-1 fw-bold">
                      Total Payable Amount:{" "}
                      <span className="colorgreen fw-bold">
                        {getSymbolFromCurrency(userCountryCurrency)}
                        {totalPrice ? parseFloat(totalPrice).toFixed(2) : 0}
                      </span>
                    </div>

                    <div>Including all Taxes</div>
                  </div>
                </div>
                {cartData?.isApplicableForInstallment ? (
                  <div
                    className={`boxshadow rounded p-2 py-3 font12 mb-3 installementCard ${
                      installmentType === "2" ? "active" : ""
                    }`}
                  >
                    <div
                      className={`fw-bold ${
                        installmentType === "2" ? "colorOrange" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className="me-2"
                        name="installment"
                        onChange={() => setInstallmentType("2")}
                        checked={installmentType === "2" ? true : false}
                      />{" "}
                      Pay fee in 2 EMI
                    </div>

                    {installmentType === "2" ? (
                      <div className="mt-2 ms-4">
                        <p className="mb-1 fw-bold mt-1">
                          Total Payable Amount:{" "}
                          <span className="successcolor fw-bold ms-2">
                            {getSymbolFromCurrency(userCountryCurrency)}
                            {cartData?.netAmount
                              ? parseFloat(cartData?.netAmount).toFixed(2)
                              : 0}
                          </span>
                        </p>
                        <div className="px-2 py-2 rounded w-50 borderBlue rounded border">
                          <p className="mb-1 fw-bold">
                            1st Installment{" "}
                            <span className="successcolor fw-bold ms-2">
                              {getSymbolFromCurrency(userCountryCurrency)}{" "}
                              {installmentData?.installment1
                                ? installmentData?.installment1
                                    ?.firstInstallment
                                : 0}
                            </span>
                          </p>
                          <p className="mb-0 font10">Pay by Today</p>
                        </div>
                        <div className="px-2 py-2 rounded border mt-2 w-50">
                          <p className="mb-1 fw-bold">
                            2nd Installment{" "}
                            <span className="successcolor fw-bold ms-2">
                              {getSymbolFromCurrency(userCountryCurrency)}{" "}
                              {installmentData?.installment2
                                ? installmentData?.installment2
                                    ?.secondInstallment
                                : 0}
                            </span>
                          </p>
                          {/* <p className="mb-0 font10">Pay installment by {formatted}</p> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {cartData?.isApplicableForInstallment ? (
                  <div
                    className={`boxshadow rounded p-2 py-3 font12 mb-3 installementCard ${
                      installmentType === "3" ? "active" : ""
                    }`}
                  >
                    <div
                      className={`fw-bold ${
                        installmentType === "3" ? "colorOrange" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className="me-2"
                        name="installment"
                        onChange={() => setInstallmentType("3")}
                        checked={installmentType === "3" ? true : false}
                      />{" "}
                      Pay fee in 3 EMI
                    </div>
                    {installmentType === "3" ? (
                      <div className="my-2 ms-4">
                        <p className="mb-1 fw-bold mt-1">
                          Total Payable Amount:{" "}
                          <span className="successcolor fw-bold ms-2">
                            {getSymbolFromCurrency(userCountryCurrency)}
                            {cartData?.netAmount
                              ? parseFloat(cartData?.netAmount).toFixed(2)
                              : 0}
                          </span>
                        </p>
                        <div className="px-2 py-2 w-50 borderBlue  rounded border">
                          <p className="mb-1 fw-bold">
                            1st Installment{" "}
                            <span className="successcolor fw-bold ms-2">
                              {getSymbolFromCurrency(userCountryCurrency)}{" "}
                              {installmentData?.installment1
                                ? installmentData?.installment1
                                    ?.firstInstallment
                                : 0}
                            </span>
                          </p>
                          <p className="mb-0 font10">Pay by Today</p>
                        </div>
                        <div className="px-2 py-2 border mt-2 w-50 rounded">
                          <p className="mb-1 fw-bold">
                            2nd Installment{" "}
                            <span className="successcolor fw-bold ms-2">
                              {getSymbolFromCurrency(userCountryCurrency)}{" "}
                              {installmentData?.installment2
                                ? installmentData?.installment2
                                    ?.secondInstallment
                                : 0}
                            </span>
                          </p>
                          {/* <p className="mb-0 font10">Pay installment by {formatted}</p> */}
                        </div>
                        <div className="px-2 py-2 border mt-2 w-50 rounded">
                          <p className="mb-1 fw-bold">
                            3rd Installment{" "}
                            <span className="successcolor fw-bold ms-2">
                              {getSymbolFromCurrency(userCountryCurrency)}{" "}
                              {installmentData?.installment3
                                ? installmentData?.installment3
                                    ?.thirdInstallment
                                : 0}
                            </span>
                          </p>
                          {/* <p className="mb-0 font10">Pay installment by {formatted}</p> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              {isExtended ? null : (
                <div>
                  <div className="buyNowCarddiv mb-3 ms-2 ">
                    <div className="d-flex ">
                      <div className="position-relative">
                        <img
                          src={localImages?.offerimg}
                          className="offerimg position-absolute ms-1"
                          alt="offerimg"
                        />
                        <input
                          className="border-0 border-bottom border-dark couponinput colorblue fw-bold"
                          type="text"
                          placeholder="Have a Coupon Code?"
                          value={couponName}
                          maxLength={20}
                          //  disabled={showRemoveCoupon}
                          style={{ width: "233px" }}
                          onChange={(value: any) =>
                            onchangeCouponName(value?.target.value)
                          }
                        />
                      </div>
                      <div className="text-center ps-4">
                        {couponName?.length > 0 && !showRemoveCoupon ? (
                          <span
                            className="colorgreen position-absolute fw-bold fs-6 mt-1 cursor"
                            onClick={handleClick}
                          >
                            Apply Code
                          </span>
                        ) : (
                          ""
                        )}

                        {couponName?.length > 0 && showRemoveCoupon ? (
                          <span
                            className="textRed fs-6 fw-bold  position-absolute mt-1 cursor"
                            onClick={onChangeRemoveCoupon}
                          >
                            <DeleteIcon />
                            Remove
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div>
                      {successText && showRemoveCoupon ? (
                        <span className="colorgreen  mt-1  font11 ">
                          {successText}
                        </span>
                      ) : (
                        ""
                      )}
                      {errorText && showRemoveCoupon ? (
                        <span className="textRed mt-1  font12">
                          {" "}
                          {couponName} {errorText}!
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
              <BuyNowCard
                btnname="Place Order"
                setOnClick={PlaceOrder}
                amount={
                  installmentType == "2" || installmentType === "3"
                    ? installmentData?.installment1?.firstInstallment
                    : totalPrice
                }
                count={cartList?.length}
              />
              {/* {checkout ? <PayPal total={500} open={checkout} /> : null} */}
            </div>
          </div>
        </div>
      </div>
      <SessionPopup
        open={openSession}
        handleClose={closeSession}
        sessionData={sessionData}
        handleChange={handleChange}
        addToCart={addToCart}
        SelectedSession={SelectedSession}
      />
    </div>
  );
}

export default PlaceOrder;
