import React, { useState } from "react";
import "../Assets/Css/AccordianTemplate.css";
import parser from "html-react-parser";
import { parseHtmlWithTargetBlank } from "../Utils/utils";

export default function AccordianTemplate(props: any) {
  return (
    <div className="accordion" id="accordionExample">
      {JSON.parse(props?.data?.desc)?.map((card:any, index:any) => (
        <div className="accordion-item mb-2 rounded">
          <h2 className="accordion-header" id={"heading" + index}>
            <button
              className="accordion-button collapsed rounded"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#collapse" + index}
              aria-expanded="false"
              aria-controls={"collapse" + index}
            >
              {card?.Heading}
            </button>
          </h2>
          <div
            id={"collapse" + index}
            className="accordion-collapse collapse"
            aria-labelledby={"heading" + index}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body font16 overflow-auto">
              {card?.img?
              <div>
                {card?.imgPos===1?
                <div className="row mx-0">
                  <div className="col-12 col-md-4">
                    {
                      <img src={card?.img} alt={card?.Heading} className="w-100"/>
                    }
                  </div>
                  <div className="col-12 col-md-8">
                    {parser(parseHtmlWithTargetBlank(card?.Text))}
                  </div>
                </div>:
                card?.imgPos===2?
                <div className="row mx-0">               
                <div className="col-12 col-md-8">
                  {parser(parseHtmlWithTargetBlank(card?.Text))}
                </div>
                <div className="col-12 col-md-4">
                  {
                    <img src={card?.img} alt={card?.Heading} className="w-100"/>
                  }
                </div>
              </div>:
                <div className="row mx-0">
                  <div className="col-12 text-center">
                  {
                    <img src={card?.img} alt={card?.Heading} className="centerImgAccordion"/>
                  }
                </div>               
                <div className="col-12">
                  {parser(parseHtmlWithTargetBlank(card?.Text))}
                </div>
                
              </div>}
              </div>
            :parser(parseHtmlWithTargetBlank(card?.Text))}
              
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
