import React, { useEffect, useState } from 'react';
import '../Assets/Css/textLeftImageWithStrip.css';
function TextRightImageWithStrip(props:any) { 
   
  return (
    <div className="row textImgMargin justify-content-end">
      <div className="col-md-5 ps-md-5 d-flex flex-column justify-content-center mt-4 mt-md-0 order-2 order-md-1">
        <h1 className='headingFont mb-3'>{props?.data?.title}</h1>
        <div className='font16'>{props?.data?.desc}</div>
      </div>
      <div className="col-md-6 position-relative px-0 text-end order-1 order-md-2">
        <img src={props?.data?.imgPath} alt={props?.data?.title} className='leftImgText'/>
        <div className='row align-items-baseline bottomStripleft'>
          <div className='col-7 orangeStrip'></div>
          <div className='col-5 blueStrip'></div>
        </div>
      </div>
      
    </div>
  );
}

export default TextRightImageWithStrip;
