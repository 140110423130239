import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { useState } from 'react';

interface Props {
  lableName:any
  setInputText:any
  options:any
  defaultValue?:any
  Isrequired?:any
  reset?:any
}

function CustomDropdown(props: Props) {
  const [names, setName] = useState(props.lableName);
  const [tempName, setTempName] = useState(props.lableName);

  React.useEffect(() => {
    var newArray;
    if (props.defaultValue != undefined) {
      newArray = props.options.filter(function (el:any) {
        return el._id === props.defaultValue;
      });
    }
    newArray?.map((item:any) => {
      setName(item.name);
      props.setInputText(item);
    });
  }, [props.defaultValue]);

  React.useEffect(() => {
    if(props.reset != null){
      setName(tempName);
    }
  }, [props.reset]);

  const handleChange = (e:any) => {
    setName(e.target.value.name);
    props.setInputText(e.target.value);
  };

  return (
    <div className="d-flex">
      <Select
        className="custom-Dropdown"
        defaultValue={names}
        value={names}
        label="name"
        onChange={handleChange}
        required={props.Isrequired}>
        <MenuItem disabled value={names}>
          {names}
        </MenuItem>
        {props.options !== undefined
          ? props.options?.map((item:any) => (
              <MenuItem key={item?._id} value={item}>
                {item?.name}
              </MenuItem>
            ))
          : null}
      </Select>
      {props.Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}

export default CustomDropdown;
