
import '../App.css';
import { Routes,Route } from 'react-router-dom';
import SuccessSnackbar from '../Component/successSnackbar';
import ErrorSnackbar from '../Component/errorSnackbar'; 
import MyProfile from './myProfile';
import { SidebarDataFreeUser,SidebarDataPaidUser } from '../Module/sidebarData';
import Sidebar from '../Module/sidebar';
import Header from '../Component/header';
import OnlineCourse from './onlineCourse'; 
import FreeDashboard from './freeDashboard';
import WIP from './wip';
import Learn from './learn';
import LectureSchedule from './lectureSchedule';
import DemoLecture from './demoLecture';
import OrderHistory from './orderHistory';
import OrderDetails from './orderDetails';
import MyLearning from './myLearning';
import { useSelector } from 'react-redux';
import Dashboard from './dashboard';
import Lecture from './lecture';
import Topics from './topics';
import OnlineTest from './onlineTest';
import ScholershipTest from './scholershipTest';
import DemoDashboard from './demoDashboard';
import HeaderHome from '../Component/headerHome';
import ClassSchedule from './classSchedule';
import Notification from './notification';
function Parent() {
  const {ACCESS_TOKEN,hasActivePaidPlan} = useSelector((state:any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
  }));
  return (
    <div>
      {/* <Header  isHidden={false} /> */}
      <HeaderHome isHidden={false} />
    <div className="d-flex mainContainer">  
      <SuccessSnackbar />
      <ErrorSnackbar /> 
      <Sidebar data={hasActivePaidPlan===true?SidebarDataPaidUser:SidebarDataFreeUser}/>   
      <Routes>
      
      <Route path="/Dashboard" element={<Dashboard/>}/>
      <Route path="/demoDashboard" element={<DemoDashboard/>}/>
        <Route path="/Course" element={<FreeDashboard/>}/>
        <Route path="/MyProfile" element={<MyProfile/>}/>
        <Route path="/OnlineCourse" element={<OnlineCourse/>}/> 
        {/* <Route path="/Learn" element={<Learn/>}/>   */}
        <Route path="/MyLearning" element={<MyLearning/>}/> 
        <Route path="/LectureSchedule" element={<LectureSchedule/>}/>   
        <Route path="/demoLecture" element={<DemoLecture/>}/> 
        <Route path="/OrderHistory" element={<OrderHistory/>}/> 
        <Route path="/OrderDetails" element={<OrderDetails/>}/> 
        <Route path="/Lecture" element={<Lecture/>}/>
        <Route path="/Topics" element={<Topics/>}/>
        <Route path="/OnlineTest" element={<OnlineTest/>}/>
        <Route path="/ScholershipTest" element={<ScholershipTest/>}/>
        <Route path="/ClassSchedule" element={<ClassSchedule/>}/>
        <Route path="/Notification" element={<Notification/>}/>
        
        <Route path="*" element={<WIP />} />    
      </Routes>
    </div>
    </div>
  );
}

export default Parent;
