import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
function StudentOverallRating(props: any) {
  function valuetext(value: number) {
    return `${value}`;
  }
  function valueLabelFormat(value: number) {
    return `${props?.marks[value]?.labelText}`;
  }
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      props?.setValue(newValue);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiSlider-thumb": {
          color: "#F15D22",
        },
        "& .MuiSlider-track": {
          color: "#F15D22",
        },
        "& .MuiSlider-rail": {
          color: "#F15D22",
        },
        "& .MuiSlider-active": {
          color: "#F15D22",
        },
      }}
    >
      <Slider
        aria-label="Custom marks"
        defaultValue={0}
        getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        marks={props?.marks}
        max={5}
        color="secondary"
        onChange={handleChange}
        value={props?.val}
        disabled={props?.disabled}
      />
    </Box>
  );
}

export default StudentOverallRating;
