import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface props {
    title?: string;
    description?: string;
    canonical?: string;
    name?: string;
    image?:string;
    noScript?:string
  }

const MetaDataFile = ({ title, description, canonical, name, image, noScript }:props) => {
  const { pathname } = useLocation();
  const canonicalLocal = canonical ? canonical : 'https://lurnigo.com' + pathname;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title ? title : ''} />
      <meta name="description" content={description ? description : ''} />
      <link rel="canonical" href={canonicalLocal} />
      <meta property="og:title" content={title ? title : ''} />
      <meta property="og:description" content={description ? description : ''} />
      <meta name="twitter:creator" content={name ? name : ''} />
      <meta name="twitter:title" content={title ? title : ''} />
      <meta name="twitter:description" content={description ? description : ''} />
      <meta property="og:locale" content={'en_US'} />
      <meta property="og:type" content={'website'} />
      <meta property="og:image" content={image} />
      <meta name="og:url" content={canonicalLocal} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@ifasEdutech" />
      <meta name="twitter:image:src" content={image} />
      <noscript>{`<img height="1" width="1" style="display:none" src="${noScript}" />`}</noscript>
    </Helmet>
  );
};
export default MetaDataFile;
