// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alumniCard{
    background: #ffffff;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.alumniCard:hover{
   background: #E8E8E8;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/alumniCard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;GACG,mBAAmB;AACtB","sourcesContent":[".alumniCard{\n    background: #ffffff;\n    border-radius: 20px;\n    -webkit-border-radius: 20px;\n    -moz-border-radius: 20px;\n    -ms-border-radius: 20px;\n    -o-border-radius: 20px;\n}\n.alumniCard:hover{\n   background: #E8E8E8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
