import "../App.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getDemoSessionData,
  getPackageList,
} from "../Redux/Actions/examActions";
import { useSelector } from "react-redux";
import "../Assets/Css/demoDashboard.css";
import localImages from "../Constant/localImagesNew";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CountDownTimer from "../Module/countDownTimer";
import UserFeedbackPopup from "../Module/userFeedbackPopup";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Lottie from "react-lottie";
import waiting from "../Assets/Images/json/waiting.json";
import {
  getCourseExam,
  getGeneralExamList,
  getSubjectList,
  gradeLevelList,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import DropdownNew from "../Component/dropdownNew";
import SubscriptionPlan from "../Module/subscriptionPlan";
import funFactOpen from "../Assets/Images/UserLecture/funFactOpen.png";
function DemoDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [demoData, setdemoData] = useState<any>("");
  const [demoList, setdemoList] = useState<any>([]);
  const [demoVal, setdemoVal] = useState("");
  const [targetDate, setTargetDate] = useState("");
  const [istimeExpire, setIstimeExpire] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [noVideo, setNoVideo] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [packageData, setpackageData] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: waiting,
    renderer: "svg",
  };
  const { ACCESS_TOKEN, mobileNo, courseType, examTypeId } = useSelector(
    (state: any) => ({
      ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
      mobileNo: state?.AuthReducer?.mobileNo,
      courseType: state?.AuthReducer?.courseType,
      examTypeId: state?.AuthReducer?.examTypeId,
    })
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getCourseExam(
        (data: any) => {
          setCourseList(data);
        },
        () => {}
      )
    );
    dispatch(
      gradeLevelList(
        (data: any) => {
          setGradeList(data);
        },
        () => {}
      )
    );
  }, []);
  const [examList, setExamList] = useState([]);
  const [exam, setExam] = useState<any>(examTypeId);
  const [grade, setGrade] = useState<any>("");
  const [course, setCourse] = useState<any>("" + courseType);
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState("");

  useEffect(() => {
    dispatch(
      getDemoSessionData(
        { mobile: mobileNo },
        (res: any) => {
          if (res?.length > 0) {
            setdemoList(res);
            setdemoData(res[0]);
            setTargetDate(res[0]?.date?.slice(0, 11) + "" + res[0]?.time);
            setCourse(res[0]?.courseTypeId);
            if (res[0]?.courseTypeId?.type === 2) {
              setGrade(res[0]?.gradeId);
            }
            if (res[0]?.courseTypeId?.type === 1) {
              dispatch(
                getGeneralExamList(
                  {
                    courseId: res[0]?.courseTypeId?._id,
                  },
                  (data: any) => {
                    setExamList(data?.data);
                    setExam(res[0]?.examtypeId);
                  },
                  () => {}
                )
              );

              setExam(res[0]?.examtypeId);
            }
            setSubject(res[0]?.subjectId);
          }
        },
        () => {}
      )
    );
  }, [mobileNo]);
  useEffect(() => {
    if (demoData?.statusId === 5) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [demoData?.statusId]);
  useEffect(() => {
    if (course?.type === 3) {
      const filterCourse: any = courseList?.filter(
        (item: any) => item?._id === parseInt(course?._id)
      );
      setExamList(filterCourse[0]?.examList);
      setExam(filterCourse[0]?.examList[0]?._id);
    }
  }, [course, courseList]);

  function goToLink(val: string): void {
    if (val === "Feedback") {
      navigate("/" + val, {
        state: { demoData: demoData },
      });
    } else if (val === "BookSession") {
      navigate("/" + val, {
        state: {
          mobNo: "",
          coutryCodeNo: "",
          isOTPVerify: ACCESS_TOKEN ? true : false,
        },
      });
    } else {
      navigate("/" + val);
    }
  }
  const joinLive = () => {
    window.open(
      demoData?.attendeeUrl,
      "_blank",
      "width=" +
        window.screen?.availWidth +
        ",height=" +
        window.screen?.availHeight
    );
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const target=demoData?.date?.slice(0,11) + ''+ demoData?.time;
  // const target="2024-04-26T12:25:00"
  // console.log('target', target);
  function gotoCourseDetail(data: any) {
    if (data?.linkStatus === 1) {
      setNoVideo(true);
    } else {
      navigate("/VideoPlayer", {
        state: { lectureId: "", recordedData: data },
      });
    }
  }
  const getDemo = (val: any) => {
    setIstimeExpire(false);
    setdemoVal(val.target.value);
    const filterDemo = demoList?.filter(
      (item: any) => item.demoReqId === parseInt(val.target.value)
    );
    setdemoData(filterDemo[0]);
    setCourse(filterDemo[0]?.courseTypeId);

    const postData: any = {
      coursetypeId: filterDemo[0]?.courseTypeId?._id,
      // examtypeId:filterDemo[0]?.examtypeId,
    };
    if (filterDemo[0]?.courseTypeId?.type === 2) {
      setGrade(filterDemo[0]?.gradeId);
      postData["gradeId"] = filterDemo[0]?.gradeId;
    }
    if (filterDemo[0]?.courseTypeId?.type === 1) {
      dispatch(
        getGeneralExamList(
          {
            courseId: filterDemo[0]?.courseTypeId?._id,
          },
          (res: any) => {
            setExamList(res?.data);
            setExam(filterDemo[0]?.examtypeId);
            // setExam(res?.data[0]?._id)
            postData["examtypeId"] = filterDemo[0]?.examtypeId;
          },
          () => {}
        )
      );
    }
    setSubjectList([]);
    dispatch(
      getSubjectList(
        postData,
        (res: any) => {
          if (res?.data?.length > 0) {
            setSubjectList(res?.data);
            setSubject(res?.data[0]?._id);
          }
        },
        () => {}
      )
    );
    setTargetDate(filterDemo[0]?.date?.slice(0, 11) + "" + filterDemo[0]?.time);
  };

  const getGrade = (val: any) => {
    setGrade(val);
    setSubjectList([]);
  };

  useEffect(() => {
    const postData: any = {
      coursetypeId: course?._id,
    };
    if (course?.type === 2) {
      postData["gradeId"] = grade;
    }
    if (course?.type === 1) {
      postData["examtypeId"] = exam;
    }
    dispatch(
      getSubjectList(
        postData,
        (res: any) => {
          if (res?.data?.length > 0) {
            setSubjectList(res?.data);
            setSubject(res?.data[0]?._id);
          }
        },
        () => {}
      )
    );
  }, [grade, exam, course]);
  const getExam = (val: any) => {
    setSubjectList([]);
    setExam(val);
  };

  const onChangeSubject = (val: any) => {
    setpackageData("");
    setSubject(val);
  };

  useEffect(() => {
    const postData: any = {
      coursetypeId: course?._id,
      subjectId: subject,
    };
    if (course?.type === 2) {
      postData["gradeId"] = grade;
    }
    if (course?.type === 1) {
      postData["examtypeId"] = exam;
    }
    dispatch(
      getPackageList(
        postData,
        (res: any) => {
          if (res?.data.length > 0) {
            setpackageData(res?.data[0]);
          }
        },
        () => {}
      )
    );
  }, [subject]);

  const UserData = [
    {
      name: "Kshama Patil",
      address: "10, Chicago, Illinois, USA",
      text: "The teachers are very enthusiastic, energetic and knowledgable. They always explain the asked questions and in the end of the lesson they ask questions to see if the student understood the topic. We started with one subject and already continued with 3 subjects, this shows how happy we are to have found this service. My child feels much more confident at school. We highly recommend LurniGo!",
      imgPath: localImages?.teacherImg,
    },
  ];

  return (
    <div className="rightPanel">
      <div className="d-flex justify-content-between flex-wrap">
        <div className="d-flex">
          <div className="dashboardQoutes d-inline-block rounded-pill fw-bold mb-2 mb-md-0">
            Great day to learn something new{" "}
            <img
              src={localImages?.firelogo}
              alt="Demo Session"
              className="ms-3"
              style={{ width: "20px" }}
            />
          </div>
          {demoList?.length > 0 ? (
            <div className="dropdowncolor ms-2 d-flex align-items-center">
              <select
                className="cursor form-select rounded-pill p-2 pe-4"
                aria-label="Default select example"
                //  defaultValue={defaultValue}
                value={demoVal}
                onChange={getDemo}
              >
                {demoList?.map((item: any, index: number) => {
                  return (
                    <option value={item.demoReqId}>
                      {item.name ? item.name : item.Name}
                    </option>
                  );
                })}
              </select>
              {/* <Dropdown
            // placeholder="Select Package"
            options={demoList}
            value={demoVal}
            defaultValue={deafultdemoId}
            setInputText={(value: any) => getDemo(value)}
          />*/}
            </div>
          ) : null}
        </div>

        {demoData?.statusId === 5 ? (
          <button
            className="btn btnbg px-4 font14 rounded-pill"
            onClick={() => goToLink("Feedback")}
          >
            Give Feedback
          </button>
        ) : null}
        {demoData?.statusId === 8 ? (
          <button
            className="btn btnbg px-4 font14 rounded-pill"
            onClick={() => goToLink("Feedback")}
          >
            View Feedback
          </button>
        ) : null}
      </div>
      {demoData !== "" ? (
        <div className="row mt-3">
          <div className="col-md-7 ps-md-0 mb-3 mb-md-0">
            <div className="demoSessionCard row align-items-center">
              <div className="col-md-3">
                <img src={funFactOpen} alt="Demo Session" className="w-100" />
              </div>
              <div className="col-md-9">
                <div className="">Demo Details</div>
                <h5 className="fw-bold my-2">
                  Hey! your trial class is{" "}
                  {demoData?.statusId === 1
                    ? "accepted"
                    : demoData?.statusId === 2 || demoData?.statusId === 3
                    ? "confirmed"
                    : demoData?.statusId === 4
                    ? "cancelled"
                    : demoData?.statusId === 6
                    ? "live"
                    : "completed"}
                </h5>
                {demoData?.statusId === 5 ||
                demoData?.statusId === 7 ||
                demoData?.statusId === 8 ? (
                  <div>{demoData?.name}</div>
                ) : null}
                {demoData?.statusId === 1 ||
                demoData?.statusId === 2 ||
                demoData?.statusId === 3 ||
                demoData?.statusId === 6 ||
                demoData?.statusId === 5 ||
                demoData?.statusId === 7 ? (
                  <div className="d-flex mt-3 mb-2">
                    <CalendarMonthIcon />
                    <span className="ms-2">
                      {moment(demoData?.date?.slice(0, 19)).format("LL")}
                    </span>
                  </div>
                ) : null}
                {demoData?.statusId === 1 ||
                demoData?.statusId === 2 ||
                demoData?.statusId === 3 ||
                demoData?.statusId === 6 ||
                demoData?.status !== "unscheduled" ? (
                  <div className="d-flex ">
                    <AccessTimeIcon />
                    <span className="ms-2">{demoData?.time}</span>
                  </div>
                ) : null}
                {istimeExpire ? (
                  <div className="mt-4 d-flex">
                    {demoData?.statusId < 7 && demoData?.statusId !== 5 ? (
                      <button
                        className="btn btnbg px-4 font14"
                        disabled={
                          demoData?.attendeeUrl !== "\n<!D" ? false : true
                        }
                        onClick={joinLive}
                      >
                        JOIN LIVE
                      </button>
                    ) : null}

                    {demoData?.statusId === 5 ||
                    demoData?.statusId === 7 ||
                    demoData?.statusId === 8 ? (
                      <div>
                        {" "}
                        <button
                          className="btn btnbg px-4 font14"
                          onClick={() => gotoCourseDetail(demoData)}
                        >
                          View Video
                        </button>{" "}
                      </div>
                    ) : null}
                  </div>
                ) : demoData?.status === "unscheduled" ? (
                  ""
                ) : (
                  <div className="mt-2">
                    <p className="mb-0 fw-bold font16">Your class start in</p>
                    <CountDownTimer
                      targetDate={targetDate}
                      setIstimeExpire={setIstimeExpire}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="teacherCard row align-items-center h-100">
              <div className="col-md-3 px-0">
                <img
                  src={localImages?.logoIcon}
                  alt="Demo Session"
                  className="w-100"
                />
              </div>
              <div className="col-md-9">
                <div>Assigned Teacher :</div>
                <h5 className="fw-bold my-2">
                  {demoData?.teacherName
                    ? demoData?.teacherName
                    : "Teacher will be assigned soon"}
                </h5>
                <div>Subject :</div>
                <h5 className="fw-bold my-2"> {demoData?.subjectName}</h5>
                {demoData?.teacherName ? (
                  <div className="d-flex">
                    <div className="pe-2">
                      <div className="fw-bold">
                        Demo <ArrowForwardIosIcon className="ms-1 font16" />
                      </div>
                      <div>{demoData?.numberOfDemoClasses}</div>
                    </div>
                    <div className="pe-2">
                      <div className="fw-bold">
                        Classes
                        <ArrowForwardIosIcon className="ms-1 font16" />
                      </div>
                      <div>{demoData?.numberOfClasses}</div>
                    </div>
                    <div className="pe-2">
                      <div className="fw-bold">
                        Experience
                        <ArrowForwardIosIcon className="ms-1 font16" />
                      </div>
                      <div>{demoData?.yearOfExperience} Years</div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-3 demoProcessBox px-5">
          <h4 className="fw-bold my-2">Schedule Your Free Trial Class</h4>
          <button
            className="btn btnbg rounded-pill mt-3"
            onClick={() => goToLink("BookSession")}
          >
            Pick a slot
          </button>
        </div>
      )}
      {demoData !== "" ? (
        <div className="mt-3 demoProcessBox">
          <div className="font16 fw-bold">Demo Process Flow</div>
          {demoData?.statusId === 4 ? (
            <div className="d-flex align-items-center mt-4 justify-content-center justify-content-md-start flex-wrap flex-md-nowrap">
              <div className="demoProcessCard shadow-sm bgLightLevender mb-2 position-relative">
                <div>Trial Class Cancelled</div>
                <div className="d-flex justify-content-between align-items-center">
                  {/* <img src={bubble} alt="Demo Session" className="imgWidth" /> */}
                  <TaskAltIcon className="text-success" />
                  <img
                    src={localImages?.trialSession}
                    alt="Trial Class Accepted"
                    className="stepFlowImg"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center mt-4 justify-content-center justify-content-md-start flex-wrap flex-md-nowrap">
              <div className="demoProcessCard shadow-sm bgLightLevender mb-2 position-relative">
                <div>Trial Class Accepted</div>
                <div className="d-flex justify-content-between align-items-center">
                  <TaskAltIcon className="text-success" />
                  <img
                    src={localImages?.trialSession}
                    alt="Trial Class Accepted"
                    className="stepFlowImg"
                  />
                </div>
              </div>
              <div className="line active d-none d-md-block"></div>
              <div
                className={`demoProcessCard shadow-sm  position-relative mb-2 ${
                  demoData?.statusId === 2 ||
                  demoData?.statusId === 3 ||
                  demoData?.statusId === 2 ||
                  demoData?.statusId === 5 ||
                  demoData?.statusId === 6 ||
                  demoData?.statusId === 7 ||
                  demoData?.statusId === 8
                    ? "bgLightYellow"
                    : ""
                }`}
              >
                <div>Teacher Assigned</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {demoData?.statusId === 2 ||
                    demoData?.statusId === 3 ||
                    demoData?.statusId === 5 ||
                    demoData?.statusId === 6 ||
                    demoData?.statusId === 7 ||
                    demoData?.statusId === 8 ? (
                      <TaskAltIcon className="text-success" />
                    ) : null}
                  </div>

                  <img
                    src={localImages?.teacherAssign}
                    alt="Trial Class Accepted"
                    className="stepFlowImg"
                  />
                </div>
              </div>

              <div
                className={`line d-none d-md-block ${
                  demoData?.statusId === 2 ||
                  demoData?.statusId === 3 ||
                  demoData?.statusId === 2 ||
                  demoData?.statusId === 5 ||
                  demoData?.statusId === 6 ||
                  demoData?.statusId === 7 ||
                  demoData?.statusId === 8
                    ? "active"
                    : ""
                }`}
              ></div>

              <div
                className={`demoProcessCard shadow-sm position-relative mb-2 ${
                  demoData?.statusId === 2 ||
                  demoData?.statusId === 3 ||
                  demoData?.statusId === 2 ||
                  demoData?.statusId === 5 ||
                  demoData?.statusId === 6 ||
                  demoData?.statusId === 7 ||
                  demoData?.statusId === 8
                    ? "bglightPitch"
                    : ""
                }`}
              >
                <div>Trial Confirmed</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {demoData?.statusId === 2 ||
                    demoData?.statusId === 3 ||
                    demoData?.statusId === 2 ||
                    demoData?.statusId === 5 ||
                    demoData?.statusId === 6 ||
                    demoData?.statusId === 7 ||
                    demoData?.statusId === 8 ? (
                      <TaskAltIcon className="text-success" />
                    ) : null}
                  </div>

                  <img
                    src={localImages?.trialConfirm}
                    alt="Trial Class Accepted"
                    className="stepFlowImg"
                  />
                </div>
              </div>

              <div
                className={`line d-none d-md-block ${
                  demoData?.statusId === 2 ||
                  demoData?.statusId === 3 ||
                  demoData?.statusId === 5 ||
                  demoData?.statusId === 7 ||
                  demoData?.statusId === 8
                    ? "active"
                    : ""
                }`}
              ></div>

              <div
                className={`demoProcessCard shadow-sm position-relative mb-2 ${
                  demoData?.statusId === 5 ||
                  demoData?.statusId === 7 ||
                  demoData?.statusId === 8
                    ? "bglightBlue"
                    : ""
                }`}
              >
                <div>Trial Completed</div>
                <div className="d-flex justify-content-between align-items-center ">
                  <div>
                    {demoData?.statusId === 5 ||
                    demoData?.statusId === 7 ||
                    demoData?.statusId === 8 ? (
                      <TaskAltIcon className="text-success" />
                    ) : null}
                  </div>
                  <img
                    src={localImages?.trialComplete}
                    alt="Trial Class Accepted"
                    className="stepFlowImg"
                  />
                </div>
              </div>

              <div
                className={`line d-none d-md-block ${
                  demoData?.statusId === 5 ||
                  demoData?.statusId === 7 ||
                  demoData?.statusId === 8
                    ? "active"
                    : ""
                }`}
              ></div>

              <div
                className={`demoProcessCard shadow-sm position-relative mb-2 ${
                  demoData?.statusId === 7 || demoData?.statusId === 8
                    ? "bglightgreen"
                    : ""
                }`}
              >
                <div>Counselling Completed</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {demoData?.statusId === 7 || demoData?.statusId === 8 ? (
                      <TaskAltIcon className="text-success" />
                    ) : null}
                  </div>
                  <img
                    src={localImages?.counsellingComplete}
                    alt="Trial Class Accepted"
                    className="stepFlowImg"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}

      <div className="mt-4 p-4 p-md-5 shadow subscriptionBox">
        <h2 className="fw-bold my-2 text-center">
          Our pricing & subscription plans
        </h2>
        <div className="text-center mx-auto subscriptionSubHeading mt-3">
          Elevate your learning experience with Lurnigo's Advanced subjects with
          challenging engaging content designed to stimulate critical thinking
          and foster a love for learning.
        </div>

        <div className="d-flex row justify-content-center mt-4">
          {course?.type === 2 ? (
            <div className="col-md-3 mb-2 mb-md-0">
              <DropdownNew
                placeholder="Select Grade"
                options={gradeList}
                value={grade}
                setInputText={(value: any) => getGrade(value)}
              />
            </div>
          ) : null}
          {course?.type === 1 ? (
            <div className="col-md-3 mb-2 mb-md-0">
              <DropdownNew
                placeholder="Select Exam"
                options={examList}
                value={exam}
                setInputText={(value: any) => getExam(value)}
              />
            </div>
          ) : null}
          <div className="col-md-3 mb-2 mb-md-0">
            <DropdownNew
              placeholder="Select Subject"
              options={subjectList}
              value={subject}
              setInputText={(value: any) => onChangeSubject(value)}
            />
          </div>
        </div>
        <div className="featureDiv border rounded p-4 mt-5">
          <SubscriptionPlan priceData={packageData} />
        </div>
      </div>
      <div className="mt-4 p-4 p-md-5">
        <div className="row">
          <div className="col-12 col-md-5 d-flex flex-column justify-content-center">
            <h2 className="fw-bold display-4 poppinsBoldFont">
              What <span className="bookSessionOrange">our achievers say?</span>
            </h2>
            <div className="fw-bold  font24 mt-4">
              Explore skill Development courses and kick-start your career with
              Lurnigo
            </div>
          </div>
          <div className="col-12 col-md-7 ">
            <div
              id="carouselExampleFade"
              className="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {UserData?.map((item, index) => (
                  <div
                    className={`carousel-item position-relative ${
                      index === 0 ? "active" : ""
                    }`}
                    key={index}
                  >
                    <div className="homeCurouserImgDiv">
                      <img
                        src={item?.imgPath}
                        className="d-block homeCurouserImg"
                        alt="skill development courses"
                      />
                      <div className="imageBorder"></div>
                    </div>
                    <div className="homeCurouserTextDiv shadow">
                      <div>{item?.text}</div>
                      <div className="fw-bold mt-2">{item?.name}</div>
                      <div>{item?.address}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="teacherCard mt-3 p-4 p-md-5">
        <video muted autoPlay loop className="videotag m-auto">
          <source src={localImages.homeBannerVideo} type="video/mp4" />
        </video>
      </div>

      <UserFeedbackPopup
        open={open}
        handleClose={handleClose}
        goto={() => goToLink("Feedback")}
      />
      {noVideo && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={noVideo}
          onClose={() => {
            setNoVideo(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={noVideo}>
            <div className="addUpi-modal col-md-4">
              <div>
                <div className="col mb-2 text-end">
                  <ClearOutlinedIcon
                    className="colorblue cursor"
                    onClick={() => {
                      setNoVideo(false);
                    }}
                  />
                </div>
                <div className="modal-info-holder">
                  <div className="animation-holder mb-4">
                    <Lottie
                      options={defaultOptions}
                      height={200}
                      width={200}
                    ></Lottie>
                  </div>
                  <div className="text-holder text-center my-5">
                    <h3 className="colorBlue">Please Wait for a While!!! </h3>
                    <p className="text-success fw-bold">
                      Lecture Will Be Available Soon...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
}

export default DemoDashboard;
