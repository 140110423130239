
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import DatePicker from "../Component/datePicker";
import { reScheduleClass } from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import moment from "moment";
import { useState } from "react";
function ReschedulePopup(props: any) {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState("");
  const [time, settime] = useState<string>("");  

  const classScheduleArr = [
    {
      _id: "9",
      name: "NineAM",
      time: "9.00AM",
      isSelected: false,
    },
    {
      _id: "10",
      name: "TenAM",
      time: "10.00AM",
      isSelected: false,
    },
    {
      _id: "11",
      name: "ElevenAM",
      time: "11.00AM",
      isSelected: false,
    },
    {
      _id: "12",
      name: "TwelveAM",
      time: "12.00AM",
      isSelected: false,
    },
    {
      _id: "13",
      name: "OnePM",
      time: "1.00PM",
      isSelected: false,
    },
    {
      _id: "14",
      name: "TwoPM",
      time: "2.00PM",
      isSelected: false,
    },
    {
      _id: "15",
      name: "ThreePM",
      time: "3.00PM",
      isSelected: false,
    },
    {
      _id: "16",
      name: "FourPM",
      time: "4.00PM",
      isSelected: false,
    },
    {
      _id: "17",
      name: "FivePM",
      time: "5.00PM",
      isSelected: false,
    },
    {
      _id: "18",
      name: "SixPM",
      time: "6.00PM",
      isSelected: false,
    },
    {
      _id: "19",
      name: "SevenPM",
      time: "7.00PM",
      isSelected: false,
    },
    {
      _id: "20",
      name: "EightPM",
      time: "8.00PM",
      isSelected: false,
    },
    {
      _id: "21",
      name: "NinePM",
      time: "9.00PM",
      isSelected: false,
    },
  ];
  const onClickTime = (val:any) => {
    settime(val?._id)
  
  };
  const handleSubmit=()=>{
    const postData={
      
      titleId:""+props?.packageData?.titleId,
      scheduleFrom:startDate,
      sessionTime:time
    }
    console.log('postData', postData);
    dispatch(reScheduleClass(
      postData,
        (res: any) => {
            props.handleClose()
        },
        () => { })
    )

  }
  function validate() {
    return (
        time==="" ||startDate===""
    );
}

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={props.openPopup}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.openPopup}>
        <div className="slotmodeldiv">
          <div className=" d-flex mt-0 justify-content-center  ">
            <p className="fw-bolder fs-5 text-primary">Select Time & Date</p>
          </div>
          <div className="">
            <div className="row d-flex mt-3">
              <div className="col-md-3">
                <label className="mt-1 fw-bold">Start Date</label>
              </div>
              <div className="col-md-6">

                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                  defaultValue={startDate} mindate={moment(new Date()).format("YYYY-MM-DD")}/>
                  
              </div>

            </div>
            <div className="row d-flex mt-3">
              <div className="col-md-3">
                <label className="mt-1 fw-bold">Time Slot</label>
              </div>
              <div className="col-md-8 ">
                <div className="d-flex flex-wrap">
                  {classScheduleArr?.map((value: any, index: any) => {
                    return (
                      <div
                        className={`border rounded p-3 cursor  m-2 timeSlot ${time === value?._id ? 'bg-primary text-white' : ''} `}

                        onClick={() => onClickTime(value)}
                      >
                        {value?.time}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <button className="btn btn-secondary me-2 " onClick={props.handleClose}>Cancel</button>
              <button className={`btn  ${validate()?'btn-secondary':'btn-primary'}`} disabled={validate()}  onClick={handleSubmit}>Save</button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default ReschedulePopup;
