// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.day{
    width: 30%;
    text-align: center;
    border: 1px solid #ccc;
    color: var(--theme1);
    flex-grow: 1;
    padding: 3px;
    cursor: pointer;
    margin: 3px;
    background: #fff;
}
.day:hover {
    /* background: #f6f7f8; */
    border-color: var(--bookSessionOrange);
    color: var(--bookSessionOrange);
}
.day.active{
    background-color: var(--bookSessionOrange);
    border-color: var(--bookSessionOrange);
    color: #fff;
}
.timeCardDiv{
    width: 87%;
      margin: auto;
  }
.timeCard{
    width: 31.3%;
    text-align: center;
    color: #707070;
    border: 1px solid #707070;
    /* flex-grow: 1; */
    cursor: pointer;
    margin: 5px;
    padding: 12px;
    background: #fff;
}
.timeCard:hover{
    background-color: var(--bookSessionOrange);
    border-color: var(--bookSessionOrange);
      cursor: pointer;
      color: #fff;
}
.timeCard.active{
    background-color: var(--bookSessionOrange);
  border-color: var(--bookSessionOrange);
    cursor: pointer;
    color: #fff;
}
@media screen and (max-width:567px) {
  .timeCard{
    width: 29%;
  }  
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/BookSlotDatePicker.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,sBAAsB;IACtB,oBAAoB;IACpB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,sCAAsC;IACtC,+BAA+B;AACnC;AACA;IACI,0CAA0C;IAC1C,sCAAsC;IACtC,WAAW;AACf;AACA;IACI,UAAU;MACR,YAAY;EAChB;AACF;IACI,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,0CAA0C;IAC1C,sCAAsC;MACpC,eAAe;MACf,WAAW;AACjB;AACA;IACI,0CAA0C;EAC5C,sCAAsC;IACpC,eAAe;IACf,WAAW;AACf;AACA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".day{\n    width: 30%;\n    text-align: center;\n    border: 1px solid #ccc;\n    color: var(--theme1);\n    flex-grow: 1;\n    padding: 3px;\n    cursor: pointer;\n    margin: 3px;\n    background: #fff;\n}\n.day:hover {\n    /* background: #f6f7f8; */\n    border-color: var(--bookSessionOrange);\n    color: var(--bookSessionOrange);\n}\n.day.active{\n    background-color: var(--bookSessionOrange);\n    border-color: var(--bookSessionOrange);\n    color: #fff;\n}\n.timeCardDiv{\n    width: 87%;\n      margin: auto;\n  }\n.timeCard{\n    width: 31.3%;\n    text-align: center;\n    color: #707070;\n    border: 1px solid #707070;\n    /* flex-grow: 1; */\n    cursor: pointer;\n    margin: 5px;\n    padding: 12px;\n    background: #fff;\n}\n.timeCard:hover{\n    background-color: var(--bookSessionOrange);\n    border-color: var(--bookSessionOrange);\n      cursor: pointer;\n      color: #fff;\n}\n.timeCard.active{\n    background-color: var(--bookSessionOrange);\n  border-color: var(--bookSessionOrange);\n    cursor: pointer;\n    color: #fff;\n}\n@media screen and (max-width:567px) {\n  .timeCard{\n    width: 29%;\n  }  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
