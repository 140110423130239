import { useState, useEffect } from "react";
import "../App.css";
// import DatePicker from "react-horizontal-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CourseCard from "../Module/courseCard";
import { getRcordedVideos } from "../Redux/Actions/AuthAction";
import { getRecordedforFreeUser } from "../Redux/Actions/examActions";
import NoRecord from "../Component/noRecord";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Lottie from 'react-lottie';
import waiting from '../Assets/Images/json/waiting.json';
function DemoLecture(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recordedVideoList, setRecordedVideoList] = useState<any>();
  const [freeUserRecordedVideoList, setFreeUserRecordedVideoList] = useState<any>();
  const [noVideo, setNoVideo] = useState(false);
  const location = useLocation();
  const { packageID, examID } = location?.state;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: waiting,
    renderer: 'svg'
  };
  useEffect(() => {

    let postData = {};
    if (packageID) {
      postData = {
        packageId: packageID,
      };
      dispatch(
        getRcordedVideos(
          postData,
          (res: any) => {
            setRecordedVideoList(res?.data);
          },
          () => { }
        )
      );
    } else {
      postData = {
        examtypeId: examID,
      };
      dispatch(
        getRecordedforFreeUser(
          postData,
          (res: any) => {
            setFreeUserRecordedVideoList(res?.data);
          },
          () => { }
        )
      );
    }

  }, [props?.examID, props?.packageID]);
  function gotoCourseDetail(data: any) {
    if (packageID) {
      navigate("/User/Lecture", { state: { subjectData: data } })
    } else {
      if(data?.linkStatus===1){
        setNoVideo(true)
      }
      else{
        navigate("/VideoPlayer", {
          state: { lectureId: "", recordedData: data },
        });
      }
     
    }
  }
  return (
    <div className="rightPanel">
      <div className="d-flex">
        {recordedVideoList?.length !== 0 ? <>{recordedVideoList?.map((c: any, index: any) => { return (<h3 className="fw-bold boldfont me-5">{c?.name}</h3>) })}</> : <h3 className="fw-bold boldfont me-5">Lectures</h3>}
        {freeUserRecordedVideoList ? <h3 className="fw-bold boldfont me-5">DEMO LECTURES</h3> : ""}
      </div>
      <div className="row mx-0 mt-3">
        {recordedVideoList?.length !== 0 ? <>{recordedVideoList?.map((c: any, index: any) => (
          <div className="courseimg col-sm-6 col-lg-4 p-2 px-md-4" key={index}>
            <CourseCard
              course={c?.name}
              imgpath={c?.bannerImage}
              onClick={() => gotoCourseDetail(c)}
            />
          </div>
        ))}</> : <NoRecord />}
        {freeUserRecordedVideoList?.length !== 0 ? <>{freeUserRecordedVideoList?.map((c: any, index: any) => (
          <div className="courseimg col-sm-6 col-lg-4 p-2 px-md-4" key={index}>
            <CourseCard
              course={c?.name}
              imgpath={c?.bannerImage}
              onClick={() => gotoCourseDetail(c)}
            />
          </div>
        ))}</> : <NoRecord />}

      </div>

      {noVideo && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={noVideo}
          onClose={() => {
            setNoVideo(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <Fade in={noVideo}>
            <div className="addUpi-modal col-md-4">
              <div>
                <div className="col mb-2 text-end">
                  <ClearOutlinedIcon
                    className="colorblue cursor"
                    onClick={() => {
                      setNoVideo(false);
                    }}
                  />
                </div>
                <div className="modal-info-holder">
                  <div className="animation-holder mb-4">
                    <Lottie options={defaultOptions} height={200} width={200}></Lottie>
                  </div>
                  <div className="text-holder text-center my-5">
                    <h3 className="colorBlue">Please Wait for a While!!! </h3>
                    <p className="text-success fw-bold">Lecture Will Be Available Soon...</p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
}

export default DemoLecture;
