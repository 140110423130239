import React, { useState } from "react";
import "../Assets/Css/ImageTabTemplate.css";
import parser from "html-react-parser";
import { parseHtmlWithTargetBlank } from "../Utils/utils";

export default function ImageTabTemplate(props: any) {
  const [data, setData] = useState<any>("");
  const getdata = (val: any) => {
    setData(val);
  };

  return (
    <div className="tabularData">
      <div className=" mt-5">
        <div className="d-flex row mx-0">
          {JSON.parse(props?.data?.desc)?.map((c: any, index: any) => {
            return (
              <div
                className="col-md-2 p-3 text-center"
                onClick={() => getdata(c)}
              >
                <div
                  className={` tabularBtn p-1 rounded ${
                    data?.Heading === c?.Heading ? "active" : ""
                  }`}
                >
                  <img
                    src={c?.img}
                    className="img-fluid tabularImg"
                    alt={c?.Heading}
                  />
                </div>
                <p className="mt-3"> {c?.Heading}</p>
              </div>
            );
          })}
        </div>

        {data ? (
          <div className="border p-3 rounded overflow-auto">
            <h4 className="fw-bold">{data?.Heading}</h4>
            {data ? (
              <div className="font16">
                {parser(parseHtmlWithTargetBlank(data?.Text))}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
