import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAppSelector,useAppDispatch } from '../hooks'; 
import { clearSnackbar } from '../Redux/Actions/snackbarAction';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert( props, ref,) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props}/>
});

function ErrorSnackbar() {
  const dispatch = useAppDispatch();
  const { successSnackbarOpen, errorSnackbarOpen, infoSnackbarOpen, message } = useAppSelector(
    (state:any) => ({
      successSnackbarOpen: state.uiReducer.successSnackbarOpen,
      errorSnackbarOpen: state.uiReducer.errorSnackbarOpen,
      infoSnackbarOpen: state.uiReducer.infoSnackbarOpen,
      message: state.uiReducer.message
    })
  );
  function handleClose() {
     dispatch(clearSnackbar());
  }
  return (
    <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ErrorSnackbar;
