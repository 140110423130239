 
export const validateFullNameErrMsg = "Please enter valid name. "
export const validateLastNameErrMsg = "Please enter valid Last name. "
export const validateIsNumberOnlyErrMsg = "Please enter number only"
export const validateEmailAddressErrMsg = "Please enter valid email address"
export const validateSchoolNameErrMsg = "Please enter valid school name. "
export const maxAttemptReachedMsg = "Max attempt reached";
export const maxDecimalLimiReachedMsg = "Max Decimal Limit reached";
export const markedForReviewMsg = "Now your response is not considered for evaluation";
export const selectOptionMsg = "Please Select An option first or Click on the Next Button";
export const selectOptionNATMsg = "Please provide your input";
export const clearResponseAndClickNextMsg = "Please clear response first and then select An option";
export const maxAttemptReachedPopupMsg = "You Exceed The Limit Of The Maximum No Questions Were Attempted For This Section.";
 