// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lectureheight {
    max-height: calc(100vh - 230px);
    overflow: auto;
    scrollbar-width: none;
  }

  .LectureAccordion .accordion-button:not(.collapsed){
    background-color: var(--lightOrange) !important;
  }
  .LectureAccordion .accordion-button:focus{
box-shadow: 0 0 0 0.25rem var(--lightOrange);
  }
 
  .orangeBtn{
    background: transparent;
      color: var(--bookSessionOrange);
      border: 1px solid var(--bookSessionOrange);
      font-size: 0.9em;
      padding: 5px 10px;
      display: flex;
      justify-content: space-between;
      /* margin: auto 0 auto auto; */
}
.orangeBtn.active,.orangeBtn:hover{
  color: #fff;
  background:var(--bookSessionOrange);
  border: 1px solid var(--bookSessionOrange);
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/lecture.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,+CAA+C;EACjD;EACA;AACF,4CAA4C;EAC1C;;EAEA;IACE,uBAAuB;MACrB,+BAA+B;MAC/B,0CAA0C;MAC1C,gBAAgB;MAChB,iBAAiB;MACjB,aAAa;MACb,8BAA8B;MAC9B,8BAA8B;AACpC;AACA;EACE,WAAW;EACX,mCAAmC;EACnC,0CAA0C;AAC5C","sourcesContent":[".lectureheight {\n    max-height: calc(100vh - 230px);\n    overflow: auto;\n    scrollbar-width: none;\n  }\n\n  .LectureAccordion .accordion-button:not(.collapsed){\n    background-color: var(--lightOrange) !important;\n  }\n  .LectureAccordion .accordion-button:focus{\nbox-shadow: 0 0 0 0.25rem var(--lightOrange);\n  }\n \n  .orangeBtn{\n    background: transparent;\n      color: var(--bookSessionOrange);\n      border: 1px solid var(--bookSessionOrange);\n      font-size: 0.9em;\n      padding: 5px 10px;\n      display: flex;\n      justify-content: space-between;\n      /* margin: auto 0 auto auto; */\n}\n.orangeBtn.active,.orangeBtn:hover{\n  color: #fff;\n  background:var(--bookSessionOrange);\n  border: 1px solid var(--bookSessionOrange);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
