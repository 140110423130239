// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customTable{
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.customTable th{
    background-color: #EAECF0 !important;
    color: #667085 !important;
    padding: 15px!important;
}
.customTable th:first-child{ 
  border-top-left-radius: 10px;
}
.customTable th:last-child{
    border-top-right-radius: 10px;
}
.customTable td{
    padding: 15px !important;
    font-size: 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/customTable.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,uBAAuB;AAC3B;AACA;EACE,4BAA4B;AAC9B;AACA;IACI,6BAA6B;AACjC;AACA;IACI,wBAAwB;IACxB,gBAAgB;AACpB","sourcesContent":[".customTable{\n    border-radius: 10px;\n    -webkit-border-radius: 10px;\n    -moz-border-radius: 10px;\n    -ms-border-radius: 10px;\n    -o-border-radius: 10px;\n}\n.customTable th{\n    background-color: #EAECF0 !important;\n    color: #667085 !important;\n    padding: 15px!important;\n}\n.customTable th:first-child{ \n  border-top-left-radius: 10px;\n}\n.customTable th:last-child{\n    border-top-right-radius: 10px;\n}\n.customTable td{\n    padding: 15px !important;\n    font-size: 1.2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
