import { useState } from 'react';
import '../App.css';
// import DatePicker from "react-horizontal-datepicker";
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import NoRecord from '../Component/noRecord';
import LecturedatePicker from '../Module/lecturedatePicker';
import { getLiveLecture } from '../Redux/Actions/examActions';
import BbMeetingPopup from '../Module/bbMeetingPopup';


function LectureSchedule() {
  const [scheduleData, setScheduleData] = useState([]);
  const location = useLocation();
  const { packageID } = location?.state;
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const dispatch = useDispatch();
  const [openVideo, setopenVideo] = useState(false);
  const [bblink, setbblink] = useState(false);
  const [date, setDate] = useState(new Date());
  
  const selectedDay = (val: any) => {
    setScheduleData([])
    setSelectedDate(moment(val).format('YYYY-MM-DD'));
    let requestData = {};
    if (packageID) {
      requestData = {
        packageId: packageID,
        date: moment(val).format('YYYY-MM-DD')
      };
    } else {
      requestData = {
        date: moment(val).format('YYYY-MM-DD')
      };
    }
    dispatch(
      getLiveLecture(
        requestData,
        (res: any) => {
          setScheduleData(res.data)
        },
        () => { }
      )
    );
  };
  const openBbmeeitng=(link:any)=>{
    setopenVideo(true);
    setbblink(link)
  }
  const handleBbbVideoClose = () => {
    setopenVideo(false);
  };
  return (
    <div className="rightPanel">
      <div className="sticky-top bgwhite">
        {/* <h4>{selectedTiles.name}</h4> */}
      </div>
      <div className="w-50 mt-4 ">
        <div className="p-2 sticky-top bgwhite "   style={{ zIndex: "1" }}>
          <LecturedatePicker selectedDay={selectedDay} />

        </div>
        <div className="lecturediv">
          {scheduleData.length > 0 ? (
            scheduleData.map((c: any, index: any) => {
              return (
                <>
                {c.status === 2 ?
                <div className="boxshadow rounded mb-4 customcontainer bgwhite w-78 mt-4 p-3" onClick={()=>openBbmeeitng(c.attendeeUrl)}>

                  <div className="d-flex justify-content-between pt-1 pb-0">
                    {/* {c?.lectureTitle ? ( */}
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center border px-2 py-1 ms-2 border-dark rounded">
                        <span className="font12">{index + 1}</span>
                      </div>
                      <span className="ms-3 font12 colorblue fw-bold">{c.lectureTitle}</span>
                    </div>
                    {/* ) : null} */}

                    <div className="d-flex align-items-center">
                      <p className="mb-0 fw-bold">{c.status === 1 ? <div className='text-success'>Upcoming</div> : c.status === 2 ? <div className='text-danger'>Live</div> : 'Completed'} </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between  pt-0 mt-2">
                    <span className=" font12 ms-2">{c?.teacherId?.name}</span>
                    <div className="d-flex align-items-center ">
                      <span className="ms-3 font12">
                        {moment.utc(c?.sessionTime, 'hh:mm:ss').format('LT')}
                      </span>
                    </div>
                  </div>
                </div>
                :
                <div className="boxshadow rounded mb-4 customcontainer bgwhite w-78 mt-4 p-3">

                  <div className="d-flex justify-content-between pt-1 pb-0">
                    {/* {c?.lectureTitle ? ( */}
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center border px-2 py-1 ms-2 border-dark rounded">
                        <span className="font12">{index + 1}</span>
                      </div>
                      <span className="ms-3 font12 colorblue fw-bold">{c.lectureTitle}</span>
                    </div>
                    {/* ) : null} */}

                    <div className="d-flex align-items-center">
                      <p className="mb-0 fw-bold">{c.status === 1 ? <div className='text-success'>Upcoming</div> : c.status === 2 ? <div className='text-danger'>Live</div> : 'Completed'} </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between  pt-0 mt-2">
                    <span className=" font12 ms-2">{c?.teacherId?.name}</span>
                    <div className="d-flex align-items-center ">
                      <span className="ms-3 font12">
                        {moment.utc(c?.sessionTime, 'hh:mm:ss').format('LT')}
                      </span>
                    </div>
                  </div>
                </div>}
                </>
              );
            })
          ) : (
            <div className='m-2'>
              <NoRecord />
            </div>
          )}
        </div>
        <BbMeetingPopup open={openVideo} handleClose={handleBbbVideoClose} link={bblink}/>
      </div>
    </div>
  );
}

export default LectureSchedule;
