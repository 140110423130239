
import '../Assets/Css/home.css'; 
import dataConstant from '../Constant/dataConstant';
import localImages from '../Constant/localImagesNew';
function CertificateSection(props:any) { 
   const data=[
    {
      img:localImages?.stem,
      text:"( STEM Accreditation )"
    },
    {
      img:localImages?.naac,
      text:"( NACAC membership )"
    }
   ]
  return (
    <div className='commonPad bgBlue learnSolDiv'>
      <img src={localImages?.award} className='learnSolleft' alt='learnSolleft' />
      <img src={localImages?.award2} className='awardright' alt='awardright' />
      <div className='width800 mx-auto mt-5 mb-5'>
        <h1 className='headingFont text-center'>Awards & Certifications</h1>
      </div>
      <div className=' mt-5 w-100 py-5 row d-flex justify-content-between'>
         
          {data?.map((c,index)=>{
            return(<div className={"col-md-6 text-start my-2  "}>
            <div className='stateBox row d-flex h-100'>
            {/* <div className='statNo'><CountUp duration={25} className="counter" end={item?.count} />+</div> */}
            <div className='col-md-4 '>
              <img src={c?.img} className='img-fluid awardimg ' alt='crtificate'/> </div>
              
            <div className='col-md-8 awardsubtext d-flex align-items-center'><p className='mb-0'>{c?.text}</p>  </div>
            </div>
          </div>)})}
        

      </div>
    
    </div>
  );
}

export default CertificateSection;
