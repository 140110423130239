// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeorderBox{
    width: 100%;
    max-width: 700px;
    margin: auto;
    padding: 15px;
    background: #fdf5f1;
}
.installementCard{
    background: #fff;
    border: 1px solid var(--lightgray);
    
}
.installementCard.active{
    box-shadow: 0px 0px 5px 0px var(--bookSessionOrange);
    border: 1px solid var(--bookSessionOrange);
}
.cartDetailsBox{
    overflow: auto;
    max-height: calc(100vh - 120px);
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/placeorder.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,kCAAkC;;AAEtC;AACA;IACI,oDAAoD;IACpD,0CAA0C;AAC9C;AACA;IACI,cAAc;IACd,+BAA+B;AACnC","sourcesContent":[".placeorderBox{\n    width: 100%;\n    max-width: 700px;\n    margin: auto;\n    padding: 15px;\n    background: #fdf5f1;\n}\n.installementCard{\n    background: #fff;\n    border: 1px solid var(--lightgray);\n    \n}\n.installementCard.active{\n    box-shadow: 0px 0px 5px 0px var(--bookSessionOrange);\n    border: 1px solid var(--bookSessionOrange);\n}\n.cartDetailsBox{\n    overflow: auto;\n    max-height: calc(100vh - 120px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
