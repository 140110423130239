import React from 'react'

interface props{
    placeholder:string,
    value:string,
    setValue:Function,
    maxLength?:any
    minLength?:any,
    className?:string
}
export default function PhoneInputField({placeholder,value,setValue,maxLength,minLength}:props) {
  return (
    <input 
    className="form-control ps-2 border-start-0 rounded-0 rounded-end phoneInput" 
    type="text" 
    placeholder={placeholder} 
    aria-label="default input example" 
    value={value} 
    onChange={(e)=>setValue(e.target.value)} 
    maxLength={maxLength}
    minLength={minLength}/>
  )
}
