
import '../Assets/Css/home.css'; 
import dataConstant from '../Constant/dataConstant';
import localImages from '../Constant/localImagesNew';
function StatCard(props:any) { 
   
  return (
    <div className='commonPad bgBlue learnSolDiv'>
      <img src={localImages?.learnSolleft} className='learnSolleft' alt='learnSolleft' />
      <img src={localImages?.learnSolright} className='learnSolright' alt='learnSolright' />
      <div className='width800 mx-auto mt-5 mb-5'>
        <h1 className='headingFont text-center'>Spreading positivity unveiling the Lurnigo effect, stat by stat</h1>
      </div>
      <div className=' mt-5 w-100 py-5   row d-flex justify-content-between'>
        {dataConstant?.statData?.map((item, index) => (
          <div className={"col-md-4 text-start my-2"}>
            <div className='stateBox'>
            {/* <div className='statNo'><CountUp duration={25} className="counter" end={item?.count} />+</div> */}
            <div className='statNo'>{item?.count}</div>
            <div className='statHeading'>{item?.heading}</div>
            </div>
          </div>
        ))}

      </div>
      <div className='text-center my-4'>
        <button className='exploreBtn px-4 border' onClick={props?.handleClick}>{props?.buttonName}</button>
      </div>
    </div>
  );
}

export default StatCard;
