// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tutoringServices{
    padding: 10px 30px;
    font-size: 1.72em;
    background: rgba(255, 95, 47, 0.6);
    display: inline-block;
    margin: 10px;
    text-align: center;
    width: 23%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 600;
}
@media screen and (max-width: 1024px) {
    .tutoringServices{
        width: 30%;
    }
}
@media screen and (max-width: 567px) {
    .tutoringServices{
        width: 44%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/testPrep.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,kCAAkC;IAClC,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,0BAA0B;IAC1B,uBAAuB;IACvB,sBAAsB;IACtB,qBAAqB;IACrB,gBAAgB;AACpB;AACA;IACI;QACI,UAAU;IACd;AACJ;AACA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".tutoringServices{\n    padding: 10px 30px;\n    font-size: 1.72em;\n    background: rgba(255, 95, 47, 0.6);\n    display: inline-block;\n    margin: 10px;\n    text-align: center;\n    width: 23%;\n    border-radius: 5px;\n    -webkit-border-radius: 5px;\n    -moz-border-radius: 5px;\n    -ms-border-radius: 5px;\n    -o-border-radius: 5px;\n    font-weight: 600;\n}\n@media screen and (max-width: 1024px) {\n    .tutoringServices{\n        width: 30%;\n    }\n}\n@media screen and (max-width: 567px) {\n    .tutoringServices{\n        width: 44%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
