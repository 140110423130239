import React from 'react';
import localImages from '../Constant/localImagesNew';

let universityLogo: string[];
universityLogo = [
  localImages.University1,
  localImages.University2,
  localImages.University3,
  localImages.University4,
  localImages.University5,
  localImages.University6,
  localImages.University7,
  localImages.University8,
  localImages.University9,
]
export default function Universities() {
  return (

    <div className='align-items-center  overflow-hidden'>
      {/* <h1 className=" textshadow text-center boldfont my-5">
        <span className='colorblue'>100+ </span>
        Worlds Top Universities our Alumni Study
      </h1> */}

      <div className="university">
        {universityLogo?.map((item, index) => {
          return (
            <div className='universitycard' key={index}>
              <img
                src={item}
                className="img-fluid"
                alt="Universities"
              />
            </div>)
        })}

      </div>
    </div>


  )
}
