import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import localImages from "../Constant/localImagesNew";
import InputField from "../Atom/InputField";
import Dropdown from "./dropdown";
import {
  addDSATlandingpage,
  getCountry,
  gradeLevelList,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import { validateFullName, validateIsNumberOnly } from "../Utils/validators";
import PhoneInputField from "../Atom/phoneInputField";
import LearnLandingForm from "./learnLandingForm";

interface props {
  open: boolean;
  handleClose: Function;
}
export default function LearnLandingPopup1({
  open,
  handleClose,
}: props) {
  const dispatch = useAppDispatch();
  const [firstName1, setfirstname1] = useState<string>("");
  const [lastName1, setlastName1] = useState<string>("");
  const [email1, setemail1] = useState<string>("");
  const [mob1, setmob1] = useState<string>("");
  const [countryData1, setCountryData1] = useState([]);
  const [coutryCode1, setcoutryCode1] = useState<string>("");
  const [gradeList1, setGradeList1] = useState([]);
  const [grade1, setGrade1] = useState<any>("");
  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  useEffect(() => {
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData1(data);
        },
        () => {}
      )
    );
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 4);
          setGradeList1(filterGrade);
        },
        () => {}
      )
    );
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      onClose={closePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldiv p-0 position-relative container">
          <div className="row mx-0 bgBlue">
            <div className="d-none d-md-block col-md-5">
              <div className=" d-flex justify-content-center align-items-center h-100">
                <div>
                  <div className="font28 colorWhite fw-bold text-center">Take Your First Step Towards</div>
                  <div className="font28 colorOrange fw-bold text-center">Academic Success !</div>
                  <div className="text-center mt-4">
                    <img src={localImages?.learnPopupBg} alt="Learn" className="w-100" style={{maxWidth:"300px"}}/>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="col-md-7 bgwhite rounded overflow-hidden p-3">
              <div className="bookfreeSession">
                <div className="col mb-2 d-flex justify-content-end ">
                  <ClearOutlinedIcon
                    className="colorblue cursor font28"
                    onClick={closePopup}
                  />
                </div>
                <div className="mt-4">
                <div className="font24 colorgray fw-bold px-3 mb-4">Book Your Trial Session Now!</div>
                  <LearnLandingForm handleClose={handleClose}/>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </Fade>
    </Modal>
  );
}
