import React, { useState } from 'react';
import '../App.css';
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
import Lottie from 'react-lottie';
import orderCompleted from '../Assets/Images/json/ordercompleted.json';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

function ExamSuccesfull(props: { examData: { resultDate: moment.MomentInput; }; }) {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: orderCompleted,
    renderer: 'svg'
  };

  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const handleClose = (e: { preventDefault: () => void; }) => {
    // setOpen(false);
    // dispatch(getCartList(() => {}, true));
    // if (hasActivePaidPlan) navigate('/PaidUser/PaidDashboard');
    // else navigate('/Dashboard');
    setOpen(false);
    e.preventDefault();
    // e.returnValue = '';
    localStorage.setItem("startTest", '2')
    sessionStorage.removeItem("examId");
    sessionStorage.removeItem("examData");
    window.close();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="model"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <div className="modeldiv p-5 text-center">
          <Lottie options={defaultOptions} height={130} width={200}></Lottie>
          <p className="text-center colorblue font14">
            <b>Thank You!</b>
          </p>
          <p className="text-center colorblue font14 " >
            <b>For Participating In A Contest!</b>
          </p>
          <p className="text-center font12 ">The result will be displayed here </p>
          <p className="text-center font12 mt-0">By {moment.utc(props.examData.resultDate).format('MMMM Do YYYY, h:mm:ss a')}</p>

          <div className='pt-4'>
            <button type="button" className="btn  btn-primary w-50 " onClick={handleClose}>
              Back to home
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default ExamSuccesfull;
