import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from 'react-redux';
import { Tabs, Tab } from "react-bootstrap";
import { useAppDispatch } from "../hooks";
import "../Assets/Css/onlineTestExam.css";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import MenuIcon from '@mui/icons-material/Menu';
import LocalImages from "../Constant/localImages";
import { useLocation, useNavigate } from "react-router-dom"; 
import parser from "html-react-parser";
import AnalysisSidebar from "../Module/analysisSidebar";
import "../Assets/Css/analysis.css"
import ResultAnalysis from "../Component/resultAnalysis";
import QuestionReport from "../Component/questionReport";
import { getAcademicAnalysis, getDemoAcademicAnalysis } from "../Redux/Actions/examActions";

function DetailsAnalysis() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [questionsData, setQuestionsData] = useState([]);
  const [examsData, setExamsData] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [lectureTitle, setLectureTitle] = useState("");
  const [ date, setDate] = useState(""); 
  const { lectureId ,studentId,isDemo, demoId} = location?.state
  console.log("lectureId",lectureId,demoId ,isDemo)
  
  // const {studentId} = useSelector((state:any) => ({
  //   studentId: state?.AuthReducer?.studentId, 
  // }));
  useEffect(() => {
    if(isDemo){
      dispatch(
        getDemoAcademicAnalysis(
          {
            studentId: studentId,
            lectureId: lectureId,
            demoId:demoId
          },
          (res: any) => {
            if (res) {
              console.log("getAcademicAnalysis",res)
              setQuestionsData( res?.data[0]?.questionsData);
              setExamsData(res?.data[0]?.examStats);
              setLeaderboard(res?.Leaderboard);
              setLectureTitle(res?.data[0]?.title);
              setDate(res?.data[0]?.createdAt) 
            }
          },
          () => {}
        )
      );
    }
    else{
    dispatch(
      getAcademicAnalysis(
        {
          studentId: studentId,
          lectureId: lectureId,
        },
        (res: any) => {
          if (res) {
            console.log("getAcademicAnalysis",res)
            setQuestionsData( res?.data[0]?.questionsData);
            setExamsData(res?.data[0]?.examStats);
            setLeaderboard(res?.Leaderboard);
            setLectureTitle(res?.data[0]?.title);
            setDate(res?.data[0]?.createdAt) 
          }
        },
        () => {}
      )
    );
  }
   
  }, []);

  console.log("questionsData",questionsData)
  console.log("leaderboard",leaderboard)
  console.log("examsData",examsData)
    function handleRedirect() {

      navigate("/LectureDashboard");      
      }
  return (
    <>
    <div className="" style={{ position: 'sticky', top: '0', width: '100%', zIndex: '99999' }}>
    <header className="d-flex align-items-center justify-content-between position-sticky">
      <div className="d-flex align-items-center">
        <div className="cursor" onClick={() => navigate(`/${ "../"}`)}>
          <img
            src={LocalImages.logo}
            alt="Lurnigo Logo"
            className="logo"
            style={{ width: '120px' }}
          />
        </div>
        <h5 className="mb-0 ms-3">{ }</h5>
      </div>
       <div>
        <button
          type="button"
          className="btn btn-primary py-2 px-5 font12 me-md-1"
          onClick={handleRedirect}>
            Back to dashbord
        </button>
      </div>  
      <div className="d-xs-flex d-sm-flex d-md-none me-2 cursor"  >
        <MenuIcon />
      </div>
    </header>
  </div>
    <div className="">
    <div className="d-flex p-4">
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className="analysisSidebar" id="sidebarMenu">
              < AnalysisSidebar  />
            </div>
            <div className="rightPanel">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                 <ResultAnalysis
                      examsStats={examsData} 
                      title={lectureTitle}
                      date={date}
                      leaderboard={leaderboard}
                      questionsData={questionsData}
                  />  
                  
                </Tab.Pane>
                  <Tab.Pane  eventKey="second">
                        <QuestionReport
                        questionsData={questionsData}
                        examsStats={examsData} 
                      />  
                    </Tab.Pane>  
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
    </div>
    </>
  );
}

export default DetailsAnalysis;
