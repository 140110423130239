import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const SemiCircleChart = ({ color, marks, totalMarks }) => {
  const isNegative = marks < 0;
  const backgroundColor = isNegative ? '#EFEFEF' : color;
  const adjustedTotalMarks = totalMarks - marks;
   const text = [totalMarks ];
  const data = {
     labels: [text],
    datasets: [
      {
        data: [marks, adjustedTotalMarks],
        backgroundColor: [backgroundColor, '#EFEFEF'],
        borderColor: [backgroundColor, '#EFEFEF'],
        circumference: 180,
        rotation: 270,
        cutout: '80%'
      }
    ]
  };
  const options = {
    aspectRatio: 2,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    tooltip: {
      enabled: false // <-- this option disables tooltips
    }
  };

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';

      const segments = [
        {
          text: isNegative
            ? `-${Math.abs(data.datasets[0].data[0])}`
            : `${data.datasets[0].data[0]}`,
          fontSize: '15px',
          fontWeight: 'bold',
          color: '#000'
        },
        // { text: `${data.datasets[0].data[1]}`, fontSize: "15px", fontWeight: "normal", color: "#9E9E9E" },
        { text: `${data.labels[0]}`, fontSize: '15px', fontWeight: 'normal', color: '#9E9E9E' }
      ];

      const lineHeight = 20;
      const centerX = chart.getDatasetMeta(0).data[0].x;
      const centerY = chart.getDatasetMeta(0).data[0].y - lineHeight * (segments.length / 2);
      const lineY = centerY + lineHeight / 7;
      ctx.beginPath();
      ctx.moveTo(centerX - 30, lineY);
      ctx.lineTo(centerX + 30, lineY);
      ctx.strokeStyle = '#000';
      ctx.stroke();

      segments.forEach((segment, index) => {
        ctx.font = `${segment.fontWeight} ${segment.fontSize} Arial`;
        ctx.fillStyle = segment.color;
        ctx.fillText(segment.text, centerX, centerY + lineHeight * index);
      });
      ctx.restore();
    }
  };
  return (
    <div className="mx-auto" style={{ width: '20vh' }}>
      <Doughnut data={data} options={options} plugins={[textCenter]} />
    </div>
  );
};
export default SemiCircleChart;
