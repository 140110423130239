import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import "../Assets/Css/demoDashboard.css";
import JitsiComponent from "../Component/JitsiComponent";
function JitSiConference() {
  const location = useLocation();
  const navigate = useNavigate();

  const { isJitsiVisible, roomName, domainName } = location.state;

  const { ACCESS_TOKEN, firstName, lastName } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    firstName: state?.AuthReducer?.firstName,
    lastName: state?.AuthReducer?.lastName,
  }));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onClickCallEnd = () => {
    navigate("/User/demoDashboard");
  };

  return (
    <div
      style={{
        height: "calc(100vh - 0px)",
        padding: "10px",
      }}
    >
      {isJitsiVisible && (
        <JitsiComponent
          domain={domainName}
          roomName={roomName}
          displayName={`${firstName} ${lastName}`}
          onCallEnd={onClickCallEnd}
        />
      )}
    </div>
  );
}

export default JitSiConference;
