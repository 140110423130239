import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setExamStatusSectionChangeAction,
  setExamStatusValueChangeAction
} from '../Redux/Actions/examActions';
const ExamStatus = (props:any) => {
  const [answered, setAnswered] = useState(props.answered);
  const [notAnswered, SetNotAnswered] = useState(props.notAnswered);
  const [marked, setMarked] = useState(props.marked);
  const [markAndSave, setMarkAndSave] = useState(props.markAndSave);
  const [notVisited, setNotVisited] = useState(props.notVisited); 
  const dispatch = useDispatch();
  const isChangeSectionValue = useSelector(
    (state:any) => state.SetOptionValueFalseReducer.isExamStatusSectionChanged
  );
  const isChangeValue = useSelector(
    (state:any) => state.SetOptionValueFalseReducer.isExamStatusValueChanged
  );

  if (isChangeValue && !props.isSection) {
    setAnswered(props.answered);
    SetNotAnswered(props.notAnswered);
    setMarked(props.marked);
    setMarkAndSave(props.markAndSave);
    setNotVisited(props.notVisited);
    dispatch(setExamStatusSectionChangeAction(true));
    dispatch(setExamStatusValueChangeAction(false));
  }

  if (isChangeSectionValue & props.isSection) {
    setAnswered(props.answered);
    SetNotAnswered(props.notAnswered);
    setMarked(props.marked);
    setMarkAndSave(props.markAndSave);
    setNotVisited(props.notVisited);
    dispatch(setExamStatusSectionChangeAction(false));
  }

  useEffect(() => {
    if (props.selectedSection?._id) {
      setAnswered(props.answered);
      SetNotAnswered(props.notAnswered);
      setMarked(props.marked);
      setMarkAndSave(props.markAndSave);
      setNotVisited(props.notVisited);
    }
  }, [props.selectedSection?._id]); 

  return (
    <div className="d-flex border-bottom pb-2 examstatus">
      <div className="text-center border-end px-2">
        <span className="colorgreen d-block fw-bold">{props.answered}</span>
        <span className="insttxt colorgrey d-block fw-bold">Answered</span>
      </div>
      <div className="text-center border-end px-2">
        <span className="errorcolor d-block fw-bold">{props.notAnswered}</span>
        <span className="insttxt colorgrey d-block fw-bold">Not Answered</span>
      </div>
      <div className="text-center px-2 border-end">
        <span className="colorpurple d-block fw-bold">{props.marked}</span>
        <span className="insttxt colorgrey d-block fw-bold">Marked</span>
      </div>
      <div className="text-center px-2 border-end">
        <span className="colorpurple d-block fw-bold marknsaveval">{props.markAndSave}</span>
        <span className="insttxt colorgrey d-block fw-bold">Mark & save</span>
      </div>
      <div className="text-center px-2">
        <span className="colorgrey d-block fw-bold">{props.notVisited}</span>  
        <span className="insttxt colorgrey d-block fw-bold">Not Visited</span>
      </div>
    </div>
  );
};

export default ExamStatus;
