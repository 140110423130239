// src/components/Canvas.js
import React, { useRef, useState, useEffect } from 'react';

const Canvas = () => {
  const canvasRef:any = useRef(null);
  const contextRef:any = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [mode, setMode] = useState('pen');
  const [history, setHistory] = useState<any>([]);
  const [redoStack, setRedoStack] = useState<any>([]);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
console.log('history', history);
  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = 600;
    canvas.height = 600;
    canvas.style.width = `${600}px`;
    canvas.style.height = `${600}px`;

    const context = canvas.getContext('2d');
    context.scale(1, 1); // scale removed, as canvas is now fixed size
    context.lineCap = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 5;
    contextRef.current = context;
  }, []);

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    setStartPosition({ x: offsetX, y: offsetY });
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const finishDrawing = () => {
    if (!isDrawing) return;
    contextRef.current.closePath();
    setIsDrawing(false);
    const url = canvasRef.current.toDataURL();
    setHistory([...history, url]);
    setRedoStack([]);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = nativeEvent;
    const { x, y } = startPosition;

    contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    if (history.length > 0) {
      const image = new Image();
      image.src = history[history.length - 1];
      image.onload = () => {
        contextRef.current.drawImage(image, 0, 0);
        drawShape(offsetX, offsetY);
      };
    } else {
      drawShape(offsetX, offsetY);
    }
  };

  const drawShape = (offsetX:any, offsetY:any) => {
    const { x, y } = startPosition;
    if (mode === 'pen') {
      contextRef.current.lineTo(offsetX, offsetY);
      contextRef.current.stroke();
    } else if (mode === 'eraser') {
      contextRef.current.clearRect(offsetX, offsetY, 10, 10);
    } else if (mode === 'circle') {
      const radius = Math.sqrt(Math.pow(offsetX - x, 2) + Math.pow(offsetY - y, 2));
      contextRef.current.beginPath();
      contextRef.current.arc(x, y, radius, 0, 2 * Math.PI);
      contextRef.current.stroke();
    } else if (mode === 'rectangle') {
      contextRef.current.beginPath();
      contextRef.current.rect(x, y, offsetX - x, offsetY - y);
      contextRef.current.stroke();
    } else if (mode === 'triangle') {
      contextRef.current.beginPath();
      contextRef.current.moveTo(x, y);
      contextRef.current.lineTo(offsetX, offsetY);
      contextRef.current.lineTo(x, offsetY);
      contextRef.current.closePath();
      contextRef.current.stroke();
    }
  };

  const undo = () => {
    if (history.length === 0) return;
    const lastState = history[history.length - 1];
    setRedoStack([...redoStack, lastState]);
    setHistory(history.slice(0, -1));
    const image = new Image();
    image.src = lastState;
    image.onload = () => {
      contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      contextRef.current.drawImage(image, 0, 0);
    };
  };

  const redo = () => {
    if (redoStack.length === 0) return;
    const lastState = redoStack[redoStack.length - 1];
    setHistory([...history, lastState]);
    setRedoStack(redoStack.slice(0, -1));
    const image = new Image();
    image.src = lastState;
    image.onload = () => {
      contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      contextRef.current.drawImage(image, 0, 0);
    };
  };

  const clearCanvas = () => {
    contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setHistory([]);
    setRedoStack([]);
  };

  return (
    <div>
      <div>
        <button onClick={() => setMode('pen')}>Pen</button>
        <button onClick={() => setMode('eraser')}>Eraser</button>
        <button onClick={() => setMode('circle')}>Circle</button>
        <button onClick={() => setMode('rectangle')}>Rectangle</button>
        <button onClick={() => setMode('triangle')}>Triangle</button>
        <button onClick={undo}>Undo</button>
        <button onClick={redo}>Redo</button>
        <button onClick={clearCanvas}>Clear</button>
      </div>
      <canvas
        onMouseDown={startDrawing}
        onMouseUp={finishDrawing}
        onMouseMove={draw}
        ref={canvasRef}
      />
    </div>
  );
};

export default Canvas;
