import React, { useEffect, useState } from "react";
import "../App.css";
import "../Assets/Css/placeorder.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../Component/header";
import { paymentExecute } from "../Redux/Actions/examActions";
import PaymentSuccessfull from "../Module/paymentSuccessfull";
import PaymentHold from "../Module/paymentHold";
import HeaderHome from "../Component/headerHome";

function PaymentSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSuccess, setopenSuccess] = useState(false);
  const [openPending, setopenPending] = useState(false);
  const [orderNo, setorderNo] = useState("");
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const PayerID = queryParameters.get("PayerID");
  const paymentId = queryParameters.get("paymentId");
  const { userCountryCurrency } = useSelector((state: any) => ({
    userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
  }));
  useEffect(() => {
    // setopenSuccess(true)
    setopenPending(true);
  }, []);

  useEffect(() => {
    const postData = {
      payerId: PayerID,
      paymentId: paymentId,
      token: token,
      currency: userCountryCurrency === "INR" ? "USD" : userCountryCurrency,
    };
    console.log("postData 111", postData);
    dispatch(
      paymentExecute(
        postData,
        (data: any) => {
          setopenPending(false);
          setorderNo(data?.data?.orderNo);
          setopenSuccess(true);
        },
        () => {}
      )
    );
  }, [paymentId]);

  return (
    <div>
      {/* <Header isHidden={false} />      */}
      <HeaderHome isHidden={false} />
      <PaymentSuccessfull open={openSuccess} orderNo={orderNo} />
      <PaymentHold open={openPending} />
    </div>
  );
}

export default PaymentSuccess;
