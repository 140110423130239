import React, { useEffect, useState } from 'react';
import '../Assets/Css/buyNowcard.css'; 
import { useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map'
import localImages from '../Constant/localImagesNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { applyCoupon } from '../Redux/Actions/examActions';
import { useDispatch } from 'react-redux';
function BuyNowCard(props:any) { 
  const dispatch = useDispatch();
  const [couponName, setCouponName] = useState<any>("");
  const {userCountryCurrency} = useSelector((state:any) => ({
    userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
  }));


  return (
    <div className="buyNowCarddiv">
       <div className="shadow-md shadow-lg shadow-xl topshadow py-3 px-4 rounded bgwhite">   
         
            <div className='d-flex justify-content-between mt-3'>
          <div >
            <h6 className="fw-bold mb-1">Total Amount</h6>
            <h6 className="mb-0">
              <span className='colorblue'>{getSymbolFromCurrency(userCountryCurrency)}{props?.amount?parseFloat(props?.amount).toFixed(2):0} </span>({props?.count} Item)
            </h6>
          </div>
        
        <div>
          <button
            className={`w-100 rounded-pill px-4 border-0 font14 ${props.disabled ? "btnDisable" : "btnActive"}`}
            disabled={props.disabled} onClick={props?.setOnClick}>
            {props?.btnname}
          </button>
        </div>
        </div>
      </div>
    </div>
  );
}

export default BuyNowCard;
