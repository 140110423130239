import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Desmos from 'desmos';

function DesmosPopup(props: any) {
  const ref = useRef<HTMLDivElement | null>(null);
const [data,setData]=useState<any>("")
useEffect(() => {
  if(ref.current){
    let calculator: any;
    const elt = document.createElement('div');
    elt.style.width = '100%';
    elt.style.height = '100%';
     ref?.current?.appendChild(elt);
    calculator = Desmos.GraphingCalculator(elt);
    calculator.setExpression({ id: 'graph1', latex: 'y=x^2' });
    // return () => {
    //    calculator.destroy();
    //   ref?.current?.removeChild(elt); 
    // };
  }  
  // }
}, [data]);
useEffect(() => {
  let x = Math.random() * 10;
setData(""+x)
}, [props?.open]);
  
  return (
    <div className="courseModel">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
      >
        <Fade in={props.open}>
          <div className="desmosmodel text-center">
            <div className="d-flex justify-content-end align-items-center">
              <ClearOutlinedIcon
                className="colorblue fs-3 rounded-circle cursor float-end"
                onClick={props.handleClose}
              />
            </div>
            <div id="calculator" ref={ref} style={{ width: "100%", height: "95%" }}></div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default DesmosPopup;
