import React, { useState } from "react";
import "../Assets/Css/MatchingPairs.css";

const data = [
  { State: "Maharashtra", Capital: "Mumbai" },
  { State: "Punjab", Capital: "Chandigarh" },
  { State: "Karnataka", Capital: "Bengaluru" },
  { State: "Himachal Pradesh", Capital: "Shimla" },
  { State: "Rajasthan", Capital: "Jaipur" }
];

const MatchingPairs = () => {
  const [states, setStates] = useState(data);
  const [capitals, setCapitals] = useState<any>(shuffleArray([...data]));
  const [selectedState, setSelectedState] = useState<any>(null);
  const [lines, setLines] = useState<any>([]);
  const [score, setScore] = useState(0);

  function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  const handleStateClick = (state) => {
    setSelectedState(state);
  };

  const handleCapitalClick = (capital) => {
    if (selectedState) {
      const isMatch = selectedState.Capital === capital.Capital;
      setLines([...lines, { state: selectedState, capital }]);

      if (isMatch) {
        setScore(score + 1);
      } else {
        setScore(score - 1);
      }

      setSelectedState(null);
    }
  };

  return (
    <div className="matching-container">
      <div className="states">
        {states.map((state, index) => (
          <div
            key={index}
            className={`state ${selectedState === state ? "selected" : ""}`}
            onClick={() => handleStateClick(state)}
          >
            {state.State}
          </div>
        ))}
      </div>
      <div className="capitals">
        {capitals.map((capital, index) => (
          <div
            key={index}
            className="capital"
            onClick={() => handleCapitalClick(capital)}
          >
            {capital.Capital}
          </div>
        ))}
      </div>
      <div className="lines">
        {lines.map((line, index) => (
          <div key={index} className="line">
            {line.state.State} → {line.capital.Capital}
          </div>
        ))}
      </div>
      <div className="score">
        <h3>Score: {score}</h3>
      </div>
    </div>
  );
};

export default MatchingPairs;
