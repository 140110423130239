import React, { useState, useEffect } from 'react';
import { Stage, Layer, Line } from 'react-konva';
import '../Assets/Css/matchThePair.css';

const colors = ['red', 'blue', 'green', 'purple', 'orange'];

const pairs = [
  { id: 1, left: 'A', right: '1' },
  { id: 2, left: 'B', right: '2' },
  { id: 3, left: 'C', right: '3' },
  // Add more pairs as needed
];

const MatchThePairWithLine = () => {
  const [lines, setLines] = useState<any>([]);
  const [startItem, setStartItem] = useState<any>(null);
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  
  const handleItemClick = (event, itemId, isLeft) => {
    const rect = event.target.getBoundingClientRect();
    const x = isLeft ? rect.right : rect.left;  // Start from the right edge of left div or left edge of right div
    const y = rect.top + rect.height / 2; // Center vertically within the div

    const clickedItem = {
      x: x,
      y: y,
      id: itemId,
    };

    if (startItem) {
      // If startItem is already selected, this click defines the endItem and draws the line
      const newLine = {
        startX: startItem.x,
        startY: startItem.y,
        endX: clickedItem.x,
        endY: clickedItem.y,
        color: colors[currentColorIndex % colors.length],
        leftId: isLeft ? startItem.id : clickedItem.id, // Identify left side
        rightId: isLeft ? clickedItem.id : startItem.id, // Identify right side
      };
      setLines([...lines, newLine]);
      setCurrentColorIndex(currentColorIndex + 1);
      setStartItem(null); // Reset start item after drawing the line
    } else {
      // If no startItem is selected, this click defines the startItem
      setStartItem(clickedItem);
    }
  };

  const checkPairs = () => {
    let correctPairs = 0;
    lines.forEach(line => {
      const correctPair = pairs.find(
        pair => pair.left === line.leftId && pair.right === line.rightId
      );
      if (correctPair) correctPairs += 1;
    });
    alert(`You have ${correctPairs} correct pairs out of ${pairs.length}`);
  };

  return (
    <div className='d-flex'>
      <div className="left">
        {pairs.map(pair => (
          <div
            key={pair.id}
            onClick={(event) => handleItemClick(event, pair.id, true)}
            style={{ margin: '20px', padding: '10px', border: '1px solid #000', cursor: 'pointer', position: 'relative' }}
          >
            {pair.left}
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                width: '10px',
                height: '10px',
                backgroundColor: 'blue',
                borderRadius: '50%',
              }}
            />
          </div>
        ))}
      </div>
      <div className="lineCanvascontainer mx-2 border">
        <Stage width={window.innerWidth} height={window.innerHeight}>
          <Layer>
            {lines.map((line, index) => (
              <Line
                key={index}
                points={[line.startX, line.startY, line.endX, line.endY]}
                stroke={line.color}
                strokeWidth={3}
                lineCap="round"
                lineJoin="round"
              />
            ))}
          </Layer>
        </Stage>
      </div>
      <div className="right">
        {pairs.map(pair => (
          <div
            key={pair.id}
            onClick={(event) => handleItemClick(event, pair.id, false)}
            style={{ margin: '20px', padding: '10px', border: '1px solid #000', cursor: 'pointer', position: 'relative' }}
          >
            {pair.right}
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                width: '10px',
                height: '10px',
                backgroundColor: 'red',
                borderRadius: '50%',
              }}
            />
          </div>
        ))}
      </div>
      <button onClick={checkPairs}>Check Pairs</button>
    </div>
  );
};

export default MatchThePairWithLine;
