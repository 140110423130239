import moment from "moment";
import { useState } from "react";
import '../Assets/Css/BookSlotDatePicker.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
function BookSlotDatePicker(props) {
  const [startDate, setStartDate] = useState(moment().add(1, 'day'));
  const [selectedDate, setSelectedDate] = useState(moment().add(1, 'day').format().slice(0, 10));

  const handleNext = () => {
    setStartDate(startDate.clone().add(1, 'day'));
  };

  const handleBack = () => {
    if (!startDate.isSame(moment().add(1, 'day'), 'day')) {
      setStartDate(startDate.clone().subtract(1, 'day'));
    }
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < 3; i++) {
      const currentDate = startDate.clone().add(i, 'day');
      const dateformat=moment(currentDate).format().slice(0, 10);
      days.push(
        <div key={i} className={`day rounded ${props?.selectedDate===dateformat?'active':''}`} onClick={()=>props?.selectedDay(moment(currentDate).format())}>
          <div className="fw-bold">{moment(currentDate).format('ddd')}</div>
          {currentDate.format('MMM DD')}
        </div>
      );
    }
    return days;
  };
  const getSelectedDate=(val)=>{
    setSelectedDate(val?.slice(0, 10))
  }

  return (
      <div className="d-flex">
      <button className="sendOTPBtn px-2 text-center rounded align-items-center my-1" onClick={handleBack} disabled={startDate.isSame(moment().add(1, 'day'), 'day')}>
       <ArrowBackIosIcon/>
      </button>
      <div className="calendar d-flex w-100 rounded">
        {renderDays()}
      </div>
      <button className="sendOTPBtn px-2 text-center rounded align-items-center my-1" onClick={handleNext}><ArrowForwardIosIcon/></button>
    </div>
  );
}

export default BookSlotDatePicker;
