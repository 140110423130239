import moment from "moment";
import { Key } from "react";
import { updateMoreValues } from "../Redux/Actions/AuthAction";
import { getExam } from "../Redux/Actions/examActions";
// import { data } from "jquery";
// import life_science from "./../Assets/DummyData/life_science.json"
// import mathematical_science from "./../Assets/DummyData/mathematical_science.json"
// import physical_science from "./../Assets/DummyData/physical_science.json"
// import chemical_science from "./../Assets/DummyData/chemical_science -.json"
// import classroomCoaching from "../Assets/DummyData/classroomCoaching.json";
// import ReactHtmlParser from "react-html-parser";
import parser from "html-react-parser";

export const getQuestionType = (key: any) => {
  const hashmap: any = {
    0: "MCQ",
    1: "MCQ",
    2: "MSQ",
    3: "NAT",
  };
  return key in hashmap ? hashmap[key] : "";
};

export const getPracticeQuestionType = (key: any) => {
  const hashmap: any = {
    0: "MCQ",
    1: "MCQ",
    2: "FIXED",
    3: "MSQ",
    4: "DESC",
    5: "MATCH",
    6: "CODING",
  };
  return key in hashmap ? hashmap[key] : "";
};

export const getQuestionDescription = (question: any) => {
  return question?.title?.map(
    (item: { descType: string; _id: Key; description: string }) => {
      return item?.descType === "img" ? (
        <div className="text-start">
          <img
            className="qusimg cursor"
            key={item?._id}
            src={item?.description}
            alt="description"
          />
        </div>
      ) : (
        parser(item?.description)
      );
    }
  );
};

export const _retrieveData = async ({
  examId,
  dispatch,
  getExamById,
  notSyncedDataFromStore,
  selectedQuestionIndex,
  notSyncedDataAction,
  examIdDataFromStore,
}: any) => {
  try {
    let strNotSyncedData = notSyncedDataFromStore || "{}";
    let notSyncedData = JSON.parse(strNotSyncedData);
    selectedQuestionIndex = +(notSyncedData[examId] || 0);
    dispatch(notSyncedDataAction(JSON.stringify(notSyncedData)));
  } catch (error) {
    console.error("error while getting retrieved data", error);
  }

  try {
    const value = examIdDataFromStore;
    // if (value !== null) {
    //   let examData = JSON.parse(value);
    //   let examDataT = JSON.parse(`{"examData": [${value}]}`);

    //   dispatch(setInstructionsAction(examDataT));
    //   if (examData.examStatus === 2) {
    //     //   navigate('/DetailResult', {state: {examId: examId, examData: examData}})
    //           sessionStorage.setItem("examId",examId)
    //           sessionStorage.setItem("examData",JSON.stringify(examData))
    //           window.open('/DetailResult',"_blank","toolbar=yes,scrollbars=yes,resizable=yes" );
    //   }else {
    //        let tmp = [examData]
    //     //   try{
    //     //     getFullScreen();
    //     //     sessionStorage.setItem("examId",examId)
    //     //     sessionStorage.setItem("examData",tmp);
    //     //     return window.open('/Test',"_blank","toolbar=yes,scrollbars=yes,resizable=yes" );
    //     //      //return navigate(`/${ScreenNames.Exam}`, { state: { examId: examId, examData: tmp } });
    //     //   }catch{
    //         //
    //         sessionStorage.setItem("examId",examId)
    //         sessionStorage.setItem("examData",JSON.stringify(tmp))
    //         return window.open('/ExamInstruction', "_blank","toolbar=yes,scrollbars=yes,resizable=yes");
    //         // navigate(`/${ScreenNames.ExamInstruction}`, {state: {examId: examId, examData: tmp}})
    //     //   }
    //   }
    // }else{
    const args = { examId, dispatch, getExamById };
    getExamAPI(args);
    // }
  } catch (error) {
    const args = { examId, dispatch, getExamById };
    getExamAPI(args);
  }
};

export const getExamAPI = ({ examId, dispatch, getExamById }: any) => {
  dispatch(updateMoreValues({ isLoader: true }));
  dispatch(
    getExam(
      examId,
      (data: any) => {
        dispatch(updateMoreValues({ isLoader: false }));
        let examData = data;
        let examStatus = examData[0].status;
        let isContestTest = examData[0].examSetTypeId;
        if (examStatus === 2) {
          if (isContestTest === true) {
            let currentDate = moment(new Date()).format(
              "MMMM Do YYYY, h:mm:ss a"
            );
            let contestEndDate = moment
              .utc(examData[0].contestEndDate)
              .format("MMMM Do YYYY, h:mm:ss a");
            // let resultDate = new Date(examData[0].resultDate)
            let resultDate = moment
              .utc(examData[0].resultDate)
              .format("MMMM Do YYYY, h:mm:ss a");
            let startDate = moment
              .utc(examData[0].contestStartDate)
              .format("MMMM Do YYYY, h:mm:ss a");
            if (currentDate < startDate) {
              alert(`Exam will start on ${startDate}`);
              return;
            } else if (currentDate > contestEndDate) {
              // Result Screen
              //   navigate('/DetailResult', { state: {examId: examId, examData: examData[0]}})
              sessionStorage.setItem("examId", examId);
              sessionStorage.setItem("examData", JSON.stringify(examData[0]));
              window.open(
                "/DetailResult",
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes"
              );
            } else if (currentDate < resultDate) {
              //alert(`Result will be shown on ${moment(resultDate).format('lll')}` );
              //alert(`The result will be displayed On ${resultDate}` );
            } else {
              // alert("Contest has not ended yet");
              // navigate('/DetailResult', { state: {examId: examId, examData: examData[0]}})
              sessionStorage.setItem("examId", examId);
              sessionStorage.setItem("examData", JSON.stringify(examData[0]));
              window.open(
                "/DetailResult",
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes"
              );
            }
          } else {
            //   navigate('/DetailResult',  {state: {examId: examId, examData: examData[0]}})
            sessionStorage.setItem("examId", examId);
            sessionStorage.setItem("examData", JSON.stringify(examData[0]));
            window.open(
              "/DetailResult",
              "_blank",
              "toolbar=yes,scrollbars=yes,resizable=yes"
            );
          }
        } else if (examStatus === 1 && isContestTest === 4) {
          let currentDate = moment(new Date()).format(
            "MMMM Do YYYY, h:mm:ss a"
          );
          let contestEndDate = moment
            .utc(examData[0].endDate)
            .format("MMMM Do YYYY, h:mm:ss a");
          let resultDate = new Date(examData[0].resultDate);
          let startDate = moment
            .utc(examData[0].startDate)
            .format("MMMM Do YYYY, h:mm:ss a");
          if (currentDate < startDate) {
            // live exam has not started yet
            alert(`Exam will start on ${startDate}`);
          } else if (currentDate > contestEndDate) {
            // live exam has not started yet
            alert(`Exam end on ${contestEndDate}`);
          } else {
            // live exam has started
            // navigate(`/${ScreenNames.ExamInstruction}`,  {state: {examId: examId, examData: examData}})
            sessionStorage.setItem("examId", examId);
            sessionStorage.setItem("examData", JSON.stringify(examData));
            window.open(
              "/ExamInstruction",
              "_blank",
              "toolbar=yes,scrollbars=yes,resizable=yes"
            );
          }
        } else {
          //   navigate(`/${ScreenNames.ExamInstruction}`,  {state: {examId: examId, examData: examData}})
          sessionStorage.setItem("examId", examId);
          sessionStorage.setItem("examData", JSON.stringify(examData));
          window.open(
            "/ExamInstruction",
            "_blank",
            "toolbar=yes,scrollbars=yes,resizable=yes"
          );
        }
      },
      () => {
        dispatch(updateMoreValues({ isLoader: false }));
      }
    )
  );
};

// export const getDateAndShortMonthStringFromDate = (value:any) => {
//     const date = new Date(value)
//     if(date=="Invalid Date") return ""
//     let dateString = date.getDate()
//     dateString = dateString < 10 ? `0${dateString}`: `${dateString}`
//     const monthString = date.toLocaleString('default', { month: 'short' })
//     return `${dateString} ${monthString}`
// }

export const timeConvert = (n: any) => {
  if (n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return `${rhours} hours ${rminutes} minutes`;
  } else return "";
};

export const convertFromHtmlToPlainText = (html: any) => {
  // converts htmlString to plan text

  // Create a new div element
  const tempDivElement = document.createElement("div");
  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;
  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

export const getCssClassNameFromQuestionType = (questionType: any) => {
  let cssClassName = "";
  switch (questionType) {
    case 0:
      // not visited yet
      cssClassName = "notVisited";
      break;
    case 1:
      // save and next
      cssClassName = "answered";
      break;
    case 2:
      // visited but not answered
      cssClassName = "notAnswered";
      break;
    case 3:
      // mark for review
      cssClassName = "marked";
      break;
    case 4:
      // markAndSave
      cssClassName = "marknsave";
      break;
    default:
      cssClassName = "notVisited";
      break;
  }
  return cssClassName;
};

export const totalAnsweredCountForReview = (
  questionSet = [],
  sectionId: any
) => {
  // count totalAnswered questions for review since there are only two options total answered and total not answered
  // total answered is sum of all answers(Answered + MarkedAndSave) .i.e. questions having ansType=1 or 4
  let count = 0;
  if (sectionId) {
    questionSet = questionSet.filter(
      (element: any) => element.section?.sectionId === sectionId
    );
  }
  questionSet.forEach((question: any, index: any) => {
    if (question.ansType === 1 || question.ansType === 4) {
      count++;
    }
  });
  return count;
};

export const totalNotAnsweredCountForReview = (
  questionSet = [],
  sectionId: any
) => {
  // count totalAnswered questions for review since there are only two options total answered and total not answered
  // total answered is sum of all answers(NotAnswered + Marked + Not Visited) .i.e. questions having ansType=0, 2 or 4
  let count = 0;
  if (sectionId) {
    questionSet = questionSet.filter(
      (element: any) => element.section?.sectionId === sectionId
    );
  }
  questionSet.forEach((question: any, index) => {
    if (
      question.ansType === 0 ||
      question.ansType === 2 ||
      question.ansType === 3
    ) {
      count++;
    }
  });
  return count;
};

export const getStatsFromQuestionData = (questionData = []) => {
  let correctAnswers = questionData?.filter(
    (item: any) =>
      item.ansStatus == 1 && (item.ansType == 1 || item.ansType == 4)
  );
  let wrongAnswers = questionData?.filter(
    (item: any) =>
      item.ansStatus == 2 && (item.ansType == 1 || item.ansType == 4)
  );

  let skippedAnswers = questionData?.filter(
    (item: any) => item.ansType != 1 && item.ansType != 4
  );
  let markedForReviewAnswers = questionData?.filter(
    (item: any) => item.ansType === 3
  );

  let markEarned = questionData
    ?.filter(
      (item: any) =>
        item.ansStatus == 1 && (item.ansType == 1 || item.ansType == 4)
    )
    .reduce(function (val, obj: any) {
      return val + obj.correctMarks;
    }, 0);

  let negativeMark = questionData
    ?.filter(
      (item: any) =>
        item.ansStatus == 2 && (item.ansType == 1 || item.ansType == 4)
    )
    .reduce(function (val, obj: any) {
      return val + obj.negativeMarks;
    }, 0);
  let totalCorrentMark = markEarned - negativeMark;

  let takenTime = questionData?.reduce(function (val, obj: any) {
    return val + obj.timeSpent;
  }, 0);

  return {
    correctAnswers,
    wrongAnswers,
    skippedAnswers,
    markedForReviewAnswers,
    markEarned,
    takenTime,
    totalCorrentMark,
    negativeMark,
  };
};

export const getTimeTakenToStringFormat = (seconds: any) => {
  if (seconds == null) return "undefined";
  let totalMinutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds - totalMinutes * 60;
  return `${totalMinutes} Min : ${remainingSeconds} Sec`;
};
export const getTimeTakenToMinutesStringFormat = (seconds: any) => {
  if (seconds == null) return "undefined";
  let totalMinutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds - totalMinutes * 60;
  return `${totalMinutes}.${remainingSeconds}`;
};

export const getFullScreen = () => {
  const elem = document.body;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  }
  // else if (elem.webkitRequestFullscreen) { /* Safari */
  //     elem.webkitRequestFullscreen();
  // } else if (elem.msRequestFullscreen) { /* IE11 */
  //     elem.msRequestFullscreen();
  // }
};

// export const exitFullScreenMode = () => {
//     if (document.exitFullscreen){
//         return document.exitFullscreen();
//     }else if (document.webkitExitFullscreen) { /* Safari */
//         document.webkitExitFullscreen();
//     } else if (document.msExitFullscreen) { /* IE11 */
//         document.msExitFullscreen();
//     }
// }

export const ctrlShiftKey = (e: any, keyCode: any) => {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
};

// export const getCoursedata =(baseCourseName) =>{
//     let data = {};
// if (baseCourseName==="MATHEMATICAL SCIENCE")
//  data =  mathematical_science
// else if(baseCourseName==="LIFE SCIENCE")
//  data =  life_science
//  else if(baseCourseName==="PHYSICAL SCIENCE")
//  data =  physical_science
//  else if(baseCourseName==="CHEMICAL SCIENCE")
//  data =  chemical_science
//  return data;
// }

// export const getQuestionDescription = (question:any) => {
//     return question.en?.title?.map((item:any) => {
//         return item?.descType === "img" ? <div className="text-start"><img className='qusimg cursor'  key={item?._id} src={item?.description} /></div> : ReactHtmlParser(item?.description)
//     })
// }

export const getAnswerStatusFromQuestion = (question: any) => {
  let answerStatus;
  switch (question?.ansType) {
    case 0:
      answerStatus = "notVisited";
      break;
    case 1:
      answerStatus = "answered";
      break;
    case 2:
      answerStatus = "notAnswered";
      break;
    case 3:
      answerStatus = "marked";
      break;
    default:
      answerStatus = "marknsave";
      break;
  }
  return answerStatus;
};

export const secondsMinute = (seconds: any) => {
  let minutes: any = Math.floor(seconds / 60);
  let extraSeconds: any = seconds % 60;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
  if (minutes === "00") return `${extraSeconds} sec`;
  return `${minutes} min and ${parseFloat(extraSeconds).toFixed(2)} sec`;
};

export const parseHtmlWithTargetBlank = (htmlString: String) => {
  const modifiedHtml = htmlString?.replace(/<a/g, '<a target="_blank"');
  return modifiedHtml;
};

// export const getClassroomCoaching =(baseCourseName) =>{
//     let classdata = {};
// if (baseCourseName==="MATHEMATICAL SCIENCE")
// classdata = classroomCoaching
// else if(baseCourseName==="LIFE SCIENCE")
// classdata =  life_science
//  else if(baseCourseName==="PHYSICAL SCIENCE")
//  classdata =  physical_science
//  else if(baseCourseName==="CHEMICAL SCIENCE")
//  classdata =  chemical_science
//  return data;
// }
