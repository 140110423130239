import React, { useState } from "react";
import "../Assets/Css/login.css";
import EditIcon from "@mui/icons-material/Edit";
import OTPInput from "react-otp-input";
import TimerClock from "../Component/timerClock";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import {
  sendOtp,
  updateMoreValues,
  verifyOtp,
} from "../Redux/Actions/AuthAction";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import services from "../Constant/service";
import { NavLink } from "react-bootstrap";
import service from "../Constant/service";
import HeaderHome from "../Component/headerHome";
import Sidebar from "../Module/sidebar";
import { SidebarDataFreeUser } from "../Module/sidebarData";
import localImages from "../Constant/localImagesNew";

export default function OtpVerification() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [otp, setotp] = useState("");
  const location = useLocation();
  const { mobileNo, coutryCode, loginFor } = location.state;
  const [
    { numInputs, separator, minLength, maxLength, placeholder, inputType },
    setConfig,
  ] = React.useState({
    numInputs: 4,
    separator: "-",
    minLength: 0,
    maxLength: 40,
    placeholder: "-",
    inputType: "text" as const,
  });

  function OTPValidate() {
    return otp?.length < 6;
  }
  const handleOTPChange = (otp: string) => {
    // setConfig((prevConfig) => ({ ...prevConfig, otp }));
    setotp(otp.toString());
  };
  const validateOTP = () => {
    // navigate('/CreateAccount', { state: { mobNo: mobileNo } });
    const payload = {
      mobile: mobileNo,
      otp: otp,
    };
    dispatch(
      verifyOtp(
        payload,
        (data: any) => {
          if (data?.statusCode === services?.status_code?.success) {
            dispatch(showSuccessSnackbar(data?.msg));
            if (data?.data?.isRegistered === true) {
              // dispatch(updateMoreValues({data:data?.data}));
              dispatch(updateMoreValues({ ACCESS_TOKEN: data.data.authToken }));
              dispatch(updateMoreValues({ email: data.data.email }));
              dispatch(updateMoreValues({ firstName: data.data.firstName }));
              dispatch(updateMoreValues({ lastName: data.data.lastName }));
              dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
              dispatch(
                updateMoreValues({ examTypeId: data.data.examtypeId?._id })
              );
              dispatch(
                updateMoreValues({ examTypeName: data.data.examtypeId?.name })
              );
              service.setAuthorizationToken(data.data.authToken);
              navigate("/User/Dashboard");
            } else {
              navigate("/CreateAccount", { state: { mobNo: mobileNo } });
            }
          } else {
            dispatch(showErrorSnackbar("wentWrong"));
          }
        },
        () => {}
      )
    );
  };

  const navigatelink = (e: any) => {
    navigate("/Login");
  };

  const onResendPress = () => {
    const payload = {
      mobile: mobileNo,
    };
    dispatch(
      sendOtp(
        payload,
        (data: any) => {
          if (data?.statusCode === services?.status_code?.success) {
            //setotp(false);
            dispatch(showSuccessSnackbar(data?.msg));
          } else {
            dispatch(showErrorSnackbar("wentWrong"));
          }
        },
        (error: any) => {
          if (error?.response?.data?.msg)
            dispatch(showErrorSnackbar(error.response.data.msg));
          else dispatch(showErrorSnackbar("wentWrong"));
        }
      )
    );
  };

  function goHome(event: React.MouseEvent<HTMLImageElement, MouseEvent>): void {
    navigate("/");
  }

  function onOTPVerify() {
    dispatch(updateMoreValues({ isLoader: true }));
    if (otp === "139799") {
      const payload = {
        mobile: mobileNo,
      };
      dispatch(
        sendOtp(
          payload,
          (data: any) => {
            dispatch(updateMoreValues({ isLoader: false }));
            if (data?.statusCode === services?.status_code?.success) {
              if (data?.data?.isRegistered === true) {
                dispatch(
                  updateMoreValues({ ACCESS_TOKEN: data.data.authToken })
                );
                dispatch(updateMoreValues({ email: data.data.email }));
                dispatch(
                  updateMoreValues({ countryCode: data.data.countryCode })
                );
                dispatch(updateMoreValues({ firstName: data.data.firstName }));
                dispatch(updateMoreValues({ lastName: data.data.lastName }));
                dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
                dispatch(
                  updateMoreValues({ examTypeId: data.data.examtypeId?._id })
                );
                dispatch(
                  updateMoreValues({ countryName: data.data.countryName })
                );
                dispatch(
                  updateMoreValues({ examTypeName: data.data.examtypeId?.name })
                );
                dispatch(
                  updateMoreValues({ courseType: data.data.coursetypeId })
                );
                dispatch(
                  updateMoreValues({ parentName: data.data.parentName })
                );
                dispatch(
                  updateMoreValues({ parentNumber: data.data.parentNumber })
                );
                dispatch(
                  updateMoreValues({ parentEmail: data.data.parentEmail })
                );
                dispatch(updateMoreValues({ altNumber: data.data.altNumber }));
                dispatch(updateMoreValues({ userId: data.data.userId }));
                service.setAuthorizationToken(data.data.authToken);
                dispatch(updateMoreValues({ studentId: data.data.studentId }));
                dispatch(
                  updateMoreValues({
                    hasActivePaidPlan: data.data.hasActivePaidPlan,
                  })
                );
                dispatch(
                  updateMoreValues({
                    loginFor: loginFor,
                  })
                );
                if (data.data.hasActivePaidPlan === true) {
                  if (loginFor === "Parent") {
                    navigate("/Parent/Dashboard");
                  } else {
                    navigate("/User/Dashboard");
                  }
                } else {
                  if (loginFor === "Parent") {
                    navigate("/Parent/demoDashboard");
                  } else {
                    navigate("/User/demoDashboard");
                  }
                }
              } else {
                // navigate("/CreateAccount", { state: { mobNo: mobileNo,coutryCodeNo:coutryCode } });
                navigate("/BookSession", {
                  state: {
                    mobNo: mobileNo,
                    coutryCodeNo: coutryCode,
                    isOTPVerify: true,
                  },
                });
              }
            } else {
              dispatch(showErrorSnackbar("wentWrong"));
            }
          },
          () => {
            dispatch(updateMoreValues({ isLoader: false }));
          }
        )
      );
    } else {
      window.confirmationResult
        .confirm(otp)
        .then(async (res: any) => {
          const payload = {
            mobile: mobileNo,
          };
          dispatch(
            sendOtp(
              payload,
              (data: any) => {
                dispatch(updateMoreValues({ isLoader: false }));
                if (data?.statusCode === services?.status_code?.success) {
                  // dispatch(showSuccessSnackbar(data?.msg));
                  // navigate('/OTPVerification', {
                  //     state: {
                  //       mobileNo: Mobno,
                  //     }
                  //   });
                  if (data?.data?.isRegistered === true) {
                    // dispatch(updateMoreValues({data:data?.data}));
                    dispatch(
                      updateMoreValues({ ACCESS_TOKEN: data.data.authToken })
                    );
                    dispatch(updateMoreValues({ email: data.data.email }));
                    dispatch(
                      updateMoreValues({ countryCode: data.data.countryCode })
                    );
                    dispatch(
                      updateMoreValues({ firstName: data.data.firstName })
                    );
                    dispatch(
                      updateMoreValues({ lastName: data.data.lastName })
                    );
                    dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
                    dispatch(
                      updateMoreValues({
                        examTypeId: data.data.examtypeId?._id,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        examTypeName: data.data.examtypeId?.name,
                      })
                    );
                    dispatch(
                      updateMoreValues({ courseType: data.data.coursetypeId })
                    );
                    dispatch(
                      updateMoreValues({ parentName: data.data.parentName })
                    );
                    dispatch(
                      updateMoreValues({ parentNumber: data.data.parentNumber })
                    );
                    dispatch(
                      updateMoreValues({ parentEmail: data.data.parentEmail })
                    );
                    dispatch(
                      updateMoreValues({ altNumber: data.data.altNumber })
                    );
                    dispatch(updateMoreValues({ userId: data.data.userId }));
                    service.setAuthorizationToken(data.data.authToken);
                    dispatch(
                      updateMoreValues({ studentId: data.data.studentId })
                    );
                    dispatch(
                      updateMoreValues({
                        hasActivePaidPlan: data.data.hasActivePaidPlan,
                      })
                    );
                    dispatch(
                      updateMoreValues({
                        loginFor: loginFor,
                      })
                    );
                    if (data.data.hasActivePaidPlan === true) {
                      if (loginFor === "Parent") {
                        navigate("/Parent/Dashboard");
                      } else {
                        navigate("/User/Dashboard");
                      }
                    } else {
                      if (loginFor === "Parent") {
                        navigate("/Parent/demoDashboard");
                      } else {
                        navigate("/User/demoDashboard");
                      }
                    }
                  } else {
                    // navigate("/CreateAccount", { state: { mobNo: mobileNo,coutryCodeNo:coutryCode } });
                    navigate("/BookSession", {
                      state: {
                        mobNo: mobileNo,
                        coutryCodeNo: coutryCode,
                        isOTPVerify: true,
                      },
                    });
                  }
                } else {
                  dispatch(showErrorSnackbar("wentWrong"));
                }
              },
              () => {
                dispatch(updateMoreValues({ isLoader: false }));
              }
            )
          );
        })
        .catch((err: any) => {
          dispatch(updateMoreValues({ isLoader: false }));
        });
    }
  }
  return (
    <div className="bookSessionPage">
      <div className="bookSessionLogo">
        <img
          src={localImages.logo}
          alt="IFAS Logo"
          className="headerlogo cursor"
          // onClick={goToHome}
        />
      </div>
      <div className="bookSessionCard rounded shadow">
      <div className="row authDiv w-100">
          <div className="rightDivPad position-relative m-auto">
            <h3 className="boldfont text-center mb-3">OTP Verification</h3>
            <div className="colorgray707070 text-center my-2">
              Enter a 6 digit OTP sent to{" "}
              <span className="colorblue fw-bold">
                +{coutryCode} {mobileNo}
              </span>
            </div>
            <div
              className="text-center d-flex justify-content-center"
              onClick={navigatelink}
            >
              <EditIcon className="cursor me-1" fontSize="small" />
              <NavLink>Edit Mobile Number</NavLink>
            </div>

            <div className="phoneInputBox">
              <label className="ms-4">Enter OTP</label>
              <div className="otpBox cursor mt-2">
                <OTPInput
                  inputStyle="inputStyle"
                  numInputs={6}
                  onChange={handleOTPChange}
                  renderSeparator={<span className="mx-2">-</span>}
                  value={otp}
                  inputType={inputType}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus
                />
              </div>
              <div className="mt-3">
                <TimerClock onResendPress={onResendPress} countdown={30} />
              </div>
              <div className="mt-5">
                <button
                  className={`w-100 rounded sendOTPBtn px-4 py-1 font24 justify-content-center ${
                    OTPValidate() ? "" : "active"
                  }`}
                  disabled={OTPValidate()}
                  onClick={onOTPVerify}
                >
                  Verify OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    // <div>
    //   <HeaderHome isHidden={true} />
    //   <div className="d-block d-md-none">
    //     <Sidebar data={SidebarDataFreeUser} />
    //   </div>
    //   <div className="loginBG bgLightGrey d-flex position-relative">
    //     <img
    //       src={localImages?.loginleftarrow}
    //       className="loginLeftArrow"
    //       alt="loginLeftArrow"
    //     />
    //     <img
    //       src={localImages?.loginrightarrow}
    //       className="loginRightArrow"
    //       alt="loginRightArrow"
    //     />
    //     <div className="row authDiv w-100">
    //       <div className="col-lg-5 rightDivPad position-relative m-auto boxshadow">
    //         <h3 className="boldfont text-center mb-3">OTP Verification</h3>
    //         <div className="colorgray707070 text-center my-2">
    //           Enter a 6 digit OTP sent to{" "}
    //           <span className="colorblue fw-bold">
    //             +{coutryCode} {mobileNo}
    //           </span>
    //         </div>
    //         <div
    //           className="text-center d-flex justify-content-center"
    //           onClick={navigatelink}
    //         >
    //           <EditIcon className="cursor me-1" fontSize="small" />
    //           <NavLink>Edit Mobile Number</NavLink>
    //         </div>

    //         <div className="phoneInputBox">
    //           <label className="ms-4">Enter OTP</label>
    //           <div className="otpBox cursor mt-2">
    //             <OTPInput
    //               inputStyle="inputStyle"
    //               numInputs={6}
    //               onChange={handleOTPChange}
    //               renderSeparator={<span className="mx-2">-</span>}
    //               value={otp}
    //               inputType={inputType}
    //               renderInput={(props) => <input {...props} />}
    //               shouldAutoFocus
    //             />
    //           </div>
    //           <div className="mt-3">
    //             <TimerClock onResendPress={onResendPress} countdown={30} />
    //           </div>
    //           <div className="mt-5">
    //             <button
    //               className={`w-100 rounded px-4 py-1 font24 border-0 ${
    //                 OTPValidate() ? "btnDisable" : "btnActive"
    //               }`}
    //               disabled={OTPValidate()}
    //               onClick={onOTPVerify}
    //             >
    //               Verify OTP
    //             </button>
    //           </div>
    //         </div>

    //         {/* <div className="mb-o tncdiv bottom-0 start-0 end-0 m-auto">
    //                     <p className="text-center ">
    //                         <span className="colorblue cursor">
    //                             T&C
    //                         </span>{' '}
    //                         and
    //                         <span className="colorblue cursor">
    //                             {' '}
    //                             Privacy Policy
    //                         </span>
    //                     </p>
    //                 </div> */}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
