import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import '../Assets/Css/SubscriptionPlan.css'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useState } from 'react';
import AdditionalPackagePopup from './additionalPackagePopup';
function SubscriptionPlan(props: any) {
  const navigate = useNavigate();
 const {priceData}=props;
 const [openPackagePopup,setopenPackagePopup]=useState<boolean>(false)
 const {ACCESS_TOKEN} = useSelector((state:any) => ({
  ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
}));

 const {userCountryCurrency} = useSelector((state:any) => ({
  userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
}));

function gotoCourseDetail(packageData:any,Levelid: any,level:number) {
  if(ACCESS_TOKEN){
    if(level===4){
      console.log('packageData', packageData);
      setopenPackagePopup(true)
    }
    else{
      navigate("/CourseDetails/" + packageData?._id+"/"+Levelid);
    }
  }
  else{
    navigate("/Login");
  }
}
  return (
    <div className="row">
    <div className="col-md-4 mb-5 mb-md-0 p-2 pt-1">
    <div className=' p-2 pt-0 rounded'>
      <div className="mb-4">
        <div className="FeatHeadingCard">
          OUR FEATURES
        </div>
        <div className="tringle"></div>
      </div>
      <div className="border priceDiv">
        <div className="regularPrice bookSessionOrange">REGULAR PRICE</div>
      </div>
      {priceData?.featureNames?.map((item:any, index:number) => (
        <div className="selectionCard border font16 d-flex align-items-center" key={index}>
          {item}
        </div>
      ))}
      <div className="selectionCard border font16 d-flex align-items-center">
      Brochure
        </div>
</div>
    </div>
    <div className="col-md-8 px-0">
      <div className="row">
        {priceData?.packageOptions?.sort((a:any, b:any) => a?.priorityOrder - b?.priorityOrder)?.map((item:any, index:number) => (
          <div className="col-md-3 mb-5 mb-md-0 p-1">
           <div className={` rounded position-relative ${item?.isRecommended===true?'border-orange':'border'}`}>
            {item?.isRecommended?<div className='position-absolute top-0 start-0 end-0 mx-auto backgroundOrange text-white recommended rounded-0 rounded-bottom font10'>Recommended</div>:null}
            <div className="mb-4">
              <div className={`FeatHeadingCard ${"lvl" + (index + 1)}`}>
                {item?.levelName}
              </div>
              <div className={`tringle ${"lvl" + (index + 1)}`}></div>
            </div>
            <div className="border-top border-bottom priceDiv">
              {item?.levelId!==4?<div><span className="price bookSessionOrange me-1">{getSymbolFromCurrency(userCountryCurrency)}{item?.offerPrice}</span>{getSymbolFromCurrency(userCountryCurrency)}<s>{item?.sellingPrice}</s></div>:null}
              <div className='colorBlue'>( <span className='colorblue fw-bold'>{item?.offerLectureCount} Session</span> )</div>
            </div>
            {
              item?.features?.map((data:any) => (
                <div className="selectionCard border-top border-bottom text-center d-flex align-items-center justify-content-center">
                  {data?.isSelected === true ?
                    <DoneIcon className="text-success" /> :
                    <CloseIcon className="text-danger" />
                  }
                </div>
              ))
            }
            <div className="selectionCard border-top border-bottom text-center d-flex align-items-center justify-content-center">
                  <a href={priceData?.packagePDF} target='_blank' rel="noreferrer"><PictureAsPdfIcon/></a>
                </div>
            <div className="selectionCard border text-center d-flex align-items-center justify-content-center p-0">
              <div className='w-100 h-100'>
                <button className={`buyNowBtn w-100 h-100 rounded-0 fw-bold font16 ${"lvl" + (index + 1)}`} onClick={()=>gotoCourseDetail(priceData,item?.packageOptionId,item?.levelId)}>Buy Now</button>
              </div>
            </div>
          </div>
          </div>
        ))}
      </div>
    </div>
    <AdditionalPackagePopup open={openPackagePopup} handleClose={()=>setopenPackagePopup(false)} packageData={priceData}/>
  </div>
  );
}

export default SubscriptionPlan;
