// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailBox{
    background: var(--lightGreen);
    height: 100%;
    max-height: 360px;
    overflow: auto;
}
.tickMark{
    font-size: 1.2em;
    color: var(--green);
    width: 1.2em;
    height: 1.2em;
    margin-right: 10px;
}
.courseBanner{
    aspect-ratio: 16/9;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/courseDetail.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".detailBox{\n    background: var(--lightGreen);\n    height: 100%;\n    max-height: 360px;\n    overflow: auto;\n}\n.tickMark{\n    font-size: 1.2em;\n    color: var(--green);\n    width: 1.2em;\n    height: 1.2em;\n    margin-right: 10px;\n}\n.courseBanner{\n    aspect-ratio: 16/9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
