import React from 'react';
import localImages from '../Constant/localImages';
import { recommendedCourse } from '../Utils/dataConstants';
import getSymbolFromCurrency from 'currency-symbol-map';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useSelector } from 'react-redux';
function RecommendedCard(props: any) {
  const {userCountryCurrency} = useSelector((state:any) => ({
    userCountryCurrency: state?.AuthReducer?.userCountryCurrency,
  }));
  return (       
        <div className="border shadow-sm font12 p-3 px-4 rounded mb-3 bgwhite">
          <div className='row'>
            <div className='col-4 d-flex align-items-center'>
            <img className="w-100" src={props?.data?.bannerImage} alt="Video Icon" />
            </div>
            <div className='col-8'>
            <div className="d-flex justify-content-between mb-2">
            <span className="fw-bold font16">{props?.data?.name}</span>
      
            <span className="colorblue d-flex">              
              <div
                className="justify-content-between "
              >
                <a href={props?.data?.packagePDF} target="_blank" rel="noreferrer">
                  <PictureAsPdfIcon className='colorblue'/>
                </a>
              </div>
            </span>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <span className="fw-bold font16">{props?.data?.levelName}</span>
          </div>

          <div className="mb-1">
         <span className='fw-bold'>Lecture :</span>
         <span className="ms-2 colorgrey fw-bold">{props?.data?.offerCount}</span>
       </div>

       <div className="mb-1 d-flex">
       <span>Course Price :</span>
         <span className="ms-2">
          <span className="fw-bold">          
          <div><span className="colorblue">{getSymbolFromCurrency(userCountryCurrency)}{props?.data?.offerPrice}</span>
          <span> <s className='ms-1'>{getSymbolFromCurrency(userCountryCurrency)}{props?.data?.sellingPrice}</s></span></div>
           </span>          
         </span>
       </div>

          <div className="d-flex justify-content-between align-items-center mt-2">
            <button
              className="btn btn-secondary px-2"
              style={{ fontSize: '10px' }} onClick={()=>props?.addCart(props?.data)}
            >
              Add +
            </button>
          </div>
            </div>

          </div>
          
        </div>
  );
}

export default RecommendedCard;
