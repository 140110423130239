import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
function OverallRating(props:any) { 
  const marks = [
    {
      value: 0,
      label: '0',
      labelText:'Bad'
    },
    {
      value: 1,
      label: '1',
      labelText:'Bad'
    },
    {
      value: 2,
      label: '2',
      labelText:'Bad'
    },
    {
      value: 3,
      label: '3',
      labelText:'Below Average'
    },
    {
      value: 4,
      label: '4',
      labelText:'Below Average'
    },
    {
      value: 5,
      label: '5',
      labelText:'Average'
    },
    {
      value: 6,
      label: '6',
      labelText:'Average'
    },
    {
      value: 7,
      label: '7',
      labelText:'Good'
    },
    {
      value: 8,
      label: '8',
      labelText:'Good'
    },
    {
      value: 9,
      label: '9',
      labelText:'Excellent'
    },
    {
      value: 10,
      label: '10',
      labelText:'Excellent'
    },
  ];
  
  function valuetext(value: number) {
    return `${value}`;
  }
  function valueLabelFormat(value: number) {  
    return `${marks[value]?.labelText}`;
  }
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      props?.setValue(newValue);
    }
  };
  return (
    <Box sx={{ width: "100%",'& .MuiSlider-thumb': {
      color: "#F15D22"
  },
  '& .MuiSlider-track': {
      color: "#F15D22"
  },
  '& .MuiSlider-rail': {
      color: "#F15D22"
  },
  '& .MuiSlider-active': {
      color: "#F15D22"
  } }}>
    <Slider
      aria-label="Custom marks"
      defaultValue={0}
      getAriaValueText={valuetext}
      step={1}
      valueLabelDisplay="auto"
      valueLabelFormat={valueLabelFormat}
      marks={marks}
      max={10}
      color="secondary"
      onChange={handleChange}
      value={props?.val}
      disabled={props?.disabled}
    />
  </Box>
  );
}

export default OverallRating;
