import React, { useEffect, useState } from "react";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { useAppDispatch } from "../hooks";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  addAffilatemarketingPage,
  addMarketingLandingData,
  getCountry,
  getCourseTypelist,
  getGeneralExamList,
  getSubjectList,
  gradeLevelList,
} from "../Redux/Actions/AuthAction";
import { validateFullName, validateIsNumberOnly } from "../Utils/validators";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import InputField from "../Atom/InputField";
import PhoneInputField from "../Atom/phoneInputField";
import Dropdown from "./dropdown";
import DropdownCustom from "./dropdownCustom";

export default function AffilateMarketingForm(props?: any) {
  const dispatch = useAppDispatch();
  const [firstName, setfirstname] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [email, setemail] = useState("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("1");
  const [isSubmit, setIsSubmit] = useState<boolean>(false); 
  const [gradeList, setGradeList] = useState([]);
  const [grade, setGrade] = useState<string>(""); 
  const [country, setCountry] = useState(""); 
  const { userCountryCallingCode } = useSelector((state: any) => ({
    userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode,
  }));
  const currentURLarr = useLocation().pathname.split('/');
  let referalid = currentURLarr[currentURLarr.length - 1];
  console.log("https://devadmin.lurnigo.com/" ,referalid)
  useEffect(() => { 
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 4);

          setGradeList(filterGrade);
        },
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    dispatch(getCountry(
      (data: any) => {
        const filterCountry = data?.filter((item: any) => item?.phonecode == userCountryCallingCode?.substring(1))
        setCountry(filterCountry[0]?._id)
      },
      () => { })
    );

  }, [userCountryCallingCode]);
  
  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfirstname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputText = (e: any) => {
    if (validateFullName(e)) setlastName(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };

  function submitForm(e: { preventDefault: () => void }) {
    e.preventDefault();
    const PostData: any = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: mob,
      countryCode:"+"+coutryCode, 
      gradeId: grade,  
      sourceId: 6, 
      refId:referalid,
      countryId: country,
    };
    
    console.log("PostData", PostData);
    dispatch(
      addAffilatemarketingPage(
        PostData,
        (res: any) => {
          setIsSubmit(true);
          clearData();
          props?.handleClose();
        },
        () => {}
      )
    );
    // return (true)
  }

  function clearData() {
    setfirstname("");
    setemail("");
    setmob(""); 
    setlastName("")
  }

  function validation() {
    return (
      firstName === "" ||
      lastName ==="" ||
      email === "" ||
      mob?.length < 10 
       
    );
  }
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
 
  return (
    <div className="row mx-0">
      <form className="w-100">
        <div className="row mx-0">
        <div className="my-2 col-md-6">
                      <label className="mb-2">
                        Parents First Name <span className="  ">*</span>
                      </label>
                      <div className="d-flex ">
                        <InputField
                          placeholder="Enter Parents Name"
                          value={firstName}
                          setValue={handleFirstInputText}
                        />
                      </div>
                    </div>
                    <div className="my-2 col-md-6">
                      <label className="mb-2">
                        Parents Last Name <span className="  ">*</span>
                      </label>
                      <div className="d-flex ">
                        <InputField
                          placeholder="Enter Parents Name"
                          value={lastName}
                          setValue={handleLastInputText}
                        />
                      </div>
                    </div>
          <div className="my-2 col-md-6">
            <label className="mb-2 colorgray707070">Phone Number</label>
            <div className="d-flex position-relative">
              <select
                className="form-select cursor coutryCode  rounded-0 rounded-start"
                aria-label="Default select example"
                defaultValue={1}
                value={coutryCode}
                onChange={onChangeCoutryCode}
              >
                {countryData?.map((item: any, index) => {
                  return (
                    <option value={item.phonecode}>
                      {item?.isoCode} +{item?.phonecode}
                    </option>
                  );
                })}
              </select>
              <PhoneInputField
                placeholder="Enter Mobile Number"
                value={mob}
                maxLength={"10"}
                setValue={setPhoneNumber}
              />
              <span className="mendatory ms-2 fs-5">*</span>
            </div>
          </div>
          <div className="my-2 col-md-6">
            <label className="mb-2 colorgray707070">Email</label>
            <div className="d-flex">
              <input
                className="form-control p-2"
                type="email"
                value={email}
                placeholder="Enter Your Email Address"
                onChange={(e) => setemail(e.target.value)}
              />
              <span className="mendatory ms-2 fs-5">*</span>
            </div>
          </div>
          <div className="my-2 col-md-6">
            <label className="mb-2 colorgray707070">Student Grade</label>
            <div className="d-flex">
              <Dropdown
                placeholder="Select Grade"
                setInputText={(value: any) => setGrade(value)}
                value={grade}
                options={gradeList}
              />
              <span className="mendatory ms-2 fs-5">*</span>
            </div>
          </div>
          <div className="my-2 col-md-6 ">
                      <label className="mb-2">
                        Referal<span>*</span>
                      </label>
                      <div className= "col-sm-6">
                        <input
                          className="form-control"
                          type=" "
                          value={referalid}
                          placeholder="Enter Referal ID"
                          // onChange={(e) => setReferalID(e.target.value)}
                        />  
                           
                   
                       
                      </div>
                    </div>
  
        </div>
      </form>
      <div className="p-2 px-4 border-top my-3">
        <button
          onClick={submitForm}
          className={`bookSessionButton w-100 rounded font16 mt-2 ${
            validation() ? "bg-secondary" : ""
          }`}
          disabled={validation()}
        >
          Book Your Trial Session
        </button>
      </div>
    </div>
  );
}
