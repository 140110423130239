import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import "../Assets/Css/lectureDashboard.css";
import parser from "html-react-parser";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Lottie from "react-lottie";
import Hints_Coins from "../Assets/Images/UserLecture/Hints_Coins.webp";
import funFactClose from "../Assets/Images/UserLecture/funFact.png";
import funFactOpen from "../Assets/Images/UserLecture/funFactOpen.png";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { useSpeech } from "react-text-to-speech";
import ImgPopup from "../Module/imgPopup";
import localImages from "../Constant/localImages";
import localImagesNew from "../Constant/localImagesNew";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import TimerCountDown from "../Component/timerCountDown";
import DesmosPopup from "../Module/desmosPopup";
import LectureCanvasBox from "../Component/lectureCanvasBox";
import {
  addAcademicData,
  addDemoData,
  addSubmitDescriptiveQuestion,
} from "../Redux/Actions/examActions";
import useSound from "use-sound";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import CodeEditor from "./codeEditor";
import PythonCodeEditor from "./pythonCodeEditor";
import MatchThePair from "./matchThePair";
import LectureTimer from "../Module/lectureTimer";
import CanvasBox from "../Component/canvasBox";
import Polypad from "../Module/Polypad";
import CategoryIcon from "@mui/icons-material/Category";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FlipCardTemplate from "../Component/flipCardTemplate";
import AccordianTemplate from "../Component/accordianTemplate";
import HorizontalTabTemplet from "../Component/horizontalTabTemplet";
import ImageTabTemplate from "../Component/imageTabTemplate";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DrawIcon from "@mui/icons-material/Draw";
import MenuIcon from "@mui/icons-material/Menu";
import ProgressBar from "react-bootstrap/ProgressBar";
import WidgetsIcon from "@mui/icons-material/Widgets";

export default function LetureDetails() {
  const config = {
    loader: { load: ["input/asciimath"] },
    asciimath: {
      displaystyle: true,
      delimiters: [
        ["$", "$"],
        ["`", "`"],
      ],
    },
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pointIndex } = location?.state;
  const sessionData: any = sessionStorage?.getItem("Lecture");
  const updatedData: any = JSON.parse(sessionData);
  const AllId: any = sessionStorage?.getItem("AllId");
  const IDData: any = JSON.parse(AllId);
  const [LectureDetailsData, setLectureDetailsData] =
    useState<any>(updatedData);
  const [show, setShow] = useState(false);
  const [showFallenCoin, setShowFallenCoin] = useState(false);
  const [showFlipCoin, setShowFlipCoin] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [showQueDescription, setShowQueDescription] = useState(false);
  const [disabledQues, setDisabledQues] = useState(false);
  const [showWrong, setshowWrong] = useState(false);
  const [funFactPopup, setFunFactPopup] = useState(false);
  const [disabledOptions, setDisabledOptions] = useState<any>([]);
  const [CorrectAns, setCorrectAns] = useState<string>("");
  const [attempt, setAttempt] = useState<number>(1);
  const [firstSelectedOption, setFirstSelectedOption] = useState<number>(0);
  const [firstEnterAns, setfirstEnterAns] = useState<String>("");
  const [toggle, setToggle] = useState(false);
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [ImgPath, setImgPath] = useState("");
  const [OpenImage, setOpenImage] = useState(false);
  const bottomRef: any = useRef(null);
  const [startTime, setStartTime] = useState<any>(null);
  const [timeTaken, setTimeTaken] = useState<any>(0);
  const [duration, setduration] = useState<any>(0);
  const [isNewTimer, setisNewTimer] = useState(false);
  const [isTimerComplete, setisTimerComplete] = useState(false);
  const [pauseTimer, setpauseTimer] = useState(false);
  const [openDesmos, setOpenDesmos] = useState(false);
  const [openPolypad, setOpenPolypad] = useState(false);
  const [showSubmitDescription, setshowSubmitDescription] = useState(false);
  const [showCoding, setshowCoding] = useState<boolean>(false);
  const [fullScreenCoding, setfullScreenCoding] = useState<boolean>(false);
  const [firstSelectedMSQOption, setFirstSelectedMSQOption] = useState<any>([]);
  const [isAudio, setisAudio] = useState<boolean>(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [play] = useSound(localImages?.coinFlipSound, {
    volume: 0.1,
    playbackRate: 1,
    interrupt: true,
  });
  const [dndMatchs, setdndMatchs] = useState<any>(
    updatedData.lecturePoint[pointIndex]?.questionType === "5" &&
      updatedData.lecturePoint[pointIndex].userAnswer
      ? JSON?.parse(updatedData.lecturePoint[pointIndex].userAnswer)
      : {}
  );
  const [firstAttemptDrop, setFirstAttemptDrop] = useState<any>({});
  const [leftItems, setLeftItems] = useState<any>([]);
  const [rightItems, setRightItems] = useState<any>([]);

  /*********************** Text To Speech********************************* */
  const funFactText = useMemo(
    () => (
      <>
        {updatedData?.lecturePoint[pointIndex].funFactData?.map((item: any) => {
          return (
            <div>
              {item?.descType === 1 ? (
                <div className="px-2 font20">{parser(item?.content)}</div>
              ) : null}
              {item?.descType === 2 ? (
                <div className="px-2 font20">{parser(item?.content)}</div>
              ) : null}
              {item?.descType === 3 ? (
                <div className="px-2 font20">{parser(item?.content)}</div>
              ) : null}
              {item?.descType === 4 ? (
                <ul className="mb-3">
                  {item?.content
                    ?.split(" || ")
                    ?.map((subItem: any, j: number) => (
                      <li className="mb-2 font20" key={j}>
                        {subItem}
                      </li>
                    ))}
                </ul>
              ) : null}
            </div>
          );
        })}
      </>
    ),
    []
  );

  const {
    Text, // Component that returns the modified text property
    speechStatus, // String that stores current speech status
    start, // Function to start the speech or put it in queue
    pause, // Function to pause the speech
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({
    text: funFactText,
    voiceURI: "Google US English",
  });

  /*********************** Text To Speech********************************* */

  const correctFireCrackerOptions = {
    loop: true,
    autoplay: true,
    animationData: localImages?.correctFireCracker,
    renderer: "svg",
  };
  useEffect(() => {
    if (updatedData.lecturePoint[pointIndex].type === 2) {
      if (updatedData.lecturePoint[pointIndex].questionType === "5") {
        const leftItemsData = updatedData.lecturePoint[
          pointIndex
        ].options?.filter((data: any) => data?.part === "partA");
        const rightItemsData = updatedData.lecturePoint[
          pointIndex
        ].options?.filter((data: any) => data?.part === "partB");
        setLeftItems(leftItemsData);
        setRightItems(rightItemsData);
      }
      if (updatedData.lecturePoint[pointIndex].status === 3) {
        setTimeTaken(0);
        setisNewTimer(true);
        setpauseTimer(true);
      } else {
        setStartTime(Date.now());
        setduration(updatedData.lecturePoint[pointIndex]?.duration);
        setTimeTaken(0);
        setisNewTimer(true);
        setisTimerComplete(false);
        setpauseTimer(false);
      }
    } else {
      // setisNewTimer(true);
      // setTimeTaken(0);
      setpauseTimer(true);
    }
  }, [pointIndex]);

  useEffect(() => {
    if (updatedData.lecturePoint[pointIndex].questionType === "5") {
      const convertToString = JSON.stringify(dndMatchs);
      updatedData.lecturePoint[pointIndex].userAnswer = convertToString;
      setLectureDetailsData(updatedData);
      sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
    }
  }, [dndMatchs]);

  const flipCoinOptions = {
    loop: true,
    autoplay: true,
    animationData: localImages?.flipCoin,
    renderer: "svg",
  };

  const fallenCoinOptions = {
    loop: true,
    autoplay: true,
    animationData: localImages?.fallenCoin,
    renderer: "svg",
  };
  const speakText = (text: string) => {
    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = "en-US"; // Set language
    speechSynthesis.speak(speech);
  };
  const stripHtmlTags = (text: string) => {
    return text.replace(/<[^>]*>?/gm, ""); // Remove HTML tags
  };

  const readAllDescriptions = () => {
    let combinedText = "";
    updatedData.lecturePoint[pointIndex]?.contentDetails.forEach((item) => {
      // Remove HTML tags for speech
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = item.desc;
      const cleanText = tempDiv.textContent || tempDiv.innerText || "";
      combinedText += cleanText + " "; // Combine texts
    });
    speakText(combinedText);
  };
  const handleNext = () => {
    const lecturePoint = updatedData.lecturePoint[pointIndex];
    const currentContent = lecturePoint?.contentDetails?.[index];

    if (lecturePoint?.type === 1) {
      // Check if content type requires split handling
      if (currentContent?.textType === 4) {
        const descArray = currentContent?.desc?.split(" || ") || [];
        if (subIndex < descArray.length - 1) {
          // Handle sub-index content
          setSubIndex(subIndex + 1);
          const listItem = descArray[subIndex + 1];
          if (listItem && isAudio) {
            speakText(stripHtmlTags(listItem));
          }
        } else if (index < lecturePoint?.contentDetails?.length - 1) {
          // Move to the next content item
          const nextContent = lecturePoint?.contentDetails[index + 1];
          if (nextContent?.desc && isAudio) {
            speakText(stripHtmlTags(nextContent.desc));
          }
          setIndex(index + 1);
          setSubIndex(0);
        } else {
          nextLecture();
        }
      } else {
        // Handle other content types
        if (index < lecturePoint?.contentDetails?.length - 1) {
          const nextContent = lecturePoint?.contentDetails[index + 1];
          if (nextContent?.desc && isAudio) {
            const cleanText =
              nextContent.textType === 4
                ? stripHtmlTags(nextContent?.desc?.split(" || ")[0])
                : stripHtmlTags(nextContent.desc);
            speakText(cleanText);
          }
          setIndex(index + 1);
          setSubIndex(0);
        } else {
          nextLecture();
        }
      }
    } else {
      // Handle non-text content
      if (lecturePoint?.status !== 3) {
        switch (lecturePoint?.questionType) {
          case "1":
            // Reset selected option for single-choice questions
            lecturePoint?.options?.forEach((item) => (item.isSelected = false));
            lecturePoint.options[firstSelectedOption].isSelected = true;
            break;
          case "5":
            // Store user answer for drop-based questions
            lecturePoint.userAnswer = JSON.stringify(firstAttemptDrop);
            break;
          case "3":
            // Handle multiple-choice questions
            lecturePoint?.options?.forEach((item) => (item.isSelected = false));
            firstSelectedMSQOption.forEach((optionIndex) => {
              if (lecturePoint?.options[optionIndex]) {
                lecturePoint.options[optionIndex].isSelected = true;
              }
            });
            break;
          default:
            break;
        }
      }
      setAttempt(1);
      nextLecture();
    }
  };

  // useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === "ArrowRight") {
      handleOnArrowNext();
    }
    if (event.key === "ArrowLeft") {
    }
    // Call the function on any key press
  };

  // Add event listener for keydown
  window.addEventListener("keydown", handleKeyDown);

  // Clean up the event listener on component unmount
  // return () => {
  //   window.removeEventListener('keydown', handleKeyDown);
  // };
  // }, []);

  const handleOnArrowNext = () => {
    const lecturePoint = updatedData.lecturePoint[pointIndex];
    const currentContent = lecturePoint?.contentDetails?.[index];

    if (lecturePoint?.type === 1) {
      // Check if content type requires split handling
      if (currentContent?.textType === 4) {
        const descArray = currentContent?.desc?.split(" || ") || [];
        if (subIndex < descArray.length - 1) {
          // Handle sub-index content
          setSubIndex(subIndex + 1);
          const listItem = descArray[subIndex + 1];
          if (listItem && isAudio) {
            speakText(stripHtmlTags(listItem));
          }
        } else if (index < lecturePoint?.contentDetails?.length - 1) {
          // Move to the next content item
          const nextContent = lecturePoint?.contentDetails[index + 1];
          if (nextContent?.desc && isAudio) {
            speakText(stripHtmlTags(nextContent.desc));
          }
          setIndex(index + 1);
          setSubIndex(0);
        } else {
          nextLecture();
        }
      } else {
        // Handle other content types
        if (index < lecturePoint?.contentDetails?.length - 1) {
          const nextContent = lecturePoint?.contentDetails[index + 1];
          if (nextContent?.desc && isAudio) {
            const cleanText =
              nextContent.textType === 4
                ? stripHtmlTags(nextContent?.desc?.split(" || ")[0])
                : stripHtmlTags(nextContent.desc);
            speakText(cleanText);
          }
          setIndex(index + 1);
          setSubIndex(0);
        } else {
          nextLecture();
        }
      }
    }
  };

  const handleOnArrowBack = () => {
    const lecturePoint = updatedData.lecturePoint[pointIndex];
    const currentContent = lecturePoint?.contentDetails?.[index];

    if (lecturePoint?.type === 1) {
      // Check if content type requires split handling
      if (currentContent?.textType === 4) {
        const descArray = currentContent?.desc?.split(" || ") || [];
        if (subIndex < descArray.length - 1) {
          // Handle sub-index content
          setSubIndex(subIndex - 1);
          const listItem = descArray[subIndex - 1];
          if (listItem && isAudio) {
            speakText(stripHtmlTags(listItem));
          }
        } else if (index < lecturePoint?.contentDetails?.length - 1) {
          // Move to the next content item
          const nextContent = lecturePoint?.contentDetails[index - 1];
          if (nextContent?.desc && isAudio) {
            speakText(stripHtmlTags(nextContent.desc));
          }
          setIndex(index - 1);
          setSubIndex(0);
        } else {
          nextLecture();
        }
      } else {
        // Handle other content types
        if (index < lecturePoint?.contentDetails?.length - 1) {
          const nextContent = lecturePoint?.contentDetails[index - 1];
          if (nextContent?.desc && isAudio) {
            const cleanText =
              nextContent.textType === 4
                ? stripHtmlTags(nextContent?.desc?.split(" || ")[0])
                : stripHtmlTags(nextContent.desc);
            speakText(cleanText);
          }
          setIndex(index - 1);
          setSubIndex(0);
        } else {
          nextLecture();
        }
      }
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [bottomRef?.current]);

  useEffect(() => {
    if (updatedData) {
      if (updatedData.lecturePoint[pointIndex].status === 3) {
        setDisabledQues(true);
        if (updatedData.lecturePoint[pointIndex]?.type === 1) {
          setIndex(
            updatedData.lecturePoint[pointIndex]?.contentDetails?.length - 1
          );
          if (
            updatedData.lecturePoint[pointIndex]?.contentDetails[
              updatedData.lecturePoint[pointIndex]?.contentDetails?.length - 1
            ]?.textType === 4
          ) {
            setSubIndex(
              updatedData.lecturePoint[pointIndex]?.contentDetails[
                updatedData.lecturePoint[pointIndex]?.contentDetails?.length - 1
              ].desc?.split(" || ").length - 1
            );
          } else {
            setSubIndex(0);
          }
        }
      }
    }
  }, [updatedData]);

  const nextLecture = () => {
    setCorrectAns("");
    setDisabledOptions([]);
    setshowWrong(false);
    setShow(false);
    setshowSubmitDescription(false);
    setShowFallenCoin(false);
    setShowFlipCoin(false);
    setShowHint(false);
    setShowQueDescription(false);
    setToggle(false);
    setDisabledQues(false);
    setIndex(0);
    setSubIndex(0);
    setStartTime(null);
    setisNewTimer(false);
    setFirstSelectedOption(0);
    setFirstSelectedMSQOption([]);
    setfirstEnterAns("");
    setFirstAttemptDrop({});
    setdndMatchs({});
    setLeftItems([]);
    setRightItems([]);
    setMenuVisible(false);
    if (updatedData.lecturePoint?.length - 1 === parseInt(pointIndex)) {
      if (updatedData.lecturePoint[pointIndex]?.type === 1) {
        updatedData.lecturePoint[pointIndex].status = 3;
      }
      const sendData = {
        topicId: IDData.topicId,
        studentId: IDData.studentId,
        teacherId: IDData?.teacherId,
        teacher: IDData?.teacher,
      };
      // const encodedData = encodeURIComponent(JSON.stringify(sendData));
      if (updatedData.status !== 3) {
        // updatedData.lecturePoint[pointIndex].status = 3;
        updatedData.status = 3;
        const filterQuestion = updatedData?.lecturePoint?.filter(
          (item: any) => item?.type === 2
        );
        let correctQuestion = 0,
          incorrectQuestion = 0,
          descriptiveQuestionCount = 0;

        for (let data of filterQuestion) {
          let isCorrectAns = false;
          let isDescAns = false;
          if (data?.questionType === "1") {
            isCorrectAns =
              data?.options.find(
                (element: any) =>
                  element.isCorrect &&
                  element.isSelected &&
                  data?.earnedPoints > 0
              ) != ( undefined || null);
          } else if (data?.questionType === "2") {
            isCorrectAns = data?.options[0]?.option === data?.userAnswer;
          } else if (data?.questionType === "4") {
            isDescAns = true;
          }
          if (isCorrectAns) {
            correctQuestion++;
          } else if (isDescAns) {
            descriptiveQuestionCount++;
          } else {
            incorrectQuestion++;
          }
        }
        updatedData.correctQuestion = correctQuestion;
        updatedData.incorrectQuestion = incorrectQuestion;
        updatedData.descriptiveQuestionCount = descriptiveQuestionCount;
        const postdata = updatedData;
        postdata["teacherId"] = IDData?.teacherId;
        postdata["studentId"] = IDData?.studentId;
        postdata["lectureId"] = IDData?.lectureId;
        postdata["topicId"] = IDData?.topicId;
        if (IDData?.isDemo) {
          postdata["demo"] = true;

          dispatch(
            addDemoData(
              postdata,
              (res: any) => {
                navigate("/LectureIndex", {
                  // state: { lectureDataIndex: lectureDataIndex },
                });
                // navigate("/LectureDashboard", { state: { data: sendData } });
              },
              () => {}
            )
          );
        } else {
          dispatch(
            addAcademicData(
              postdata,
              (res: any) => {
                navigate("/LectureDashboard", { state: { data: sendData } });
              },
              () => {}
            )
          );
        }
      } else {
        if (IDData?.isDemo) {
          navigate("/LectureIndex", {
            // state: { lectureDataIndex: lectureDataIndex },
          });
        } else {
          navigate("/LectureDashboard", { state: { data: sendData } });
        }

        // window.open(
        //   `${process.env.REACT_APP_WEBLINK}/LectureDashboard?data=${encodedData}`,
        //   "_parent"
        // );
        //  window.open(`http://localhost:3700/LectureDashboard?data=${encodedData}`, '_parent');
      }

      sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));

      // navigate("/LectureDashboard");
    } else {
      updatedData.lecturePoint[pointIndex].status = 3;
      if (updatedData.lecturePoint[pointIndex + 1].status === 3) {
      } else {
        updatedData.lecturePoint[pointIndex + 1].status = 2;
      }
      sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
      navigate("/letureDetails", {
        state: {
          pointIndex: parseInt(pointIndex) + 1,
        },
      });
    }
  };

  const prevLecture = () => {
    setCorrectAns("");
    setDisabledOptions([]);
    setshowWrong(false);
    setShow(false);
    setshowSubmitDescription(false);
    setShowFallenCoin(false);
    setShowFlipCoin(false);
    setToggle(false);
    setDisabledQues(false);
    setIndex(0);
    setSubIndex(0);
    navigate("/letureDetails", {
      state: {
        pointIndex: parseInt(pointIndex) - 1,
      },
    });
  };
  const goToBack = () => {
    setCorrectAns("");
    setDisabledOptions([]);
    setshowWrong(false);
    setShow(false);
    setshowSubmitDescription(false);
    setShowFallenCoin(false);
    setShowFlipCoin(false);
    setShowHint(false);
    setShowQueDescription(false);
    setToggle(false);
    setDisabledQues(false);
    setIndex(0);
    setSubIndex(0);
    navigate("/LectureIndex", {
      // state: { lectureDataIndex: lectureDataIndex },
    });
  };

  const onChangeHint = (value: any) => {
    if (!showHint && updatedData?.totalHints > 0) {
      setShowHint(value);
      updatedData.totalHints -= 1;
      updatedData.totalUsedHints += 1;
      updatedData.lecturePoint[pointIndex].usedhints = 1;
      sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
    }
  };

  const onChangeDescription = (value: any) => {
    setShowQueDescription(value);
  };
  const getSelectedData = (index: number) => {
    updatedData.lecturePoint[pointIndex].options?.forEach(
      (item: any) => (item.isSelected = false)
    );
    updatedData.lecturePoint[pointIndex].options[index].isSelected = true;
    setLectureDetailsData(updatedData);
    sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
  };

  const getSelectedMSQData = (e: boolean, index: number) => {
    // updatedData.lecturePoint[pointIndex].options?.forEach(
    //   (item: any) => (item.isSelected = false)
    // );
    updatedData.lecturePoint[pointIndex].options[index].isSelected = e;
    setLectureDetailsData(updatedData);
    sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
  };

  const onChangeInput = (value: any) => {
    updatedData.lecturePoint[pointIndex].userAnswer = value;
    setLectureDetailsData(updatedData);
    sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
  };
  console.log("attempt", attempt);
  const handleSubmit = () => {
    const timeDifference = (Date.now() - startTime) / 1000;

    if (updatedData.lecturePoint[pointIndex]?.questionType === "1") {
      const selectedOption = updatedData.lecturePoint[
        pointIndex
      ].options?.filter((item: any) => item?.isSelected);
      const selectedIndex = updatedData.lecturePoint[
        pointIndex
      ].options?.findIndex((item: any) => item?.isSelected);
      if (attempt === 1) {
        setFirstSelectedOption(selectedIndex);
      }
      if (selectedOption?.length > 0) {
        setAttempt((oldAttemptCount) => oldAttemptCount + 1);
        if (selectedOption[0]?.isCorrect) {
          let userTimeTaken: any = (
            Math.round(timeDifference * 100) / 100
          ).toFixed(2);
          const minutes: any = Math.floor(userTimeTaken / 60);
          if (
            parseInt(minutes) <
            parseInt(updatedData.lecturePoint[pointIndex]?.duration)
          ) {
            updatedData.lecturePoint[pointIndex].timeTaken = userTimeTaken;
          } else {
            const duration =
              parseInt(updatedData.lecturePoint[pointIndex]?.duration) * 60;
            const durationIn = duration.toString();
            updatedData.lecturePoint[pointIndex].timeTaken = durationIn;
            setLectureDetailsData(updatedData);
          }
          setTimeTaken(userTimeTaken);
          setCorrectAns(selectedIndex);
          setShow(true);
          setDisabledQues(true);
          setshowWrong(false);
          updatedData.lecturePoint[pointIndex].status = 3;
          if (!isTimerComplete) {
            setpauseTimer(true);
          }

          if (updatedData.lecturePoint?.length - 1 === parseInt(pointIndex)) {
            // updatedData.status = 3;
          } else {
            updatedData.lecturePoint[pointIndex + 1].status = 2;
          }

          setTimeout(() => {
            setShow(false);
          }, 3000);
          if (
            attempt === 1 &&
            !isTimerComplete &&
            updatedData.lecturePoint[pointIndex].usedhints === 0
          ) {
            setDisabledBtn(true);
            setTimeout(() => {
              play();
              setShowFlipCoin(true);
            }, 3000);
            setTimeout(() => {
              updatedData.lecturePoint[pointIndex].earnedPoints =
                updatedData.lecturePoint[pointIndex].points;
              updatedData.totalEarnedPoint +=
                updatedData.lecturePoint[pointIndex].points;
              updatedData.lectureEarnedPoints +=
                updatedData.lecturePoint[pointIndex].points;
              sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
              setShowFlipCoin(false);
              // setDisabledQues(true);
              setDisabledBtn(false);
            }, 4800);
          } else {
            // setDisabledQues(true);
            sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
          }
        } else if (selectedIndex >= 0) {
          setDisabledOptions((prevDisabledOptions: any) => [
            ...prevDisabledOptions,
            selectedIndex,
          ]);
          setShow(false);
          setShowFallenCoin(false);
          setShowFlipCoin(false);
          setshowWrong(true);
          setTimeout(() => {
            setshowWrong(false);
          }, 3000);
        }
      } else {
        dispatch(showErrorSnackbar("Please Select atleast one option") as any);
      }
    } else if (updatedData.lecturePoint[pointIndex]?.questionType === "2") {
      if (attempt === 1) {
        setfirstEnterAns(updatedData.lecturePoint[pointIndex].userAnswer);
      }
      if (updatedData.lecturePoint[pointIndex].userAnswer) {
        setAttempt((oldAttemptCount) => oldAttemptCount + 1);
        let userTimeTaken: any = (
          Math.round(timeDifference * 100) / 100
        ).toFixed(2);
        const minutes: any = Math.floor(userTimeTaken / 60);
        if (
          parseInt(minutes) <
          parseInt(updatedData.lecturePoint[pointIndex]?.duration)
        ) {
          updatedData.lecturePoint[pointIndex].timeTaken = userTimeTaken;
        } else {
          const duration =
            parseInt(updatedData.lecturePoint[pointIndex]?.duration) * 60;
          const durationIn = duration.toString();
          updatedData.lecturePoint[pointIndex].timeTaken = durationIn;
          setLectureDetailsData(updatedData);
        }
        setTimeTaken(userTimeTaken);
        updatedData.lecturePoint[pointIndex].status = 3;
        if (updatedData.lecturePoint?.length - 1 === parseInt(pointIndex)) {
          // updatedData.status = 3;
        } else {
          updatedData.lecturePoint[pointIndex + 1].status = 2;
        }
        setDisabledQues(true);
        if (
          updatedData.lecturePoint[pointIndex].userAnswer ===
          updatedData.lecturePoint[pointIndex].options[0].description?.trim()
        ) {
          setShow(true);
          setshowWrong(false);

          if (!isTimerComplete) {
            setpauseTimer(true);
          }
          setTimeout(() => {
            setShow(false);
          }, 3000);
          if (
            attempt === 1 &&
            !isTimerComplete &&
            updatedData.lecturePoint[pointIndex].usedhints === 0
          ) {
            setDisabledBtn(true);
            setTimeout(() => {
              play();
              setShowFlipCoin(true);
            }, 3000);
            setTimeout(() => {
              updatedData.lecturePoint[pointIndex].earnedPoints =
                updatedData.lecturePoint[pointIndex].points;
              updatedData.totalEarnedPoint +=
                updatedData.lecturePoint[pointIndex].points;
              updatedData.lectureEarnedPoints +=
                updatedData.lecturePoint[pointIndex].points;
              sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
              setShowFlipCoin(false);
              // setDisabledQues(true);
              setDisabledBtn(false);
            }, 4800);
          } else {
            // setDisabledQues(true);
            sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
          }
        } else {
          sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
          //setDisabledQues(true);
          console?.log("heloo vipul");
          setShow(false);
          setShowFallenCoin(false);
          setShowFlipCoin(false);
          setshowWrong(true);
          setTimeout(() => {
            setshowWrong(false);
          }, 3000);
        }
      } else {
        dispatch(showErrorSnackbar("Please fill answer") as any);
      }
    } else if (updatedData.lecturePoint[pointIndex]?.questionType === "3") {
      const selectedOption = updatedData.lecturePoint[
        pointIndex
      ].options?.filter((item: any) => item?.isSelected);
      const selectedIndex = updatedData.lecturePoint[pointIndex].options
        ?.map((item: any, index: number) => (item?.isSelected ? index : -1))
        .filter((index: any) => index !== -1);
      if (attempt === 1) {
        setFirstSelectedMSQOption(selectedIndex);
      }
      if (selectedOption?.length > 0) {
        setAttempt((oldAttemptCount) => oldAttemptCount + 1);
        const allSelectedCorrect = selectedOption.every(
          (item) => item.isCorrect
        );
        const correctOptions = updatedData.lecturePoint[
          pointIndex
        ].options.filter((item) => item.isCorrect);
        const correctSelection =
          selectedOption.length === correctOptions.length;

        if (allSelectedCorrect && correctSelection) {
          let userTimeTaken: any = (
            Math.round(timeDifference * 100) / 100
          ).toFixed(2);
          const minutes: any = Math.floor(userTimeTaken / 60);
          if (
            parseInt(minutes) <
            parseInt(updatedData.lecturePoint[pointIndex]?.duration)
          ) {
            updatedData.lecturePoint[pointIndex].timeTaken = userTimeTaken;
          } else {
            const duration =
              parseInt(updatedData.lecturePoint[pointIndex]?.duration) * 60;
            const durationIn = duration.toString();
            updatedData.lecturePoint[pointIndex].timeTaken = durationIn;
            setLectureDetailsData(updatedData);
          }
          setTimeTaken(userTimeTaken);
          setCorrectAns(selectedIndex);
          setShow(true);
          setDisabledQues(true);
          setshowWrong(false);
          updatedData.lecturePoint[pointIndex].status = 3;
          if (!isTimerComplete) {
            setpauseTimer(true);
          }

          if (updatedData.lecturePoint?.length - 1 === parseInt(pointIndex)) {
            // updatedData.status = 3;
          } else {
            updatedData.lecturePoint[pointIndex + 1].status = 2;
          }

          setTimeout(() => {
            setShow(false);
          }, 3000);
          if (
            attempt === 1 &&
            !isTimerComplete &&
            updatedData.lecturePoint[pointIndex].usedhints === 0
          ) {
            setDisabledBtn(true);
            setTimeout(() => {
              play();
              setShowFlipCoin(true);
            }, 3000);
            setTimeout(() => {
              updatedData.lecturePoint[pointIndex].earnedPoints =
                updatedData.lecturePoint[pointIndex].points;
              updatedData.totalEarnedPoint +=
                updatedData.lecturePoint[pointIndex].points;
              updatedData.lectureEarnedPoints +=
                updatedData.lecturePoint[pointIndex].points;
              sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
              setShowFlipCoin(false);
              // setDisabledQues(true);
              setDisabledBtn(false);
            }, 4800);
          } else {
            // setDisabledQues(true);
            sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
          }
        } else {
          // setDisabledQues(true);
          setShow(false);
          setShowFallenCoin(false);
          setShowFlipCoin(false);
          setshowWrong(true);
          setTimeout(() => {
            setshowWrong(false);
          }, 3000);
        }
      } else {
        dispatch(showErrorSnackbar("Please Select atleast one option") as any);
      }
    } else if (
      updatedData.lecturePoint[pointIndex]?.questionType === "4" ||
      updatedData.lecturePoint[pointIndex]?.questionType === "6"
    ) {
      console?.log("Type 4 or 6");
      if (updatedData.lecturePoint[pointIndex].userAnswer) {
        setAttempt((oldAttemptCount) => oldAttemptCount + 1);
        let userTimeTaken: any = (
          Math.round(timeDifference * 100) / 100
        ).toFixed(2);
        updatedData.lecturePoint[pointIndex].timeTaken = userTimeTaken;

        setTimeTaken(userTimeTaken);

        const postdata = updatedData.lecturePoint[pointIndex];
        postdata["lectureId"] = IDData.lectureId;
        postdata["teacherId"] = IDData.teacherId;
        postdata["studentId"] = IDData.studentId;
        dispatch(
          addSubmitDescriptiveQuestion(
            postdata,
            (res: any) => {
              setDisabledQues(true);
              setshowSubmitDescription(true);
              updatedData.lecturePoint[pointIndex].status = 3;

              if (
                updatedData.lecturePoint?.length - 1 ===
                parseInt(pointIndex)
              ) {
              } else {
                updatedData.lecturePoint[pointIndex + 1].status = 2;
              }
              sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
            },
            () => {}
          )
        );
      } else {
        dispatch(showErrorSnackbar("Please fill answer") as any);
      }
    } else if (updatedData.lecturePoint[pointIndex]?.questionType === "5") {
      // const leftItems = updatedData.lecturePoint[pointIndex].options?.filter((data:any)=>data?.part==="partA");

      if (attempt === 1) {
        setFirstAttemptDrop(dndMatchs);
      }
      if (dndMatchs !== "null" && dndMatchs !== "undefined") {
        setAttempt((oldAttemptCount) => oldAttemptCount + 1);
        let results = leftItems.map((leftItem) => {
          const matchedItems = dndMatchs[leftItem.id] || [];

          // Check if the number of matched items is the same as the number of correct matches
          if (
            matchedItems.length !== leftItem?.correctMatch?.split(",")?.length
          ) {
            return false;
          }

          // Check if every matched item ID exists in the correct ID array
          const isEveryMatchCorrect = matchedItems.every((matchedItem) =>
            leftItem.correctMatch.includes(matchedItem.id)
          );

          return isEveryMatchCorrect;
        });

        // If all results are true, all matches are correct
        // alert(results.every((result) => result) ? 'All correct!' : 'Some matches are incorrect.');
        let userTimeTaken: any = (
          Math.round(timeDifference * 100) / 100
        ).toFixed(2);
        const minutes: any = Math.floor(userTimeTaken / 60);
        if (
          parseInt(minutes) <
          parseInt(updatedData.lecturePoint[pointIndex]?.duration)
        ) {
          updatedData.lecturePoint[pointIndex].timeTaken = userTimeTaken;
        } else {
          const duration =
            parseInt(updatedData.lecturePoint[pointIndex]?.duration) * 60;
          const durationIn = duration.toString();
          updatedData.lecturePoint[pointIndex].timeTaken = durationIn;
          setLectureDetailsData(updatedData);
        }
        setTimeTaken(userTimeTaken);
        setDisabledQues(true);
        updatedData.lecturePoint[pointIndex].status = 3;
        if (results.every((result) => result)) {
          // setCorrectAns(selectedIndex);
          setShow(true);

          setshowWrong(false);
          // updatedData.lecturePoint[pointIndex].status = 3;
          if (!isTimerComplete) {
            setpauseTimer(true);
          }

          // if (updatedData.lecturePoint?.length - 1 === parseInt(pointIndex)) {
          //   // updatedData.status = 3;
          // } else {
          //   updatedData.lecturePoint[pointIndex + 1].status = 2;
          // }

          setTimeout(() => {
            setShow(false);
          }, 3000);
          if (
            attempt === 1 &&
            !isTimerComplete &&
            updatedData.lecturePoint[pointIndex].usedhints === 0
          ) {
            setDisabledBtn(true);
            setTimeout(() => {
              play();
              setShowFlipCoin(true);
            }, 3000);
            setTimeout(() => {
              updatedData.lecturePoint[pointIndex].earnedPoints =
                updatedData.lecturePoint[pointIndex].points;
              updatedData.totalEarnedPoint +=
                updatedData.lecturePoint[pointIndex].points;
              updatedData.lectureEarnedPoints +=
                updatedData.lecturePoint[pointIndex].points;
              sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
              setShowFlipCoin(false);
              // setDisabledQues(true);
              setDisabledBtn(false);
            }, 4800);
          } else {
            // setDisabledQues(true);
            sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
          }
        } else {
          // setDisabledOptions((prevDisabledOptions: any) => [
          //   ...prevDisabledOptions,
          //   selectedIndex,
          // ]);
          setShow(false);
          setShowFallenCoin(false);
          setShowFlipCoin(false);
          setshowWrong(true);
          setTimeout(() => {
            setshowWrong(false);
          }, 3000);
        }
      } else {
        dispatch(showErrorSnackbar("Please Select atleast one option") as any);
      }
    }
  };

  const getImage = (imgPath: any) => {
    console.log("imgPath", imgPath);
    setImgPath(imgPath);
    // setOpenImage(true);
  };
  const onTimerFinished = () => {
    setisTimerComplete(true);
  };

  const onChangeCode = (value: any) => {
    updatedData.lecturePoint[pointIndex].userAnswer = value;
    setLectureDetailsData(updatedData);
    sessionStorage?.setItem("Lecture", JSON.stringify(updatedData));
  };
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  function progressBarVal() {
    // const now = 60;
    let count = (pointIndex / (updatedData?.lecturePoint?.length - 1)) * 100;
    console.log(
      "count",
      count,
      pointIndex,
      updatedData?.lecturePoint?.length - 1
    );

    return (
      <div className="">
        <ProgressBar now={count} style={{ height: "3px" }} />
      </div>
    );
  }

  const parseHtmlWithTargetBlank = (htmlString) => {
    const modifiedHtml = htmlString?.replace(/<a/g, '<a target="_blank"');
    return modifiedHtml;
  };
  return (
    <div className="lectureDashboard academic">
      <div className="w-100">
        <div className="lectureHeader d-flex align-items-center justify-content-between position-relative">
          <div className="d-flex align-items-center flex-fill">
            {pointIndex !== 0 ||
            (updatedData.lecturePoint[pointIndex]?.type === 2 &&
              !disabledQues) ? (
              <button
                className="btn bgOrangeWithTextWhite me-3"
                onClick={prevLecture}
                disabled={disabledBtn}
              >
                <KeyboardArrowLeftIcon />
              </button>
            ) : (
              <div>
                <button
                  className="btn bgOrangeWithTextWhite me-3"
                  onClick={goToBack}
                >
                  <HomeIcon />
                </button>
              </div>
            )}

            <div className="font20 fw-bold ms-3">
              {updatedData.lecturePoint[pointIndex]?.pointName}
            </div>
          </div>

          <div className="d-flex h-100">
            <div className="d-flex font24 ms-3 fw-bold align-items-center me-3 position-relative">
              {updatedData.lecturePoint[pointIndex]?.type === 2 ? (
                <div>
                  <span className="totalQuesHints fw-bold">
                    {updatedData?.totalHints}
                  </span>
                  <span className="totalQuesEarnedPoints zoom-in-out-box fw-bold">
                    {updatedData?.totalEarnedPoint}
                  </span>
                  <img
                    src={Hints_Coins}
                    style={{ width: "150px" }}
                    alt={"hints_coins"}
                  />
                </div>
              ) : null}
               {showFlipCoin ? (  
                <div className="flipCoin">
                  <Lottie
                    options={flipCoinOptions}
                    height={285}
                    width={100}
                  ></Lottie>
                </div>
               ) : null}    
            </div>
            {updatedData.lecturePoint[pointIndex]?.coding && !showCoding ? (
                  <div
                    className="font20 bgOrangeWithTextWhite d-flex justify-content-between align-items-center px-2 mx-2 h-100 ms-0 cursor rounded"
                    onClick={() => setshowCoding(true)}
                  >
                    &lt; &#47; &gt; 
                  </div>
                ) : null}
            <div
              onClick={() => {
                setToggle(!toggle);
                setOpenPolypad(false);
              }}
              style={{ cursor: "pointer", zIndex: "1" }}
              className="bgOrangeWithTextWhite d-flex justify-content-between align-items-center px-2 mx-2 h-100 ms-0 cursor rounded"
            >
              <DrawIcon className="fs-5" />
            </div>
          </div>

          {updatedData.lecturePoint[pointIndex]?.type === 2 &&
          !disabledQues ? null : updatedData.lecturePoint?.length - 1 ===
              parseInt(pointIndex) &&
            updatedData.lecturePoint[pointIndex]?.type === 1 &&
            index ===
              updatedData.lecturePoint[pointIndex]?.contentDetails?.length -
                1 &&
            subIndex ===
              (updatedData.lecturePoint[pointIndex]?.contentDetails[
                updatedData.lecturePoint[pointIndex]?.contentDetails?.length - 1
              ]?.textType === 4
                ? updatedData.lecturePoint[pointIndex]?.contentDetails[
                    updatedData.lecturePoint[pointIndex]?.contentDetails
                      ?.length - 1
                  ].desc?.split(" || ").length - 1
                : 0) ? (
            <button className="btn bgOrangeWithTextWhite" onClick={handleNext}>
              Complete
            </button>
          ) : updatedData.lecturePoint?.length - 1 === parseInt(pointIndex) &&
            updatedData.lecturePoint[pointIndex]?.type === 2 ? (
            <button className="btn bgOrangeWithTextWhite" onClick={handleNext}>
              Complete
            </button>
          ) : (
            <>
              <button
                className="btn bgOrangeWithTextWhite"
                onClick={handleNext}
                disabled={disabledBtn}
              >
                <KeyboardArrowRightIcon />
              </button>
            </>
          )}
          {/* {updatedData.lecturePoint[pointIndex]?.type === 1 &&
          updatedData.lecturePoint[pointIndex].status !== 3 ? (
            <div className="d-flex align-items-center">
              <div className="d-flex me-2">
                {isAudio ? <VolumeUpIcon /> : <VolumeOffIcon />}
              </div>
              <label className="toggle-switch me-3">
                <input
                  type="checkbox"
                  checked={isAudio}
                  onChange={(e: any) => {
                    setisAudio(e.target.checked);
                  }}
                />
                <div className="toggle-switch-background">
                  <div className="toggle-switch-handle"></div>
                </div>
              </label>
              <LectureTimer />
            </div>
          ) : null} */}
        </div>
        <div>{progressBarVal()}</div>
        <div className="position-relative">
          <div className="position-absolute LectureTimer">
            {updatedData.lecturePoint[pointIndex].type === 2 &&
            duration &&
            updatedData.lecturePoint[pointIndex].status !== 3 &&
            updatedData.lecturePoint[pointIndex]?.questionType !== "4" ? (
              <div className="d-flex align-items-center">
                <span className="timerLecture">
                  <TimerCountDown
                    countdown={duration * 60 - (timeTaken ?? 0)}
                    onTimerFinished={onTimerFinished}
                    counttime={timeTaken || 0}
                    isNewTimer={isNewTimer}
                    pauseTimer={pauseTimer}
                  />
                </span>
              </div>
            ) : null}
          </div>
        </div>

        {updatedData.lecturePoint[pointIndex]?.type === 1 ? (
          <div className="d-flex lectureContentBox">
            <div
              className={`contentDiv position-relative row d-flex tabularData ${
                openPolypad ? "active" : ""
              }`}
            >
              <div
                className={`lectureContent position-relative ${
                  showCoding && !fullScreenCoding
                    ? "col-md-6 codingPadding"
                    : showCoding && fullScreenCoding
                    ? "d-none"
                    : "col-md-12"
                }`}
              >
                {updatedData.lecturePoint[pointIndex]?.contentDetails
                  .slice(0, index + 1)
                  .map((item: any, i: number) => {
                    let textimageData: any = {};
                    if (
                      (item?.textType === 7 ||
                        item?.textType === 8 ||
                        item?.textType === 9 ||
                        item?.textType === 10) &&
                      item?.desc
                    ) {
                      textimageData = JSON.parse(item?.desc);
                    }
                    console.log("textimageData", textimageData);
                    return (
                      <div
                        key={i}
                        ref={
                          i ===
                          updatedData.lecturePoint[
                            pointIndex
                          ]?.contentDetails.slice(0, index + 1).length -
                            1
                            ? bottomRef
                            : null
                        }
                        className="mb-4"
                      >
                        {item.textType === 1 ? (
                          <div className="lectureHeading font24 mb-3 fw-bold colorOrange">
                            {parser(item?.desc)}
                          </div>
                        ) : item.textType === 2 ? (
                          <div className="lectureSubHeading font20">
                            {parser(item?.desc)}
                          </div>
                        ) : item.textType === 3 ? (
                          <div className="lectureSubHeading font18">
                            {parser(item?.desc)}
                          </div>
                        ) : item.textType === 4 ? (
                          <ul>
                            {item.desc
                              .split(" || ")
                              .slice(
                                0,
                                i === index ? subIndex + 1 : item.desc.length
                              )
                              .map((subItem: any, j: number) => (
                                <li className="mb-2 font18" key={j}>
                                  {subItem}
                                </li>
                              ))}
                          </ul>
                        ) : item.textType === 5 ? (
                          <div className="text-center">
                            <img
                              src={item?.desc}
                              alt="Imagetxt"
                              className={`lectureImg ${
                                item?.imgType === "16:9" ||
                                item?.imgType === "2:1"
                                  ? "lectureImgar169"
                                  : item?.imgType === "1:1"
                                  ? "lectureImgar11"
                                  : item?.imgType === "2:3"
                                  ? "lectureImgar23"
                                  : ""
                              }`}
                              onClick={() => getImage(item)}
                            />
                          </div>
                        ) : item.textType === 6 ? (
                          <div className="text-center">
                            <video className="videoClass" controls>
                              <source src={item?.desc} type="video/mp4" />
                              <source src="movie.ogg" type="video/ogg" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        ) : item.textType === 7 ? (
                          <div className="row mx-0">
                            <div className="col-md-3">
                              <div className="text-center">
                                <img
                                  src={textimageData?.img}
                                  alt="Imagetxt"
                                  // className={`lectureImg ${textimageData?.ratio === "16:9" ||
                                  //   textimageData?.ratio === "2:1"
                                  //   ? "lectureImgar169"
                                  //   : textimageData?.ratio === "1:1"
                                  //     ? "lectureImgar11"
                                  //     : textimageData?.ratio === "2:3"
                                  //       ? "lectureImgar23"
                                  //       : ""
                                  //   }`}
                                  className="img-fluid"
                                  // onClick={() => getImage(item)}
                                />
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="font18">
                                {parser(textimageData?.text)}
                              </div>
                            </div>
                          </div>
                        ) : item.textType === 8 ? (
                          <div className="row mx-0">
                            <div className="col-md-9">
                              <div className="font18">
                                {parser(textimageData?.text)}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="text-center">
                                <img
                                  src={textimageData?.img}
                                  alt="Imagetxt"
                                  // className={`lectureImg ${textimageData?.ratio === "16:9" ||
                                  //   textimageData?.ratio === "2:1"
                                  //   ? "lectureImgar169"
                                  //   : textimageData?.ratio === "1:1"
                                  //     ? "lectureImgar11"
                                  //     : textimageData?.ratio === "2:3"
                                  //       ? "lectureImgar23"
                                  //       : ""
                                  //   }`}
                                  className="img-fluid"
                                  // onClick={() => getImage(item)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : item.textType === 9 ? (
                          <div className="row mx-0">
                            <div className="col-md-4">
                              <div className="font18">
                                {parser(textimageData?.LeftText)}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="text-center">
                                <img
                                  src={textimageData?.centerimg}
                                  alt="Imagetxt"
                                  // className={`lectureImg ${textimageData?.ratio === "16:9" ||
                                  //   textimageData?.ratio === "2:1"
                                  //   ? "lectureImgar169"
                                  //   : textimageData?.ratio === "1:1"
                                  //     ? "lectureImgar11"
                                  //     : textimageData?.ratio === "2:3"
                                  //       ? "lectureImgar23"
                                  //       : ""
                                  //   }`}
                                  className="img-fluid"
                                  // onClick={() => getImage(item)}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="font18">
                                {parser(textimageData?.RightText)}
                              </div>
                            </div>
                          </div>
                        ) : item.textType === 10 ? (
                          <div className="row mx-0">
                            <div className="col-md-4">
                              <div className="text-center">
                                <img
                                  src={textimageData?.Leftimg}
                                  alt="Imagetxt"
                                  className="img-fluid"
                                  // onClick={() => getImage(item)}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="font18">
                                {parser(textimageData?.text)}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="text-center">
                                <img
                                  src={textimageData?.Rightimg}
                                  alt="Imagetxt"
                                  className="img-fluid"
                                  // onClick={() => getImage(item)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : item.textType === 11 ? (
                          <div className="mt-4">
                            <FlipCardTemplate data={item} />
                          </div>
                        ) : item.textType === 12 ? (
                          <div className="mt-4 textContainer">
                            <AccordianTemplate data={item} />
                          </div>
                        ) : item.textType === 13 ? (
                          <div className="mt-4 textContainer">
                            <ImageTabTemplate data={item} />
                          </div>
                        ) : item.textType === 14 ? (
                          <div className="mt-4 textContainer">
                            <HorizontalTabTemplet data={item} />
                          </div>
                        ) : (
                          <div className=" mb-3">{item?.desc}</div>
                        )}
                      </div>
                    );
                  })}
                {/* {updatedData.lecturePoint[pointIndex]?.coding && !showCoding ? (
                  <div
                    className="codingBtn font20"
                    onClick={() => setshowCoding(true)}
                  >
                    &lt; &#47; &gt; 
                  </div>
                ) : null} */}
              </div>
              {showCoding ? (
                <div
                  className={`${fullScreenCoding ? "col-md-12" : "col-md-6 "}`}
                >
                  {updatedData.lecturePoint[pointIndex]?.codingLanguage ===
                  4 ? (
                    <PythonCodeEditor
                      isPraciseQues={false}
                      onClose={() => setshowCoding(false)}
                      setfullScreenCoding={setfullScreenCoding}
                      fullScreenCoding={fullScreenCoding}
                    />
                  ) : (
                    <CodeEditor
                      isPraciseQues={false}
                      onClose={() => setshowCoding(false)}
                      setfullScreenCoding={setfullScreenCoding}
                      fullScreenCoding={fullScreenCoding}
                    />
                  )}
                </div>
              ) : null}
            </div>
            <div className={`polypadDiv  ${openPolypad ? "active" : ""}`}>
              <Polypad
                handleClose={() => setOpenPolypad(false)}
                open={openPolypad}
              />
            </div>

            <CanvasBox toggle={toggle} setToggle={setToggle} />
          </div>
        ) : (
          <div className="d-flex lectureContentBox ">
            <div
              className={`contentDiv position-relative row d-flex ${
                openPolypad ? "active" : ""
              }`}
            >
              <div className={`lectureContent position-relative`}>
                <div className="d-flex align-items-center">
                  <h4>{updatedData.lecturePoint[pointIndex]?.topicName}</h4>
                </div>

                <div className="font18 mt-3 fw-bold">
                  {updatedData.lecturePoint[pointIndex]?.ques?.map(
                    (item: any, index: number) => {
                      if (item?.descType === "text") {
                        return <div>{parser(item.description)}</div>;
                      } else if (item?.descType === "image") {
                        return (
                          <div className="text-center">
                            <img
                              src={item?.description}
                              alt="img"
                              className="w-100"
                              style={{ maxWidth: "400px" }}
                              onClick={() => getImage(item)}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div className="text-center">
                            <video className="videoClass" controls>
                              <source
                                src={item?.description}
                                type="video/mp4"
                              />
                              <source src="movie.ogg" type="video/ogg" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        );
                      }
                    }
                  )}
                </div>
                {updatedData.totalHints !== 0 &&
                // showHintButton &&
                updatedData.lecturePoint[pointIndex]?.usedhints === 0 &&
                !disabledQues ? (
                  <button
                    type="button"
                    className="btn btn-success my-1 mt-3"
                    onClick={() => {
                      onChangeHint(true);
                    }}
                    disabled={showHint}
                  >
                    <span className="text-white fw-bold font12">
                      <TipsAndUpdatesIcon /> Get a Hint
                    </span>
                  </button>
                ) : null}
                {showHint ||
                updatedData.lecturePoint[pointIndex]?.usedhints === 1 ? (
                  <div
                    className="d-flex flex-column mt-2 alert alert-info border-0 border-start border-5 border-info"
                    role="alert"
                  >
                    <div className="me-3 d-flex align-items-center">
                      <TipsAndUpdatesIcon />{" "}
                      <div className="fw-bold ms-2">Hint</div>
                    </div>

                    <div className="ms-2 mt-2">
                      {updatedData.lecturePoint[pointIndex]?.hintText ? (
                        <div>
                          {parser(
                            updatedData.lecturePoint[pointIndex]?.hintText
                          )}
                        </div>
                      ) : null}
                      {updatedData.lecturePoint[pointIndex]?.hintImage ? (
                        <div className="text-center">
                          <img
                            src={
                              updatedData.lecturePoint[pointIndex]?.hintImage
                            }
                            alt="answer"
                            style={{ maxWidth: "500px" }}
                          />
                        </div>
                      ) : null}
                      {updatedData.lecturePoint[pointIndex]?.hintVideo ? (
                        <div className="text-center">
                          <video className="videoClass" controls>
                            <source
                              src={
                                updatedData.lecturePoint[pointIndex]?.hintVideo
                              }
                              type="video/mp4"
                            />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="radioSuccess mt-3">
                  {updatedData.lecturePoint[pointIndex]?.questionType === "1" ||
                  updatedData.lecturePoint[pointIndex]?.questionType === "2" ||
                  updatedData.lecturePoint[pointIndex]?.questionType === "3" ? (
                    updatedData.lecturePoint[pointIndex]?.options?.map(
                      (item: any, index: number) =>
                        updatedData.lecturePoint[pointIndex]?.questionType ===
                        "1" ? (
                          <div
                            key={index}
                            className={`form-check mb-3 px-5 py-3 border rounded position-relative cursor ${
                              item?.isSelected
                                ? "active alert alert-success"
                                : null
                            } `}
                            onClick={() =>
                              disabledQues
                                ? console.log("Disabled")
                                : getSelectedData(index)
                            }
                          >
                            {disabledOptions?.includes(index) ? (
                              <HighlightOffIcon className="colorred incorrectOption" />
                            ) : parseInt(CorrectAns) === index ? (
                              <CheckCircleIcon className="incorrectOption colorgreen" />
                            ) : (
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={item?.isSelected}
                                onChange={(e) => getSelectedData(index)}
                                disabled={disabledQues}
                              />
                            )}

                            <label
                              className="form-check-label ms-3 font18 cursor CustomOption"
                              htmlFor="flexRadioDefault1"
                            >
                              {/* { parser(item?.option)} */}
                              {item?.descType === "text" ? (
                                parser(item?.description)
                              ) : (
                                <div className="text-center">
                                  <img
                                    src={item?.description}
                                    alt="optionImg"
                                    className="optionImg"
                                    onClick={() => getImage(item)}
                                  />
                                </div>
                              )}
                            </label>
                          </div>
                        ) : updatedData.lecturePoint[pointIndex]
                            ?.questionType === "2" ? (
                          <div
                            key={index}
                            className={`mb-1 px-3 py-2 border rounded`}
                          >
                            <label className="fw-bold mb-2">Answer</label>
                            <div className="d-flex justify-content-between">
                              <div
                                className="col-md-6"
                                style={{ marginLeft: "1px" }}
                              >
                                <textarea
                                  className="form-control cursor"
                                  value={
                                    updatedData.lecturePoint[pointIndex]
                                      .userAnswer
                                  }
                                  onChange={(e: any) =>
                                    onChangeInput(e.target.value)
                                  }
                                  style={{ height: "100px" }}
                                  disabled={disabledQues}
                                />
                              </div>
                              <div
                                className="col-md-6"
                                style={{ marginLeft: "5px" }}
                              >
                                {updatedData.lecturePoint[pointIndex]
                                  ?.options[0]?.answerType === 2 ? (
                                  <MathJaxContext config={config}>
                                    <MathJax dynamic>
                                      <h6
                                        className="d-flex align-items-center justify-content-center border rounded"
                                        style={{
                                          height: "100px",
                                          overflow: "auto",
                                        }}
                                      >{`$${updatedData.lecturePoint[pointIndex].userAnswer}$`}</h6>
                                    </MathJax>{" "}
                                  </MathJaxContext>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ) : updatedData.lecturePoint[pointIndex]
                            ?.questionType === "3" ? (
                          <div
                            key={index}
                            className={`form-check mb-3 px-5 py-3 border rounded position-relative cursor ${
                              item?.isSelected
                                ? "active alert alert-success"
                                : null
                            } `}
                            onClick={(e) =>
                              disabledQues
                                ? console.log("Disabled")
                                : getSelectedMSQData(!item?.isSelected, index)
                            }
                          >
                            {/* {disabledOptions?.includes(index) ? (
                              <HighlightOffIcon className="colorred incorrectOption" />
                            ) : parseInt(CorrectAns) === index ? (
                              <CheckCircleIcon className="incorrectOption colorgreen" />
                            ) : ( */}
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={item?.isSelected}
                              onChange={(e) =>
                                getSelectedMSQData(e?.target?.checked, index)
                              }
                              disabled={disabledQues}
                            />
                            {/* )} */}

                            <label
                              className="form-check-label ms-3 font18 cursor CustomOption"
                              htmlFor="flexRadioDefault1"
                            >
                              {/* { parser(item?.option)} */}
                              {item?.descType === "text" ? (
                                parser(item?.description)
                              ) : (
                                <div className="text-center">
                                  <img
                                    src={item?.description}
                                    alt="optionImg"
                                    className="optionImg"
                                    onClick={() => getImage(item)}
                                  />
                                </div>
                              )}
                            </label>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className={`mb-1 px-3 py-2 border rounded`}
                          >
                            <label className="fw-bold mb-2">Answer</label>
                            <div className="d-flex justify-content-between">
                              <div
                                className="col-md-6"
                                style={{ marginLeft: "1px" }}
                              >
                                <textarea
                                  className="form-control cursor"
                                  value={
                                    updatedData.lecturePoint[pointIndex]
                                      .userAnswer
                                  }
                                  onChange={(e: any) =>
                                    onChangeInput(e.target.value)
                                  }
                                  style={{ height: "100px" }}
                                  disabled={disabledQues}
                                />
                              </div>
                              <div
                                className="col-md-6"
                                style={{ marginLeft: "5px" }}
                              >
                                {updatedData.lecturePoint[pointIndex]
                                  ?.options[0]?.answerType === 1 ? (
                                  <h6
                                    className="d-flex align-items-center justify-content-center border rounded"
                                    style={{
                                      height: "100px",
                                      overflow: "auto",
                                    }}
                                  >{`${updatedData.lecturePoint[pointIndex].userAnswer}`}</h6>
                                ) : (
                                  <MathJaxContext config={config}>
                                    <MathJax dynamic>
                                      <h6
                                        className="d-flex align-items-center justify-content-center border rounded"
                                        style={{
                                          height: "100px",
                                          overflow: "auto",
                                        }}
                                      >{`$${updatedData.lecturePoint[pointIndex].userAnswer}$`}</h6>
                                    </MathJax>{" "}
                                  </MathJaxContext>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                    )
                  ) : updatedData.lecturePoint[pointIndex]?.questionType ===
                    "4" ? (
                    <div key={index} className={`mb-1 px-0 py-2 rounded`}>
                      <label className="fw-bold mb-2">Answer: </label>
                      <div className="d-flex justify-content-between">
                        <div className="col-md-6" style={{ marginLeft: "1px" }}>
                          <textarea
                            className="form-control cursor"
                            value={
                              updatedData.lecturePoint[pointIndex].userAnswer
                            }
                            onChange={(e: any) => onChangeInput(e.target.value)}
                            style={{ height: "150px" }}
                            disabled={disabledQues}
                          />
                        </div>
                      </div>
                    </div>
                  ) : updatedData.lecturePoint[pointIndex]?.questionType ===
                    "5" ? (
                    <div>
                      <MatchThePair
                        setdndMatchs={setdndMatchs}
                        dndMatchs={
                          updatedData.lecturePoint[pointIndex].userAnswer
                            ? JSON?.parse(
                                updatedData.lecturePoint[pointIndex].userAnswer
                              )
                            : {}
                        }
                        disabledQues={disabledQues}
                        leftItems={leftItems}
                        rightItems={rightItems}
                      />
                    </div>
                  ) : updatedData.lecturePoint[pointIndex]?.questionType ===
                    "6" ? (
                    <div className="col-md-12">
                      {updatedData.lecturePoint[pointIndex]?.codingLanguage ===
                      4 ? (
                        <PythonCodeEditor
                          isPraciseQues={true}
                          onClose={() => setshowCoding(false)}
                          setCodingData={(val: any) => onChangeCode(val)}
                          code={updatedData.lecturePoint[pointIndex].userAnswer}
                          disabled={disabledQues}
                        />
                      ) : (
                        <CodeEditor
                          isPraciseQues={true}
                          onClose={() => setshowCoding(false)}
                          setCodingData={(val: any) => onChangeCode(val)}
                          code={updatedData.lecturePoint[pointIndex].userAnswer}
                          disabled={disabledQues}
                        />
                      )}
                    </div>
                  ) : null}
                  {CorrectAns >= "0" &&
                  (updatedData.lecturePoint[pointIndex]?.answerText ||
                    updatedData.lecturePoint[pointIndex]?.answerImage ||
                    updatedData.lecturePoint[pointIndex]?.answerVideo) ? (
                    <button
                      className="btn text-primary fw-bold mt-2 p-0"
                      onClick={() => onChangeDescription(true)}
                    >
                      See how we answer this question
                    </button>
                  ) : null}
                  {showQueDescription ? (
                    <div
                      className="d-flex align-items-center mt-3 alert alert-warning border-0 border-start border-5 border-warning"
                      role="alert"
                    >
                      <div className="me-3">
                        <MenuBookIcon />{" "}
                      </div>
                      {updatedData.lecturePoint[pointIndex]?.answerText ? (
                        <div>
                          {parser(
                            updatedData.lecturePoint[pointIndex]?.answerText
                          )}
                        </div>
                      ) : null}
                      {updatedData.lecturePoint[pointIndex]?.answerImage ? (
                        <div className="text-center">
                          <img
                            src={
                              updatedData.lecturePoint[pointIndex]?.answerImage
                            }
                            alt="answer"
                          />
                        </div>
                      ) : null}
                      {updatedData.lecturePoint[pointIndex]?.answerVideo ? (
                        <div className="text-center">
                          <video className="videoClass" controls>
                            <source
                              src={
                                updatedData.lecturePoint[pointIndex]
                                  ?.answerVideo
                              }
                              type="video/mp4"
                            />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {show ? (  
              <div className="correctFireCracker">
                <Lottie
                  options={correctFireCrackerOptions}
                  height={450}
                  width={1000}
                ></Lottie>
              </div>
             ) : null}  
            {showFallenCoin ? (
              <div className="fallenCoin">
                <Lottie
                  options={fallenCoinOptions}
                  height={750}
                  width={392}
                ></Lottie>
              </div>
            ) : null}
            <div className={`polypadDiv  ${openPolypad ? "active" : ""}`}>
              <Polypad
                handleClose={() => setOpenPolypad(false)}
                open={openPolypad}
              />
            </div>

            <CanvasBox toggle={toggle} setToggle={setToggle} />
          </div>
        )}

        <div className="funfactBtn">
          {updatedData.lecturePoint[pointIndex]?.funFactData?.length > 0 ? (
            <div className="d-flex  fw-bold align-items-center pe-2 m-2 ms-0">
              <div className="me-0">
                {!funFactPopup ? (
                  updatedData.lecturePoint[pointIndex]?.funFactData?.length >
                  0 ? (
                    <img
                      className="funFactImage cursor"
                      src={funFactClose}
                      style={{ width: "40px" }}
                      alt={"funFactClose"}
                      onClick={() => setFunFactPopup(true)}
                    />
                  ) : null
                ) : null}
              </div>
            </div>
          ) : null}
        </div>

        <div className="d-flex">
          {!menuVisible ? (
            <button onClick={toggleMenu} className="menu-toggle rounded-end">
              {/* <MenuIcon /> */}
              <WidgetsIcon />
            </button>
          ) : null}
          <div
            className={`footer-menu d-flex ${
              menuVisible ? "slide-in border" : "slide-out"
            }`}
          >
            {pointIndex !== 0 ? (
              <div
                className="d-flex justify-content-between align-items-center border-2 border-end  pe-2 m-2 cursor"
                onClick={goToBack}
              >
                {/* <button
                  className="btn bgOrangeWithTextWhite me-3"
                  onClick={goToBack}
                > */}
                <HomeIcon className="colorgray fs-2" />
                {/* </button> */}
              </div>
            ) : null}
            <div
              className="d-flex justify-content-between align-items-center border-2 border-end  pe-2 m-2 cursor"
              onClick={() => setOpenDesmos(true)}
            >
              {/* <CalculateIcon /> */}
              <img
                src={localImagesNew?.calculatorIcon}
                alt="calculator"
                className=""
                style={{ width: "35px" }}
              />
            </div>
            <div
              className="d-flex justify-content-between align-items-center border-2 border-end pe-2 m-2 ms-0 cursor"
              onClick={() => {
                setOpenPolypad(!openPolypad);
                setOpenDesmos(false);
                setToggle(false);
              }}
            >
              <CategoryIcon className="fs-1 colorgray" />
            </div>
            {/* {updatedData.lecturePoint[pointIndex]?.funFactData?.length > 0 ? (
              <div className="d-flex  fw-bold align-items-center border-2 border-end pe-2 m-2 ms-0">
                <div className="me-0">
                  {!funFactPopup ? (
                    updatedData.lecturePoint[pointIndex]?.funFactData?.length >
                    0 ? (
                      <img
                        className="funFactImage cursor"
                        src={funFactClose}
                        style={{ width: "40px" }}
                        alt={"funFactClose"}
                        onClick={() => setFunFactPopup(true)}
                      />
                    ) : null
                  ) : null}
                </div>
              </div>
            ) : null} */}
            {/* <div
              onClick={() => {
                setToggle(!toggle);
                setOpenPolypad(false);
              }}
              style={{ cursor: "pointer" }}
              className=" d-flex justify-content-between align-items-center pe-2 m-2 ms-0 cursor border-2 border-end"
            >
              <DrawIcon className="colorgray fs-2" />
            </div> */}

            {updatedData.lecturePoint[pointIndex]?.type === 1 &&
            updatedData.lecturePoint[pointIndex].status !== 3 ? (
              <div
                className="d-flex justify-content-between align-items-center pe-2 m-2 ms-0 cursor border-2 border-end"
                onClick={() => setisAudio(!isAudio)}
              >
                {isAudio ? (
                  <VolumeUpIcon className="colorgray" />
                ) : (
                  <VolumeOffIcon className="colorgray" />
                )}
              </div>
            ) : null}

            {updatedData.lecturePoint[pointIndex]?.type === 1 &&
            updatedData.lecturePoint[pointIndex].status !== 3 ? (
              <div className="d-flex justify-content-between align-items-center pe-2 m-2 ms-0 cursor border-2 border-end">
                {" "}
                <LectureTimer />
              </div>
            ) : null}

            <div className="m-1 ms-4">
              <button
                onClick={toggleMenu}
                className="menu-toggle-inside rounded-start h-100"
              >
                <KeyboardArrowLeftIcon />
              </button>
            </div>
          </div>

          <div className="d-flex ms-0 fw-bold align-items-center mx-0">
            <div className="me-0">
              {funFactPopup ? (
                <img
                  className="funFactImage cursor position-fixed"
                  src={funFactOpen}
                  style={{ width: "200px", zIndex: "2" }}
                  alt={"funFactOpen"}
                  onClick={() => setFunFactPopup(false)}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="LecturefooterDiv position-fixed">
          {/* <div className="d-flex align-items-center">
            <span className="pageCount">{pointIndex + 1}</span>/
            <span className="pageCount">
              {updatedData.lecturePoint?.length}
            </span>
          </div> */}

          <div className="d-flex">
            {/* <div className="d-flex font24 ms-3 fw-bold align-items-center mx-5 position-relative">
              {updatedData.lecturePoint[pointIndex]?.type === 2 ? (
                <div>
                  <span className="totalQuesHints fw-bold">
                    {updatedData?.totalHints}
                  </span>
                  <span className="totalQuesEarnedPoints zoom-in-out-box fw-bold">
                    {updatedData?.totalEarnedPoint}
                  </span>
                  <img
                    src={Hints_Coins}
                    style={{ width: "175px" }}
                    alt={"hints_coins"}
                  />
                </div>
              ) : null}
              {showFlipCoin ? (
                <div className="flipCoin">
                  <Lottie
                    options={flipCoinOptions}
                    height={285}
                    width={100}
                  ></Lottie>
                </div>
              ) : null}
            </div> */}

            {/* {pointIndex !== 0 ||
            (updatedData.lecturePoint[pointIndex]?.type === 2 &&
              !disabledQues) ? (
              <button
                className="btn bgOrangeWithTextWhite me-3"
                onClick={prevLecture}
                disabled={disabledBtn}
              >
                Prev
              </button>
            ) : null} */}

            {updatedData.lecturePoint[pointIndex]?.type === 2 &&
            !disabledQues ? (
              <button
                className="btn bgOrangeWithTextWhite"
                onClick={handleSubmit}
                disabled={disabledBtn}
              >
                Submit Answer
              </button>
            ) : null}
            {/* {updatedData.lecturePoint[pointIndex]?.type === 2 &&
            !disabledQues ? (
              <button
                className="btn bgOrangeWithTextWhite"
                onClick={handleSubmit}
                disabled={disabledBtn}
              >
                Submit Answer
              </button>
            ) : updatedData.lecturePoint?.length - 1 === parseInt(pointIndex) &&
              updatedData.lecturePoint[pointIndex]?.type === 1 &&
              index ===
                updatedData.lecturePoint[pointIndex]?.contentDetails?.length -
                  1 &&
              subIndex ===
                (updatedData.lecturePoint[pointIndex]?.contentDetails[
                  updatedData.lecturePoint[pointIndex]?.contentDetails?.length -
                    1
                ]?.textType === 4
                  ? updatedData.lecturePoint[pointIndex]?.contentDetails[
                      updatedData.lecturePoint[pointIndex]?.contentDetails
                        ?.length - 1
                    ].desc?.split(" || ").length - 1
                  : 0) ? (
              <button
                className="btn bgOrangeWithTextWhite"
                onClick={handleNext}
              >
                Complete
              </button>
            ) : updatedData.lecturePoint?.length - 1 === parseInt(pointIndex) &&
              updatedData.lecturePoint[pointIndex]?.type === 2 ? (
              <button
                className="btn bgOrangeWithTextWhite"
                onClick={handleNext}
              >
                Complete
              </button>
            ) : (
              <>
                <button
                  className="btn bgOrangeWithTextWhite"
                  onClick={handleNext}
                  disabled={disabledBtn}
                >
                  Next
                </button>
              </>
            )} */}

            {/* <button className="btn btn-primary" onClick={handleNext}>
      handleNext
        </button> */}
          </div>
          {show ? (
            <div className="correct border rounded">
              <div className="d-flex justify-content-end">
                <CloseIcon
                  className="position-absolute"
                  onClick={() => setShow(false)}
                />
              </div>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <CheckCircleIcon className="quesMsgImg colorgreen" />
                </div>
                <div>
                  <div className="fw-bold font20 colorgreen">Great work!</div>
                  <div className="">Keep up the great persistence!</div>
                  <div>You have taken {timeTaken} seconds</div>
                  {updatedData.lecturePoint[pointIndex]?.answerText ||
                  updatedData.lecturePoint[pointIndex]?.answerImage ||
                  updatedData.lecturePoint[pointIndex]?.answerVideo ? (
                    <button
                      className="btn text-primary fw-bold mt-2 p-0"
                      onClick={() => onChangeDescription(true)}
                    >
                      See how we answer this question
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          {showWrong ? (
            <div className="correct border rounded">
              <div className="d-flex justify-content-end">
                <CloseIcon
                  className="position-absolute"
                  onClick={() => setshowWrong(false)}
                />
              </div>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <HighlightOffIcon className="quesMsgImg colorred" />
                </div>
                <div>
                  <div className="fw-bold font20 colorred">Not Quite!</div>
                  <div className="">Give it another try!</div>
                  {updatedData.totalHints !== 0 &&
                  updatedData.lecturePoint[pointIndex]?.usedhints === 0 ? (
                    <button
                      className="btn text-success fw-bold mt-2 p-0"
                      onClick={() => onChangeHint(true)}
                      disabled={showHint}
                    >
                      <TipsAndUpdatesIcon /> Get a Hint
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          {funFactPopup ? (
            <div className="funFact">
              <div className="funFactPopup">
                <div>
                  <div className="d-flex justify-content-end cursor px-3 py-2">
                    <span
                      className="position-absolute funFactCloseBtn"
                      onClick={stop}
                    >
                      <CloseIcon onClick={() => setFunFactPopup(false)} />
                    </span>
                  </div>
                  <h3 className="fw-bold text-center">Did You Know?</h3>
                </div>
                <div className="funFactInner">
                  <div>
                    <div className="m-4">
                      <Text />
                      {updatedData?.lecturePoint[pointIndex].funFactData?.map(
                        (item: any) => {
                          return (
                            <div>
                              <div className="d-flex justify-content-center">
                                <span className="">
                                  {item?.descType === 5 ? (
                                    <img
                                      src={item?.content}
                                      style={{ width: "600px",height:"350px",aspectRatio:"1/1",objectFit:"contain" }}
                                      alt={""}
                                      onClick={() => getImage(item)}
                                    />
                                  ) : null}
                                </span>
                                <span className="">
                                  {item?.descType === 6 ? (
                                    <video width="500" height="300" controls>
                                      <source
                                        src={item?.content}
                                        type="video/mp4"
                                      />
                                    </video>
                                  ) : null}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end cursor fw-bold funFactAudio">
                  {speechStatus !== "started" ? (
                    <span className="cursor px-2" onClick={start}>
                      <PlayCircleOutlineIcon className="font28" />
                    </span>
                  ) : (
                    <span
                      className="cursor"
                      style={{ color: "#ee612a" }}
                      onClick={pause}
                    >
                      <PauseCircleOutlineIcon className="font28" />
                    </span>
                  )}
                  {speechStatus === "started" ? (
                    <span className="text-danger cursor px-2" onClick={stop}>
                      <StopCircleIcon className="font28" />
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          {showSubmitDescription ? (
            <div className="descriptiveSubmitPopup border rounded">
              <div className="d-flex justify-content-end">
                <CloseIcon
                  className="position-absolute"
                  onClick={() => setshowSubmitDescription(false)}
                />
              </div>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <CheckCircleIcon className="quesMsgImg colorgreen" />
                </div>
                <div>
                  <div className="fw-bold font18 colorgreen">
                    Your answer has been successfully submitted!
                  </div>
                  <div className="">
                    The points will be awarded by your teacher after reviewing
                    your submission.
                  </div>
                  <button
                    className="btn text-primary fw-bold mt-2 p-0"
                    onClick={() => onChangeDescription(true)}
                  >
                    See how we answer this question
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <ImgPopup
          imgPath={ImgPath}
          handleClose={() => setOpenImage(false)}
          open={OpenImage}
        />
        <DesmosPopup
          handleClose={() => setOpenDesmos(false)}
          open={openDesmos}
        />
        {/* <Polypad
          handleClose={() => setOpenPolypad(false)}
          open={openPolypad}
        /> */}
      </div>
    </div>
  );
}
