import '../Assets/Css/courses.css';

interface Props {
  course: string
  imgpath: string
  onClick?: any
}
function CourseCard(props: Props) {
  return (
    <div className="card boxshadow border-0 cursor" onClick={props.onClick}>
      <div>
        <img className="rounded courseimg w-100" src={props.imgpath} alt={props.course} /></div>
      <div className='p-2 mt-3'>
        <h6 className="extraboldfont packageName">{props.course}</h6>
      </div>
    </div>
  );
}

export default CourseCard;
