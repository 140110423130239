import { CSSProperties } from "react";
import '../Assets/Css/loader.css'
import { Backdrop } from "@mui/material";
const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

const Loader = (props: { loader: boolean }) => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: '10001' }} open>
          <span className="spinner"></span>
        </Backdrop>
    );
};

export default Loader;