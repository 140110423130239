import React, { useEffect, useState } from 'react';
import localImages from '../Constant/localImagesNew';
import '../Assets/Css/home.css';
import '../Assets/Css/CustomPagination.css';
import Faq from '../Component/faq';
import Footer from '../Component/footer';
import { useNavigate } from 'react-router-dom';
import HeaderHome from '../Component/headerHome';
import Sidebar from '../Module/sidebar';
import { useSelector } from 'react-redux';
import { SidebarDataFreeUser, SidebarDataPaidUser } from '../Module/sidebarData';
import StudentSayCarousel from '../Component/studentSayCarousel';
import BannerImage from '../Module/bannerImage';
import { Pagination } from '@mui/material';
import { usePagination } from '../Utils/appConstants';
import AlumniCard from '../Module/alumniCard';
import dataConstant from '../Constant/dataConstant';
import BookSession from '../Module/bookSession';
import JoinLurnigoPopup from '../Component/joinLurnigoPopup';


export default function TutorNetwork() {
    const navigate = useNavigate();
    const homebannerdiv = React.useRef<HTMLDivElement>(null);
    const [openBookSeat, setopenBookSeat] = useState<boolean>(false);
    const [alumni, setalumni] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(9);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(count / rowsPerPage);
    const _DATA = usePagination(String(count), rowsPerPage);
    const closesBooseat = () => {
        setopenBookSeat(false)
    }
    const {ACCESS_TOKEN,hasActivePaidPlan} = useSelector((state:any) => ({
        ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
        hasActivePaidPlan: state?.AuthReducer?.hasActivePaidPlan,
      }));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    const contactUS = (val:string) => {
        const element = document.getElementById(val);
        if (element) { 
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      };
   
    const AlumniData=[
        {
            imgPath:localImages?.TefenciaBarretto,
            name:"Tefencia Barretto",
            university:"English, 8 yrs"
        },
        {
            imgPath:localImages?.SARITACHOPRA,
            name:"Sarita Chopra",
            university:"Science, 15 yrs",
            logo:localImages?.University2
        },
        {
            imgPath:localImages?.ROJIBALA,
            name:"Roji Bala",
            university:"English, 8 yrs"
        },
        {
            imgPath:localImages?.PrathibaRajalakshmi,
            name:"Prathiba",
            university:"Science, 10 yrs"
        },
        {
            imgPath:localImages?.PARKHIRAJVANSHI,
            name:"Parkhi Rajvanshi",
            university:"English, 8 yrs",
        },
        {
            imgPath:localImages?.NoorHassanKhan,
            name:"Noor Ul Hassan",
            university:"Science, 10 yrs"
        },
        {
            imgPath:localImages?.MasumAhmed,
            name:"Masum",
            university:"English, 15 yrs"
        },
        {
            imgPath:localImages?.KANHAIYATHAKUR,
            name:"kanhaiya Thankur",
            university:"Science, 15 yrs",
        },
        {
            imgPath:localImages?.AnikethSam,
            name:"Aniketh Sam",
            university:"Math, 8 yrs"
        },
    ]
    useEffect(()=>{
        onPageChange(page, rowsPerPage,2);
    },[])
    const handleChange = (e:any, page:any) => {
         onPageChange(page, rowsPerPage, 1);
         setPage(page - 1);
         setCurrentPage(page);
         _DATA.jump(page);
      };
      const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
        /************ page change****************** */
        let data = {skip: 0,
            limit: count};
        if (flag === 1) {
          data = {
            skip: (page - 1) * rowsPerPage,
            limit: rowsPerPage,
          };
        } else if (flag === 2) {
          data = {
            skip: 0,
            limit: rowsPerPage,
          };
        } else {
          data = {
            skip: 0,
            limit: count,
          };
        }
    
    const filterArray= AlumniData.slice(data?.skip, data?.skip + data?.limit);
    setalumni(filterArray)
    setCount(AlumniData?.length)
      };
      function goToLink(val:string): void {
        if(val==="BookSession"){
            navigate('/'+val,{ state: { mobNo: "",coutryCodeNo:"",isOTPVerify:ACCESS_TOKEN?true:false } });  
        }
        else{
            navigate('/'+val);
        }
      }
    return (
        <div>
            <HeaderHome isHidden={true} />
            <div className="d-block d-md-none">
            <Sidebar data={hasActivePaidPlan===true?SidebarDataPaidUser:SidebarDataFreeUser}/> 
            </div>
            {/*************Banner************* */}
            <div className='position-relative homeBannerContainer' id='homebanner' ref={homebannerdiv}>
                <div className='row mx-0 '>
                <div className="col-md-6 position-relative ps-0">
                        <BannerImage align="Left" src={localImages.tutorBanner}/>
                    </div>
                    <div className="col-md-6">
                    <div className="homeBannerRightSide">
                        <h1 className='homeBannerHeading'>Spotlight on Lurnigo's Dedicated Tutors and Mentors</h1>
                        {/* <h1 className='homeBannerHeading'>academics needs</h1> */}
                        
                        <div className='bannersubtext mt-3'>Meet the exceptional individuals who play a pivotal role in shaping the educational journeys of our students. Explore the diverse backgrounds, expertise, and passion that define the community of Lurnigo tutors.</div>
                        <div className='mt-5'>
                        <button className='signBtn px-4 font16' onClick={()=>setopenBookSeat(true)}>Join Lurnigo Today</button>
                        </div>
                        
                    </div>
                    </div>
                    
                </div>
            </div>
            <div className='commonPad'>
            <div className='width800 mx-auto my-5 commonPad pb-0'>
                    <h1 className='headingFont text-center'>Meet our tutors</h1>
                    <div className='bannersubtext mb-3 text-center commonPad pb-0'>Join us in celebrating the mentors who inspire, guide, and contribute to the success of our learners.</div>
                </div>
                <div className='d-flex flex-wrap'>
                    {alumni?.map((item:any, index:number) => (
                        <AlumniCard data={item} />
                    ))}
                </div>
                <div className='mt-4'>
                    <Pagination className="CustomPagination" count={totalPages} variant="outlined" shape="rounded" page={currentPage} onChange={handleChange} />
                </div>           
            </div>
           
            <div className='commonPad bgLightGrey'>
                <div className='width800 mx-auto my-5'>
                    <h1 className='headingFont text-center'>Don't just take our word for it</h1>
                    <div className='bannersubtext mb-3 text-center'>Hear what our tutor have to say</div>
                </div>
                <div>
                    <StudentSayCarousel Data={dataConstant?.studentSayData} id='carouselStudent' />
                </div>
                <div>
                </div>

            </div>
           
            <div className='commonPad '>
                <div className='row mx-0'>
                    <div className='col-md-3'>
                        <h1 className='headingFont mb-4'>FAQs</h1>
                        <div className='mb-4'>
                            <button className='contactUSbtn px-5' onClick={()=>contactUS('Footer')}>Contact Us</button>
                        </div>
                    </div>
                    <div className='col-md-9'>
                    <Faq faqData={dataConstant?.faqData} id={"HomeFAQ"}/>
                    </div>                   
                </div>                
            </div>
            <div className='commonPad '>
            <BookSession clickEvent={()=>goToLink('BookSession')}/>
            </div>
                    {/***************Footer*************** */}
                    <Footer id='Footer'/>
                    <JoinLurnigoPopup open={openBookSeat} handleClose={closesBooseat} />
            </div>
            )
}
