import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

function Polypad(props: any) {
const [data,setData]=useState<any>("")
useEffect(() => {
  // Load the Polypad script
  const script = document.createElement('script');
  script.src = "https://static.mathigon.org/api/polypad-en-v5.0.5.js";
  script.async = true;

  script.onload = () => {
    // Initialize Polypad when script is loaded
    if ((window as any).Polypad) {
      (window as any).Polypad.create(document.querySelector('#polypad'));
    }
  };

  document.body.appendChild(script);

  // Cleanup: remove the script when the component unmounts
  return () => {
    document.body.removeChild(script);
  };
}, [data]);

useEffect(() => {
  let x = Math.random() * 10;
setData(""+x)
}, [props?.open]);
  
  return (
    <div className="courseModel h-100">
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model bbmodel"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
      >
        <Fade in={props.open}> */}
          <div className="card text-center w-100 h-100 rounded-0">
            {/* <div className='"card-header px-4 pt-2'>
            <div className="d-flex justify-content-end align-items-center">
              <ClearOutlinedIcon
                className="colorblue fs-3 rounded-circle cursor float-end"
                onClick={props.handleClose}
              />
            </div>
            </div> */}
            <div className="card-body p-0">
            <div id="polypad" className='polypadFrame'></div>
            </div>
            
           
          </div>
        {/* </Fade>
      </Modal> */}
    </div>
  );
}

export default Polypad;
