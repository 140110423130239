import React, { useEffect, useState } from "react";
import "../Assets/Css/login.css";
import { useNavigate } from "react-router-dom";
import { validateIsNumberOnly } from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../Utils/errormsg";
import { useAppDispatch } from "../hooks";
import { getCountry, updateMoreValues, verifyPassword } from "../Redux/Actions/AuthAction";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import PhoneInputField from "../Atom/phoneInputField";
import HeaderHome from "../Component/headerHome";
import localImages from "../Constant/localImagesNew";
import Sidebar from "../Module/sidebar";
import { SidebarDataFreeUser } from "../Module/sidebarData";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../Modals";
import services from "../Constant/service";
import service from "../Constant/service";
import ErrorSnackbar from "../Component/errorSnackbar";
declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [Mobno, setMobno] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [loginFor, setLoginFor] = useState("Student");
  const [selectedOption, setSelectedOption] = useState("OTP");
  const [password,setPassword]=useState("")
  const { firstName, countryCode } = useSelector(
    (state: { AuthReducer: AuthReducerModal }) => ({
      firstName: state?.AuthReducer?.firstName,
      countryCode: state?.AuthReducer?.userCountryCallingCode,
    })
  );
  const [coutryCode, setcoutryCode] = useState<string>(
    countryCode ? countryCode.substring(1) : "61"
  );
  useEffect(() => {
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
  }, []);
  console.log("coutryCode", coutryCode);
  function validations() {
    return (
      (coutryCode !== "61" && Mobno?.length < 10) ||
      (coutryCode === "61" && Mobno?.length < 9) || (selectedOption==="Password" && password==="")
    );
  }

  const onSignup = () => {
    if(selectedOption==="OTP"){
    dispatch(updateMoreValues({ isLoader: true }));
    const formatPh = "+" + coutryCode + Mobno;
    let verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });

    if (auth && formatPh && verifier) {
      signInWithPhoneNumber(auth, formatPh, verifier)
        .then(function (result: any) {
          if (result) {
            dispatch(updateMoreValues({ isLoader: false }));
            window.confirmationResult = result;
            dispatch(showSuccessSnackbar("OTP sent successfully!"));
            verifier.clear();
            navigate("/OTPVerification", {
              state: {
                mobileNo: Mobno,
                coutryCode: coutryCode,
                loginFor: loginFor,
              },
            });
          }
        })
        .catch(function (err: any) {
          dispatch(updateMoreValues({ isLoader: false }));
          dispatch(showErrorSnackbar(err.name));
          verifier.clear();
          // window.location.reload()
        });
    }
  }
  else{
    const payload = {
      mobile: Mobno,
      password: password,
    };
    dispatch(
      verifyPassword(
        payload,
        (data: any) => {

          dispatch(updateMoreValues({ isLoader: false }));
          if (data?.statusCode === services?.status_code?.success) {
            if (data?.data?.isRegistered === true) {
              dispatch(
                updateMoreValues({ ACCESS_TOKEN: data.data.authToken })
              );
              dispatch(updateMoreValues({ email: data.data.email }));
              dispatch(
                updateMoreValues({ countryCode: data.data.countryCode })
              );
              dispatch(updateMoreValues({ firstName: data.data.firstName }));
              dispatch(updateMoreValues({ lastName: data.data.lastName }));
              dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
              dispatch(
                updateMoreValues({ examTypeId: data.data.examtypeId?._id })
              );
              dispatch(
                updateMoreValues({ countryName: data.data.countryName })
              );
              dispatch(
                updateMoreValues({ examTypeName: data.data.examtypeId?.name })
              );
              dispatch(
                updateMoreValues({ courseType: data.data.coursetypeId })
              );
              dispatch(
                updateMoreValues({ parentName: data.data.parentName })
              );
              dispatch(
                updateMoreValues({ parentNumber: data.data.parentNumber })
              );
              dispatch(
                updateMoreValues({ parentEmail: data.data.parentEmail })
              );
              dispatch(updateMoreValues({ altNumber: data.data.altNumber }));
              dispatch(updateMoreValues({ userId: data.data.userId }));
              service.setAuthorizationToken(data.data.authToken);
              dispatch(updateMoreValues({ studentId: data.data.studentId }));
              dispatch(
                updateMoreValues({
                  hasActivePaidPlan: data.data.hasActivePaidPlan,
                })
              );
              dispatch(
                updateMoreValues({
                  loginFor: loginFor,
                })
              );
              if (data.data.hasActivePaidPlan === true) {
                if (loginFor === "Parent") {
                  navigate("/Parent/Dashboard");
                } else {
                  navigate("/User/Dashboard");
                }
              } else {
                if (loginFor === "Parent") {
                  navigate("/Parent/demoDashboard");
                } else {
                  navigate("/User/demoDashboard");
                }
              }
            } else {
              // navigate("/CreateAccount", { state: { mobNo: mobileNo,coutryCodeNo:coutryCode } });
              navigate("/BookSession", {
                state: {
                  mobNo: Mobno,
                  coutryCodeNo: coutryCode,
                  isOTPVerify: true,
                },
              });
            }
          } else {
            dispatch(showErrorSnackbar("wentWrong"));
          }
        },
        (error:any) => {
    console.log('error', error);
    dispatch(showErrorSnackbar(error?.data?.msg));
        }
      )
    );
  }
  
  };
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setMobno(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  return (
    // <div>
    //   <HeaderHome isHidden={true} />
    //   <div className="d-block d-md-none">
    //     <Sidebar data={SidebarDataFreeUser} />
    //   </div>
    //   <div className="loginBG bgLightGrey d-flex position-relative">
    //     <img
    //       src={localImages?.loginleftarrow}
    //       className="loginLeftArrow"
    //       alt="loginLeftArrow"
    //     />
    //     <img
    //       src={localImages?.loginrightarrow}
    //       className="loginRightArrow"
    //       alt="loginRightArrow"
    //     />
    //     <div className="row authDiv w-100">
    //       <div className="col-lg-5 rightDivPad position-relative m-auto boxshadow">
    //         <h3 className="boldfont text-center mb-2">Welcome to Lurnigo</h3>
    //         <div className="colorblue text-center my-2">Let's Get Started</div>
    //         <div className="colorgray707070 text-center">
    //           To get started, please verify mobile number
    //         </div>

    //         <div className="mobileField mt-5 px-2">
    //           <div className="phoneInputBox">
    //             {/* <div className="d-flex mb-3">
    //               <div className="form-check me-4">
    //                 <input
    //                   className="form-check-input"
    //                   type="radio"
    //                   name="flexRadioDefault"
    //                   id="flexRadioDefault1"
    //                   checked={loginFor === "Student" ? true : false}
    //                   onChange={() => setLoginFor("Student")}
    //                 />
    //                 <label
    //                   className="form-check-label font16"
    //                   htmlFor="flexRadioDefault1"
    //                 >
    //                   Student
    //                 </label>
    //               </div>
    //               <div className="form-check">
    //                 <input
    //                   className="form-check-input"
    //                   type="radio"
    //                   name="flexRadioDefault"
    //                   id="flexRadioDefault2"
    //                   checked={loginFor === "Parent" ? true : false}
    //                   onChange={() => setLoginFor("Parent")}
    //                 />
    //                 <label
    //                   className="form-check-label font16"
    //                   htmlFor="flexRadioDefault2"
    //                 >
    //                   Parent
    //                 </label>
    //               </div>
    //             </div> */}
                
    //             <div className="tab-container">
    //               <button className={`tab tab--1 ${selectedOption==="OTP"?'active':''}`} onClick={()=>{setSelectedOption('OTP')}}>Sign In With OTP</button>
    //               <button className={`tab tab--2 ${selectedOption==="Password"?'active':''}`} onClick={()=>{setSelectedOption('Password')}}>Sign In With Password</button>
    //               <div className="indicator"></div>
    //             </div>
    //             <div className="my-2">
    //               <label className="mb-2">Mobile Number</label>
    //               <div className="d-flex position-relative">
    //                 <select
    //                   className="form-select cursor coutryCode  rounded-0 rounded-start"
    //                   aria-label="Default select example"
    //                   defaultValue={1}
    //                   value={coutryCode}
    //                   onChange={onChangeCoutryCode}
    //                 >
    //                   {countryData?.map((item: any, index) => {
    //                     return (
    //                       <option value={item.phonecode}>
    //                         {item?.isoCode} +{item?.phonecode}
    //                       </option>
    //                     );
    //                   })}
    //                 </select>
    //                 <PhoneInputField
    //                   placeholder="Enter Your Mobile Number"
    //                   value={Mobno}
    //                   maxLength={"10"}
    //                   setValue={setPhoneNumber}
    //                 />
    //                 {/* <span className="mendatory ms-2 fs-5">*</span> */}
    //               </div>
    //             </div>
    //             <div id="recaptcha-container"></div>
    //             <div className="mt-5">
    //               <button
    //                 className={`w-100 rounded px-4 py-1 font24 border-0 ${
    //                   validations() ? "btnDisable" : "btnActive"
    //                 }`}
    //                 disabled={validations()}
    //                 onClick={onSignup}
    //                 // onClick={sendOTP}
    //               >
    //                 Login
    //               </button>
    //             </div>
    //           </div>
    //         </div>

    //         {/* <div className="mb-o tncdiv bottom-0 start-0 end-0 m-auto">
    //           <p className="text-center ">
    //             <span className="colorblue cursor" onClick={gototerms}>
    //               T&C
    //             </span>{" "}
    //             and
    //             <span className="colorblue cursor" onClick={gotoprivacypolicy}>
    //               {" "}
    //               Privacy Policy
    //             </span>
    //           </p>
    //         </div> */}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="bookSessionPage">
      <div className="bookSessionLogo">
        <img
          src={localImages.logo}
          alt="IFAS Logo"
          className="headerlogo cursor"
          // onClick={goToHome}
        />
      </div>
      <div className="bookSessionCard rounded shadow">
      <div className="row authDiv w-100 h-100 m-auto">
          <div className="rightDivPad position-relative m-auto">
            <h3 className="boldfont text-center mb-2">Welcome to Lurnigo</h3>
            <div className="colorblue text-center my-2">Let's Get Started</div>
            <div className="colorgray707070 text-center">
              To get started, please verify mobile number
            </div>

            <div className="mobileField mt-5 px-2">
              <div className="phoneInputBox">
                <div className="d-flex mb-3 justify-content-center radio-input">
                  {/* <div className="form-check me-4"> */}
                    <label className="label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={selectedOption==="OTP" ? true : false}
                      onClick={()=>{setSelectedOption('OTP')}}
                    />
                      <p className="text">Sign In With OTP</p>
                    </label>
                    {/* <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={selectedOption==="OTP" ? true : false}
                      onClick={()=>{setSelectedOption('OTP')}}
                    />
                    <label
                      className="form-check-label font16"
                      htmlFor="flexRadioDefault1"
                    >
                      Sign In With OTP
                    </label> */}
                  {/* </div> */}
                 
                  <label className="label">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={selectedOption==="Password" ? true : false}
                      onClick={()=>{setSelectedOption('Password')}}
                    />
                      <p className="text">Sign In With Password</p>
                    </label>
                    {/* <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={selectedOption==="Password" ? true : false}
                      onClick={()=>{setSelectedOption('Password')}}
                    />
                    <label
                      className="form-check-label font16"
                      htmlFor="flexRadioDefault2"
                    > */}
                      {/* Sign In With Password */}
                    {/* </label> */}
                 
                </div>
                {selectedOption==="Password"?<div className="my-2 text-danger fw-bold">Only for paid User</div>:null}
                
                {/* <div className="tab-container">
                  <button className={`tab tab--1 ${selectedOption==="OTP"?'active':''}`} onClick={()=>{setSelectedOption('OTP')}}>Sign In With OTP</button>
                  <button className={`tab tab--2 ${selectedOption==="Password"?'active':''}`} onClick={()=>{setSelectedOption('Password')}}>Sign In With Password</button>
                  <div className="indicator"></div>
                </div> */}
                <div className="my-2">
                  <label className="mb-2">Mobile Number</label>
                  <div className="d-flex position-relative">
                    <select
                      className="form-select cursor coutryCode  rounded-0 rounded-start"
                      aria-label="Default select example"
                      defaultValue={1}
                      value={coutryCode}
                      onChange={onChangeCoutryCode}
                    >
                      {countryData?.map((item: any, index) => {
                        return (
                          <option value={item.phonecode}>
                            {item?.isoCode} +{item?.phonecode}
                          </option>
                        );
                      })}
                    </select>
                    <PhoneInputField
                      placeholder="Enter Your Mobile Number"
                      value={Mobno}
                      maxLength={"10"}
                      setValue={setPhoneNumber}
                    />
                    {/* <span className="mendatory ms-2 fs-5">*</span> */}
                  </div>
                </div>
                {selectedOption==="Password" ?<div className="my-2">
                  <label className="mb-2">Password</label>
                  <div className="d-flex position-relative">
                  <input type="password" placeholder="Enter password" className="form-control" id="inputPassword" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                  </div>
                </div>:null}
                <div id="recaptcha-container"></div>
                <div className="mt-5">
                  <button
                    className={`sendOTPBtn rounded px-4 w-100 justify-content-center  ${validations() ? "" : "active"
                      }`}
                    onClick={onSignup}
                    disabled={validations()}
                  >
                    Login
                  </button>
                  {/* <button
                    className={`w-100 rounded px-4 py-1 font24 border-0 ${
                      validations() ? "btnDisable" : "btnActive"
                    }`}
                    disabled={validations()}
                    onClick={onSignup}
                    // onClick={sendOTP}
                  >
                    Login
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
}
