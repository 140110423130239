import React, { useEffect, useState, useRef } from "react";
import localImages from "../Constant/localImagesNew";
import "../Assets/Css/home.css";
import "../Assets/Css/dsatLandingPage.css";
import Faq from "../Component/faq";
import { useNavigate, useParams } from "react-router-dom";
import StudentSayCarousel from "../Component/studentSayCarousel";
import dataConstant from "../Constant/dataConstant";
import {
  addDSATlandingpage,
  getCountry,
  gradeLevelList,
  sendOtp,
  updateMoreValues,
} from "../Redux/Actions/AuthAction";
import { useAppDispatch } from "../hooks";
import CertificateSection from "../Module/certificateSection";
import InputField from "../Atom/InputField";
import PhoneInputField from "../Atom/phoneInputField";
import {
  validateEmailAddress,
  validateEmailAddress1,
  validateFullName,
  validateIsNumberOnly,
} from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import {
  validateFullNameErrMsg,
  validateIsNumberOnlyErrMsg,
  validateLastNameErrMsg,
} from "../Utils/errormsg";
import Dropdown from "../Component/dropdown";
import HeaderDsat from "../Component/headerDsat";
import CloseIcon from "@mui/icons-material/Close";
import LandingPageFooter from "../Component/landingPageFooter";
import CollegeCouncellingPopup from "../Component/collegeCouncellingPopup";
import StatsCollegeCounsell from "../Module/statsCAC";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OTPInput from "react-otp-input";
import service from "../Constant/service";
import { useSelector } from "react-redux";
import MetaDataFile from "../Component/metaDataFile";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import "../Assets/Css/swipper.css";
import ProgramFaq from "../Component/programFaq";
import { colors } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}
export default function CollegeCounseling() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const homebannerdiv = React.useRef<HTMLDivElement>(null);
  const [isHidden, setisHidden] = useState<boolean>(true);
  const [firstName, setfirstname] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [mob, setmob] = useState<string>("");
  const [countryData, setCountryData] = useState([]);
  const [coutryCode, setcoutryCode] = useState<string>("1");
  const [gradeList, setGradeList] = useState([]);
  const [closePopup, setClosePopup] = useState<boolean>(false);
  const targetRef: any = useRef(null);
  const [openpopup1, setopenpopup1] = useState<boolean>(false);
  const [popupShown, setPopupShown] = useState(false); // To track if the popup has been shown before
  const [grade, setGrade] = useState<any>("");
  const [step, setStep] = useState<string>("step1");
  const [otp, setotp] = useState("");
  const [programData, setProgramData] = useState<any>("");

  const [{ inputType }] = React.useState({
    numInputs: 4,
    separator: "-",
    minLength: 0,
    maxLength: 40,
    placeholder: "-",
    inputType: "text" as const,
  });
  const statRef: any = useRef(null);

  const { ACCESS_TOKEN } = useSelector((state: any) => ({
    ACCESS_TOKEN: state?.AuthReducer?.ACCESS_TOKEN,
    userCountryCallingCode: state?.AuthReducer?.userCountryCallingCode,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getCountry(
        (data: any) => {
          setCountryData(data);
        },
        () => {}
      )
    );
    dispatch(
      gradeLevelList(
        (data: any) => {
          const filterGrade = data?.filter((item: any) => item?._id >= 7);

          setGradeList(filterGrade);
        },
        () => {}
      )
    );

    setProgramData(dataConstant?.counselingProgramData[0]);
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !popupShown) {
          // setopenpopup1(true);
          setPopupShown(true);
        }
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [popupShown]);

  const contactUS = (val: string) => {
    const element = document.getElementById(val);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  var myScrollFunc = function () {
    var y = window.scrollY;
    const maxScroll = document.body.scrollHeight - window.innerHeight;
    if (y >= 500 && y < maxScroll - 300) {
      setisHidden(false);
    } else {
      setisHidden(true);
    }
  };
  window.addEventListener("scroll", myScrollFunc);

  const approach = [
    {
      heading: "Precision Planning",
      subtext:
        "Tailored strategies highlight each student's strengths and interests, streamlining the path from essay writing to university selection, ensuring every application shines.",
    },
    {
      heading: "Profound Understanding",
      subtext:
        "We delve deep, ensuring students fully grasp and convey their academic passions and achievements, enriching their applications with genuine insights and enthusiasm.",
    },
    {
      heading: "Persistent Progress",
      subtext:
        "Our structured milestones keep students on track, consistently pushing boundaries through rigorous practice and feedback, optimizing every step toward college admission. ",
    },
  ];

  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfirstname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };
  const handleLastInputText = (e: any) => {
    if (validateFullName(e)) setlastName(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const setPhoneNumber = (e: any) => {
    if (!validateIsNumberOnly(e)) {
      setmob(e);
    } else {
      dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
    }
  };
  const onChangeCoutryCode = (e: any) => {
    setcoutryCode(e.target.value);
  };
  const onclickSendotp = () => {
    if (mob === "") {
      dispatch(showErrorSnackbar("Please enter Mobile Number"));
    } else {
      const formatPh = "+" + coutryCode + mob;
      let verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });

      if (auth && formatPh && verifier) {
        signInWithPhoneNumber(auth, formatPh, verifier)
          .then(function (result: any) {
            if (result) {
              window.confirmationResult = result;
              dispatch(showSuccessSnackbar("OTP sent successfully!"));
              verifier.clear();
              const payload: any = {
                email: email,
                mobile: mob,
                countryCode: "+1",
                countryId: "226",
                coursetypeId: 15,
                sourceId: 5,
                statusId: 1,
                otpVerified: false,
                smsourceId: id,
              };
              dispatch(
                addDSATlandingpage(
                  payload,
                  (data: any) => {
                    setStep("step2");
                  },
                  (error: any) => {
                    dispatch(showErrorSnackbar(error?.data?.msg));
                  }
                )
              );
            }
          })
          .catch(function (err: any) {
            dispatch(showErrorSnackbar(err.name));
            verifier.clear();
            // window.location.reload()
          });
      }
    }
  };
  // const handleSubmit = (e: any) => {
  //   e.preventDefault();

  //   const postData = {
  //     firstName: firstName,
  //     lastName: lastName,
  //      email: email,
  //     mobile: mob,
  //     countryCode: "+1",
  //     countryId: "226",
  //     gradeId: grade,
  //     coursetypeId: 15,
  //     sourceId: 5,
  //     statusId: 1,
  //     otpVerified: true,
  //     smsourceId: id,
  //   };
  //   dispatch(
  //     addDSATlandingpage(
  //       postData,
  //       (res: any) => {
  //         const payload = {
  //           mobile: mob,
  //         };
  //         dispatch(
  //           sendOtp(
  //             payload,
  //             (data: any) => {
  //               if (data?.statusCode === service?.status_code?.success) {
  //                 if (data?.data?.isRegistered === true) {
  //                   dispatch(
  //                     updateMoreValues({
  //                       ACCESS_TOKEN: data.data.authToken,
  //                     })
  //                   );
  //                   dispatch(updateMoreValues({ email: data.data.email }));
  //                   dispatch(
  //                     updateMoreValues({
  //                       countryCode: data.data.countryCode,
  //                     })
  //                   );
  //                   dispatch(
  //                     updateMoreValues({ firstName: data.data.firstName })
  //                   );
  //                   dispatch(
  //                     updateMoreValues({ lastName: data.data.lastName })
  //                   );
  //                   dispatch(updateMoreValues({ mobileNo: data.data.mobile }));
  //                   dispatch(
  //                     updateMoreValues({
  //                       examTypeId: data.data.examtypeId?._id,
  //                     })
  //                   );
  //                   dispatch(
  //                     updateMoreValues({
  //                       examTypeName: data.data.examtypeId?.name,
  //                     })
  //                   );
  //                   dispatch(
  //                     updateMoreValues({
  //                       courseType: data.data.coursetypeId,
  //                     })
  //                   );
  //                   dispatch(
  //                     updateMoreValues({
  //                       parentName: data.data.parentName,
  //                     })
  //                   );
  //                   dispatch(
  //                     updateMoreValues({
  //                       parentNumber: data.data.parentNumber,
  //                     })
  //                   );
  //                   dispatch(
  //                     updateMoreValues({
  //                       parentEmail: data.data.parentEmail,
  //                     })
  //                   );
  //                   dispatch(
  //                     updateMoreValues({ altNumber: data.data.altNumber })
  //                   );
  //                   dispatch(updateMoreValues({ userId: data.data.userId }));
  //                   service.setAuthorizationToken(data.data.authToken);
  //                   dispatch(
  //                     updateMoreValues({ studentId: data.data.studentId })
  //                   );
  //                   dispatch(
  //                     updateMoreValues({
  //                       hasActivePaidPlan: data.data.hasActivePaidPlan,
  //                     })
  //                   );

  //                   navigate("/ThankYou", {
  //                     state: { pagename: "CollegeCounseling" },
  //                   });
  //                 }
  //               } else {
  //                 dispatch(showErrorSnackbar("wentWrong"));
  //               }
  //             },
  //             () => {}
  //           )
  //         );
  //       },
  //       (error: any) => {
  //         dispatch(showErrorSnackbar(error?.data?.msg));
  //       }
  //     )
  //   );
  // };
  const getGrade = (val: any) => {
    setGrade(val);
  };

  const cardData = [
    {
      id: 1,
      heading: "97%",
      dis: "Success Rate for college counseling Students Who Work With Us",
    },
    {
      id: 2,
      heading: "4/5",
      dis: "students get admits from Top 50",
    },
    {
      id: 3,
      heading: "5x",
      dis: "Higher Acceptance Rates at Ivy League & Top 10 Universities",
    },
    {
      id: 4,
      heading: "1K+",
      dis: "Students Who Achieved Their Dream Admissions!",
    },
  ];

  function validation() {
    return (
      email === "" ||
      !validateEmailAddress1(email) ||
      (coutryCode !== "1" && mob?.length < 10) ||
      firstName === "" ||
      lastName === "" ||
      mob === "" ||
      grade === ""
    );
  }
  const data = [
    {
      img: localImages?.stem,
      text: "( STEM Accreditation )",
    },
    {
      img: localImages?.naac,
      text: "( NACAC membership )",
    },
  ];
  const handleOTPChange = (otp: string) => {
    setotp(otp.toString());
  };
  function OTPValidate() {
    return otp?.length < 6;
  }
  const onOTPVerify = () => {
    window.confirmationResult
      .confirm(otp)
      .then(async (res: any) => {
        const postData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          mobile: mob,
          countryCode: "+1",
          countryId: "226",
          gradeId: grade,
          coursetypeId: 15,
          sourceId: 5,
          statusId: 1,
          otpVerified: true,
          smsourceId: id,
        };
        dispatch(
          addDSATlandingpage(
            postData,
            (res: any) => {
              const payload = {
                mobile: mob,
              };
              dispatch(
                sendOtp(
                  payload,
                  (data: any) => {
                    if (data?.statusCode === service?.status_code?.success) {
                      if (data?.data?.isRegistered === true) {
                        dispatch(
                          updateMoreValues({
                            ACCESS_TOKEN: data.data.authToken,
                          })
                        );
                        dispatch(updateMoreValues({ email: data.data.email }));
                        dispatch(
                          updateMoreValues({
                            countryCode: data.data.countryCode,
                          })
                        );
                        dispatch(
                          updateMoreValues({ firstName: data.data.firstName })
                        );
                        dispatch(
                          updateMoreValues({ lastName: data.data.lastName })
                        );
                        dispatch(
                          updateMoreValues({ mobileNo: data.data.mobile })
                        );
                        dispatch(
                          updateMoreValues({
                            examTypeId: data.data.examtypeId?._id,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            examTypeName: data.data.examtypeId?.name,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            courseType: data.data.coursetypeId,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentName: data.data.parentName,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentNumber: data.data.parentNumber,
                          })
                        );
                        dispatch(
                          updateMoreValues({
                            parentEmail: data.data.parentEmail,
                          })
                        );
                        dispatch(
                          updateMoreValues({ altNumber: data.data.altNumber })
                        );
                        dispatch(
                          updateMoreValues({ userId: data.data.userId })
                        );
                        service.setAuthorizationToken(data.data.authToken);
                        dispatch(
                          updateMoreValues({ studentId: data.data.studentId })
                        );
                        dispatch(
                          updateMoreValues({
                            hasActivePaidPlan: data.data.hasActivePaidPlan,
                          })
                        );

                        navigate("/ThankYou", {
                          state: { pagename: "CollegeCounseling" },
                        });
                      }
                    } else {
                      dispatch(showErrorSnackbar("wentWrong"));
                    }
                  },
                  () => {}
                )
              );
            },
            (error: any) => {
              dispatch(showErrorSnackbar(error?.data?.msg));
            }
          )
        );
      })
      .catch((err: any) => {});
  };

  const onChangeProgram = (val: any) => {
    setProgramData(val);
  };

  const callToData = [
    {
      title: "Personalized Profile Evaluation",
      desc: "Our expert Ivy League admissions consultants will assess your child's strengths, achievements, and areas for improvement, offering tailored insights to help them excel in the competitive admissions landscape.",
    },
    {
      title: "Guidance on Majors and Universities Selection",
      desc: "Collaborate with our college counseling experts to explore your child's interests and aspirations, identifying best-fit majors and universities including prestigious institutions like Harvard, MIT, Stanford and Princeton.",
    },
    {
      title: "Passion Projects and Internship Planning",
      desc: "We provide personalized college counseling to guide your child in pursuing meaningful projects and internships that align with their goals, showcasing their unique talents to impress admissions committees.",
    },
    {
      title: "Comprehensive Plan of Action ",
      desc: "Receive practical college admissions counseling strategies to enhance your child's profile, including a personalized college application strategy aimed at achieving 5X higher acceptance rates to their dream schools.",
    },
  ];

  const progressGeneralData = [
    {
      universityname: "Harvard University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "3",
      lurnigoRate: "6",
    },
    {
      universityname: "Columbia University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "4",
      lurnigoRate: "4",
    },
    {
      universityname: "MIT University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "8",
      lurnigoRate: "23",
    },
    {
      universityname: "Stanford University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "5",
      lurnigoRate: "6",
    },
    {
      universityname: "Brown University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "5",
      lurnigoRate: "13",
    },
    {
      universityname: "University of Chicago",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "6",
      lurnigoRate: "19",
    },
    {
      universityname: "Yale University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "5",
      lurnigoRate: "17",
    },
    {
      universityname: "Duke University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "8",
      lurnigoRate: "23",
    },
    {
      universityname: "Princeton University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "9",
      lurnigoRate: "15",
    },
    {
      universityname: "Cornell University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "10",
      lurnigoRate: "10",
    },
    {
      universityname: "Johns Hopkins University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "7",
      lurnigoRate: "21",
    },
    {
      universityname: "University of Pennsylvania",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "8",
      lurnigoRate: "19",
    },
    {
      universityname: "UCLA",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "10",
      lurnigoRate: "38",
    },
    {
      universityname: "Carnegie Mellon University",
      generalcolor: "#1a4696 ",
      lurnigocolor: "",
      generalRate: "15.4",
      lurnigoRate: "20",
    },
    {
      universityname: "Northwestern University",
      generalcolor: "#1a4696",
      lurnigocolor: "",
      generalRate: "7.5",
      lurnigoRate: "15",
    },
  ];

  const ProgressBar = ({
    value,
    color = "progress-bar-blue",
    height = "10px",
    padding = "0px",
    max = 100,
  }) => {
    const [animatedWidth, setAnimatedWidth] = useState(0);

    useEffect(() => {
      setTimeout(() => {
        setAnimatedWidth(Math.min((value / max) * 200, 100));
      }, 100);
    }, [value, max]);
    return (
      <div className="progress " style={{ height, padding }}>
        <div
          className={`text-center ${color}`}
          role="progressbar"
          style={{
            // width: `${Math.min((value / max) * 100, 100)}%`,
            // width: `${Math.min((value / max) * 200, 100)}%`,
            width: `${animatedWidth}%`,
            height: "100%",
            padding,
            lineHeight: height,
            backgroundColor: color,
            transition: "width 1.5s ease-in-out",
          }}
          aria-valuenow={value}
          aria-valuemin={50}
          aria-valuemax={max}
        >
          {value}%
        </div>
      </div>
    );
  };
  return (
    <div>
      <HeaderDsat isHidden={false} pagename={"College_Counseling"} />

      <MetaDataFile title="#1 Best College Consultant in USA | Lurnigo" />

      {/* <div className="position-relative homeBannerContainer mt-4 mt-md-0 boxshadow">
        <img
          src={localImages?.homeBanner}
          alt="home banner"
          className="w-100 d-none d-md-block"
        />
        <img
          src={localImages?.homeBannerMob}
          alt="home banner"
          className="w-100 d-block d-md-none"
        />
      </div> */}
      <div
        className="position-relative mt-1 homeBannerContainer mb-3"
        id="Home"
        ref={homebannerdiv}
      >
        <div className="row mx-0 ">
          <div className="col-md-5 bgBlue   ">
            <div className="collegeBannerLeftDiv d-none d-md-flex justify-content-center">
              <img
                src={localImages?.landingPageBanner}
                alt="Digital sat Banner"
                className="dsatBanner mt-5"
              />
            </div>
          </div>

          <div className="col-12 col-md-7  col-lg-7 academicsHeading mt-4 mt-md-0 border-bottom boxshadow">
            <div className="academicsHeading">
              <h1 className="homeBannerHeading">
                Personalized College Counseling Journey to Ivy Leagues Starts
                here
              </h1>
              {/* <h1 className="homeBannerHeading">
                Navigate Your Way into Your{" "}
                <span className="footerIcon">Dream University</span> with Ease!
              </h1> */}
              <div className="font16 mt-4">
                Proven College Application help by Ivy League Admissions
                consultants for Top Universities Acceptance and more!
              </div>
              <div className="row mx-0 my-2  flex-nowrap img-main-dev">
                {data?.map((c, index) => {
                  return (
                    <>
                      <div className="col-4 col-md-3 d-flex align-items-center justify-content-center bannerdivImg  boxshadow rounded g-2 gap-2 me-2 px-2">
                        <img
                          src={c?.img}
                          className="img-cards"
                          alt="crtificate"
                        />
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="mt-4 d-flex align-items-center justify-content-sm-center  justify-content-md-start mb-xl-0 mb-3 flex-wrap gap-2">
                {/* <div className=" alert alert-danger fw-bold p-2 mx-2 my-0" role="alert">
                limited spots left!
                </div> */}
                <div
                  className="alert alert-danger fw-bold p-2 mx-2 my-0 alertBtn"
                  role="alert"
                >
                  <span>Limited spots left!</span>
                </div>

                <button
                  // onClick={onclickSendotp}
                  onClick={() => setopenpopup1(true)}
                  className={`bookSessionButton col-12 col-md-6 rounded py-2" ${
                    validation() ? "" : "active"
                  }`}
                  // disabled={validation()}
                  type="button"
                >
                  Book a Free Strategy Session Now!
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container statsDiv">
          <div className="row g-2">
            {cardData.map((item: any, i: number) => (
              <div className="col-12 col-md-3 ">
                <div className="card h-100 p-2 statsInnerDiv">
                  <div className="d-flex align-items-center h-100  ">
                    <div className="statsNumber fw-bold px-2 col-md-4 col-3">
                      {item.heading}
                    </div>
                    <div className="fs-6 col-md-8 col-9">{item.dis}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="OurProgram"></div>
      <div className="commonPad position-relative bgBlue" ref={targetRef}>
        <h1 className="headingFont text-center">Our Program consists of?</h1>
        <div className="row mx-0 my-4">
          {dataConstant?.OurProgramConsists?.map((c: any, index) => {
            return (
              <div className="col-md-4 gap-2 g-2" key={c.id}>
                <div className="card callToActionBox p-4 h-100">
                  <div className="font20 fw-bold mb-3">{c.title}</div>
                  <div className="">{c.desc}</div>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="d-none d-sm-block "> */}
        {/* <div className="row px-5 mx-5 d-flex justify-content-center mt-5 "> */}
        {/* {learnOurProgramData?.map((item, index) => (
            <div
              className="col-md-4 mb-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay={`${index * 0.5}s`}
              key={index}
            >
              <div className="learnourProgram">
                <div className="icon-container">
                  <div className="learnourProgramImgCard">
                    <img
                      src={item?.imgPath}
                      alt={item?.title}
                      className="learnourProgramImg"
                    />
                  </div>
                </div>
                <div className="fw-bold font20 my-3 text-center">
                  {item?.title}
                </div>
                <div className="text-center font16">{item?.desc}</div>
              </div>
            </div>
          ))} */}
        {/* <div className="col-md-4 col-12">
              {dataConstant?.counselingProgramData?.map(
                (c: any, index: any) => {
                  return (
                    <div className=" mt-2 programCard">
                      <div
                        className={`programSectionData  ${
                          c?._id === programData?._id ? "active" : ""
                        }`}
                        onClick={() => onChangeProgram(c)}
                      >
                        <p className="font20 fw-bold mb-0">{c?.title}</p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div className="col-md-6 p-3 pt-0 mt-2 pb-0">
              <div className="p-2  pb-0 bg-light rounded h-100 d-flex flex-column justify-content-center">
                <div className="text-center mt-2">
                  <h4 className="colorOrange fw-bold ">{programData?.title}</h4>
                </div>
                <div className="px-4">
                  <ul className="mt-4" style={{ listStyle: "disc" }}>
                    <li className="text-dark font16 mt-3">
                      {programData?.desc1}
                    </li>
                    <li className="text-dark font16 mt-3">
                      {programData?.desc2}
                    </li>
                    <li className="text-dark font16 mt-3">
                      {programData?.desc3}
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="col-md-9 d-block d-sm-none">
          <ProgramFaq
            faqData={dataConstant?.counselingProgramData}
            id={"HomeFAQ"}
          />
        </div> */}
      </div>
      <div id="chatWithUs"></div>
      <div className="commonPad bgLightGrey">
        <div className="row mx-0">
          <div className="col-md-6">
            {/* <div className="bookSessionHeading">
          What will you gain in this 45 mins Counseling session? 
              </div> */}
            <div className="bookSessionHeading mb-5 fw-bold ms-2 text-center mx-auto">
              What will you gain in this 45 mins Counseling session?
            </div>
            <div className="row mx-0">
              {callToData.map((c: any, index) => {
                return (
                  <div className="col-md-6 gap-2 g-2">
                    <div className="callToActionBox boxshadow rounded p-3 h-100 bgwhite">
                      <div className="font20 fw-bold mb-3">{c.title}</div>
                      <div className="">{c.desc}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center flex-column">
            {/* <div className="mb-5 fw-bold bookSessionHeading text-center">Get In Touch</div> */}
            <div className="font28 fw-bold text-center my-4">
              Begin Your Personalized College Counseling Journey to Your{" "}
              <span className="colorOrange fw-bold text-center">
                Dream University today!
              </span>
            </div>

            <div className="row d-flex justify-content-between boxshadow p-4 rounded bgBlue colorWhite">
              <div className="my-2 col-md-6">
                <label className="mb-2">
                  First Name <span className="  ">*</span>
                </label>
                <div className="d-flex ">
                  <InputField
                    placeholder="Enter First Name"
                    value={firstName}
                    setValue={handleFirstInputText}
                    disabled={step === "step2" ? true : false}
                  />
                </div>
              </div>
              <div className="my-2 col-md-6">
                <label className="mb-2">
                  Last Name <span className="  ">*</span>
                </label>
                <div className="d-flex ">
                  <InputField
                    placeholder="Enter Last Name"
                    value={lastName}
                    setValue={handleLastInputText}
                    disabled={step === "step2" ? true : false}
                  />
                </div>
              </div>
              <div className="my-2 col-md-6">
                <label className="mb-2">
                  Mobile Number <span className="  ">*</span>
                </label>
                <div className="d-flex position-relative">
                  <select
                    className="form-select cursor coutryCode  rounded-0 rounded-start"
                    aria-label="Default select example"
                    defaultValue={1}
                    value={coutryCode}
                    onChange={onChangeCoutryCode}
                    disabled
                  >
                    {countryData?.map((item: any, index) => {
                      return (
                        <option value={item.phonecode}>
                          {item?.isoCode} +{item?.phonecode}
                        </option>
                      );
                    })}
                  </select>
                  <PhoneInputField
                    placeholder="Enter Mobile Number"
                    value={mob}
                    maxLength={"10"}
                    setValue={setPhoneNumber}
                    disabled={step === "step2"}
                  />
                </div>
              </div>

              <div className="my-2 col-md-6">
                <label className="mb-2">
                  Email <span className="  ">*</span>
                </label>
                <div className="d-flex ">
                  <InputField
                    placeholder="Enter Email"
                    setValue={setemail}
                    value={email}
                    disabled={step === "step2"}
                    // onChange={(e) => setemail(e.target.value)}
                  />
                </div>
              </div>
              <div className="my-2 col-md-6 ">
                <label className="mb-2">
                  Student Grade <span>*</span>
                </label>
                <div className="  ">
                  <Dropdown
                    placeholder="Select Grade"
                    options={gradeList}
                    value={grade}
                    setInputText={(value: any) => getGrade(value)}
                    disabled={step === "step2"}
                  />
                </div>
              </div>
              {step === "step2" ? (
                ""
              ) : (
                <div className="d-flex justify-content-center mb-5 mt-4 text-center">
                  <button
                    type="button"
                    onClick={onclickSendotp}
                    className={`bookSessionButton rounded fw-bold mt-2 mt-md-0 font16 col-12 col-sm-6 col-md-4" ${
                      validation() ? "" : "active"
                    }`}
                    disabled={validation()}
                  >
                    Submit{" "}
                  </button>
                </div>
              )}
              <div id="recaptcha-container"></div>

              {step === "step2" ? (
                <div className="">
                  <div className="landingPageotpBox cursor mt-2 mt-4">
                    <OTPInput
                      inputStyle="inputStyle"
                      numInputs={6}
                      onChange={handleOTPChange}
                      renderSeparator={<span className="mx-2 mx-md-3"></span>}
                      value={otp}
                      inputType={inputType}
                      renderInput={(props) => <input {...props} />}
                      shouldAutoFocus
                    />
                  </div>
                  <div className="d-flex justify-content-center mb-5 mt-4 text-center">
                    <button
                      type="button"
                      onClick={onOTPVerify}
                      className={`bookSessionButton rounded fw-bold col-12 col-sm-6 col-md-4" ${
                        OTPValidate() ? "" : "active"
                      }`}
                      disabled={OTPValidate()}
                    >
                      Verify OTP{" "}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="commonPad  ">
        <div className=" mx-md-5 my-5">
          <h2 className="headingFont text-center colorOrange mb-4">
            Unlock Your Child’s Chances to Prestigious Universities
          </h2>
          <div className="font32 text-center fw-bold fontLibre"></div>
        </div>
        {/* <div>
        <div className="progress">
  <div className="progress-bar progress-bar-striped" role="progressbar" style={{width:" 10%"}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
</div>
<div className="progress">
  <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width:" 25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
</div>
<div className="progress">
  <div className="progress-bar progress-bar-striped bg-info" role="progressbar" style={{width:" 50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
</div>
<div className="progress">
  <div className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{width:" 75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
</div>
<div className="progress">
  <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{width:" 100%"}}  aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
</div>
        </div> */}
        {/* <div>
          <div className=" row">
            <div className="col-md-5">
              <div className=" fs-4   p-2 text-end my-2 fw-bold ">
                General Acceptance Rate
              </div>
            </div>
            <div className=" col-md-2"></div>
            <div className="col-md-5">
              <div className=" fs-4   p-2  text-start my-2 fw-bold ">
                LurniGo Acceptance Rate
              </div>
            </div>
          </div>
          {progressGeneralData.map((progress: any, index: number) => (
            <div className="row">
              <div className="col-md-5 col-12">
                <div key={index} className="mb-2 gap-2 g-2">
                  <ProgressBar
                    value={progress.generalRate}
                    color={progress.generalcolor}
                    direction="rtl"
                    height="30px"
                    max={100}
                  />
                </div>
              </div>
              <div className=" col-md-2">
                <div className="fw-bold text-center shadow-sm rounded-3 p-2 callToActionBox mb-2">{progress.universityname}</div>
              </div>
              <div className="col-md-5 col-12">
                <ProgressBar
                  key={index}
                  value={progress.lurnigoRate}
                  color={progress.lurnigocolor}
                  height="30px"
                  max={100}
                />
              </div>
            </div>
          ))}
        </div> */}
        <div className="mt-5">
          <table className="table customTable ">
            <thead>
              <tr>
                <th scope="col">University</th>
                <th scope="col">General Admission Rate</th>
                <th scope="col">LurniGo student Admission Rate</th>
              </tr>
            </thead>

            <tbody className="border">
              {progressGeneralData.map((progress: any, index: number) => (
                <tr key={index}>
                  <td className="p-0 w-25">
                    <div className="fw-bold text-center">
                      {progress.universityname}
                    </div>
                  </td>
                  <td className="p-0">
                    <ProgressBar
                      key={index}
                      value={progress.generalRate}
                      color={"progress-bar-blue"}
                      height="30px"
                      max={100}
                    />
                  </td>

                  <td className="p-0">
                    <ProgressBar
                      key={index}
                      value={progress.lurnigoRate}
                      color={"progress-bar-orange"}
                      height="30px"
                      max={100}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* <Swiper
          slidesPerView={4}
          spaceBetween={10}
          breakpoints={{
            1400: {
              width: 1400,
              slidesPerView: 4,
            },
            1200: {
              width: 1200,
              slidesPerView: 3,
            },
            500: {
              width: 600,
              slidesPerView: 2,
            },
            400: {
              width: 400,
              slidesPerView: 1,
            },
            300: {
              width: 300,
              slidesPerView: 1,
            },
          }}
          modules={[Pagination, Navigation]}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          classNameName="mySwiper"
        >
          <div className="">
            {dataConstant?.collegeCounselingData?.map((c: any, index) => {
              return (
                <SwiperSlide>
                  <div className="row d-flex justify-content-center pb-5">
                    <div className="col-md-10 col-sm-12 border border-2 py-4 px-2 m-2 shadow">
                      <div className="text-center">
                        {/* <p className="mb-0 font18 fw-bold">
                          {c?.universityname}
                        </p> */}
        {/* </div>
                      <div className="d-flex justify-content-center ">
                        <div className="col-md-6 py-3 d-flex justify-content-center">
                          <img
                            src={c?.universityPath}
                            className="collegecardImg "
                            alt="university"
                          />
                        </div>
                      </div>
                      <div className="row d-flex align-items-end">
                        <div className="d-flex justify-content-between my-2">
                          <div className="fw-bold fs-6">
                            General Acceptance Rate
                          </div>
                          <div className="fw-bold fs-6">{c?.generalRate}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          {/* Acceptance */}
        {/* <div className="fw-bold fs-6">
                            LurniGo Acceptance Rate
                          </div>
                          <div className="fw-bold fs-6">{c?.lurnigoRate}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>  */}
      </div>

      {/* <div ref={statRef}>
        <StatsCollegeCounsell />
      </div> */}

      <div id="Testimonials"></div>
      <div className="commonPad bgLightGrey">
        <div className="width800 mx-auto my-5">
          <h1 className="headingFont text-center">
            Don't just take our word for it
          </h1>
          <div className="bannersubtext mb-3 text-center">
            Hear what our students have to say
          </div>
        </div>
        <div>
          <StudentSayCarousel
            Data={dataConstant?.CollegestudentSayData}
            id="carouselStudent"
          />
        </div>
        <div></div>
      </div>
      {/* <div id="Awards"></div>
      <CertificateSection /> */}

      <div className="commonPad bglightSkyblue ">
        <div className="bgwhite p-5 boxshadow rounded position-relative overflow-hidden bg-gradients">
          <div className="row">
            <div className="col-md-8">
              <div className="bookSessionHeading">
                Gain Admissions into your dream University!
              </div>
              <div className="bookSessionSubText">
                Our Admission Counselor will get in touch to discuss further.
              </div>
              <div className="mt-3">
                <button
                  className="btn  col-12 col-md-5"
                  onClick={() => setopenpopup1(true)}
                  style={{ background: "#1a4696", color: "#fff" }}
                >
                  {/* Start your Counseling Journey Today */}
                  Start your Counseling Journey Today
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <img
                src={localImages?.bookSessionLine}
                className="position-absolute bottom-0 end-0 d-none d-md-block"
                alt="collegePredright"
                style={{ maxWidth: "400px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="FAQs"></div>
      <div className="commonPad ">
        <div className="row mx-0">
          <div className="col-md-3">
            <h1 className="headingFont mb-4">FAQs</h1>
            <div className="mb-4">
              <button
                className="contactUSbtn px-5"
                onClick={() => contactUS("Footer")}
              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="col-md-9">
            <Faq
              faqData={dataConstant?.collegeCounselingfaqData}
              id={"HomeFAQ"}
            />
          </div>
        </div>
      </div>

      <LandingPageFooter id="Footer" hideMenu={true} />
      <CollegeCouncellingPopup
        open={openpopup1}
        handleClose={() => setopenpopup1(false)}
        mob={mob}
        countryCode={coutryCode}
      />

      {!isHidden ? (
        <div className={`fixedFooter d-none d-md-block`}>
          <div className="row mx-0">
            <div className="col-12 col-md-8 d-flex align-items-center justify-content-center justify-content-sm-start py-2">
              <div className="fw-bold font20 colorblue">
                Begin Your Personalized College Counseling Journey to Your dream
                university today !
              </div>
            </div>
            <div className="col-12 col-md-4 d-flex align-items-center flex-wrap position-relative justify-content-center justify-content-sm-end">
              {/* <div className="d-none d-md-flex position-relative me-2 col-md-6">
                <select
                  className="form-select cursor coutryCode  rounded-0 rounded-start d-none d-md-flex"
                  aria-label="Default select example"
                  defaultValue={1}
                  value={coutryCode}
                  disabled
                  onChange={onChangeCoutryCode}
                >
                  {countryData?.map((item: any, index) => {
                    return (
                      <option value={item.phonecode}>
                        {item?.isoCode} +{item?.phonecode}
                      </option>
                    );
                  })}
                </select>
                <PhoneInputField
                  placeholder="Enter Your Mobile Number"
                  value={mob}
                  maxLength={"10"}
                  setValue={setPhoneNumber}
                />
              </div> */}
              <button
                className="bookSessionButton rounded fw-bold mt-2 mt-md-0 font16"
                onClick={() => setopenpopup1(true)}
              >
                Start your Counseling Journey Today
              </button>
              {!closePopup ? (
                <div
                  style={{
                    width: "330px",
                    background: "#fff",
                    padding: "15px",
                    boxShadow: "0 0 10px var(--boxshadow)",
                    position: "absolute",
                    top: "-393px",
                    right: 0,
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: 0,
                      height: 0,
                      borderLeft: "15px solid transparent",
                      borderRight: "15px solid transparent",
                      borderTop: "15px solid #f1f1f1",
                    }}
                  ></div>
                  <div className="d-flex flex-fill justify-content-end mb-3">
                    <CloseIcon
                      className=" cursor"
                      onClick={() => setClosePopup(true)}
                    />
                  </div>
                  <div className="font16 text-center fw-bold">
                    Begin Your Personalized College Counseling Journey to Your{" "}
                    <span className="textRed">dream university</span> today!
                  </div>
                  <div className="text-center my-2">
                    <img
                      src={localImages?.dsathintImg}
                      alt="dsathintImg"
                      className="dsathintImg"
                    />
                  </div>
                  <div className="text-center">
                    Book Your Trial Session With Us!
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <div
        className="position-fixed chatCollagebtn cursor mb-sm-0 mb-3 d-md-none"
        style={{ right: "20px", bottom: "40px" }}
        onClick={() => setopenpopup1(true)}
      >
        <SchoolIcon sx={{ fontSize: "30px" }} />
      </div>
    </div>
  );
}
