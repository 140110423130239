// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkoutHeight {
  height: calc(100vh - 350px);
  overflow: auto;
  scrollbar-width: none;
}

.placeOrderContentHeight {
  height: calc(100vh - 320px);
  overflow: auto;
  scrollbar-width: none;
}

.cartdiv {
  display: none;
}

.carticon:hover .cartdiv {
  display: block;
}

.cartbox {
  max-height: calc(100vh - 90px);
  overflow: auto;
  background: #fff;
  right: 154px;
  top: 43px;
}
.cartcontainer{
  max-width: 1120px;
  margin: auto;
  margin-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/cart.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,2BAA2B;EAC3B,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,8BAA8B;EAC9B,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,SAAS;AACX;AACA;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".checkoutHeight {\n  height: calc(100vh - 350px);\n  overflow: auto;\n  scrollbar-width: none;\n}\n\n.placeOrderContentHeight {\n  height: calc(100vh - 320px);\n  overflow: auto;\n  scrollbar-width: none;\n}\n\n.cartdiv {\n  display: none;\n}\n\n.carticon:hover .cartdiv {\n  display: block;\n}\n\n.cartbox {\n  max-height: calc(100vh - 90px);\n  overflow: auto;\n  background: #fff;\n  right: 154px;\n  top: 43px;\n}\n.cartcontainer{\n  max-width: 1120px;\n  margin: auto;\n  margin-top: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
