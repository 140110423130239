// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tabularBtn{
    border: 2px solid #e5e5e5; 
    cursor: pointer;
}
.tabularBtn.active,.tabularBtn:hover{
    border: 2px solid #F15D22;
}
.tablebox{
    height: 100px;
   
}
.tabularImg{
    aspect-ratio: 16/9;
    object-fit: cover;
}
.tabularData table{
    width: 100%;
    border: 1px solid var(--theme1);
}
.tabularData th{
    background: var(--bookSessionOrange);
    color: #fff;
    padding: 10px;
    border: 1px solid #fff;
}
.tabularData td{
    padding: 5px;
    border: 1px solid var(--theme1);
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/ImageTabTemplate.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa;;AAEjB;AACA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,+BAA+B;AACnC;AACA;IACI,oCAAoC;IACpC,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,YAAY;IACZ,+BAA+B;AACnC","sourcesContent":["\n.tabularBtn{\n    border: 2px solid #e5e5e5; \n    cursor: pointer;\n}\n.tabularBtn.active,.tabularBtn:hover{\n    border: 2px solid #F15D22;\n}\n.tablebox{\n    height: 100px;\n   \n}\n.tabularImg{\n    aspect-ratio: 16/9;\n    object-fit: cover;\n}\n.tabularData table{\n    width: 100%;\n    border: 1px solid var(--theme1);\n}\n.tabularData th{\n    background: var(--bookSessionOrange);\n    color: #fff;\n    padding: 10px;\n    border: 1px solid #fff;\n}\n.tabularData td{\n    padding: 5px;\n    border: 1px solid var(--theme1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
